import styled from 'styled-components';
import Button from '../components/Button';

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  padding: 1rem;
  margin: 1rem 0;

  background-color: #fff;
  border: 1px solid #d9d9d9;

  h5 {
    padding-bottom: 1rem;
  }
`;

export const PageHeader = styled.div`
  width: 100%;
  gap: 16px;
  align-items: center;
  display: inline-flex;
`;

export const RowSectionHeader = styled.div`
  display: flex;
  align-items: center;

  height: 2.875rem;

  gap: 0.75rem;
`;

export const SectionHeader = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding-bottom: 0.5rem;
`;

export const ButtonHeader = styled(Button)`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.12rem;
`;

export const Body = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Content = styled.div`
  width: 100%;
  display: inline-flex;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  padding: 1rem;
  background-color: #ffffff;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1.5rem 2.93rem;
  overflow-y: scroll;
  background-color: ${props => props.theme.colors.neutral.gray[2]};
`;
