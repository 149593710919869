import { memo } from 'react';

import { Input } from 'antd';
import { SearchProps } from 'antd/lib/input';

const Search: React.FC<SearchProps> = ({ ...props }) => {
  return <Input.Search {...props} />;
};

export default memo(Search);
