export const base = 'graphs';

export const getBarChart = `${base}/bar-chart`;
export const getStackedChart = `${base}/stacked-chart`;
export const getThreeDimChart = `${base}/three-dim-chart`;
export const getMarketShareTopPersonas = `${base}/market-share-top-persona`;
export const getTinyChart = `${base}/tiny-chart`;
export const getRadarChart = `${base}/radar-chart`;
export const getTreeMapChart = `${base}/tree-map-chart`;
export const getHorizontalChart = `${base}/horizontal-chart`;
export const getMultiAxisChart = `${base}/multi-axis-chart`;
export const getGeoHashs = `${base}/geo-hashs`;
export const getRfvChart = `${base}/rfv-chart`;
