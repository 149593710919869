/* eslint-disable react-hooks/exhaustive-deps */
import { Progress } from 'antd';
import { ProgressSize } from 'antd/lib/progress/progress';
import React, { useEffect, useState } from 'react';
import { H5 } from '../../styles/fonts';

// import { Container } from './styles';

interface ProgressBarProps {
  fileImportStatusId: number;
  size: ProgressSize;
  hasLabel?: boolean;
}

const ProgressBar = ({
  fileImportStatusId,
  size,
  hasLabel,
}: ProgressBarProps) => {
  const [percent, setPercent] = useState(0);
  const [label, setLabel] = useState('');

  const handleProgress = () => {
    switch (fileImportStatusId) {
      case 0:
        setPercent(25);
        setLabel('Iniciando');
        break;
      case 1:
        setPercent(50);
        setLabel('Validando dados');
        break;
      case 2:
        setPercent(75);
        setLabel('Enriquecendo contatos');
        break;
      case 3:
        setPercent(100);
        setLabel('Enriquecido com sucesso !!!');
        break;

        break;
      default:
        setPercent(0);
    }
  };

  useEffect(() => {
    handleProgress();
  }, [fileImportStatusId, handleProgress]);

  return (
    <>
      <Progress percent={percent} size={size} status="active" />
      {hasLabel && <H5>{label}</H5>}
    </>
  );
};

export default ProgressBar;
