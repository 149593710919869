import { Tabs } from 'antd';
import styled from 'styled-components';
import pattern from '../../assets/pattern.svg';
import { TabTitle } from '../../styles/fonts';
import GenericButton from '../Button';

export const ModalTabTitle = styled(TabTitle)`
  color: ${props => props.theme.colors.character.secondary};
  text-transform: uppercase;
`;

export const ModalImportDetails = styled.div`
  width: 57rem;
  height: 40rem;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
`;
export const Sider1 = styled.div`
  width: 2.5rem;
  height: 100%;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  background-color: ${props => props.theme.colors.primary[6]};
  //background-image: url(${pattern});
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;
export const PageHeader = styled.div`
  justify-content: space-between;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;
export const Column01 = styled.div`
  display: inline-flex;
  flex-direction: column;
`;
export const Column02 = styled.div`
  display: inline-flex;
  gap: 1rem;
  align-items: center;
`;
export const Body1 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid ${props => props.theme.colors.neutral.gray[5]};
`;
export const TabGroup = styled(Tabs).attrs({
  tabBarStyle: {
    padding: '0',
    borderRight: '1px solid #d9d9d9',
    height: '29rem',
    width: '7.625rem',
  },
})`
  .ant-tabs-tab-btn {
    white-space: normal;
    font-size: 12px;
    text-align: initial;
    padding: 0;
  }
`;

export const TabGroupPane = styled(Tabs.TabPane).attrs({})``;

export const ActionBar = styled.div`
  justify-content: space-between;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;

export const ExportFileButton = styled(GenericButton)`
  .anticon svg {
    color: ${props => props.theme.colors.neutral.gray[1]};
  }
`;

export const UpdateAnalysisButton = styled(GenericButton)``;

export const Button = styled(GenericButton)``;

export const Buttons = styled.div`
  display: inline-flex;
  gap: 0.5rem;
`;
