import { Tabs } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1.5rem 2.93rem;
  background-color: #fbfbfb;
  position: relative;
  overflow-y: scroll;
`;

export const PageHeader = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0rem 0rem 5.5rem 0rem;
`;

export const Fields = styled.div`
  display: inline-flex;
  gap: 0.5rem;
`;

export const TableImports = styled.div`
  display: inline-flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  background-color: #ffffff;
`;
export const TabGroup = styled(Tabs).attrs({
  tabBarStyle: {
    padding: '0rem 2rem 0rem 2rem',
  },
})`
  width: 100%;
`;

export const TabGroupPane = styled(Tabs.TabPane)``;
