import { memo } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../Button';

import { Container, Item } from './styles';

export interface ItemAction {
  click: string | (() => void);
  label: string;
  mouseEnter?(): void;
}

export interface Props {
  items: ItemAction[];
}

const Actions: React.FC<Props> = ({ items }) => {
  return (
    <Container>
      {items.map(item => (
        <Item key={item.label} onMouseEnter={item.mouseEnter}>
          {typeof item.click === 'string' ? (
            <Link to={item.click}>{item.label}</Link>
          ) : (
            <Button type="text" onClick={item.click}>
              {item.label}
            </Button>
          )}
        </Item>
      ))}
    </Container>
  );
};

export default memo(Actions);
