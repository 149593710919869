import React from 'react';
import { useCustomTranslation } from '../../hooks/custom-translation';
import { Body, H4 } from '../../styles/fonts';
import {
  Column01,
  Content01,
  HasNoData,
  HasNoInsights,
  Wrapper,
} from './styles';

interface OnboardReportProps {
  hasData: boolean;
  insights: boolean;
}

const OnboardReport = ({ hasData, insights }: OnboardReportProps) => {
  const { tReports } = useCustomTranslation('reports');

  return (
    <Content01>
      <Column01>
        {!insights && !hasData && (
          <>
            <HasNoInsights />
            <Wrapper>
              <H4 weight="medium">{tReports('what_do_you_want_to')}</H4>
              <Body weight="regular">
                {tReports('select_the_page,_ad_account')}
              </Body>
            </Wrapper>
          </>
        )}
        {hasData && !insights && (
          <>
            <HasNoData />
            <Wrapper>
              <H4 weight="medium">{tReports('we_didnt_find_data')}</H4>
              <Body weight="regular">
                {tReports('we_were_unable_to_generate')}
              </Body>
            </Wrapper>
          </>
        )}
      </Column01>
    </Content01>
  );
};

export default OnboardReport;
