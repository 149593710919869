import styled from 'styled-components';

import { Form } from 'antd';

import pattern from '../../../../assets/pattern.svg';

import GenericCodeButton from '../../../Button';
import GenericFormItem from '../../../FormItem';
import GenericInput from '../../../Input';
import GenericInputSwitch from '../../../InputSwitch';
import GenericText from '../../../Text';

export const LayoutPage = styled.div`
  width: 35rem;
  height: 100%;
  display: flex;
  box-shadow: 1.75rem 0.5rem rgba(0, 0, 0, 0.05), 1rem rgba(0, 0, 0, 0.08),
    0.38rem -0.25rem rgba(0, 0, 0, 0.12);
`;
export const Sider1 = styled.div`
  width: 2.5rem;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  background-color: ${props => props.theme.colors.primary[3]};
  //background-image: url(${pattern});
`;
export const Wrapper = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;
export const PageHeader = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 1rem;
  align-items: center;
`;
export const Editarplano = styled(GenericText)`
  font-weight: 500;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.7);
  flex: 1;
`;

export const AtivoSwitch = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
export const InputLabelHorizontal = styled.div`
  display: inline-flex;
  gap: 0.25rem;
  align-items: center;
  padding: 0.06rem 0rem 0.06rem 0rem;
`;
export const InputLabel = styled(GenericText)`
  font-weight: 400;
  font-size: 0.88rem;
  color: rgba(0, 0, 0, 0.7);
`;

export const SwitchBasic = styled(GenericInputSwitch)`
  position: relative;
  width: 2.75rem;
`;

export const CloseButton = styled(GenericCodeButton)`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.12rem;
`;

export const ViceriForm = styled(Form)`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1.5rem;
`;
export const Fields = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
`;
export const DescricaoFormItem = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
`;
export const DescricaoLabelVertical = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 0.25rem;
  align-items: center;
  padding: 0rem 0rem 0.5rem 0rem;
`;
export const DescricaoLabelInput = styled(GenericText)`
  font-weight: 400;
  font-size: 0.88rem;
  color: rgba(0, 0, 0, 0.7);
`;

export const DescricaoInput = styled(GenericInput)``;

export const MaxImportsFormItem = styled(GenericFormItem)``;

export const MaxImportsInput = styled(GenericInput)``;

export const MaxPotentialFormItem = styled(GenericFormItem)``;

export const MaxPotentialInput = styled(GenericInput)``;
export const ValorInput = styled(GenericInput)``;
export const ValorFormItem = styled(GenericFormItem)``;

export const ActionBar = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
`;
export const CancelButton = styled(GenericCodeButton)`
  width: 6.06rem;
  height: 2.5rem;
  font-weight: 500;
  border-radius: 0.12rem;
`;

export const SaveButton = styled(GenericCodeButton)`
  width: 4.88rem;
  height: 2.5rem;
  font-weight: 500;
  border-radius: 0.12rem;
`;
