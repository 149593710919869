import { useEffect, useState } from 'react';
import IconesMeuPlano from '../../assets/icones_meu_plano.svg';
import IconesUltimoAcesso from '../../assets/icones_ultimo_acesso.svg';
import IconesMeusContatos from '../../assets/icones_meus_contatos.svg';
import IconesMinhasConexoes from '../../assets/icones_minhas_conexoes.svg';
import { H4 } from '../../styles/fonts';
import { saveFacebookToken } from '../../helpers/localStorage';
import {
  Body1,
  IconCard,
  PageHeader,
  Row01,
  Section,
  SystemSettings,
  IconCardContent,
} from './styles';
import ProgressCard from '../../components/ProgressCard';
import { useGetFacebookTokenQuery } from '../../services/facebookAuth';
import { useGetDashboardDataQuery } from '../../services/dashboard';

const Home = () => {
  const { data: facebookLongToken } = useGetFacebookTokenQuery();
  const { data: files } = useGetDashboardDataQuery({});
  const [userData, setUserData] = useState<any[]>([]);
  const [greeting, setGreeting] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [userLastAccess, setUserLastAccess] = useState<Date | null>(null);

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) return 'Bom dia';
    if (currentHour < 18) return 'Boa tarde';
    return 'Boa noite';
  };

  useEffect(() => {
    if (facebookLongToken) {
      saveFacebookToken(facebookLongToken.accessToken as string);
    }
  }, [facebookLongToken]);

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('auth') as string);
    setUserName(authData.name);
    setUserLastAccess(new Date());
    setGreeting(getGreeting());

    if (files) {
      const pfData = files.filter((item: any) => item.customerType === 'PF');
      const pjData = files.filter((item: any) => item.customerType === 'PJ');
      setUserData([...pfData, ...pjData]);
    }
  }, [files]);

  const formatDate = (date?: Date) =>
    date ? new Date().toLocaleDateString('pt-BR') : '';

  const renderCard = (
    title: string,
    value: string | number,
    IconComponent: any,
  ) => (
    <IconCard>
      <IconCardContent>
        <h1>{value.toLocaleString('pt-BR')}</h1>
        <span>{title}</span>
        <p>
          <img src={IconComponent} alt={title} style={{ width: '40px' }} />
        </p>
      </IconCardContent>
    </IconCard>
  );

  const renderEnrichmentLeadCard = (
    count: number,
    total: number,
    type: string,
    customerType: string,
  ) => (
    <IconCard className="highlighted-card">
      <IconCardContent>
        <h1>
          {count.toLocaleString('pt-BR')}
          <span>de {total.toLocaleString('pt-BR')}</span>
        </h1>
        <span>
          {type} {customerType === 'PF' ? 'PF' : 'PJ'}
        </span>
        <p>
          <ProgressCard count={count} total={total} />
        </p>
      </IconCardContent>
    </IconCard>
  );

  return (
    <>
      <SystemSettings>
        <PageHeader>
          <H4 weight="medium">
            {greeting}, {userName}
          </H4>
        </PageHeader>

        <Body1>
          <Section>
            <Row01>
              {userData.map(user => (
                <>
                  {user.customerType === 'PF' && (
                    <>
                      {renderCard(
                        'Meu plano',
                        user.planDescription,
                        IconesMeuPlano,
                      )}
                      {renderCard(
                        'Último acesso',
                        formatDate(userLastAccess || undefined),
                        IconesUltimoAcesso,
                      )}
                      {renderCard(
                        'Meus contatos',
                        user.contacts,
                        IconesMeusContatos,
                      )}
                      {renderCard(
                        'Minhas conexões',
                        user.connections,
                        IconesMinhasConexoes,
                      )}
                    </>
                  )}
                  {renderEnrichmentLeadCard(
                    user.enrichment,
                    user.maxQuantityEnrichment,
                    'Enriquecimento',
                    user.customerType,
                  )}
                  {renderEnrichmentLeadCard(
                    user.leads,
                    user.maxQuantityLeads,
                    'Leads',
                    user.customerType,
                  )}
                </>
              ))}
            </Row01>
          </Section>
        </Body1>
      </SystemSettings>
    </>
  );
};

export default Home;
