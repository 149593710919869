import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';

import { theme } from '../styles/theme';

ConfigProvider.config({
  theme: {
    primaryColor: theme.colors.primary[6],
  },
});

export const AntdProvider: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('validationMessages');
  const validateMessages = {
    required: t('required'),
    types: {
      email: t('email'),
    },
  };
  return (
    <ConfigProvider form={{ validateMessages }} locale={ptBR}>
      {children}
    </ConfigProvider>
  );
};
