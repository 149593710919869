import styled from 'styled-components';
import Button from '../../components/Button';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const SaveButton = styled(Button)`
  /* position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
  padding: 0.5rem 1rem;
  height: fit-content; */
`;

export const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const MapLoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.2);
`;
