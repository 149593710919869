/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-properties */
import React, { useCallback, useEffect } from 'react';

import { CloseOutlined } from '@ant-design/icons';

import { useCustomTranslation } from '../../../hooks/custom-translation';
import { H3 } from '../../../styles/fonts';
import Button from '../../../components/Button';

import Form, { FormWrapper } from '../../../components/Form';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';

import {
  WizardWelcome,
  Sider1,
  Wrapper,
  PageHeader,
  ActionBar,
} from './styles';
import { ShowFormMessage } from '../../../helpers/message';
import getErrors from '../../../helpers/errors';
import {
  CustomFieldProps,
  usePostCustomFieldMutation,
} from '../../../services/customField';

interface ModalAddCustomFieldProps {
  onOk: () => void;
  onCancel: () => void;
  visible: boolean;
}

const ModalAddCustomField = ({
  onOk,
  onCancel,
  visible,
}: ModalAddCustomFieldProps) => {
  const { tWizardwelcome } = useCustomTranslation('wizardwelcome');

  const [form] = Form.useForm<CustomFieldProps>();

  const [postCustomField, configPostCustomField] = usePostCustomFieldMutation();

  const handleCancel = () => {
    onCancel();
    onOk();
    form.resetFields();
  };

  const handleSubmit = async ({ name }: CustomFieldProps) => {
    await postCustomField({
      name,
      fieldTypeId: 1,
      isEnabled: true,
    }).unwrap();
  };
  const showMessagePostCustomField = useCallback(() => {
    const { isSuccess, isError, error } = configPostCustomField;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }
    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Campo adicionado com sucesso',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
    if (isSuccess) {
      form.resetFields();
      onCancel();
    }
  }, [configPostCustomField, form, onCancel]);

  useEffect(showMessagePostCustomField, [showMessagePostCustomField]);

  return (
    <>
      <Modal
        visible={visible}
        title={null}
        footer={null}
        closable={false}
        width="32rem"
      >
        <WizardWelcome>
          <Sider1 />
          <Wrapper>
            <FormWrapper>
              <Form onFinish={handleSubmit} form={form}>
                <PageHeader>
                  <H3 weight="medium">{tWizardwelcome('add_custom_field')}</H3>
                  <Button
                    type="text"
                    icon={<CloseOutlined style={{ fontSize: 18 }} />}
                    onClick={handleCancel}
                  />
                </PageHeader>

                <Form.Item name="name" label={tWizardwelcome('field_name')}>
                  <Input
                    size="large"
                    placeholder={tWizardwelcome('internal_id')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>

                <ActionBar>
                  <Button type="default" size="large" onClick={handleCancel}>
                    {tWizardwelcome('cancel')}
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={configPostCustomField.isLoading}
                  >
                    {tWizardwelcome('create_new_field')}
                  </Button>
                </ActionBar>
              </Form>
            </FormWrapper>
          </Wrapper>
        </WizardWelcome>
      </Modal>
    </>
  );
};

export default ModalAddCustomField;
