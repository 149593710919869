import { Bar } from '@ant-design/plots';
import styled from 'styled-components';

export const LineBar = styled(Bar).attrs({
  color: '#2966F5',
  barStyle: {
    stroke: '#2966F5',
  },
  xAxis: {
    label: {
      autoHide: true,
      autoRotate: false,
      style: {
        fill: '#000',
      },
    },
  },
  yAxis: {
    label: {
      style: {
        fill: '#000',
      },
    },
  },
})``;
