import { Form } from 'antd';
import { FormListProps } from 'antd/lib/form';
import { memo } from 'react';

export const List: React.FC<FormListProps> = ({ ...props }) => {
  return <Form.List {...props} />;
};

export default memo(List);

export type { FormListProps };
