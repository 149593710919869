import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCustomTranslation } from '../../hooks/custom-translation';
import Button from '../Button';
import { H3, Body } from '../../styles/fonts';
import General from './TabGroup/GeneralTab';

import Modal from '../Modal';
import {
  ModalPersona1,
  Sider1,
  ButtonSeeAll,
  Wrapper,
  PageHeader,
  HeaderActions,
  MainInfo,
  Title,
  Description,
  Topic01,
  Topic02,
  Topic03,
  Body1,
  TabGroupPane,
  TabGroup,
} from './styles';
import Financial from './TabGroup/FinancialTab';
import Lifestyle from './TabGroup/LifestyleTab';
import Consumption from './TabGroup/ConsumptionTab';
import { PersonasProps } from '../../services/personas';

import * as CONSTANTS from '../../navigation/constants';

interface ModalPersonaProps {
  onOk: () => void;
  onCancel: () => void;
  visible: boolean;
  personaProfile: number;
  personaData: PersonasProps;
}

const ModalPersona = ({
  onOk,
  onCancel,
  visible,
  personaProfile,
  personaData,
}: ModalPersonaProps) => {
  const { tModalPersona } = useCustomTranslation('modalPersona');

  const location = useLocation();
  const navigate = useNavigate();

  const handleCancel = () => {
    onCancel();
    onOk();
  };

  const handleSeeAll = () => {
    if (location.pathname !== '/portal/personas') {
      navigate(CONSTANTS.PERSONAS);
    }

    onCancel();
  };

  return (
    <>
      <Modal
        visible={visible}
        title={null}
        footer={null}
        closable={false}
        width="60rem"
      >
        <ModalPersona1>
          <Sider1 />
          <Wrapper>
            <PageHeader>
              <img
                alt="Ilustração Persona"
                src={personaData.personas[personaProfile].img_url}
              />
              <MainInfo>
                <Title>
                  <H3 weight="medium">
                    {personaData.personas[personaProfile].name}
                  </H3>
                  <HeaderActions>
                    <ButtonSeeAll type="link" onClick={handleSeeAll}>
                      {tModalPersona('see_all_the_people')}
                    </ButtonSeeAll>
                    {/* <Button type="text" icon={<FullscreenOutlined />} /> */}
                    <Button
                      type="text"
                      icon={<CloseOutlined />}
                      onClick={handleCancel}
                    />
                  </HeaderActions>
                </Title>
                <Description>
                  <Body weight="regular">
                    {personaData.personas[personaProfile].general?.description}
                  </Body>
                  <Topic01>
                    <Body weight="medium">
                      {tModalPersona('recommended_offer')}
                    </Body>
                    <Body weight="regular">
                      {
                        personaData.personas[personaProfile].general
                          ?.reccomended_offer
                      }
                    </Body>
                  </Topic01>
                  <Topic02>
                    <Body weight="medium">
                      {tModalPersona('suggested_appeal')}
                    </Body>
                    <Body weight="regular">
                      {
                        personaData.personas[personaProfile].general
                          ?.suggested_appeal
                      }
                    </Body>
                  </Topic02>
                  <Topic03>
                    <Body weight="medium">{tModalPersona('market_size')}</Body>
                    <Body weight="regular">
                      {
                        personaData.personas[personaProfile].general
                          ?.market_size
                      }
                    </Body>
                  </Topic03>
                </Description>
              </MainInfo>
            </PageHeader>
            <Body1>
              <TabGroup tabPosition="left" type="line">
                <TabGroupPane tab={tModalPersona('general')} key="0">
                  <General
                    financialProfile={
                      personaData.personas[personaProfile].general
                        ?.financial_profile
                    }
                    consumptionProfile={
                      personaData.personas[personaProfile].general
                        ?.consumption_profile
                    }
                    personality={
                      personaData.personas[personaProfile].general?.personality
                    }
                  />
                </TabGroupPane>
                <TabGroupPane tab={tModalPersona('financiero')} key="1">
                  <Financial
                    financialQuestions={
                      personaData.personas[personaProfile].financial
                        ?.financial_behavior_questions
                    }
                  />
                </TabGroupPane>
                <TabGroupPane tab={tModalPersona('lifestyle')} key="2">
                  <Lifestyle
                    food={personaData.personas[personaProfile].lifestyle?.food}
                    lifestyle={
                      personaData.personas[personaProfile].lifestyle?.lifestyle
                    }
                    healthHabits={
                      personaData.personas[personaProfile].lifestyle
                        ?.health_habits
                    }
                    socialPoliticalOpinion={
                      personaData.personas[personaProfile].lifestyle
                        ?.social_political_opinion
                    }
                    socialMedia={
                      personaData.personas[personaProfile].lifestyle
                        ?.social_media
                    }
                    values={
                      personaData.personas[personaProfile].lifestyle?.values
                    }
                  />
                </TabGroupPane>
                <TabGroupPane tab={tModalPersona('consumption')} key="3">
                  <Consumption
                    consumptionBehaviorQuestions={
                      personaData.personas[personaProfile].consumption
                        ?.consumption_behavior
                    }
                  />
                </TabGroupPane>
              </TabGroup>
            </Body1>
          </Wrapper>
        </ModalPersona1>
      </Modal>
    </>
  );
};

export default ModalPersona;
