import styled from 'styled-components';

import MaleIconImage from '../../../../../assets/3730_124802.svg';
import FemaleIconImage from '../../../../../assets/FemaleIcon.svg';
import GenericIconImage from '../../../../../assets/GenericUserIcon.svg';

export const Avatar = styled.div`
  width: 6rem;
  height: 6rem;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 6.25rem;
  padding: 1.25rem 1.5rem;
  background-color: #e8ecfe;
`;
export const MaleIcon = styled.img.attrs({
  src: MaleIconImage,
  heigth: 'auto',
  width: '100%',
})`
  max-width: 48px;
  position: relative;
`;

export const FemaleIcon = styled.img.attrs({
  src: FemaleIconImage,
  heigth: 'auto',
  width: '100%',
})`
  max-width: 48px;
  position: relative;
`;

export const GenericIcon = styled.img.attrs({
  src: GenericIconImage,
  heigth: 'auto',
  width: '100%',
})`
  max-width: 48px;
  position: relative;
`;
