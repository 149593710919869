import styled from 'styled-components';

import LayoutPageImage from '../../assets/Page.svg';
import pattern from '../../assets/pattern.svg';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  /* background-image: url(${LayoutPageImage});
  background-color: #BECE8ECFE9FB; */
  background-color: ${props => props.theme.colors.primary[3]};
  //background-image: url(${pattern});
  background-repeat: repeat;
  background-size: 50px;

  /* object-fit: contain; */
  display: flex;
  align-items: center;
  justify-content: center;
`;
