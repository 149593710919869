import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ColumnProps } from 'antd/lib/table';
import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  // PieChartOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { PaginationProps, Tag } from 'antd';
import { useCustomTranslation } from '../../hooks/custom-translation';
import { Body } from '../../styles/fonts';
import Button from '../Button';

import { Content02, Content2, TableGroup } from './styles';

import * as CONSTANTS from '../../navigation/constants';
import {
  SegmentationProps,
  useDeleteSegmentationMutation,
  useGetAllSegmentationFilteredQuery,
} from '../../services/segmentation';
import FormInput from '../FormInput';
import { HelperDate } from '../../helpers/date';
// import { AddContactButton, FAB } from '../../pages/ContactManagement/styles';
import Dropdown from '../Dropdown';
import { ShowFormMessage } from '../../helpers/message';
import getErrors from '../../helpers/errors';
import ModalDelete from '../ModalDelete';
// import DescriptiveAnalysisModal from '../DescriptiveAnalysisModal';
import { MenuDropdown } from '../Admin/UserTab/styles';
import { DropdownButton } from '../Admin/ResalesTab/styles';
import FilterIcon from '../FilterIcon';

interface MenuDropdownProps {
  id: string;
  // dataSource: string;
}

interface ISegmentsProps {
  refetch: boolean;
}

const Segments = ({ refetch }: ISegmentsProps) => {
  const { tContactManagement } = useCustomTranslation('contactManagement');

  const [page, setPage] = React.useState(1);

  const [search, setSearch] = useState('');

  // const [segmentsSource, setSegmentsSource] = useState<string>('');
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  // const [isModalAnalysisOpen, setIsModalAnalysisOpen] = useState(false);

  const [segmentationId, setSegmentationId] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState<number>(
    window.innerHeight < 900 ? 7 : 9,
  );

  const [fetchInterval, setFetchInterval] = useState<number>(0);

  const handleChangeRowsPerPage: PaginationProps['onShowSizeChange'] = (
    current,
    pageSize,
  ) => {
    setRowsPerPage(pageSize);
  };

  const { data: segmentations, refetch: segmentsRefetch } =
    useGetAllSegmentationFilteredQuery(
      {
        skip: page,
        top: rowsPerPage,
        orderby: { createdUtc: 'desc' },
        expand: ['status', 'enrichmentRequests'],
        filter: search ? [{ filter: { name: search }, type: 'contain' }] : [],
      },
      {
        pollingInterval: fetchInterval,
      },
    );

  const [deleteSegmentation, configDeleteSegmentation] =
    useDeleteSegmentationMutation();

  const navigate = useNavigate();

  const handleShowEditSegment = (id: string) => {
    navigate(`${CONSTANTS.EDIT_SEGMENT}/${id}`);
  };

  const showMessageDeleteSegmentation = useCallback(() => {
    const { isSuccess, isError, error } = configDeleteSegmentation;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }
    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Segmentação excluída com sucesso',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
  }, [configDeleteSegmentation]);

  useEffect(showMessageDeleteSegmentation, [showMessageDeleteSegmentation]);

  useEffect(() => {
    return () => setSearch('');
  }, []);

  useEffect(() => {
    const unreadySegments = segmentations?.data.filter(
      seg => seg.segmentationStatusId !== 4 && seg.segmentationStatusId !== 6,
    );

    if (unreadySegments?.length) {
      return setFetchInterval(120000);
    }

    return setFetchInterval(0);
  }, [segmentations?.data]);

  const handleInputSearch = (e: any) => {
    setSearch(e.target.value);
  };

  const handleShowModalDelete = useCallback((id: string) => {
    setSegmentationId(id);
    setIsModalDeleteOpen(true);
  }, []);

  const handleCloseModalDelete = useCallback(() => {
    setIsModalDeleteOpen(false);
  }, []);

  // const handleCloseModalAnalysis = useCallback(() => {
  //   setIsModalAnalysisOpen(false);
  // }, []);

  // const handleShowModalAnalysis = useCallback(
  //   ({ id }: { id: string; dataSource: string }) => {
  //     setSegmentationId(id);
  //     // setSegmentsSource(dataSource);
  //     // setIsModalAnalysisOpen(true);
  //   },
  //   [],
  // );

  const MenuDropDown = ({ id }: MenuDropdownProps) => {
    return (
      <MenuDropdown
        items={[
          // {
          //   key: '1',
          //   label: (
          //     <Button
          //       type="link"
          //       onClick={() => handleShowModalAnalysis({ id, dataSource })}
          //       icon={<PieChartOutlined />}
          //     >
          //       {tContactManagement('descriptive_analysis')}
          //     </Button>
          //   ),
          // },
          // {
          //   type: 'divider',
          // },
          {
            key: '2',
            label: (
              <Button
                type="link"
                onClick={() => handleShowModalDelete(id)}
                style={{ color: 'red' }}
                icon={<DeleteOutlined style={{ color: 'red' }} />}
              >
                {tContactManagement('delete_segment')}
              </Button>
            ),
          },
        ]}
      />
    );
  };

  const tagColor = [
    'default',
    'success',
    'warning',
    'success',
    'warning',
    'erro',
  ];

  const tagIcon = [
    <ExclamationCircleOutlined />,
    <CheckCircleOutlined />,
    <SyncOutlined spin />,
    <CheckCircleOutlined />,
    <SyncOutlined spin />,
    <CloseCircleOutlined />,
  ];

  const columnsTableGroup: ColumnProps<any>[] = [
    {
      title: tContactManagement('name'),
      dataIndex: 'name',
      align: 'left',
      width: '35%',
      sorter: (a, b) => a.name.localeCompare(b.name),
      filterDropdown: () => (
        <FormInput
          placeholder={tContactManagement('name')}
          onChange={handleInputSearch}
        />
      ),
      filterIcon: () => <FilterIcon isFiltered={!!search} />,
    },
    {
      title: tContactManagement('active_contacts'),
      dataIndex: 'estimatedContactsCount',
      align: 'left',
      width: '25%',
      sorter: (a, b) => a.estimatedContactsCount - b.estimatedContactsCount,
    },
    {
      title: tContactManagement('last_update'),
      dataIndex: 'modifiedUtc',
      align: 'left',
      width: '25%',
      sorter: (a, b) =>
        new Date(a.modifiedUtc).getTime() - new Date(b.modifiedUtc).getTime(),
      render: (text: Date) => (
        <Body weight="regular">{HelperDate.formateDate(text)}</Body>
      ),
    },
    {
      title: tContactManagement('analysis_status'),
      dataIndex: 'statusDaAnalise',
      align: 'left',
      width: '25%',
      render: (_, record: SegmentationProps) => {
        const hasEnrichmentsRequests = record?.enrichmentRequests.length >= 1;

        return (
          <Tag
            color={tagColor[hasEnrichmentsRequests ? 3 : record.status.id - 1]}
            icon={tagIcon[hasEnrichmentsRequests ? 3 : record.status.id - 1]}
          >
            {record.status.friendlyName}
          </Tag>
        );
      },
      sorter: (a, b) =>
        a.status.friendlyName.localeCompare(b.status.friendlyName),
    },
    {
      title: tContactManagement('actions'),
      dataIndex: 'acoes',
      align: 'center',
      width: '25%',
      render: (_, record: SegmentationProps) => (
        <Content2>
          <Button
            type="default"
            icon={<EditOutlined />}
            onClick={() => handleShowEditSegment(record.id)}
          >
            {tContactManagement('edit')}
          </Button>
          <Dropdown overlay={<MenuDropDown id={record.id} />}>
            <DropdownButton type="default" icon={<MoreOutlined />} />
          </Dropdown>
        </Content2>
      ),
    },
  ];

  const handleDeleteSegmentation = async () => {
    await deleteSegmentation(segmentationId).unwrap();
  };

  useEffect(() => {
    if (refetch) {
      segmentsRefetch();
    }
  }, [refetch]);

  return (
    <>
      <Content02>
        <TableGroup
          dataSource={segmentations?.data}
          columns={columnsTableGroup}
          rowKey="id"
          pagination={{
            defaultPageSize: rowsPerPage,
            onShowSizeChange: handleChangeRowsPerPage,
            total: segmentations?.meta?.size,
            onChange: currentPage => setPage(currentPage),
          }}
        />
        <ModalDelete
          visible={isModalDeleteOpen}
          onCancel={handleCloseModalDelete}
          title="Excluir segmentação"
          deleteButton="Excluir segmentação"
          onOk={handleDeleteSegmentation}
        >
          Ao executar a exclusão, todos os registros desta segmentação serão
          removidos do sistema.
        </ModalDelete>
      </Content02>
    </>
  );
};

export default Segments;
