import { createGlobalStyle } from 'styled-components';

import 'antd/dist/antd.variable.min.css';

const GlobalStyle = createGlobalStyle`

  * {
   margin:0;
   padding: 0;
   outline: 0;
   box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0px;
    color: inherit;
    font-weight: 500;
}

ul {
  margin-bottom: 0;
}

#inputSearch::placeholder {
  opacity: 70%;
}

.ant-form-item-control-input {
  min-height: 0px !important;
}

  body {
    -webkit-font-smoothing: antialiased;
    font-family: 'IBM Plex Sans', sans-serif;
    color: ${props => props.theme.colors.character.primary};
    background-color: ${props => props.theme.colors.neutral.gray[2]};
  }

  a {
    text-decoration:none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
    padding: 0px;
  }

  .ant-form-item {
    margin-bottom: 0px !important;
  }

  .ant-btn{
   // padding: 0px !important;
  }

  .ant-menu-sub.ant-menu-inline, .ant-menu-submenu-arrow, .ant-menu .ant-menu-item, .ant-menu{
    background: inherit;
    color: inherit;
  }

  .ant-layout {
    background: inherit;
  }

  .ant-layout-header{
	background: transparent !important;
	line-height: normal !important;
  }

  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0 !important;
  }

  .salesbox-upload, .salesbox-upload > span {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-table-cell {
    background-color: transparent !important
  }

  .ant-tabs-nav-list {
    text-transform: uppercase;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-family: 'IBM Plex Sans';
    font-weight: 700;

  }

  .ant-table-column-title, .ant-table-thead>tr>th  {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.45);
  }

  .ant-tabs-tabpane {
    padding: 0 16px 16px 16px;
  }
/*
  .ant-pagination-item-active a {
   font-weight: 700;
   width: 32px;
   height: 32px;
   border: 0.063rem solid #2966F5;
   border-radius: 0.12rem;
  } */

  .ant-btn-link, .ant-btn-link:hover{
  color: inherit;
}

.ant-col-24.ant-form-item-label>label, .ant-col-xl-24.ant-form-item-label>label, .ant-form-vertical .ant-form-item-label>label {
  margin-bottom: 8px;
}

.eAjoa-d {
  border: none;
}

.ant-tree-switcher-leaf-line {
  display: none;
}

.ant-tree-switcher-noop {
  width: 7px;
}

.ant-input-number {
  width: 100%;
}

.ant-message {
  bottom: 20px;
  top: initial;
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
  left: 0;
}
.ant-steps-vertical>.ant-steps-item {
flex: 0.06 0 auto !important
}

.budget_input > .ant-input-number-input-wrap {
  color: "#2966F5" !important;
  /* font-size: 25px; */
}

.budget_input > .ant-input-number-input-wrap > input {
  color: "#2966F5" !important;
}

iframe {
  overflow: hidden !important;
}

`;

export default GlobalStyle;
