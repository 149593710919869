import { FileExportPjRoute } from '../../config/services-route';
// import { URLs } from '../../helpers/urls';
import { TagTypes } from '../types';
// import { providesList } from '../../helpers/provides';
// import { FileExportProps } from './fileExportPj';
// import { builderQueryOdata, BuilderQueryProps } from '../../helpers/odata';
import { deepDiveApi } from '../../config/apis/deep-dive-query';
import { providesList } from '../../helpers/provides';

const fileExportApi = deepDiveApi.injectEndpoints({
  endpoints: builder => ({
    getAllFilesExportsPj: builder.query<any, any>({
      query: () => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: FileExportPjRoute.getAllDataFileExportPj,
        };
      },
      providesTags: response =>
        providesList(response?.data, TagTypes.FileImport),
      transformResponse: (response: any): any => {
        return {
          data: response,
          meta: {
            size: response['@odata.count'],
          },
        };
      },
    }),
    // getDownloadFileExport: builder.query<
    //   FileExportProps,
    //   Pick<FileExportProps, 'id'>
    // >({
    //   query: ({ id }) => {
    //     const authData = JSON.parse(localStorage?.getItem('auth') as string);

    //     return {
    //       headers: {
    //         CustomerId: authData.customer,
    //       },
    //       url: URLs.combineOdataUrl(FileExportRoute.fileExport, id),
    //     };
    //   },
    // }),
    // getFileExportById: builder.query<FileExportProps, string>({
    //   query: id => {
    //     const authData = JSON.parse(localStorage?.getItem('auth') as string);

    //     return {
    //       headers: {
    //         CustomerId: authData.customer,
    //       },
    //       url: URLs.combine(FileExportRoute.fileExport, `${id}`),
    //     };
    //   },
    //   providesTags: (response: any) =>
    //     providesList(response, TagTypes.FileExport),
    // }),
    postFileExportPj: builder.mutation<any, any>({
      query: body => {
        console.log('body: ', body);
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        console.log('authData: ', authData);
        return {
          url: FileExportPjRoute.fileExportPj,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: TagTypes.FileExport, id: 'LIST' }],
    }),
    // updateFileExport: builder.mutation<
    //   FileExportProps,
    //   Partial<FileExportProps>
    // >({
    //   query: ({ id, ...body }) => {
    //     return {
    //       url: URLs.combine(FileExportRoute.fileExport, `${id}`),
    //       method: 'PATCH',
    //       body,
    //     };
    //   },
    //   invalidatesTags: [{ type: TagTypes.FileExport, id: 'LIST' }],
    // }),
    deleteFileExportPj: builder.mutation<any, any>({
      query: id => {
        console.log('id: ', id);
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: `${FileExportPjRoute.deleteFileExportPjById}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: TagTypes.FileExport, id: 'LIST' }],
    }),
    getPresignedUrlFileExportPj: builder.query<any, any>({
      query: ({ fileExportId }) => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: FileExportPjRoute.downloadExportFilePj,
          params: { fileExportId },
        };
      },
      // providesTags: response => {
      //   return providesList(response?.url, TagTypes.FileImport);
      // },
      transformResponse: (response: any): any => response.url,
    }),
  }),
});
export const {
  // useGetAllFileExportQuery,
  // useGetFileExportByIdQuery,
  // useGetDownloadFileExportQuery,
  useGetAllFilesExportsPjQuery,
  // usePostFileExportMutation,
  // useUpdateFileExportMutation,
  useDeleteFileExportPjMutation,
  usePostFileExportPjMutation,
  useGetPresignedUrlFileExportPjQuery,
} = fileExportApi;
