import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1.5rem 2.93rem;
  background-color: #fbfbfb;
  position: relative;
  overflow-y: scroll;
`;

export const PageHeader = styled.div`
  width: 100%;
  display: inline-flex;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0rem 0rem 5.5rem 0rem;
`;

export const Content = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 1.5rem;
  align-items: center;
  padding: 2rem;
  background-color: #ffffff;

  button {
    font-size: 1rem;
    line-height: 1.5rem;
    min-height: auto;
    height: auto;
  }
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 1rem;
  justify-content: center;
`;
