import { salesBoxApi } from '../../config/apis';
import { FacebookAuth } from '../../config/services-route';
import { URLs } from '../../helpers/urls';
import { TagTypes } from '../types';
import { FacebookAuthPostResponse } from './facebookAuth';

const facebookCampaignApi = salesBoxApi.injectEndpoints({
  endpoints: builder => ({
    getFacebookToken: builder.query<any, void>({
      query: () => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: `${FacebookAuth.facebookAuth}?granttype=user`,
        };
      },
    }),
    postFacebookToken: builder.mutation<FacebookAuthPostResponse, string>({
      query: accessToken => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: FacebookAuth.facebookAuth,
          method: 'POST',
          body: {
            accessToken,
          },
        };
      },
      invalidatesTags: [{ type: TagTypes.FacebookCampaign, id: 'LIST' }],
    }),
    deleteFacebookToken: builder.mutation<void, string>({
      query: id => {
        return {
          url: URLs.combine(FacebookAuth.facebookAuth, `${id}`),
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: TagTypes.FacebookCampaign, id: 'LIST' }],
    }),
  }),
});

export const {
  useDeleteFacebookTokenMutation,
  usePostFacebookTokenMutation,
  useGetFacebookTokenQuery,
} = facebookCampaignApi;
