import { salesBoxApi } from '../../config/apis';
import { ContactRoute } from '../../config/services-route';
import { URLs } from '../../helpers/urls';
import { TagTypes, WithPagination } from '../types';
import { providesList } from '../../helpers/provides';
import { ContactProps } from './contact';
import { builderQueryOdata, BuilderQueryProps } from '../../helpers/odata';

const contactApi = salesBoxApi.injectEndpoints({
  endpoints: builder => ({
    getAllContact: builder.query<ContactProps[], void>({
      query: () => ({
        url: ContactRoute.contact,
      }),
      providesTags: response => providesList(response, TagTypes.Contact),
    }),
    getAllContactsFiltered: builder.query<
      WithPagination<ContactProps[]>,
      BuilderQueryProps<Partial<ContactProps>>
    >({
      query: props => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: URLs.combineOdataUrl(
            ContactRoute.getQueryODATA,
            builderQueryOdata<Partial<ContactProps>>(props),
          ),
        };
      },
      providesTags: response => providesList(response?.data, TagTypes.Contact),
      transformResponse: (response: any): any => {
        return {
          data: response.value,
          meta: {
            size: response['@odata.count'],
          },
        };
      },
    }),
    getContactById: builder.query<ContactProps, string>({
      query: id => ({
        url: URLs.combine(ContactRoute.contact, `${id}`),
      }),
      providesTags: (response: any) => providesList(response, TagTypes.Contact),
    }),
    getContactByDocument: builder.query<ContactProps, Pick<ContactProps, 'id'>>(
      {
        query: ({ id }) => {
          const authData = JSON.parse(localStorage?.getItem('auth') as string);
          return {
            headers: {
              CustomerId: authData.customer,
            },
            url: URLs.combineOdataUrl(ContactRoute.contact, id),
          };
        },
      },
    ),
    postContact: builder.mutation<ContactProps, Partial<ContactProps>>({
      query: body => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: ContactRoute.contact,

          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: TagTypes.Contact, id: 'LIST' }],
    }),
    updateContact: builder.mutation<ContactProps, Partial<ContactProps>>({
      query: ({ id, ...body }) => {
        return {
          url: URLs.combine(ContactRoute.contact, `${id}`),
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [{ type: TagTypes.Contact, id: 'LIST' }],
    }),
    deleteContact: builder.mutation<ContactProps, string>({
      query: id => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: URLs.combine(ContactRoute.contact, `${id}`),
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: TagTypes.Contact, id: 'LIST' }],
    }),
  }),
});
export const {
  useGetAllContactQuery,
  useGetContactByIdQuery,
  useGetContactByDocumentQuery,
  usePostContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,
  useGetAllContactsFilteredQuery,
} = contactApi;
