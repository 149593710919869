export const combine = (...args: string[]): string => {
  return args.map(url => url.replace(/\/+$/, '')).join('/');
};

export const combineFilters = (...args: string[]): string => {
  return args.map(url => url).join('');
};

export const combineOdataUrl = (...args: string[]): string => {
  return args
    .map(url => url)
    .join('')
    .replace('&$', '');
};
