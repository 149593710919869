import { Typography } from 'antd';
import styled, { css } from 'styled-components';

type FontWeightVariations = 'regular' | 'medium' | 'bold';

interface TextStylesProps {
  weight?: FontWeightVariations;
  color?: string;
}

interface HeadingConfig {
  fontSize: string;
  lineHeight: string;
  color?: string;
  variations: Array<FontWeightVariations>;
}

const fontWeightMap: { [key in FontWeightVariations]: string } = {
  regular: '400',
  medium: '500',
  bold: '700',
};

const generateTextStyle = (
  props: TextStylesProps,
  { variations, fontSize, lineHeight }: HeadingConfig,
) => {
  return css`
    font-style: normal;
    font-size: ${fontSize};
    line-height: ${lineHeight};
    color: ${props.color};

    ${variations.map(
      variation =>
        props.weight === variation &&
        css`
          font-weight: ${fontWeightMap[variation]};
        `,
    )}
  `;
};

/**
 * H1/Regular
 * H1/Medium
 *
 * @example
 * <H1 regular>H1/Regular</H1>
 * <H1 weight="medium">H1/Medium</H1>
 */
export const H1 = styled.h1<TextStylesProps>`
  ${props =>
    generateTextStyle(props, {
      fontSize: '1.875rem',
      lineHeight: '2.375rem',
      variations: ['regular', 'medium'],
      color: props.color ? props.color : 'inherit',
    })}
`;

/**
 * H2/Regular
 * H2/Medium
 *
 * @example
 * <H2 regular>H2/Regular</H2>
 * <H2 medium>H2/Medium</H2>
 */
export const H2 = styled.h2<TextStylesProps>`
  ${props =>
    generateTextStyle(props, {
      fontSize: '1.875rem',
      lineHeight: '2.375rem',
      variations: ['regular', 'medium'],
      color: props.color ? props.color : 'inherit',
    })}
`;

/**
 * H3/Regular
 * H3/Medium
 *
 * @example
 * <H3 regular>H3/Regular</H3>
 * <H3 medium>H3/Medium</H3>
 */
export const H3 = styled.h3<TextStylesProps>`
  ${props =>
    generateTextStyle(props, {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      variations: ['regular', 'medium'],
      color: props.color ? props.color : 'inherit',
    })};
`;

/**
 * H4/Regular
 * H4/Medium
 *
 * @example
 * <H4 regular>H4/Regular</H4>
 * <H4 medium>H4/Medium</H4>
 */
export const H4 = styled.h4<TextStylesProps>`
  ${props =>
    generateTextStyle(props, {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      variations: ['regular', 'medium'],
      color: props.color ? props.color : 'inherit',
    })}
`;

/**
 * H5/Regular
 * H5/Medium
 * H5/Bold
 *
 * @example
 * <H5 weight="regular">H5/Regular</H5>
 * <H5 weight="medium">H5/Medium</H5>
 * <H5 bold>H5/Bold</H5>
 */
export const H5 = styled.h5<TextStylesProps>`
  ${props =>
    generateTextStyle(props, {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      variations: ['regular', 'medium', 'bold'],
      color: props.color ? props.color : 'inherit',
    })}
`;

/**
 * Body/Regular
 * Body/Medium
 * Body/Bold
 *
 * @example
 * <Body weight="regular">Body/Regular</Body>
 * <Body weight="medium">Body/Medium</Body>
 * <Body weight="bold">Body/Bold</Body>
 */
export const Body = styled(Typography.Text)<TextStylesProps>`
  color: inherit;
  ${props =>
    generateTextStyle(props, {
      fontSize: '0.875rem',
      lineHeight: '1.375rem',
      variations: ['regular', 'medium', 'bold'],
      color: props.color ? props.color : 'inherit',
    })}
`;
/**
 * TabTitle
 *
 * @example
 * <TabTitle weight="regular">TabTitle/Regular</TabTitle>
 * <TabTitle weight="medium">TabTitle/Medium</TabTitle>
 * <TabTitle weight="bold">TabTitle/Bold</TabTitle>
 */
export const TabTitle = styled(Typography.Text)<TextStylesProps>`
  color: inherit;
  ${props =>
    generateTextStyle(props, {
      fontSize: '0.75rem',
      lineHeight: '1.375rem',
      variations: ['regular', 'medium', 'bold'],
      color: props.color ? props.color : 'inherit',
    })}
`;

/**
 * Footnote/Description
 *
 * @example
 * <Footnote>Footnote/Description</Footnote>
 */
export const Footnote = styled(Typography.Text)`
  font-weight: 400;
  font-size: 0.75rem;
`;
