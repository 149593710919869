import styled from 'styled-components';
import { Checkbox } from 'antd';
import GenericText from '../../Text';
import GenericCodeButton from '../../Button';

export const Container = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;

export const DownloadExempleText = styled(GenericText)`
  font-weight: 400;
  font-size: 0.88rem;
  color: ${props => props.theme.colors.character.primary};
`;

export const CheckboxOption = styled(Checkbox)`
  color: ${props => props.theme.colors.character.primary};
`;

export const DownloadLink = styled.a`
  color: ${props => props.theme.colors.daybreak.blue[6]};
  font-weight: bold;
  margin: 0 4px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`;
export const ButtonNextStep = styled(GenericCodeButton)`
  width: 12rem;
  height: 2.5rem;
  border-radius: 0.12rem;
`;

export const ButtonBetween = styled.div`
  display: flex;
`;

export const DownloadTemplateButton = styled.button`
  padding: 10px 20px;
  font-size: 0.88rem;
  font-weight: 400;
  color: ${props => props.theme.colors.character.primary};
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 17rem;
`;
