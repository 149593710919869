import React, { useState } from 'react';

import { SettingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCustomTranslation } from '../../hooks/custom-translation';
import { H3, H5 } from '../../styles/fonts';
import InputSearch from '../InputSearch';
import Button from '../Button';

import { ClienteChoice, Wrapper, PageHeader, TopBar, Content1 } from './styles';
import { useGetAllCustomerFilteredQuery } from '../../services/customer';
import { useAuth } from '../../hooks/auth';

import * as CONSTANTS from '../../navigation/constants';
import { salesBoxApi } from '../../config/apis';

const CustomerChoice = () => {
  const { tCustomerChoice } = useCustomTranslation('customerChoice');

  const [search, setSearch] = useState('');

  const { userInfo, updateUserInfoWithClienteId } = useAuth();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { data: customers } = useGetAllCustomerFilteredQuery({
    custom: `$filter=cast(resaleId, Edm.String) eq '${userInfo.resale}' ${
      search ? `and contains(name, '${search}')` : ''
    }`,
    top: 100,
  });

  const handleSaveCustomerId = (id: string) => {
    dispatch(salesBoxApi.util.resetApiState());
    updateUserInfoWithClienteId(id);
    navigate(CONSTANTS.DASHBOARD);
  };

  const handleSettingsButton = () => {
    navigate(CONSTANTS.SYSTEM_SETTINGS);
  };

  return (
    <>
      <ClienteChoice>
        <Wrapper>
          <PageHeader>
            <H3 weight="medium">
              {tCustomerChoice('choose_the_desired_customer')}
            </H3>
          </PageHeader>
          <TopBar>
            <InputSearch
              style={{ width: '320px' }}
              type="primary"
              size="large"
              placeholder={tCustomerChoice('search_for_customer')}
              allowClear
              enterButton
              onChange={(e: any) => setSearch(e.target.value)}
            />
            <Button
              type="default"
              icon={<SettingOutlined />}
              size="large"
              onClick={handleSettingsButton}
            >
              {tCustomerChoice('system_settings')}
            </Button>
          </TopBar>
          <Content1>
            {customers?.data.map(customer => {
              return (
                <Button
                  key={customer.id}
                  type="default"
                  style={{
                    width: '100%',
                    height: '64px',
                  }}
                  size="large"
                  onClick={() => handleSaveCustomerId(customer.id)}
                >
                  <H5 weight="medium" color="" style={{ textAlign: 'start' }}>
                    {customer.name}
                  </H5>
                </Button>
              );
            })}
          </Content1>
        </Wrapper>
      </ClienteChoice>
    </>
  );
};

export default CustomerChoice;
