import { salesBoxApi } from '../../../config/apis';
import { PotentialAudienceRoute } from '../../../config/services-route';
import { URLs } from '../../../helpers/urls';
import { TagTypes, WithPagination } from '../../types';
import { providesList } from '../../../helpers/provides';
// eslint-disable-next-line import/no-cycle
import {
  IFilters,
  IPotentialAudienceResult,
  PotentialAudienceProps,
} from './potentialAudience';
import { builderQueryOdata, BuilderQueryProps } from '../../../helpers/odata';

const potentialAudienceApi = salesBoxApi.injectEndpoints({
  endpoints: builder => ({
    getAllPotentialAudience: builder.query<PotentialAudienceProps[], void>({
      query: () => ({
        url: PotentialAudienceRoute.potentialAudience,
      }),
      providesTags: response =>
        providesList(response, TagTypes.PotentialAudience),
    }),
    getAllPotentialAudienceFiltered: builder.query<
      WithPagination<PotentialAudienceProps[]>,
      BuilderQueryProps<Partial<PotentialAudienceProps>>
    >({
      query: props => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: URLs.combineOdataUrl(
            PotentialAudienceRoute.getQueryODATA,
            builderQueryOdata<Partial<PotentialAudienceProps>>(props),
          ),
        };
      },
      providesTags: response =>
        providesList(response?.data, TagTypes.PotentialAudience),
      transformResponse: (response: any): any => {
        return {
          data: response.value,
          meta: {
            size: response['@odata.count'],
          },
        };
      },
    }),
    getPotentialAudienceById: builder.query<PotentialAudienceProps, string>({
      query: id => ({
        url: URLs.combine(
          PotentialAudienceRoute.savePotentialAudience,
          `${id}`,
        ),
      }),
    }),
    postPotentialAudience: builder.mutation<
      IPotentialAudienceResult,
      Omit<IFilters, 'id'>
    >({
      query: body => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: PotentialAudienceRoute.potentialAudience,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: TagTypes.PotentialAudience, id: 'LIST' }],
    }),
    postSavePotentialAudience: builder.mutation<
      PotentialAudienceProps,
      Omit<IFilters, 'id'>
    >({
      query: body => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: PotentialAudienceRoute.savePotentialAudience,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: TagTypes.PotentialAudience, id: 'LIST' }],
    }),
    updatePotentialAudience: builder.mutation<
      PotentialAudienceProps,
      Partial<PotentialAudienceProps>
    >({
      query: ({ id, ...body }) => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: URLs.combine(
            PotentialAudienceRoute.savePotentialAudience,
            `${id}`,
          ),
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [{ type: TagTypes.PotentialAudience, id: 'LIST' }],
    }),
    deletePotentialAudience: builder.mutation<PotentialAudienceProps, string>({
      query: id => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: URLs.combine(
            PotentialAudienceRoute.savePotentialAudience,
            `${id}`,
          ),
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: TagTypes.PotentialAudience, id: 'LIST' }],
    }),
  }),
});
export const {
  useGetAllPotentialAudienceQuery,
  useGetAllPotentialAudienceFilteredQuery,
  useGetPotentialAudienceByIdQuery,
  usePostPotentialAudienceMutation,
  usePostSavePotentialAudienceMutation,
  useUpdatePotentialAudienceMutation,
  useDeletePotentialAudienceMutation,
} = potentialAudienceApi;
