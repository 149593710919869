import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  // EditOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  NotificationOutlined,
  PlusOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { format } from 'date-fns';
import { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, PaginationProps, Tag } from 'antd';
import {
  useGetAllPotentialAudienceFilteredQuery,
  useDeletePotentialAudienceMutation,
} from '../../services/map/potentialAudience';
import { Body, H3 } from '../../styles/fonts';
import {
  ActionButtons,
  CreateCampaingButton,
  CreatePotentialAudienceButton,
  Main,
  PageHeader,
  PlusButton,
  PotentialAudienceContainer,
  PotentialAudienceTable,
  TableImports,
  TabGroup,
  TabGroupPane,
} from './styles';
import ExportsTabPotentialAudience from '../../components/ExportsPotentialAudience';
import * as CONSTANTS from '../../navigation/constants';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import { PotentialAudienceStatusProps } from '../../services/map/potentialAudienceStatus';
import getErrors from '../../helpers/errors';
import { ShowFormMessage } from '../../helpers/message';
import FormInput from '../../components/FormInput';
import { useCustomTranslation } from '../../hooks/custom-translation';
import FilterIcon from '../../components/FilterIcon';
import ModalExportPotentialAudience from './ModalExportPotentialAudience';

interface IMenu {
  id: string;
  dataSource: string;
}

interface ITagColor {
  [key: string]: string;
}

interface ITagIcon {
  [key: string]: JSX.Element;
}

const PotentialAudiencePage = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const [potentialAudienceId, setPotentialAudienceId] = useState<string>('');
  const [fileImportSource, setFileImportSource] = useState<string>('');

  const [rowsPerPage, setRowsPerPage] = useState<number>(
    window.innerHeight < 900 ? 7 : 9,
  );
  const { tPotentialAudience } = useCustomTranslation('potentialAudience');

  const handleChangeRowsPerPage: PaginationProps['onShowSizeChange'] = (
    current,
    pageSize,
  ) => {
    setRowsPerPage(pageSize);
  };

  const {
    data: potentialAudience,
    refetch: potentialAudienceRefetch,
    isLoading: potentialAudienceLoading,
  } = useGetAllPotentialAudienceFilteredQuery({
    top: rowsPerPage,
    skip: page,
    expand: ['status'],
    orderby: { createdUtc: 'desc' },
    custom: `$filter=contains(tolower(name), '${search}')`,
  });

  const [deletePotentialAudience, configDeletePotentialAudience] =
    useDeletePotentialAudienceMutation();

  const [defaultTab, setDefaultTab] = useState<string>('1');
  const [, setLoading] = useState<boolean>(false);
  const [refetchExports, setRefetchExports] = useState<boolean>(false);

  const showDeletePotentialAudienceMessage = useCallback(() => {
    const { isSuccess, isError, error } = configDeletePotentialAudience;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }
    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Público potencial exlcuído com sucesso',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
  }, [configDeletePotentialAudience]);

  useEffect(showDeletePotentialAudienceMessage, [
    showDeletePotentialAudienceMessage,
  ]);

  const handleRefetch = () => {
    if (defaultTab === '1') {
      potentialAudienceRefetch();
    } else {
      setRefetchExports(true);
    }
  };

  useEffect(() => {
    if (refetchExports) {
      setRefetchExports(false);
    }
  }, [refetchExports]);
  const handleCreatePotentialAudience = () => {
    navigate(`${CONSTANTS.CREATE_POTENTIAL_AUDIENCE}`);
  };

  const handleInputSearch = (e: any) => {
    setSearch(e.target.value.toLowerCase());
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleShowModalExportFile = useCallback(({ id, dataSource }: IMenu) => {
    setPotentialAudienceId(id);
    setFileImportSource(dataSource);
    setIsModalOpen(true);
  }, []);

  const handleCloseModalExportFile = useCallback(() => {
    setIsModalOpen(false);
    setPotentialAudienceId('');
  }, []);

  // const handleEditPotentialAudience = (id: string) => {
  //   navigate(`${CONSTANTS.EDIT_POTENTIAL_AUDIENCE}/${id}`);
  // };

  const MenuDrop = ({ id, dataSource }: IMenu) => (
    <Menu
      items={[
        // {
        //   key: '0',

        //   label: (
        //     <Button
        //       type="link"
        //       onClick={() => handleEditPotentialAudience(id)}
        //       icon={<EditOutlined />}
        //     >
        //       Editar público
        //     </Button>
        //   ),
        // },
        {
          key: '1',
          label: (
            <Button
              type="link"
              onClick={() => handleShowModalExportFile({ id, dataSource })}
              icon={<DownloadOutlined />}
            >
              Exportar público
            </Button>
          ),
        },
        {
          key: '0',
          label: (
            <Button
              type="link"
              onClick={() => deletePotentialAudience(id)}
              style={{ color: 'red' }}
              icon={<DeleteOutlined style={{ color: 'red' }} />}
            >
              Deletar público
            </Button>
          ),
        },
      ]}
    />
  );

  const tagColor: ITagColor = {
    Processando: 'warning',
    'Pronto para campanha': 'success',
    Erro: 'error',
  };

  const tagIcon: ITagIcon = {
    Criado: <ExclamationCircleOutlined />,
    Processando: <SyncOutlined spin />,
    'Pronto para campanha': <CheckCircleOutlined />,
    Erro: <CloseCircleOutlined />,
  };

  const columnsPotentialAudience: ColumnProps<any>[] = [
    {
      title: 'Nome',
      dataIndex: 'name',
      align: 'left',
      width: '20rem ',
      sorter: (a, b) => a?.name?.length - b?.name?.length,
      filterDropdown: () => (
        <FormInput placeholder="Nome" onChange={handleInputSearch} />
      ),
      filterIcon: () => <FilterIcon isFiltered={!!search} />,
    },
    {
      title: 'Contatos estimados',
      dataIndex: 'estimatedContactsCount',
      align: 'left',
      width: '16.46rem',
      sorter: (a, b) => a?.name?.length - b?.name?.length,
      render: value => <Body>{value.toLocaleString('pt-BR')}</Body>,
    },
    {
      title: 'Última atualização',
      dataIndex: 'modifiedUtc',
      align: 'left',
      width: '16.46rem',
      sorter: (a, b) => a?.name?.length - b?.name?.length,
      render: value => <Body>{format(new Date(value), 'dd/MM/yy HH:MM')}</Body>,
    },
    {
      title: 'Tipo Cliente',
      dataIndex: 'customerType',
      align: 'left',
      width: '16.46rem',
      sorter: (a, b) => a?.name?.length - b?.name?.length,
      filterDropdown: () => (
        <FormInput placeholder="Tipo Cliente" onChange={handleInputSearch} />
      ),
      filterIcon: () => <FilterIcon isFiltered={!!search} />,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'left',
      width: '16.46rem',
      sorter: (a, b) => a?.name?.length - b?.name?.length,
      render: ({ friendlyName }: PotentialAudienceStatusProps) => (
        <Tag color={tagColor[friendlyName]} icon={tagIcon[friendlyName]}>
          {friendlyName}
        </Tag>
      ),
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      align: 'center',
      width: '12.56rem',
      sorter: (a, b) => a?.name?.length - b?.name?.length,
      render: (_, record) => (
        <ActionButtons>
          <CreateCampaingButton
            onClick={() => navigate(CONSTANTS.PUBLIC_MANAGEMENT)}
            icon={<NotificationOutlined />}
          >
            Criar conexão de audiência
          </CreateCampaingButton>
          <Dropdown
            overlay={
              <MenuDrop
                id={record.id}
                dataSource={record.customerType || 'PF'}
              />
            }
          >
            <PlusButton type="default" icon={<MoreOutlined />} />
          </Dropdown>
        </ActionButtons>
      ),
    },
  ];

  return (
    <PotentialAudienceContainer>
      <PageHeader>
        <H3 weight="medium">Gestão de público potencial</H3>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            type="default"
            size="large"
            icon={<SyncOutlined />}
            onClick={() => handleRefetch()}
            loading={potentialAudienceLoading}
          >
            {tPotentialAudience('refresh')}
          </Button>
          <CreatePotentialAudienceButton
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            onClick={() => handleCreatePotentialAudience()}
          >
            Criar público potencial
          </CreatePotentialAudienceButton>
        </div>
      </PageHeader>
      <Main>
        <TableImports>
          <TabGroup
            tabPosition="top"
            type="line"
            activeKey={defaultTab}
            onChange={e => setDefaultTab(e)}
          >
            <TabGroupPane tab="Importações" key="1">
              <PotentialAudienceTable
                dataSource={potentialAudience?.data}
                columns={columnsPotentialAudience}
                rowKey="id"
                pagination={{
                  defaultPageSize: rowsPerPage,
                  onShowSizeChange: handleChangeRowsPerPage,
                  total: potentialAudience?.meta.size,
                  onChange: currentPage => setPage(currentPage),
                }}
              />
            </TabGroupPane>
            <TabGroupPane tab="Exportações" key="3">
              <ExportsTabPotentialAudience
                setLoader={setLoading}
                refetch={refetchExports}
              />
            </TabGroupPane>
          </TabGroup>
        </TableImports>
      </Main>
      <ModalExportPotentialAudience
        potentialAudienceId={potentialAudienceId}
        fileImportSource={fileImportSource}
        visible={isModalOpen}
        onCancel={handleCloseModalExportFile}
        onOk={handleCloseModalExportFile}
      />
    </PotentialAudienceContainer>
  );
};

export default memo(PotentialAudiencePage);
