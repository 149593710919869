import { Skeleton } from 'antd';

import {
  Container,
  Content,
  ContentNav,
  ContentActionsUser,
  ContentUser,
} from '../../../layouts/LandingPage/components/Header/styles';

import Logo from '../../Logo';

const Header: React.FC = () => {
  return (
    <Container>
      <Content>
        <ContentNav>
          <Skeleton.Button active shape="square" />
          <Logo />
        </ContentNav>
        <ContentActionsUser>
          <ContentUser role="button">
            <Skeleton.Input active size="small" />
          </ContentUser>
        </ContentActionsUser>
      </Content>
    </Container>
  );
};

export default Header;
