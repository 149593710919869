import { useState, useRef, useEffect } from 'react';

import { SSE } from './see.js';

const useOpenIa = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState('');

  const resultRef = useRef() as any;

  useEffect(() => {
    resultRef.current = result;
  }, [result]);

  const handleSubmitPrompt = async (prompt: any) => {
    if (prompt !== '') {
      setIsLoading(true);
      setResult('');
      const url = 'https://api.openai.com/v1/completions';
      const data = {
        model: 'gpt-3.5-turbo-instruct',
        prompt,
        temperature: 0.5,
        top_p: 0.5,
        max_tokens: 3000,
        stream: true,
        n: 1,
      };

      const source = new SSE(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer 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`,
        },
        method: 'POST',
        payload: JSON.stringify(data),
      });

      if (source) {
        source?.addEventListener?.('message', (e: any) => {
          if (e.data !== '[DONE]') {
            const payload = JSON.parse(e.data);
            const { text } = payload.choices[0];
            if (text !== '\n') {
              console.log(`Text: ${text}`);
              resultRef.current += text;
              console.log(`ResultRef.current: ${resultRef.current}`);
              setResult(resultRef?.current);
            }
          } else {
            source.close?.();
          }
        });

        source?.addEventListener?.('readystatechange', (e: any) => {
          if (e.readyState >= 2) {
            setIsLoading(false);
          }
        });

        source.stream?.();
      } else {
        alert('Please insert a prompt!');
      }
    }
  };

  return { handleSubmitPrompt, result, isLoading };
};

export default useOpenIa;
