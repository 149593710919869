import { Button, Upload as UploadAnt } from 'antd';
import { useCallback } from 'react';

// import { SystemEnvs } from '../../config/envs';
// import { useGetCustomerByIdQuery } from '../../services/customer';
// import { useAuth } from '../../hooks/auth';
import { BorderOuterOutlined } from '@ant-design/icons';
import { Feedback } from '../../helpers/message';
import { Item } from '../../layouts/LandingPage/components/UserMenu/styles';
import {
  useGetUserUrlLogoQuery,
  useLazyGetPresignedUrlQuery,
  usePostImageS3Mutation,
  useUpdateImageToS3Mutation,
} from '../../services/uploadLogo';

const ImportLogoModal = () => {
  const [updateImage] = useUpdateImageToS3Mutation();
  const [postImage] = usePostImageS3Mutation();

  const [getPresignURL] = useLazyGetPresignedUrlQuery();
  const { refetch } = useGetUserUrlLogoQuery();

  const handleGetPresignURL = useCallback(
    async (file: File) => {
      const url = await getPresignURL({
        contentType: file.type,
        fileName: file.name,
      });
      return url.data.url;
    },
    [getPresignURL],
  );

  const isValidImage = (file: File): boolean => {
    const validTypes = ['image/jpeg', 'image/png'];
    if (!file) {
      Feedback.error('Por favor, selecione um arquivo para fazer o upload.');
      return false;
    }
    const isImage = validTypes.includes(file.type);
    if (!isImage) {
      Feedback.error('Você pode apenas fazer upload de imagens!');
      return false;
    }

    return true;
  };

  const handleLogo = async (props: any) => {
    const validImage = isValidImage(props.file);
    try {
      if (validImage) {
        const url = await handleGetPresignURL(props.file);
        if (url) {
          await updateImage({
            preSignedUrl: url,
            file: props.file,
          })
            .unwrap()
            .then(() => {
              postImage({
                file: props.file,
                url: `https://salesbox-portal-public-assets.s3.amazonaws.com/logo/`,
              })
                .unwrap()
                .then(() => {
                  Feedback.success('Upload concluído com sucesso!');
                  refetch();
                })
                .catch(() => {
                  Feedback.error('Falha no upload do arquivo.');
                });
            })
            .catch(error => console.log('error', error));
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <UploadAnt
      showUploadList={false}
      customRequest={data => handleLogo(data)}
      style={{ width: '100%' }}
      maxCount={1}
      accept="image/png, image/jpeg"
    >
      <Item>
        <Button
          style={{ width: '100%' }}
          icon={<BorderOuterOutlined />}
          type="text"
        >
          Importar Logo
        </Button>
      </Item>
    </UploadAnt>
  );
};

export default ImportLogoModal;
