import { useCallback, useEffect, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { H3 } from '../../../styles/fonts';
import { Container, PageHeader, Wrapper } from './styles';
import TreeMap from '../../../components/Charts/TreeMap';
import * as CONSTANTS from '../../../navigation/constants';
import Button from '../../../components/Button';

const Rfv = () => {
  const [refetchImports, setRefetchImports] = useState<boolean>(false);
  const [refetchExports, setRefetchExports] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (refetchImports) {
      setRefetchImports(false);
    }

    if (refetchExports) {
      setRefetchExports(false);
    }
  }, [refetchImports, refetchExports]);

  const handleBackButton = useCallback(() => {
    navigate(CONSTANTS.IMPORT_RFV);
  }, [navigate]);

  return (
    <Container>
      <PageHeader>
        <Button
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={handleBackButton}
        />
        <H3 weight="medium">Análise RFV</H3>
      </PageHeader>

      <Wrapper>
        <TreeMap />
      </Wrapper>
    </Container>
  );
};

export default Rfv;
