import { DashboardRoute } from '../../config/services-route';
import { TagTypes } from '../types';
import { providesList } from '../../helpers/provides';
import { deepDiveApi } from '../../config/apis/deep-dive-query';

const dashboardApi = deepDiveApi.injectEndpoints({
  endpoints: builder => ({
    getDashboardData: builder.query<any, any>({
      query: () => ({
        url: DashboardRoute.getDashBoard,
        params: { _timestamp: new Date().getTime() },
      }),
      providesTags: (response: any) => {
        return providesList(response?.data, TagTypes.BarChart);
      },
    }),
    // getDashboadData: builder.query<any, any>({
    //   query: _ => {
    //     const authData = JSON.parse(localStorage?.getItem('auth') as string);

    //     return {
    //       headers: {
    //         CustomerId: authData.customer,
    //       },
    //       url: DashboardRoute.getDashBoard,
    //     };
    //   },
    //   providesTags: response =>
    //     providesList(response?.data, TagTypes.FileImport),
    // }),
  }),
});

export const { useGetDashboardDataQuery } = dashboardApi;
