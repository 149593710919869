import styled from 'styled-components';

import { Table } from 'antd';

export const Enrichment = styled.div`
  svg {
    color: #bfbfbf !important;
  }
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const TableGroup = styled(Table)`
  width: 100%;
`;

export const ColumnTableGroup5 = styled(Table.Column)`
  height: 10rem;
  color: #fff;
  line-height: 10rem;
  text-align: center;
  background: #364d79;
`;
