/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-properties */
import React, { useCallback, useEffect } from 'react';

import { CloseOutlined } from '@ant-design/icons';

import { useCustomTranslation } from '../../../../hooks/custom-translation';

import { Body } from '../../../../styles/fonts';

import Form, { FormActions, FormValidation, FormWrapper } from '../../../Form';
import FormInput from '../../../FormInput';
import Modal from '../../../Modal';

import {
  LayoutPage,
  Sider1,
  Wrapper,
  PageHeader,
  Editarplano,
  AtivoSwitch,
  InputLabelHorizontal,
  SwitchBasic,
  CloseButton,
  CancelButton,
  SaveButton,
} from './styles';
import {
  ServicePlanProps,
  usePostServicePlanMutation,
  useUpdateServicePlanMutation,
} from '../../../../services/servicePlan';
import { ShowFormMessage } from '../../../../helpers/message';
import getErrors from '../../../../helpers/errors';
import { Validators } from '../../../../helpers/validators';

interface ModalAddPlanProps {
  onOk: () => void;
  onCancel: () => void;
  visible: boolean;
  servicePlan?: ServicePlanProps;
}

export const currencyFormatter = (value: any) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
};

const ModallAddServicePlan = ({
  onOk,
  onCancel,
  visible,
  servicePlan,
}: ModalAddPlanProps) => {
  const { tModals, tSystemSettings } = useCustomTranslation(
    'modals',
    'systemSettings',
  );

  const [postServicePlan, configPostServicePlan] = usePostServicePlanMutation();

  const [updateServicePlan, configUpdateServicePlan] =
    useUpdateServicePlanMutation();

  const [form] = Form.useForm<ServicePlanProps>();

  useEffect(() => {
    form.setFieldsValue({
      isEnabled: servicePlan?.isEnabled,
      description: servicePlan?.description,
      maxQuantityContacts: servicePlan?.maxQuantityContacts,
      maxQuantityContactsMailing: servicePlan?.maxQuantityContactsMailing,
      planValue: servicePlan?.planValue,
      maxQuantityContactsPj: servicePlan?.maxQuantityContactsPj,
      maxQuantityContactsMailingPj: servicePlan?.maxQuantityContactsMailingPj,
    });
  }, [servicePlan]);

  const handleCancel = () => {
    onCancel();
    onOk();
    form.resetFields();
  };

  const handleSubmit = async ({
    description,
    maxQuantityContacts,
    maxQuantityContactsMailing,
    planValue,
    isEnabled,
    maxQuantityContactsPj,
    maxQuantityContactsMailingPj,
  }: ServicePlanProps) => {
    await postServicePlan({
      description,
      maxQuantityContacts,
      maxQuantityContactsMailing,
      planValue,
      isEnabled,
      maxQuantityContactsPj,
      maxQuantityContactsMailingPj,
    }).unwrap();
  };

  const handleEdit = async ({
    description,
    maxQuantityContacts,
    maxQuantityContactsMailing,
    planValue,
    isEnabled,
    maxQuantityContactsPj,
    maxQuantityContactsMailingPj,
  }: ServicePlanProps) => {
    await updateServicePlan({
      id: servicePlan?.id as string,
      description,
      maxQuantityContacts,
      maxQuantityContactsMailing,
      planValue,
      isEnabled,
      maxQuantityContactsPj,
      maxQuantityContactsMailingPj,
    }).unwrap();
  };

  const showMessagePostServicePlan = useCallback(() => {
    const { isSuccess, isError, error } = configPostServicePlan;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }
    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Plano adicionado com sucesso',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
    if (isSuccess) {
      form.resetFields();
      onCancel();
    }
  }, [configPostServicePlan, form, onCancel]);

  const showMessageUpdateServicePlan = useCallback(() => {
    const { isSuccess, isError, error } = configUpdateServicePlan;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }
    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Plano editado com sucesso',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
    if (isSuccess) {
      form.resetFields();
      onCancel();
    }
  }, [configUpdateServicePlan, form, onCancel]);

  useEffect(showMessagePostServicePlan, [showMessagePostServicePlan]);
  useEffect(showMessageUpdateServicePlan, [showMessageUpdateServicePlan]);

  const formValidation: FormValidation<ServicePlanProps> = {
    maxQuantityContacts: [Validators.Required()],
    maxQuantityContactsMailing: [Validators.Required()],
    planValue: [Validators.Required()],
    description: [Validators.Required()],
    isEnabled: [Validators.Required()],
  };

  const currencyParser = (val: any): any => {
    try {
      // for when the input gets clears
      if (typeof val === 'string' && !val.length) {
        val = '0.0';
      }

      // detecting and parsing between comma and dot
      const group = new Intl.NumberFormat('pt-BR')
        .format(1111)
        .replace(/1/g, '');
      const decimal = new Intl.NumberFormat('pt-BR')
        .format(1.1)
        .replace(/1/g, '');
      let reversedVal = val.replace(new RegExp(`\\${group}`, 'g'), '');
      reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, 'g'), '.');
      //  => 1232.21 €

      // removing everything except the digits and dot
      reversedVal = reversedVal.replace(/[^0-9.]/g, '');
      //  => 1232.21

      // appending digits properly
      const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;
      const needsDigitsAppended = digitsAfterDecimalCount > 2;

      if (needsDigitsAppended) {
        reversedVal *= Math.pow(10, digitsAfterDecimalCount - 2);
      }

      return Number.isNaN(reversedVal) ? 0 : reversedVal;
    } catch (error) {
      return '';
    }
  };

  return (
    <Modal
      visible={visible}
      title={null}
      footer={null}
      closable={false}
      width="35rem"
    >
      <LayoutPage>
        <Sider1 />
        <Wrapper>
          <FormWrapper>
            <Form
              onFinish={servicePlan?.id ? handleEdit : handleSubmit}
              layout="vertical"
              form={form}
              requiredMark={false}
              initialValues={servicePlan}
            >
              <PageHeader>
                <Editarplano>{tSystemSettings('edit_plan')}</Editarplano>
                <AtivoSwitch>
                  <InputLabelHorizontal>
                    <Body weight="regular">{tModals('active')}</Body>
                  </InputLabelHorizontal>
                  <Form.Item
                    name="isEnabled"
                    valuePropName="checked"
                    initialValue
                  >
                    <SwitchBasic defaultChecked />
                  </Form.Item>
                </AtivoSwitch>
                <CloseButton
                  type="text"
                  icon={<CloseOutlined style={{ fontSize: 18 }} />}
                  onClick={handleCancel}
                />
              </PageHeader>

              <Form.Item
                name="description"
                label={<Body weight="regular">{tModals('description')}</Body>}
                rules={formValidation.description}
              >
                <FormInput size="large" />
              </Form.Item>
              <Form.Item
                name="maxQuantityContacts"
                label={
                  <Body weight="regular">
                    {tModals('max_contacts_imports')}
                  </Body>
                }
                rules={formValidation.maxQuantityContacts}
              >
                <FormInput.Number size="large" />
              </Form.Item>
              <Form.Item
                name="maxQuantityContactsMailing"
                label={
                  <Body weight="regular">
                    {tModals('max_potential_public')}
                  </Body>
                }
                rules={formValidation.maxQuantityContactsMailing}
              >
                <FormInput.Number size="large" />
              </Form.Item>
              <Form.Item
                name="maxQuantityContactsPj"
                label={
                  <Body weight="regular">
                    {tModals('max_contacts_imports_pj')}
                  </Body>
                }
              >
                <FormInput.Number size="large" />
              </Form.Item>
              <Form.Item
                name="maxQuantityContactsMailingPj"
                label={
                  <Body weight="regular">
                    {tModals('max_potential_public_pj')}
                  </Body>
                }
              >
                <FormInput.Number size="large" />
              </Form.Item>
              <Form.Item
                name="planValue"
                label={<Body weight="regular">{tModals('price')}</Body>}
                rules={formValidation.planValue}
              >
                <FormInput.Number
                  style={{ width: '100% !important' }}
                  size="large"
                  defaultValue="0000"
                  formatter={currencyFormatter}
                  parser={currencyParser}
                />
              </Form.Item>
              <FormActions>
                <CancelButton
                  type="default"
                  size="large"
                  onClick={handleCancel}
                >
                  {tModals('cancel')}
                </CancelButton>
                <SaveButton
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={configPostServicePlan.isLoading}
                >
                  {tModals('save')}
                </SaveButton>
              </FormActions>
            </Form>
          </FormWrapper>
        </Wrapper>
      </LayoutPage>
    </Modal>
  );
};

export default ModallAddServicePlan;
