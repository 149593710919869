import { LoadingOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';
import {
  Icon,
  IconOutlinedCheckCircle,
  IconOutlinedCloseCircle,
  SpinIcon,
  TextComponentarraste,
  TextComponentcaso,
  TextWrapper,
  Upload,
} from './styles';

interface CardStatusProps {
  title: string;
  subtitle: string;
  type: 'empty' | 'validating' | 'done' | 'error';
}

const CardStatus = ({ title, subtitle, type }: CardStatusProps) => {
  const handleIcon = useCallback(() => {
    switch (type) {
      case 'validating':
        return (
          <SpinIcon
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          />
        );
      case 'error':
        return <IconOutlinedCloseCircle />;
      case 'done':
        return <IconOutlinedCheckCircle />;
      default:
        return <Icon />;
    }
  }, [type]);

  return (
    <Upload type={type}>
      {handleIcon()}
      <TextWrapper>
        <TextComponentarraste>{title}</TextComponentarraste>
        <TextComponentcaso type={type}>{subtitle}</TextComponentcaso>
      </TextWrapper>
    </Upload>
  );
};

export default CardStatus;
