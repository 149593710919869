import React from 'react';
import Lottie from 'react-lottie';

import animationData from '../../assets/loading.json';
import { LottieWrapper } from './styles';

interface LoadingProps {
  onFinish: () => void;
  looping?: number | boolean;
}

const Loading = ({ onFinish, looping = 1 }: LoadingProps) => {
  const defaultOptions = {
    loop: looping,
    autoplay: true,
    animationData,
  };

  return (
    <LottieWrapper>
      <Lottie
        options={defaultOptions}
        height={400}
        width={400}
        isStopped={false}
        isPaused={false}
        eventListeners={[
          {
            eventName: 'complete',
            callback: () => onFinish(),
          },
        ]}
      />
    </LottieWrapper>
  );
};

export default Loading;
