import { SyncOutlined, UploadOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import ExportsTabPj from '../../components/ExportsPj';
import ImportsTab from '../../components/ImportsPj';
import { useCustomTranslation } from '../../hooks/custom-translation';
import { H3 } from '../../styles/fonts';
import {
  Container,
  PageHeader,
  TabGroup,
  TabGroupPane,
  TableImports,
  Wrapper,
} from './styles';
import { AddContactButton } from '../ContactManagement/styles';
import * as CONTANTS from '../../navigation/constants';

const DatabaseManagementPj = () => {
  const { tContactManagement } = useCustomTranslation('contactManagement');
  const [defaultTab, setDefaultTab] = useState<string>('1');
  const [refetchImports, setRefetchImports] = useState<boolean>(false);
  const [refetchExports, setRefetchExports] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleRefetch = () => {
    if (defaultTab === '1') {
      setRefetchImports(true);
    } else {
      setRefetchExports(true);
    }
  };

  useEffect(() => {
    if (refetchImports) {
      setRefetchImports(false);
    }

    if (refetchExports) {
      setRefetchExports(false);
    }
  }, [refetchImports, refetchExports]);

  // Refetch automático a cada 5 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      handleRefetch();
    }, 5000); // 5000ms = 5 segundos

    // Limpa o intervalo quando o componente é desmontado
    return () => clearInterval(interval);
  }, [defaultTab]);

  const handleNewImportFile = () => {
    navigate(CONTANTS.CONTACT_IMPORT_WIZARD_PJ);
  };

  return (
    <Container>
      <PageHeader>
        <H3 weight="medium">Gestão de bases PJ</H3>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            type="default"
            size="large"
            icon={<SyncOutlined />}
            onClick={handleRefetch}
            loading={loading}
          >
            {tContactManagement('refresh')}
          </Button>
          <AddContactButton
            type="primary"
            icon={<UploadOutlined />}
            size="large"
            onClick={() => handleNewImportFile()}
          >
            {tContactManagement('import_file')}
          </AddContactButton>
        </div>
      </PageHeader>

      <Wrapper>
        <TableImports>
          <TabGroup
            tabPosition="top"
            type="line"
            activeKey={defaultTab}
            onChange={e => setDefaultTab(e)}
          >
            <TabGroupPane tab={tContactManagement('imports')} key="1">
              <ImportsTab setLoader={setLoading} refetch={refetchImports} />
            </TabGroupPane>
            <TabGroupPane tab="Exportações" key="3">
              <ExportsTabPj setLoader={setLoading} refetch={refetchExports} />
            </TabGroupPane>
          </TabGroup>
        </TableImports>
      </Wrapper>
    </Container>
  );
};

export default DatabaseManagementPj;
