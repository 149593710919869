import { useEffect, useState } from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useCustomTranslation } from '../../../hooks/custom-translation';
import { ContactProps } from '../../../services/contact';
import { Personas, PersonasProps } from '../../../services/personas';
import { getPersonasData } from '../../../services/personas/personas-service';
import { Footnote, H4, H5 } from '../../../styles/fonts';
import BarChart from '../../Contacts/Contact/ContactProfile/BarChart';
import ModalPersona from '../../ModalPersonas';
import PersonaIcon from './PersonaIcon';
import {
  Column012,
  Column022,
  Column03,
  Content01,
  Content1,
  Data01,
  Label01,
  LearnMoreButton,
  Offer,
  OfferValue,
  Row01,
  Row012,
  Wrapper,
} from './styles';

interface IProps {
  contactInfo: ContactProps | undefined;
}

const General = ({ contactInfo }: IProps) => {
  const { tContactSegmentsEdit } = useCustomTranslation('contactSegmentsEdit');
  const [offerTypes, setOfferTypes] = useState<any>([]);
  const [isModalPersonaOpen, setIsModalPersonaOpen] = useState<boolean>(false);
  const [personas, setPersonas] = useState<PersonasProps>();
  const [personaProfileNumber, setPersonaProfileNumber] = useState<number>();

  const handleCloseModalPersona = () => {
    setIsModalPersonaOpen(false);
  };

  useEffect(() => {
    const newArr = [
      [
        'Status Social',
        contactInfo?.enriched.socialStatus,
        'Tendência a buscar produtos que refletem status social',
      ],
      [
        'Valor Sazonal',
        contactInfo?.enriched.seasonalValue,
        'Tendência a compra de produtos em datas comemorativas',
      ],
      [
        'Vantagem Economica',
        contactInfo?.enriched.economicAdvantage,
        'Tendência a buscar por vantagem econômica na hora de pagar',
      ],
      [
        'Varejo Fisico',
        contactInfo?.enriched.physicalRetail,
        'Tendência a preferir lojas físicas',
      ],
      [
        'Brinde',
        contactInfo?.enriched.gift,
        'Têndencia a preferir ofertas com opção de ganhar um brinde',
      ],
      [
        'Estabilidade',
        contactInfo?.enriched.stability,
        'Têndencia a comprar de forma mais racional',
      ],
      [
        'Instabilidade',
        contactInfo?.enriched.instability,
        'Têndencia a fazer compras de forma mais impulsiva',
      ],
      [
        'Novos métodos de pagamento',
        contactInfo?.enriched.pagOption,
        'Tendência a preferir mais opções de pagamento',
      ],
      [
        'Premium',
        contactInfo?.enriched.premium,
        'Tendência a produtos premium',
      ],
      [
        'Premium Serviço',
        contactInfo?.enriched.premiumService,
        'Tendência a serviços premium',
      ],
      [
        'Confianca Marca',
        contactInfo?.enriched.brandTrust,
        'Tendência a confiar nas marcas',
      ],
      [
        'Consumo Potencial Digital',
        contactInfo?.enriched.digitalConsumptionPotential,
        'Tendência a consumo de forma digital',
      ],
      [
        'Conveniencia',
        contactInfo?.enriched.convenience,
        'Tendência a usar lojas de conveniência',
      ],
      [
        'Custo Benefício',
        contactInfo?.enriched.costBenefit,
        'Tendência a valorizar o custo-beneficio',
      ],
      [
        'ESG (Ambiental, Social e Governamental',
        contactInfo?.enriched.esg,
        'Tendência a se preocupar com questões de meio ambiente, sociais e governamentais',
      ],
      [
        'Experiência de Compra',
        contactInfo?.enriched.shoppingExperience,
        'Tendência a valorizar a expêriencia de compra',
      ],
      [
        'Facilidade de Pagamento',
        contactInfo?.enriched.easeOfPayment,
        'Tendência a preferir opções de facilidade de pagamento',
      ],
      [
        'Influência Consumo Online',
        contactInfo?.enriched.influenceOnlineConsumption,
        'Tendência a ser influenciado(a) por meios digitais',
      ],
      [
        'Influência Consumo Offline',
        contactInfo?.enriched.influenceOfflineConsumption,
        'Tendência a ser influenciado(a) por meios físicos (revistas, jornais, rádio)',
      ],
      ['Inovação', contactInfo?.enriched.innovation, 'Tendência à inovação'],
    ];

    const sorted = newArr.sort((a, b) => {
      if (a[1] && b[1]) {
        return (a[1] as number) - (b[1] as number);
      }

      return 0;
    });

    const offers = {
      1: sorted[sorted.length - 1],
      2: sorted[sorted.length - 2],
      3: sorted[sorted.length - 3],
      4: sorted[sorted.length - 4],
      5: sorted[sorted.length - 5],
    };

    setOfferTypes([
      {
        title: offers[1][0],
        value: offers[1][1],
        tooltip: offers[1][2],
      },
      {
        title: offers[2][0],
        value: offers[2][1],
        tooltip: offers[2][2],
      },
      {
        title: offers[3][0],
        value: offers[3][1],
        tooltip: offers[3][2],
      },
      {
        title: offers[4][0],
        value: offers[4][1],
        tooltip: offers[4][2],
      },
      {
        title: offers[5][0],
        value: offers[5][1],
        tooltip: offers[5][2],
      },
    ]);
  }, [contactInfo]);

  useEffect(() => {
    if (!personas) {
      getPersonasData().then((value: PersonasProps) => setPersonas(value));
    }
  }, [setPersonas, personas]);

  useEffect(() => {
    personas?.personas.map((p: Personas, index) => {
      if (
        p.name?.toLowerCase() ===
        contactInfo?.enriched.segmentName?.toLowerCase()
      ) {
        return setPersonaProfileNumber(index);
      }

      return null;
    });
  }, [contactInfo?.enriched.segmentName, personas]);

  return (
    <>
      <Content01>
        {contactInfo?.enriched.segmentName ? (
          <Column012>
            <Row01>
              <H4 weight="medium">{tContactSegmentsEdit('persona')}</H4>
              <Wrapper>
                <PersonaIcon
                  persona={contactInfo?.enriched.segmentName as string}
                />
                <Content1>
                  <H5 weight="medium">{contactInfo?.enriched.segmentName}</H5>
                  <LearnMoreButton
                    weight="medium"
                    color="#2966F5"
                    onClick={() => setIsModalPersonaOpen(true)}
                  >
                    {tContactSegmentsEdit('learn_more')}
                  </LearnMoreButton>
                </Content1>
              </Wrapper>
            </Row01>
          </Column012>
        ) : null}
        <Column022>
          <Row01>
            <Data01>
              <H4 weight="medium">{tContactSegmentsEdit('personality')}</H4>
              <Label01>
                <Footnote>{tContactSegmentsEdit('introvert')}</Footnote>
                <Footnote>{tContactSegmentsEdit('extroverted')}</Footnote>
              </Label01>
              <BarChart value={contactInfo?.enriched.scoreExtr as number} />
              <Label01>
                <Footnote>{tContactSegmentsEdit('stable')}</Footnote>
                <Footnote>{tContactSegmentsEdit('neuroticism')}</Footnote>
              </Label01>
              <BarChart value={contactInfo?.enriched.scoreNeur as number} />
              <Label01>
                <Footnote>{tContactSegmentsEdit('undisciplined')}</Footnote>
                <Footnote>{tContactSegmentsEdit('conscientiousness')}</Footnote>
              </Label01>
              <BarChart value={contactInfo?.enriched.scoreCons as number} />
              <Label01>
                <Footnote>{tContactSegmentsEdit('traditional')}</Footnote>
                <Footnote>
                  {tContactSegmentsEdit('open_to_experiences')}
                </Footnote>
              </Label01>
              <BarChart value={contactInfo?.enriched.scoreExtr as number} />
            </Data01>
          </Row01>
        </Column022>
        <Column03>
          <Row012>
            <H4 weight="medium">
              {tContactSegmentsEdit('recommended_offer_types')}
            </H4>
            {offerTypes &&
              offerTypes.map((offer: any) => (
                <Offer key={offer.title}>
                  <Tooltip
                    key={offer.title}
                    title={offer.tooltip}
                    className="tooltip"
                    overlayInnerStyle={{
                      fontSize: '0.875rem',
                      lineHeight: '1.375rem',
                    }}
                  >
                    <QuestionCircleOutlined />
                    <span>{offer.title}</span>
                  </Tooltip>

                  <OfferValue>{`${offer.value || 0}%`}</OfferValue>
                </Offer>
              ))}
          </Row012>
        </Column03>
      </Content01>

      {personas && (personaProfileNumber || personaProfileNumber === 0) && (
        <ModalPersona
          visible={isModalPersonaOpen}
          onCancel={handleCloseModalPersona}
          onOk={handleCloseModalPersona}
          personaData={personas}
          personaProfile={personaProfileNumber}
        />
      )}
    </>
  );
};

export default General;
