import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useCustomTranslation } from '../../hooks/custom-translation';
import { H3, H5 } from '../../styles/fonts';
import {
  Wrapper,
  PageHeader,
  TopBar,
  Content1,
  Search,
  Sider1,
  NumberChoice,
  Button,
  NumberSection,
  CloseButton,
} from './styles';
import Modal from '../Modal';
import { WhatsAppPhoneProps } from '../../services/whatsAppPhone';
import { maskPhone } from '../../helpers/masks/masksRegex';
import { ROOT } from '../../navigation/constants';

interface IProps {
  phoneNumbers: WhatsAppPhoneProps[] | undefined;
  selectPhone: Dispatch<SetStateAction<WhatsAppPhoneProps | undefined>>;
}

const PhoneChoice = ({ phoneNumbers, selectPhone }: IProps) => {
  const { tPhoneChoice } = useCustomTranslation('phoneChoice');

  const [searchItem, setSearchItem] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [filteredItems, setFilteredItems] = useState<WhatsAppPhoneProps[]>();

  const navigate = useNavigate();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchItem(event.target.value.toLowerCase());
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate(ROOT);
  };

  const handleSelectPhone = (phone: WhatsAppPhoneProps) => {
    selectPhone(phone);
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    setFilteredItems(
      phoneNumbers?.filter(
        item =>
          item.friendlyName.toLowerCase().includes(searchItem) ||
          item.phoneNumber.includes(searchItem),
      ),
    );
  }, [phoneNumbers, searchItem]);

  return (
    <>
      <Modal
        visible={isModalOpen}
        footer={null}
        title={null}
        closable={false}
        width="44.5rem"
      >
        <NumberChoice>
          <Sider1 />
          <Wrapper>
            <PageHeader>
              <H3 weight="medium">{tPhoneChoice('choose_phone_number')}</H3>
              <CloseButton onClick={handleCloseModal} />
            </PageHeader>
            <TopBar>
              <Search
                style={{ width: '320px' }}
                type="default"
                size="large"
                placeholder={tPhoneChoice('search_phone')}
                allowClear
                onChange={handleSearch}
              />
            </TopBar>
            <NumberSection>
              {searchItem !== '' && (
                <Content1>
                  {filteredItems?.map((option: WhatsAppPhoneProps) => (
                    <Button
                      type="default"
                      style={{
                        width: '100%',
                        height: '64px',
                      }}
                      size="large"
                      onClick={() => handleSelectPhone(option)}
                      key={option.id}
                    >
                      <H5 weight="medium" style={{ textAlign: 'start' }}>
                        {`${option.friendlyName} - ${maskPhone(
                          option.phoneNumber,
                        )}`}
                      </H5>
                    </Button>
                  ))}
                </Content1>
              )}
              {searchItem === '' && (
                <Content1>
                  {phoneNumbers?.map((phoneNumber: WhatsAppPhoneProps) => {
                    return (
                      <Button
                        type="default"
                        style={{
                          width: '100%',
                          height: '64px',
                        }}
                        size="large"
                        onClick={() => handleSelectPhone(phoneNumber)}
                        key={phoneNumber.id}
                      >
                        <H5
                          weight="medium"
                          color=""
                          style={{ textAlign: 'start' }}
                        >
                          {`${phoneNumber.friendlyName} - ${maskPhone(
                            phoneNumber.phoneNumber,
                          )}`}
                        </H5>
                      </Button>
                    );
                  })}
                </Content1>
              )}
            </NumberSection>
          </Wrapper>
        </NumberChoice>
      </Modal>
    </>
  );
};
export default PhoneChoice;
