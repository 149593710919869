import { Rule } from 'antd/lib/form';
import { useCustomTranslation } from '../../hooks/custom-translation';

export const SameAs = (field: string): Rule => {
  const { tValidationMessages } = useCustomTranslation('validationMessages');
  return ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue(field) === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(tValidationMessages('same_as')));
    },
  });
};
