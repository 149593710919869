/* eslint-disable prettier/prettier */
import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  ArrowLeftOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { FormInstance, Radio, Select } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Button from '../../../components/Button';
import Form, { FormValidation } from '../../../components/Form';
import Autocomplete from '../../../components/GoogleMaps/Autocomplete';
import Tooltip from '../../../components/Tooltip';

import { Validators } from '../../../helpers/validators';
import { useCustomTranslation } from '../../../hooks/custom-translation';
import { useMaps } from '../../../hooks/maps';
import * as CONSTANTS from '../../../navigation/constants';
import { IFilters } from '../../../services/map/potentialAudience';
import { Body, H5 } from '../../../styles/fonts';

import FormGroups from '../../ContactSegmentsEdit/FormGroups';

import {
  Container,
  Content,
  Footer,
  FooterWrapper,
  Header,
  HeaderButton,
  HeaderTitle,
  Input,
  Radius,
  RadiusContainer,
  RadiusInput,
  RadiusInputLabel,
  RadioButtonGroup,
} from './styles';

interface IProps {
  setPotentialAudience: any;
  form: FormInstance;
  setFilters: Dispatch<SetStateAction<IFilters | undefined>>;
  postPotentialAudience: any;
  isLoading: boolean;
  hasSegmentatioAnalysisTab?: boolean;
  filterPotentialAudience?: any;
}

export interface FilterForm {
  address: string;
  radius: number;
  radiusType: string;
}

const Filters = ({
  setPotentialAudience,
  form,
  setFilters,
  postPotentialAudience,
  isLoading,
  hasSegmentatioAnalysisTab,
  filterPotentialAudience
}: IProps) => {
  const { tFilter } = useCustomTranslation('filter');
  const {
    radius,
    setRadius,
    setPin,
    setCenter,
    placeSelected,
    setPlaceSelected,
  } = useMaps();

  const { id: fileImportId } = useParams();
  const { pathname } = useLocation();

  const [radiusType, setRadiusType] = useState('km');
  const radiusValue = Form.useWatch('radius', form);
  const [persistedRadius, setPersistedRadius] = useState(radiusValue || 0);

  useEffect(() => {
    if (radiusValue !== 0 && radiusValue !== undefined) {
      setPersistedRadius(radiusValue);
    }
  }, [radiusValue]);


  const navigate = useNavigate();

  const [selectedValue, setSelectedValue] = useState('PotentialAudience');

  const handleRadioChange = (e: any) => {
    setSelectedValue(e.target.value);
  };

  const formValidation: FormValidation<FilterForm> = {
    radius: [Validators.Required(), Validators.NotZero()],
    address: [Validators.Required()],
  };

  const handleFilterValue = (filterValue: any, filterValue2?: any) => {
    if (!Array.isArray(filterValue)) {
      if (filterValue2) {
        return [filterValue, filterValue2];
      }
      return [filterValue];
    }
    return filterValue;
  };

  const prepareObjPotentialAudience = useCallback(
    (data: any) => {
      const newData: any = { filterGroups: [] };
      data.groups.forEach((gr: any, index: number) => {
        const filtersDefinition = gr.filtersDefinition.map(
          (fd: any, i: number) => {
            return {
              order: i + 1,
              originFilterFieldId: fd.originFilterFieldId,
              assertFilterOperatorId: fd.assertFilterOperatorId,
              joinFilterOperatorId: fd?.joinFilterOperatorId || null,
              filterValue: handleFilterValue(fd.filterValue, fd?.filterValue2),
              origin: fd.origin,
            };
          },
        );
        newData.filterGroups.push({
          order: index + 1,
          filterOperatorId: gr.filterOperatorId,
          filtersDefinition,
        });
      });

      newData.name = form.getFieldValue('name') !== '' ? data.name : '';

      if (data.address) {
        newData.geoFilter = {
          latitude: placeSelected?.geometry?.location?.lat(),
          longitude: placeSelected?.geometry?.location?.lng(),
          address: data.address,
          radiusSize: `${data.radius}${data.radiusType}`,
        };
      }

      // if (data.radius !== ) {
      //   newData.geoFilter = {
      //     ...newData.geoFilter,
      //     radiusSize: `${data.radius}${data.radiusType}`,
      //   };
      // }

      newData.fileImportId = pathname.includes('edit-potential-audience') ? filterPotentialAudience.data[0].fileImportId : fileImportId;

      return newData;
    },
    [form, filterPotentialAudience, pathname, fileImportId, placeSelected?.geometry?.location],
  );

  useEffect(() => {
    form.resetFields();
    setFilters(undefined);
  }, [form, selectedValue, setFilters]);

  const handleSubmit = useCallback(
    async (data: FilterForm) => {

      const radiusToSet = data.radius || persistedRadius;
      if (data.radiusType === 'km') {

        setRadius(radiusToSet * (data.radiusType === 'km' ? 1000 : 1));
      } else {
        setRadius(radiusToSet);
      }
      if (placeSelected?.geometry?.location) {
        const { location } = placeSelected?.geometry;
        setPin(location);
        setCenter({ lat: location?.lat(), lng: location?.lng() });
      }
      const submitObj = prepareObjPotentialAudience(data);
      submitObj.customerType = selectedValue === "PotentialAudience" ? 'PF' : 'PJ'
      const preview = (await postPotentialAudience(submitObj)) as any;


      setPotentialAudience(preview.data);
      setFilters(submitObj);
    },
    [
      placeSelected?.geometry,
      prepareObjPotentialAudience,
      postPotentialAudience,
      setPotentialAudience,
      setFilters,
      setRadius,
      setPin,
      setCenter,
      selectedValue,
      persistedRadius
    ],
  );

  const onFinish = (data: FilterForm) => {

    const address = form?.getFieldValue('address');
    const r = form.getFieldValue('radius');
    const groups = form.getFieldValue('groups');

    if (!groups.length && !address && r !== '') {
      return form.setFields([
        { name: 'address', errors: ['O campo é obrigatório'] },
      ]);
    }
    return groups.map((group: any, groupIndex: any) =>
      group.filtersDefinition.map((filter: any, filterIndex: any) =>
        form
          .validateFields([
            [
              'groups',
              groupIndex,
              'filtersDefinition',
              filterIndex,
              'originFilterFieldId',
            ],
            [
              'groups',
              groupIndex,
              'filtersDefinition',
              filterIndex,
              'assertFilterOperatorId',
            ],
            [
              'groups',
              groupIndex,
              'filtersDefinition',
              filterIndex,
              'filterKey',
            ],
            [
              'groups',
              groupIndex,
              'filtersDefinition',
              filterIndex,
              'filterValue',
            ],
            [
              'groups',
              groupIndex,
              'filtersDefinition',
              filterIndex,
              'filterValue2',
            ],
            [
              'groups',
              groupIndex,
              'filtersDefinition',
              filterIndex,
              'joinFilterOperatorId',
            ],
            ['groups', groupIndex, 'filtersDefinition', filterIndex, 'origin'],
          ])
          .then(() => {
            if (groups) {
              if (address && r === 0 && r === '0') {
                return form.setFields([
                  { name: 'radius', errors: ['O campo é obrigatório'] },
                ]);
              }

              if (r !== 0 && r !== '0' && !address) {
                return form.setFields([
                  { name: 'address', errors: ['O campo é obrigatório'] },
                ]);
              }
            }

            form.setFields([
              { name: 'address', errors: [] },
              // { name: 'radius', errors: [] },
            ]);
            return handleSubmit(data);
          }),
      ),
    );

    // form.setFields([
    //   { name: 'address', errors: [] },
    //   { name: 'radius', errors: [] },
    // ]);
    // return handleSubmit(data);
  };

  const backToPotentialAudience = () => {
    navigate(`${CONSTANTS.MAP}`);
  };

  const selectAfter = (
    <Form.Item name="radiusType">
      <Select
        style={{ width: '70px' }}
        value={radiusType}
        onChange={val => setRadiusType(val)}
        options={[{ value: 'km', label: 'Km' }]}
      />
    </Form.Item>
  );

  useEffect(() => {
    const newRadius = Number(
      filterPotentialAudience?.data[0]?.geoFilter?.radiusSize.match(/\d+/g)
    ) || persistedRadius;
    form.setFieldsValue({
      radius: newRadius || 0,
    });
    if (placeSelected) {
      form.setFieldsValue({
        address: placeSelected.formatted_address ?? placeSelected.name,
      });
    }
  }, [filterPotentialAudience?.data, form, placeSelected]);

  return (
    <Container
      form={form}
      onFinish={data => onFinish(data)}
      layout="vertical"
      initialValues={{ radius, radiusType }}
    >
      <Header>
        <HeaderButton>
          <Button
            type="text"
            icon={<ArrowLeftOutlined />}
            onClick={() => backToPotentialAudience()}
          />
        </HeaderButton>
        <HeaderTitle>
          <H5 weight="medium">{tFilter('title')}</H5>
          <Body weight="regular">{tFilter('subtitle')}</Body>
        </HeaderTitle>
      </Header>
      <Content>
        {
          !fileImportId ?
            <RadioButtonGroup>
              <Radio.Group
                onChange={handleRadioChange}
                value={selectedValue}
                style={{ width: '100%', display: 'flex' }}>
                <Radio.Button
                  value="PotentialAudience"
                  style={{ width: '100%', textAlign: 'center' }}
                >
                  <Body weight="regular">
                    Pessoa Física
                  </Body>
                </Radio.Button>
                <Radio.Button
                  value="PotentialAudiencePJ"
                  style={{ width: '100%', textAlign: 'center' }}
                >
                  <Body weight="regular">
                    Pessoa Jurídica
                  </Body>
                </Radio.Button>
              </Radio.Group>
            </RadioButtonGroup> : <></>
        }
        <RadiusInput>
          <Form.Item
            name="address"
            label={`${tFilter('form.address')} :`}
            shouldUpdate
            rules={
              (hasSegmentatioAnalysisTab || filterPotentialAudience?.data[0]?.fileImportId) &&
                radiusValue === '0' ||
                radiusValue === '' ||
                radiusValue === 0
                ? undefined
                : formValidation.address
            }
          >
            <Autocomplete
              value={form.getFieldValue('address')}
              form={form}
              name="address"
              setPlace={setPlaceSelected}
            />
          </Form.Item>
          <RadiusContainer>
            <Radius
              name="radius"
              rules={
                hasSegmentatioAnalysisTab || filterPotentialAudience?.data[0]?.fileImportId ? undefined : formValidation.radius
              }
              validateFirst
              label={
                <RadiusInputLabel>
                  <Body>{tFilter('form.radius')}</Body>
                  <Tooltip title="O raio de análise será aplicado a partir do endereço do filtro. Você também pode escolher manualmente através do botão de raio.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                  <Body>:</Body>
                </RadiusInputLabel>
              }
            >
              <Input
                type="number"
                size="middle"
                min={0}
                addonAfter={selectAfter}
              />
            </Radius>
          </RadiusContainer>
        </RadiusInput>
      </Content>
      <Footer>
        <FooterWrapper>
          <FormGroups
            groupOrder="column"
            form={form}
            origin={selectedValue}
            hasSegmentatioAnalysisTab={hasSegmentatioAnalysisTab}
          />
        </FooterWrapper>

        <Button
          type="primary"
          htmlType="submit"
          icon={<SearchOutlined />}
          loading={isLoading}
        >
          {tFilter('filter')}
        </Button>
      </Footer>
    </Container>
  );
};

export default memo(Filters);
