import React, { useMemo } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
// import CombineFields from '../../components/ImportFileSteps/CombineFields';
import FinishImport from './UploadFile/FinishImport';
import {
  StepsItemGroupStepsContent,
  StepsItemGroupStep,
  StepsItemGroupWraper,
  StepsItemGroup,
} from './styles';
import { useCustomTranslation } from '../../hooks/custom-translation';
import {
  Body,
  ButtonHeader,
  Container,
  Content,
  PageHeader,
} from '../../styles/contents';
import { H3 } from '../../styles/fonts';

import * as CONSTANTS from '../../navigation/constants';
import UploadFile from './UploadFile';

const RFVImportWizard = () => {
  const [current, setCurrent] = React.useState(0);
  const [importerId, setImporterId] = React.useState('');
  const [customerId, setCustomerId] = React.useState('');
  const change = (a: any) => {
    setCurrent(a);
  };

  // const { tImportContact } = useCustomTranslation('importContact');
  const { tImportRfv } = useCustomTranslation('importRfv');
  const steps = useMemo(
    () => [
      {
        title: `${tImportRfv('upload_file')}`,
        content: (
          <UploadFile
            change={change}
            setImporterId={setImporterId}
            setCustomerId={setCustomerId}
          />
        ),
      },
      {
        title: `${tImportRfv('import')}`,
        content: (
          <FinishImport importerId={importerId} customerId={customerId} />
        ),
      },
    ],
    [importerId, tImportRfv],
  );

  const navigate = useNavigate();

  const backToContacts = () => {
    navigate(CONSTANTS.IMPORT_RFV);
  };
  return (
    <Container>
      <PageHeader>
        <ButtonHeader
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={backToContacts}
        />
        <H3 weight="medium">{tImportRfv('import_contacts')}</H3>
      </PageHeader>
      <Body>
        <Content>
          <StepsItemGroupWraper>
            <StepsItemGroup
              current={current}
              type="default"
              direction="horizontal"
            >
              <StepsItemGroupStep title={steps[0].title} />
              <StepsItemGroupStep title={steps[1].title} />
            </StepsItemGroup>
            <StepsItemGroupStepsContent>
              {steps[current].content}
            </StepsItemGroupStepsContent>
          </StepsItemGroupWraper>
        </Content>
      </Body>
    </Container>
  );
};

export default RFVImportWizard;
