import { memo, PropsWithChildren } from 'react';

import { Wrapper as WrapperMaps, Status } from '@googlemaps/react-wrapper';

import { SystemEnvs } from '../../../config/envs';

const render = (status: Status) => {
  return <h1>{status}</h1>;
};

const Wrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <WrapperMaps
      apiKey={SystemEnvs.GOOGLE_MAPS_API_KEY}
      render={render}
      libraries={['drawing', 'geometry', 'places', 'visualization']}
    >
      {children}
    </WrapperMaps>
  );
};

export default memo(Wrapper);
