import { CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import pattern from '../../assets/pattern.svg';
import GenericCodeButton from '../Button';
import GenericInputSearch from '../InputSearch';

export const Sider1 = styled.div`
  width: 2.5rem;
  height: 100%;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  background-color: ${props => props.theme.colors.primary[3]};
  //background-image: url(${pattern});
`;

export const NumberChoice = styled.div`
  height: 37.5rem;
  display: flex;
  background-color: #ffffff;
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem 1.5rem 0rem 1.5rem;
`;
export const PageHeader = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
`;
export const TopBar = styled.div`
  justify-content: space-between;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;
export const Content1 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
`;

export const Search = styled(GenericInputSearch)``;

export const Button = styled(GenericCodeButton)`
  // min-width: 0;
`;

export const NumberSection = styled.div`
  height: 27rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 0.5rem;
`;

export const CloseButton = styled(CloseOutlined)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.character.primary};
`;
