import styled from 'styled-components';

export const Wrapper = styled.nav``;

export const List = styled.ul`
  display: flex;
  gap: 2rem;
`;

export const Item = styled.li``;
