interface ErrorProps {
  errorCode: string;
  errorMessage: string;
}

const getErrors = (error: ErrorProps[]) => {
  return (
    <>
      {Array.isArray(error) ? (
        error?.map((erro: any) => {
          return (
            <>
              {erro.errorMessage}
              <br />
            </>
          );
        })
      ) : (
        <>
          {error}
          <br />
        </>
      )}
    </>
  );
};

export default getErrors;
