import styled from 'styled-components';
import pattern from '../../../assets/pattern.svg';
import { FormCheckboxGroup } from '../../FormCheckbox/styles';

export const CheckboxGroup = styled(FormCheckboxGroup)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  padding-bottom: 1rem;
`;

export const WizardWelcome = styled.div`
  width: 43.75rem;
  height: 40.44rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Sider1 = styled.div`
  width: 2.5rem;
  height: 100%;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  background-color: ${props => props.theme.colors.primary[3]};
  //background-image: url(${pattern});
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 40.44rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  background-color: #ffffff;
  box-shadow: 1.75rem 0.5rem rgba(0, 0, 0, 0.05), 1rem rgba(0, 0, 0, 0.08),
    0.38rem -0.25rem rgba(0, 0, 0, 0.12);
`;
export const PageHeader = styled.div`
  justify-content: space-between;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;
export const TopBar = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 1rem;
  align-items: center;
`;

export const Fields = styled.div`
  width: 100%;
  height: 23rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 0.5rem;
`;
export const Row01 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  label {
    margin-left: 0px !important;
  }
`;
export const Column01 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Column02 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Row02 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`;
export const Column011 = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Column021 = styled.div`
  width: 100%;
  height: 25rem;
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Row021 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`;
export const Column012 = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Column022 = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ActionBar = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
`;
