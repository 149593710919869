import { Rule } from 'antd/lib/form';
import { useCustomTranslation } from '../../hooks/custom-translation';

export const NotZero = (): Rule => {
  const { tValidationMessages } = useCustomTranslation('validationMessages');
  return {
    validator: (_, value) => {
      return Number(value) !== 0
        ? Promise.resolve()
        : Promise.reject(new Error(tValidationMessages('required')));
    },
  };
};
