import styled, { css } from 'styled-components';

interface ItemProps {
  type?: string;
  color?: 'danger';
}

export const Container = styled.ul`
  /* width: 16rem; */
  border-radius: 0.25rem;
  box-shadow: 0 0 50px 0 rgb(82 63 105 / 15%);
  background-color: ${props => props.theme.colors.neutral.gray[1]};
`;

export const Item = styled.li<ItemProps>`
  display: flex;
  align-items: center;

  /* padding: 0.5rem 0.75rem; */
  width: 100%;

  > button {
    color: inherit;
    > .anticon {
      font-size: 1rem;
    }
  }

  ${props =>
    props.type === 'divider' &&
    css`
      border-bottom: 1px solid ${props.theme.colors.neutral.gray[5]};
      height: 0;
      padding: 0;
    `}

  ${props =>
    props.color === 'danger' &&
    css`
      color: ${props.theme.colors.dustRed[5]};
    `}
`;
