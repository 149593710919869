import styled from 'styled-components';

import { CloseOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import GenericText from '../Text';
import GenericCodeButton from '../Button';

export const WizardWelcome = styled.div`
  width: 32rem;
  height: 15.38rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const LayoutPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  background-color: #ffffff;
  box-shadow: 1.75rem 0.5rem rgba(0, 0, 0, 0.05), 1rem rgba(0, 0, 0, 0.08),
    0.38rem -0.25rem rgba(0, 0, 0, 0.12);
`;
export const PageHeader = styled.div`
  justify-content: space-between;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;
export const Title = styled(GenericText)`
  font-weight: 500;
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.7);
`;

export const CloseIconButton1 = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  border-radius: 0.12rem;
  padding: 0.25rem;
`;
export const CloseIconButton = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
export const IconOutlinedClose = styled(CloseOutlined)`
  color: #000000;
  font-size: 1rem;
`;

export const Content2 = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
`;
export const TextComponentao = styled(GenericText)`
  font-weight: 400;
  font-size: 0.88rem;
  color: rgba(0, 0, 0, 0.7);
  flex: 1;
`;

export const Checkbox1 = styled(Checkbox)``;

export const ActionBar = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
`;
export const ButtonsButton2 = styled.div`
  display: inline-flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.12rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
`;
export const Content3 = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
export const CancelButton = styled(GenericCodeButton)`
  font-weight: 500;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.7);
`;

export const DeleteButton = styled(GenericCodeButton)`
  /* width: 8.81rem; */
  height: 2.5rem;
  font-weight: 500;
  border-radius: 0.12rem;
`;
