import { salesBoxApi } from '../../config/apis';
import { CustomFieldRoute } from '../../config/services-route';
import { URLs } from '../../helpers/urls';
import { TagTypes } from '../types';
import { providesList } from '../../helpers/provides';
import { CustomFieldProps } from './customField';

const customFieldApi = salesBoxApi.injectEndpoints({
  endpoints: builder => ({
    getAllCustomField: builder.query<CustomFieldProps[], void>({
      query: () => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: CustomFieldRoute.customField,
        };
      },
      providesTags: response => providesList(response, TagTypes.CustomField),
    }),
    getCustomFieldById: builder.query<CustomFieldProps, string>({
      query: id => ({
        url: URLs.combine(CustomFieldRoute.customField, `${id}`),
      }),
    }),
    postCustomField: builder.mutation<
      CustomFieldProps,
      Pick<CustomFieldProps, 'fieldTypeId' | 'name' | 'isEnabled'>
    >({
      query: body => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: CustomFieldRoute.customField,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: TagTypes.FileImport, id: 'LIST' }],
    }),
    updateCustomField: builder.mutation<
      CustomFieldProps,
      Partial<CustomFieldProps>
    >({
      query: ({ id, ...body }) => {
        return {
          url: URLs.combine(CustomFieldRoute.customField, `${id}`),
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: [{ type: TagTypes.CustomField, id: 'LIST' }],
    }),
    deleteCustomField: builder.mutation<CustomFieldProps, string>({
      query: id => {
        return {
          url: URLs.combine(CustomFieldRoute.customField, `${id}`),
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: TagTypes.CustomField, id: 'LIST' }],
    }),
  }),
  overrideExisting: true,
});
export const {
  useGetAllCustomFieldQuery,
  useGetCustomFieldByIdQuery,
  usePostCustomFieldMutation,
  useUpdateCustomFieldMutation,
  useDeleteCustomFieldMutation,
} = customFieldApi;
