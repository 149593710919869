/* eslint-disable react/destructuring-assignment */
// import { CloseOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd';
import Form from '../../components/Form';
import Select from '../../components/Select';
import { H5 } from '../../styles/fonts';
import {
  Content1,
  ContentHeader,
  FilterArea,
  IconWrapper,
  OrAnd01,
} from './styles';

import Query from './Query';

interface IFormGroup {
  groupOrder: 'row' | 'column';
  form: FormInstance;
  origin: string;
  hasSegmentatioAnalysisTab?: boolean;
}

const FormGroups = ({
  groupOrder,
  form,
  origin,
  hasSegmentatioAnalysisTab,
}: IFormGroup) => {
  const genericListSelectInputPotentialAudiency = [
    {
      key: 'e1391998-a637-41af-b2f9-f117dd4b00ca',
      name: 'E',
    },
    {
      key: '722bc40c-6ea4-4f4c-b829-6c78eb6c7e57',
      name: 'Ou',
    },
  ];

  const genericListSelectInputAnalysisSegmentation = [
    {
      key: '07174c20-607e-46cd-b7ac-1903f3d378ed',
      name: 'E',
    },
    {
      key: '732f6aa1-4848-432b-b675-d8b611ab1e10',
      name: 'Ou',
    },
  ];

  const genericListSelector = hasSegmentatioAnalysisTab
    ? genericListSelectInputAnalysisSegmentation
    : genericListSelectInputPotentialAudiency;

  // const handleRemoveGroup = (remove: any, fieldname: any) => {
  //   const fields = form.getFieldsValue();
  //   const { groups } = fields;

  //   if (groups.length > fieldname + 1) {
  //     Object.assign(groups[fieldname + 1], {
  //       filterOperatorId: null,
  //     });
  //   }

  //   form.setFieldsValue({ groups });

  //   remove(fieldname);
  // };

  return (
    <Form.List name="groups" initialValue={[{}]}>
      {groups => {
        return (
          <>
            {groups.map((group: any) => (
              <div key={group.name}>
                {groups.length > 1 && group.name > 0 && (
                  <OrAnd01>
                    <Form.Item
                      rules={[{ required: true }]}
                      name={[group.name, 'filterOperatorId']}
                      fieldKey={[group.fieldKey, 'filterOperatorId']}
                    >
                      <Select>
                        {genericListSelector.map(item => (
                          <Select.Option key={item.key} value={item.key}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </OrAnd01>
                )}
                <Content1>
                  <ContentHeader>
                    <H5 weight="medium">Filtros</H5>
                    <IconWrapper>
                      {/* <CloseOutlined
                        style={{
                          color: '#722ed1',
                        }}
                        onClick={() => handleRemoveGroup(remove, group.name)}
                      /> */}
                    </IconWrapper>
                  </ContentHeader>
                  <FilterArea>
                    <Query
                      origin={origin}
                      group={group}
                      groupOrder={groupOrder}
                      formInstance={form}
                      hasSegmentatioAnalysisTab={
                        hasSegmentatioAnalysisTab ?? false
                      }
                    />
                  </FilterArea>
                </Content1>
              </div>
            ))}
            {/* <div
              style={{
                width: '200',
              }}
            >
              <Button
                icon={<PlusOutlined />}
                size="large"
                onClick={() => add()}
              >
                <Body weight="medium">Adicionar outro grupo</Body>
              </Button>
            </div> */}
          </>
        );
      }}
    </Form.List>
  );
};

export default FormGroups;
