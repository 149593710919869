import styled from 'styled-components';

import { Button, Select } from 'antd';

import GenericText from '../../components/Text';
import GenericSelect from '../../components/Select';

import GenericCodeButton from '../../components/Button';

export const ContactManagement = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1.5rem 2.93rem;
  background-color: #fbfbfb;
  position: relative;
  overflow-y: scroll;
`;
export const PageHeader = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;

export const Body = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  background-color: #ffffff;
`;

export const ButtonInputSearch = styled(Button)``;

export const SelectInput = styled(GenericSelect)``;

export const SelectInputOption = styled(Select.Option)``;

export const Totals = styled(GenericText)`
  font-weight: 400;
  font-size: 1rem;
`;

export const FAB = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  padding: 0rem 1.5rem 1.5rem 0rem;
  box-shadow: 1.75rem 0.5rem rgba(0, 0, 0, 0.05), 1rem rgba(0, 0, 0, 0.08),
    0.38rem -0.25rem rgba(0, 0, 0, 0.12);
  position: absolute;
  right: 0rem;
  bottom: 0rem;
`;
export const AddContactButton = styled(GenericCodeButton)`
  max-width: 15rem;
  height: 2.5rem;
  border-radius: 0.12rem;
`;

export const ActionButton = styled(GenericCodeButton)`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.12rem;
`;

export const Title = styled(GenericText)`
  font-size: 1.88rem;
  flex: 1;
  font-weight: 500;
  color: ${props => props.theme.colors.character.primary};
`;
