import { ConfigEnvs } from '../config/envs';

export const ROOT = ConfigEnvs.PUBLIC_URL;
export const WIZARD_CONTACTS = `${ROOT}/wizard-contacts`;
export const WIZARD_NO_CONTACTS = `${ROOT}/wizard-no-contacts`;
export const WIZARD_WELCOME = `${ROOT}/wizard-welcome`;
export const UNAUTHORIZED = `${ROOT}/unauthorized`;
export const CONTACT_IMPORT_WIZARD_PF_WITH_ID = `${ROOT}/contact-import-wizard-pf/:id?`;
export const CONTACT_IMPORT_WIZARD_PF = `${ROOT}/contact-import-wizard-pf`;
export const MODAL_ADD_USER_ROLE = `${ROOT}/modal-add-user-role`;
export const MODAL_ADD_PLAN = `${ROOT}/modal-add-plan`;
export const MODAL_ADD_CLIENT = `${ROOT}/modal-add-client`;
export const MODAL_ADD_RESALE = `${ROOT}/modal-add-resale`;
export const SYSTEM_SETTINGS = `${ROOT}/system-settings`;
export const EDIT_USER = `${ROOT}/edit-user`;
export const CONTACT_MANAGENENT = `${ROOT}/contact-management`;
export const CONTACT_PROFILE = `${ROOT}/contact-profile`;
export const DASHBOARD = `${ROOT}/dashboard`;
export const EDIT_SEGMENT = `${ROOT}/edit-segment`;
export const MAP = `${ROOT}/map`;
export const CREATIVE_MANAGEMENT = `${ROOT}/creative-management`;
export const CHOOSE_GENERATOR = `${ROOT}/choose-generator`;
export const NEW_CREATIVE_TEXT = `${ROOT}/creative/text/new`;
export const CREATE_POTENTIAL_AUDIENCE = `${ROOT}/map/create-potential-audience`;
export const EDIT_POTENTIAL_AUDIENCE = `${ROOT}/map/edit-potential-audience`;
export const FACEBOOK_MANAGEMENT_CAMPAIGN = `${ROOT}/list-social-midia-campaign`;
export const NEW_CAMPAIGN_SOCIAL_MIDIA = `${ROOT}/social-midia-campaign`;
export const INTEGRATIONS = `${ROOT}/integrations`;
export const PERSONAS = `${ROOT}/personas`;
export const REPORTS = `${ROOT}/reports`;
export const WHATSAPP_MANAGEMENT_CAMPAIGN = `${ROOT}/list-whatsapp-campaign`;
export const WHATSAPP_CAMPAIGN_WIZARD = `${ROOT}/whatsapp-campaign-wizard`;
export const SOCIAL_MEDIA_POST_MANAGEMENT = `${ROOT}/social-media-post-management`;
export const SOCIAL_MEDIA_POST_WIZARD = `${ROOT}/social-media-post-wizard`;
export const CHAT = `${ROOT}/chat`;
export const DATABASE_MANAGEMENT_PF = `${ROOT}/database-management-pf`;
export const CONTACT_SEGMENTATION = `${ROOT}/contact-segmentation`;
export const SEGMENTATION_ANALYSIS = `${ROOT}/segmentation-analysis`;
export const PUBLIC_MANAGEMENT = `${ROOT}/public_management`;
export const CONNECTION_MANAGEMENT = `${ROOT}/connection_management`;
export const IMPORT_RFV = `${ROOT}/import-rfv`;
export const RFV = `${ROOT}/rfv`;
export const RFV_IMPORT_WIZARD = `${ROOT}/rfv-import-wizard`;
export const WHATSAPP = `${ROOT}/whatsapp`;
export const UPGRADE = `${ROOT}/upgrade`;
export const DATABASE_MANAGEMENT_PJ = `${ROOT}/database-management-pj`;
export const CONTACT_IMPORT_WIZARD_PJ_WITH_ID = `${ROOT}/contact-import-wizard-pj/:id?`;
export const CONTACT_IMPORT_WIZARD_PJ = `${ROOT}/contact-import-wizard-pj`;
