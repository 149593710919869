import { CheckCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Upload as UploadAnt } from 'antd';
import GenericText from '../../../Text';
import IconImage from '../../../../assets/empty-icon.svg';

const { Dragger } = UploadAnt;

export const Upload1 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 0.12rem solid #88e9df;
  box-sizing: border-box;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.12rem;
  padding: 1rem;
  background-color: #e7fffc;
`;

export const IconOutlinedCheckCircle = styled(CheckCircleOutlined)`
  color: #14c3c3;
  font-size: 3rem;
`;

export const TextWrapper1 = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
`;
export const ClientesBrasilxlsx = styled(GenericText)`
  font-size: 1rem;
`;

export const Selecionaroutroarquivo = styled(GenericText)`
  font-weight: 400;
  font-size: 0.88rem;
  color: #09989d;
`;

export const Upload = styled.div`
  width: 100%;
  height: 10rem;
  display: flex;
  flex-direction: column;
  border: 0.12rem solid #2966f5;
  box-sizing: border-box;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.12rem;
  padding: 1rem;
  background-color: #e8ecfe;
`;
export const Icon = styled.img.attrs({
  src: IconImage,
  heigth: 'auto',
  width: '100%',
})`
  max-width: 48px;
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
`;
export const TextComponentarraste = styled(GenericText)`
  font-size: 1rem;
  color: ${props => props.theme.colors.character.primary};
`;

export const TextComponentcaso = styled(GenericText)`
  font-weight: 400;
  font-size: 0.88rem;
  color: #2966f5;
  flex: 1;
`;

export const UploadDragAndDrop = styled(Dragger)`
  width: 100% !important;
`;
