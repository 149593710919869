import React, { PropsWithChildren } from 'react';
import Skeletons from '../../../components/Skeletons';

const Suspense: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <React.Suspense fallback={<Skeletons.Content />}>{children}</React.Suspense>
  );
};

export default Suspense;
