import styled from 'styled-components';

export const Content02 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem 2rem 1rem 2rem;
`;

export const Label = styled.div`
  justify-content: space-between;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;

export const Row011 = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 2.5rem;
`;
export const Data012 = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Data022 = styled.div`
  width: 50%;
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
`;
