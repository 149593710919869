import { SyncOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Segments from '../../components/Segments';
import { useCustomTranslation } from '../../hooks/custom-translation';
import { H3 } from '../../styles/fonts';
import { Container, PageHeader, Wrapper } from './styles';
import Button from '../../components/Button';
import { AddContactButton } from '../ContactManagement/styles';
import * as CONSTANTS from '../../navigation/constants';

const ContactSegmentations = () => {
  const { tContactSegmentsEdit } = useCustomTranslation('contactSegmentsEdit');
  const [refetch, setRefetch] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleRefetch = () => {
    setRefetch(true);
  };

  useEffect(() => {
    if (refetch) {
      setRefetch(false);
    }
  }, [refetch]);

  const handleNavigateNewSegment = () => {
    navigate(`${CONSTANTS.EDIT_SEGMENT}`);
  };

  return (
    <Container>
      <PageHeader>
        <H3 weight="medium">{tContactSegmentsEdit('contact_segmentation')}</H3>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            type="default"
            icon={<SyncOutlined />}
            size="large"
            onClick={handleRefetch}
            loading={refetch}
          >
            {tContactSegmentsEdit('refresh')}
          </Button>
          <AddContactButton
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            onClick={() => handleNavigateNewSegment()}
          >
            Criar Segmentação
          </AddContactButton>
        </div>
      </PageHeader>
      <Wrapper>
        <Segments refetch={refetch} />
      </Wrapper>
    </Container>
  );
};

export default ContactSegmentations;
