import styled, { keyframes } from 'styled-components';

// Animação da progressão do círculo
const progressAnimation = keyframes`
  0% {
    stroke-dasharray: 0 100;
  }
`;

// Contêiner para o círculo de progresso
export const ProgressCircle = styled.div`
  width: 200px;
  height: 200px;
  margin: 0 auto;
`;

// Estilização do gráfico circular
export const CircularChart = styled.svg`
  display: block;
  max-width: 100%;
  max-height: 100%;
`;

// Fundo do círculo
export const CircleBg = styled.circle`
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
`;

// Círculo animado
export const Circle = styled.circle`
  fill: none;
  stroke-width: 3.8;
  stroke: #2966f5;
  stroke-linecap: round;
  animation: ${progressAnimation} 1s ease-out forwards;
`;

// Texto percentual no centro do círculo
export const Percentage = styled.text`
  fill: #2c3e50;
  font-size: 0.5em;
  text-anchor: middle;
`;
