/* eslint-disable consistent-return */

/* eslint-disable array-callback-return */
type Order = 'asc' | 'desc';

type FilterTypes = 'contain' | 'eq';

type OrderProps<T> = {
  [K in keyof T]: Order;
};

type FilterProps<T> = {
  [K in keyof T]: any;
};

interface FilterInterfaceProps<T> {
  filter: FilterProps<T>;
  type: FilterTypes;
}

type ExpandProps<T> = {
  [K in keyof T]: string;
};

export interface BuilderQueryProps<T> {
  skip?: number;
  top: number;
  filter?: FilterInterfaceProps<T>[];
  count?: boolean;
  orderby?: OrderProps<T>;
  expand?: Array<ExpandProps<keyof T>>;
  custom?: string;
  nameControllerOdata?: string;
}

export interface UploadLogoQueryParams {
  fileName: string;
  contentType: string;
}

export function builderQueryOdata<T>({
  skip,
  top,
  filter,
  count = true,
  orderby,
  expand,
  custom,
}: BuilderQueryProps<T>): string {
  let query = '';

  if (top) query = `${query}?&$top=${top}`;

  if (skip || skip === 0) {
    const next = (skip as number) * 6 - 6;
    query = `${query}&$skip=${next}`;
  }
  if (count) query = `&$${query}&$count=${count}`;

  if (orderby) {
    const orderStr = Object.keys(orderby).map(
      key => `${key} ${orderby[key as keyof T]}`,
    );

    query = `${query}&$orderby=${orderStr}`;
  }

  if (Array.isArray(filter) && filter.length > 0) {
    const eqStr = filter
      .map((data: FilterInterfaceProps<T>) => {
        if (data.type === 'eq') {
          return Object.keys(data as any).map(key => {
            if (
              typeof data?.filter[key as keyof FilterProps<T>] === 'boolean'
            ) {
              return `${key} eq ${data?.filter[key as keyof FilterProps<T>]}`;
            }
            return `${key} eq '${data?.filter[key as keyof FilterProps<T>]}'`;
          });
        }
        if (data.type === 'contain') {
          return Object.keys(data.filter as any).map(key => {
            if (
              typeof data?.filter[key as keyof FilterProps<T>] === 'boolean'
            ) {
              return `contains(${key}, ${
                data?.filter[key as keyof FilterProps<T>]
              })`;
            }
            return `contains(${key}, '${
              data?.filter[key as keyof FilterProps<T>]
            }')`;
          });
        }
      })
      .join(' and ');

    query = `${query}&$filter=${eqStr}`;
  }

  if (Array.isArray(expand)) {
    const expandStr = expand.map((data: keyof T) => {
      return `${String(data)}`;
    });
    query = `${query}&$expand=${expandStr}`;
  }

  if (custom) {
    query = `${query}&${custom}`;
  }

  return query || '';
}

export function createQueryByRole(
  property: string,
  findBy: 'resale' | 'customer',
  userInfo: any,
  _search?: string,
  withFilterConnector?: boolean,
  key?: string,
): string {
  const role = userInfo.roles[0];
  let query = '';
  if (role === 'Revenda') {
    query = `${
      withFilterConnector ? '$filter=' : ''
    }cast(${property}, Edm.String) eq '${
      findBy === 'resale' ? userInfo.resale : userInfo.customer
    }' ${_search ? `and contains(${key}, '${_search}')` : ''}`;
  } else {
    query = _search
      ? `${withFilterConnector ? '$filter=' : ''}contains(${key}, '${_search}')`
      : '';
  }

  return query;
}

export function createQueryById(id?: string): string {
  const query = `$filter=cast(id, Edm.String) eq '${id}'`;

  return query;
}
