import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
`;

export const IconWrapper = styled.div`
  background-color: #25d366;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;

export const ChatBox = styled.div`
  position: absolute;
  bottom: 80px;
  right: 0;
  width: 300px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 1000;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const ProfilePicture = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;

export const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #4a4a4a;
`;

export const Message = styled.div`
  background: #f1f1f1;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #4a4a4a;

  p {
    margin: 0;
  }
`;

export const MessageInput = styled.input`
  width: calc(100% - 80px);
  border: none;
  padding: 8px;
  margin-right: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`;

export const SendButton = styled.button`
  width: 60px;
  padding: 8px;
  background-color: #25d366;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`;
