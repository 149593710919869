import { deepDiveApi } from '../../config/apis/deep-dive-query';
import { descriptiveAnalysis } from '../../config/services-route/descriptiveAnalysisPj';
import { DescriptiveAnalysisPjProps } from './descriptiveAnalysis';

const descriptiveAnalysisApi = deepDiveApi.injectEndpoints({
  endpoints: builder => ({
    getAllDescriptiveAnalysis: builder.query<DescriptiveAnalysisPjProps, any>({
      query: ({ origin }) => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: `${descriptiveAnalysis}?origin=${origin}`,
        };
      },
    }),

    getAllDescriptiveAnalysisPjByOriginId: builder.query<
      DescriptiveAnalysisPjProps,
      any
    >({
      query: ({ origin, originId }) => {
        console.log('originId: ', originId);
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: `${descriptiveAnalysis}?origin=${origin}&originId=${originId}`,
        };
      },
    }),

    updateSegmentationAnalysis: builder.mutation<
      Partial<DescriptiveAnalysisPjProps>,
      { originId: string; origin: string }
    >({
      query: body => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: descriptiveAnalysis,
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const {
  useGetAllDescriptiveAnalysisQuery,
  useGetAllDescriptiveAnalysisPjByOriginIdQuery,
  useUpdateSegmentationAnalysisMutation,
} = descriptiveAnalysisApi;
