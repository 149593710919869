import { useState, useCallback, useEffect } from 'react';
import { ColumnProps } from 'antd/lib/table';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  PlusOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu, Tag } from 'antd';
import { Navigate, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { H3 } from '../../../styles/fonts';
import * as S from './styles';
import { useCustomTranslation } from '../../../hooks/custom-translation';
import Button from '../../../components/Button';
import * as CONSTANTS from '../../../navigation/constants';
import {
  useDeleteAdCreativeMutation,
  useGetAllCreativeFilteredQuery,
} from '../../../services/creativeGenerator';
import Loading from '../../../components/Loading';
import { ShowFormMessage } from '../../../helpers/message';
import getErrors from '../../../helpers/errors';
import { AdCreativeProps } from '../../../services/creativeGenerator/creativeGenerator';

interface ITagColor {
  [key: string]: string;
}

interface ITagIcon {
  [key: string]: JSX.Element;
}

const CreativeManagementPage = () => {
  const { tCreativeGenerator } = useCustomTranslation('creativeGenerator');
  const [creatives, setCreatives] = useState<AdCreativeProps[]>([]);
  const navigate = useNavigate();

  const { data: adCreatives, isLoading } = useGetAllCreativeFilteredQuery({
    top: 100,
    expand: ['status'],
  });

  const [deleteAdCreative, configDeleteAdCreative] =
    useDeleteAdCreativeMutation();

  const showDeleteAdCreativeMessage = useCallback(() => {
    const { isSuccess, isError, error } = configDeleteAdCreative;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }
    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Creativo excluído com sucesso',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
  }, [configDeleteAdCreative]);

  const handleEditCreative = useCallback(
    (id: string) => {
      navigate(`${CONSTANTS.CREATIVE_MANAGEMENT}/${id}`);
    },
    [navigate],
  );

  const handleDeleteCreative = useCallback(
    async (id: string) => {
      const deleteCreative = await deleteAdCreative(id);
      return deleteCreative;
    },
    [deleteAdCreative],
  );

  const DropDownMenu = ({ id }: { id: string }) => (
    <Menu
      items={[
        {
          key: '0',
          label: (
            <Button
              type="link"
              onClick={() => handleDeleteCreative(id)}
              style={{ color: 'red' }}
              icon={<DeleteOutlined style={{ color: 'red' }} />}
            >
              {tCreativeGenerator('buttons.exclude')}
            </Button>
          ),
        },
      ]}
    />
  );

  const tagColor: ITagColor = {
    Processando: 'warning',
    Concluído: 'success',
    Erro: 'error',
  };

  const tagIcon: ITagIcon = {
    Criado: <ExclamationCircleOutlined />,
    Processando: <SyncOutlined spin />,
    Concluído: <CheckCircleOutlined />,
    Erro: <CloseCircleOutlined />,
  };

  const itemsColumn: ColumnProps<any>[] = [
    {
      title: tCreativeGenerator('table.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a?.name?.length - b?.name?.length,
    },
    {
      title: tCreativeGenerator('table.type'),
      dataIndex: 'adCreativeTypeId',
      key: 'adCreativeTypeId',
      sorter: (a, b) => a?.name?.length - b?.name?.length,
      render: id => (id === 1 ? 'Texto' : 'Image'),
    },
    {
      title: tCreativeGenerator('table.creationDate'),
      dataIndex: 'createdUtc',
      key: 'createdUtc',
      sorter: (a, b) => a?.name?.length - b?.name?.length,
      render: value => format(new Date(value), 'dd/MM/yy HH:MM'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'left',
      width: '16.46rem',
      sorter: (a, b) => a?.name?.length - b?.name?.length,
      render: ({ friendlyName }) => {
        return (
          <Tag color={tagColor[friendlyName]} icon={tagIcon[friendlyName]}>
            {friendlyName}
          </Tag>
        );
      },
    },
    {
      title: tCreativeGenerator('table.actions'),
      dataIndex: 'actions',
      align: 'left',
      width: '11.56rem',
      render: (_, record: any) => {
        return (
          <S.ActionsContainer>
            <Button
              type="default"
              icon={<EditOutlined />}
              onClick={() => handleEditCreative(record.id)}
            >
              {tCreativeGenerator('buttons.edit')}
            </Button>
            <Dropdown overlay={<DropDownMenu id={record.id} />}>
              <Button type="default" icon={<MoreOutlined />} />
            </Dropdown>
          </S.ActionsContainer>
        );
      },
    },
  ];

  useEffect(showDeleteAdCreativeMessage, [showDeleteAdCreativeMessage]);

  useEffect(() => {
    if (adCreatives?.data.length) {
      const creativesNotDeleted: AdCreativeProps[] = adCreatives?.data.filter(
        creative => creative.isDeleted === false,
      );
      setCreatives(creativesNotDeleted);
    }
  }, [adCreatives]);

  if (isLoading) {
    return <Loading onFinish={() => null} looping />;
  }

  if (adCreatives?.meta.size === 0) {
    return <Navigate to={CONSTANTS.CHOOSE_GENERATOR} replace />;
  }

  return (
    <S.Container>
      <S.PageHeader>
        <H3 weight="medium">{tCreativeGenerator('pages.management.title')}</H3>

        <Button
          type="primary"
          icon={<PlusOutlined />}
          size="large"
          onClick={() => navigate(CONSTANTS.CHOOSE_GENERATOR)}
        >
          {tCreativeGenerator('buttons.create')}
        </Button>
      </S.PageHeader>
      <S.Wrapper>
        <S.Content>
          <S.Table dataSource={creatives} columns={itemsColumn} />
        </S.Content>
      </S.Wrapper>
    </S.Container>
  );
};

export default CreativeManagementPage;
