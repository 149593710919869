import { message } from 'antd';
import { MessageType } from 'antd/lib/message';

interface ConfigProps {
  duration?: number;
}

type MessageTypes = 'info' | 'success' | 'warning' | 'error';

const combineClass = (className: MessageTypes) =>
  `custom-message message-${className}`;

const defaultConfig = {
  duration: 3,
};

export const info = (
  content: string,
  customConfig?: ConfigProps,
): MessageType => {
  const config = {
    ...defaultConfig,
    ...customConfig,
  };

  return message.info({
    content,
    ...config,
    className: combineClass('info'),
  });
};

export const success = (
  content: string,
  customConfig?: ConfigProps,
): MessageType => {
  const config = {
    ...defaultConfig,
    ...customConfig,
  };

  return message.success({
    content,
    ...config,
    className: combineClass('success'),
  });
};

export const warning = (
  content: string,
  customConfig?: ConfigProps,
): MessageType => {
  const config = {
    ...defaultConfig,
    ...customConfig,
  };

  return message.warning({
    content,
    ...config,
    className: combineClass('warning'),
  });
};

export const error = (
  content: string,
  customConfig?: ConfigProps,
): MessageType => {
  const config = {
    ...defaultConfig,
    ...customConfig,
  };

  return message.error({
    content,
    ...config,
    className: combineClass('error'),
  });
};
