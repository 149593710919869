import React, { useState } from 'react';
import { WhatsAppOutlined } from '@ant-design/icons';
import {
  Container,
  IconWrapper,
  ChatBox,
  MessageInput,
  SendButton,
  Header,
  ProfilePicture,
  HeaderText,
  Message,
} from './styles';
import logowhatsapp from '../../assets/logo-whats.png';

const WhatsAppWidget = () => {
  const [message, setMessage] = useState('');
  const [chatVisible, setChatVisible] = useState(false);

  const handleSendMessage = () => {
    const encodedMessage = encodeURIComponent(message);
    window.open(`https://wa.me/5511952105351?text=${encodedMessage}`, '_blank');
    setChatVisible(false); // Fecha o chat após o envio
    setMessage(''); // Limpa a mensagem após o envio
  };

  return (
    <Container>
      <IconWrapper onClick={() => setChatVisible(!chatVisible)}>
        <WhatsAppOutlined style={{ fontSize: '30px', color: 'white' }} />
      </IconWrapper>
      {chatVisible && (
        <ChatBox>
          <Header>
            <ProfilePicture src={logowhatsapp} alt="Profile" />
            <HeaderText>Suporte Sales Box</HeaderText>
          </Header>
          <Message>
            <p>Olá 👋</p>
            <p>
              Por favor, escreva sua mensagem e nossa equipe de CX estará pronta
              para ajudar.
            </p>
          </Message>
          <MessageInput
            type="text"
            placeholder="Digite sua mensagem"
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
          <SendButton onClick={handleSendMessage}>Enviar</SendButton>
        </ChatBox>
      )}
    </Container>
  );
};

export default WhatsAppWidget;
