import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { salesBoxApi } from '../config/apis/sales-box-query';
import { deepDiveApi } from '../config/apis/deep-dive-query';

export const store = configureStore({
  reducer: {
    [salesBoxApi.reducerPath]: salesBoxApi.reducer,
    [deepDiveApi.reducerPath]: deepDiveApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(salesBoxApi.middleware),
});

setupListeners(store.dispatch);
