import styled from 'styled-components';

export const PsychometricScoreSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
`;

export const Content04 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  top: 50%;
  position: relative;
  gap: 2rem;
  padding: 1rem 2rem 1rem 2rem;
`;

export const GaugeContainer = styled.div``;
