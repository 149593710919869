import React from 'react';
import {
  ProgressCircle,
  CircularChart,
  CircleBg,
  Circle,
  Percentage,
} from './styles';

interface ProgressCardProps {
  count: number;
  total: number;
}

const ProgressCard: React.FC<ProgressCardProps> = ({ count, total }) => {
  const percentage = total > 0 ? (count / total) * 100 : 0;

  return (
    <ProgressCircle>
      <CircularChart viewBox="0 0 36 36">
        <CircleBg cx="18" cy="18" r="15.915" />
        <Circle
          cx="18"
          cy="18"
          r="15.915"
          strokeDasharray={`${percentage}, 100`}
        />
        <Percentage x="18" y="20.35">
          {Math.round(percentage)}%
        </Percentage>
      </CircularChart>
    </ProgressCircle>
  );
};

export default ProgressCard;
