import styled from 'styled-components';

export const Content02 = styled.div`
  width: 100%;
  height: 22rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 2rem;
`;

export const CollapseItemContent = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 0.5rem;
  padding: 1rem 1.5rem 1rem 1.5rem;
  background-color: #ffffff;
`;

export const Data011 = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Question1 = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Label = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
