import { BackgroundBar, PointerBar } from './styles';

interface IProps {
  value: number;
  position?: 'width' | 'left';
  backgroundBarColor?: string;
  backgroundBarBorder?: string;
  pointerBarColor?: string;
  pointerBarBorder?: string;
  pointerBarBorderRadius?: string;
  backgroundBarBorderLeft?: string;
}

const BarChart = ({
  value,
  position,
  backgroundBarColor,
  backgroundBarBorder,
  pointerBarColor,
  pointerBarBorder,
  pointerBarBorderRadius,
  backgroundBarBorderLeft,
}: IProps) => {
  return (
    <BackgroundBar
      backgroundBarColor={backgroundBarColor}
      backgroundBarBorder={backgroundBarBorder}
      backgroundBarBorderLeft={backgroundBarBorderLeft}
    >
      <PointerBar
        pointerBarColor={pointerBarColor}
        pointerBarBorder={pointerBarBorder}
        pointerBarBorderRadius={pointerBarBorderRadius}
        style={{
          [position || 'left']:
            value > 90 ? `calc(${value}% - 24px)` : `${value}%`,
        }}
      />
    </BackgroundBar>
  );
};

export default BarChart;
