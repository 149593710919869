export const numberFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const stringToNumber = (num: string) => {
  const numWithDot = num.replace(/,/g, '.');
  return Number(numWithDot);
};

export const removePercentage = (num: string) => {
  return num.replace(/%/g, '');
};
