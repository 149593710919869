import styled from 'styled-components';

import { Tabs } from 'antd';

import pattern from '../../assets/pattern.svg';
import GenericCodeButton from '../Button';

export const HeaderActions = styled.div``;

export const ButtonSeeAll = styled(GenericCodeButton)`
  color: #2966f5;
`;

export const ArrowLeft = styled.div`
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem;
`;
export const ModalPersona1 = styled.div`
  width: 60rem;
  height: 38rem;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
`;
export const Sider1 = styled.div`
  width: 2.5rem;
  height: 100%;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  background-color: ${props => props.theme.colors.primary[3]};
  //background-image: url(${pattern});
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;
export const PageHeader = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 1.5rem;
  align-items: center;
`;

export const MainInfo = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
`;
export const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
`;
export const Description = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 1rem;
`;
export const Topic01 = styled.div`
  display: inline-flex;
  flex-direction: column;
`;
export const Topic02 = styled.div`
  display: inline-flex;
  flex-direction: column;
`;
export const Topic03 = styled.div`
  display: inline-flex;
  flex-direction: column;
`;
export const Body1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid ${props => props.theme.colors.neutral.gray[5]};
`;
export const TabGroup = styled(Tabs).attrs({
  tabBarStyle: {
    padding: '',
    borderRight: '1px solid #d9d9d9',
    maxWidth: '8.625rem',
    paddingTop: '0.25rem',
  },
})`
  width: 100%;

  .ant-tabs-tab {
    font-size: 12px;
  }
`;

export const TabGroupPane = styled(Tabs.TabPane)`
  padding-top: 1rem;
`;

export const ArrowRight = styled.div`
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem;
`;
