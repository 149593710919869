/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';

import { ColumnProps } from 'antd/lib/table';

import { PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import {
  Container,
  SelectColumnFile,
  SelectColumnFileOptions,
  CombineFieldsTable,
  ActionBar,
  ButtonBeginImport,
  SelectDefaultFieldGroup,
  AddCustomField,
  SelectCustomFieldGroup,
} from './styles';
import {
  CombineFieldProps,
  useGetDefaultColumnsQuery,
  useGetFileImporterByIdQuery,
  usePostCombineFieldsMutation,
} from '../../../services/fileImporter';
import { useCustomTranslation } from '../../../hooks/custom-translation';
import ModalAddCustomField from '../../../pages/ContactImportWizard/ModalAddCustomField';
import { Feedback } from '../../../helpers/message';

interface TableGroupProps {
  key: number;
  colunaDoArquivo: string;
  campoEquivalente?: string;
}

const CombineFields = ({ change, importerId }: any) => {
  const { id } = useParams();
  const { tImportContact } = useCustomTranslation('importContact');

  const { data: columns } = useGetDefaultColumnsQuery();

  const { data: importData } = useGetFileImporterByIdQuery(id || importerId);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [filteredColumns, setFilteredColumns] = useState(columns);
  const [combineFields, setCombineFields] = useState<CombineFieldProps[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [postCombineFields] = usePostCombineFieldsMutation();

  useEffect(() => {
    setFilteredColumns(columns);
  }, [columns]);

  const defaultFieldFiltered = filteredColumns?.defaultFields?.filter(
    (a: any) => !selectedItems.includes(a.name),
  );

  const customFieldFiltered = filteredColumns?.customFields?.filter(
    (a: any) => !selectedItems.includes(a.name),
  );
  const dataTableGroup = importData?.fileFields?.map(
    (value: string, idx: number) => {
      return {
        key: idx,
        colunaDoArquivo: value,
        campoEquivalente: value,
      };
    },
  );

  const handleChangeField = useCallback(
    (equivalentField: string, fileField: string) => {
      const hasField = combineFields?.find(
        (a: any) => a.fileField === fileField,
      );

      if (!hasField) {
        setCombineFields(prev => [...prev, { fileField, equivalentField }]);
      } else {
        const newArry = combineFields.map(item => {
          if (item.fileField === fileField) {
            return {
              fileField,
              equivalentField,
            };
          }
          return item;
        });
        setCombineFields(newArry);
      }

      setSelectedItems(prev => [...prev, equivalentField]);
    },
    [combineFields],
  );

  const handleDeselect = useCallback(
    (equivalentField: any) => {
      setSelectedItems(selectedItems.filter(o => o !== equivalentField));
      setCombineFields(
        combineFields.filter(a => a.equivalentField !== equivalentField),
      );
    },
    [combineFields, selectedItems],
  );

  const handleShowModalAddCustomField = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModalAddCustomField = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const saveFileFieldsCombine = useCallback(async () => {
    const hasEmailOrCpf = combineFields.find(
      field =>
        field.equivalentField === 'CPF' || field.equivalentField === 'E-mail',
    );

    if (!hasEmailOrCpf) {
      Feedback.error(tImportContact('error_required_fields'));
    } else {
      try {
        await postCombineFields({
          fileImportId: importerId || id,
          columnsMatch: combineFields,
        }).unwrap();
        change(2);
      } catch (error) {
        Feedback.error(error as string);
      }
    }
  }, [change, combineFields, id, importerId, postCombineFields]);

  const columnsTableGroup: ColumnProps<any>[] = [
    {
      title: `${tImportContact('column_field')}`,
      dataIndex: 'colunaDoArquivo',
      align: 'left',
    },
    {
      title: `${tImportContact('equivalent_field')}`,
      dataIndex: 'campoEquivalente',
      align: 'left',
      render: (record: any) => (
        <SelectColumnFile
          allowClear
          placeholder="Escolha um campo"
          onChange={(e: any) => handleChangeField(e, record)}
          onDeselect={(e: any) => handleDeselect(e)}
          dropdownRender={(menu: any) => (
            <>
              {menu}
              <AddCustomField
                size="large"
                icon={<PlusOutlined />}
                onClick={handleShowModalAddCustomField}
              >
                {tImportContact('add_custom_field')}
              </AddCustomField>
            </>
          )}
        >
          <SelectDefaultFieldGroup label={tImportContact('default_fields')}>
            {defaultFieldFiltered?.map((option: any) => (
              <SelectColumnFileOptions key={option.name} value={option.name}>
                {option.name}
              </SelectColumnFileOptions>
            ))}
          </SelectDefaultFieldGroup>

          <SelectCustomFieldGroup label={tImportContact('custom_fields')}>
            {customFieldFiltered?.map((option: any) => (
              <SelectColumnFileOptions key={option.name} value={option.name}>
                {option.name}
              </SelectColumnFileOptions>
            ))}
          </SelectCustomFieldGroup>
        </SelectColumnFile>
      ),
    },
  ];

  return (
    <Container>
      <ModalAddCustomField
        visible={isModalOpen}
        onCancel={handleCloseModalAddCustomField}
        onOk={handleCloseModalAddCustomField}
      />
      <CombineFieldsTable
        dataSource={dataTableGroup as TableGroupProps[]}
        columns={columnsTableGroup}
        pagination={false}
      />
      <ActionBar>
        <ButtonBeginImport
          type="primary"
          size="large"
          onClick={saveFileFieldsCombine}
          disabled={combineFields.length <= 0}
        >
          Iniciar importação
        </ButtonBeginImport>
      </ActionBar>
    </Container>
  );
};

export default CombineFields;
