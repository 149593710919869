import React, { useState } from 'react';
// import { VerticalAlignBottomOutlined } from '@ant-design/icons';
import { useCustomTranslation } from '../../../hooks/custom-translation';
import { usePrompt } from '../../../hooks/usePrompt';
import { FileImportProps } from '../../../services/fileImporter';
import DragAndDropFile from './DragAndDropFile';
import {
  Container,
  DownloadExempleText,
  CheckboxOption,
  ButtonWrapper,
  ButtonNextStep,
  DownloadLink,
  // ButtonBetween,
  // DownloadTemplateButton,
} from './styles';

const UploadFile = ({ change, setImporterId, importerId }: any) => {
  const [hasChecked, sethasChecked] = useState(false);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [importData, setImportData] = useState<FileImportProps | undefined>();

  const { tImportContact } = useCustomTranslation('importContact');

  const handleUploadFile = () => {
    change(1);
  };

  // const handleDownload = () => {
  //   // Lógica para iniciar o download do arquivo
  //   const downloadLink = document.createElement('a');
  //   downloadLink.href =
  //     'https://design2code-bucket-dev.s3.sa-east-1.amazonaws.com/sales-box/modelo_importacao.csv';
  //   downloadLink.download = 'modelo_importacao.csv';
  //   document.body.appendChild(downloadLink);
  //   downloadLink.click();
  //   document.body.removeChild(downloadLink);
  // };

  usePrompt(
    'Existe uma importação em andamento, caso voce navegue para outra pagina, voce perderá o processo. Deseja sair?',
    isLoadingFile && !importData,
  );

  return (
    <Container>
      <DragAndDropFile
        importData={importData}
        setImportData={setImportData}
        setImporterId={setImporterId}
        setIsLoadingFile={setIsLoadingFile}
      />
      <DownloadExempleText>
        {tImportContact('download_template_1')}
        <DownloadLink
          download
          href="https://design2code-bucket-dev.s3.sa-east-1.amazonaws.com/sales-box/modelo_importacao.csv"
        >
          baixe aqui
        </DownloadLink>
        {tImportContact('download_template_2')}
      </DownloadExempleText>
      <CheckboxOption onChange={() => sethasChecked(!hasChecked)}>
        {tImportContact('certification_lgpd')}
      </CheckboxOption>
      <ButtonWrapper>
        <ButtonNextStep
          type="primary"
          size="large"
          disabled={!hasChecked || importerId === '' || isLoadingFile}
          onClick={handleUploadFile}
        >
          {tImportContact('continue_import')}
        </ButtonNextStep>
      </ButtonWrapper>
      {/* <ButtonBetween>
        <DownloadTemplateButton onClick={handleDownload}>
          <VerticalAlignBottomOutlined />
          <DownloadExempleText>
            Template Importação de Dados
          </DownloadExempleText>
        </DownloadTemplateButton>

        <DownloadTemplateButton onClick={handleDownload}>
          <VerticalAlignBottomOutlined />
          <DownloadExempleText>Template análise RFV</DownloadExempleText>
        </DownloadTemplateButton>
      </ButtonBetween> */}
    </Container>
  );
};

export default UploadFile;
