import styled from 'styled-components';

import { Collapse } from 'antd';

export const Sanitization = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.06rem 0.06rem 0rem 0rem;
`;
export const CollapseEnrichmentDetails = styled(Collapse)`
  width: 100%;
`;

export const CollapseEnrichmentDetailsPanel = styled(Collapse.Panel)`
  svg {
    color: #bfbfbf !important;
  }
  .ant-collapse-content-box {
    font-size: 0;
    padding: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    line-height: 0;
  }
  .ant-spin-container {
    border-top: 1px solid rgba(0, 0, 0, 0.06) !important;
  }
`;
