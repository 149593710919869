import { InputRef, Select as SelectAnt, SelectProps } from 'antd';

interface CompoundedComponent
  extends React.ForwardRefExoticComponent<
    SelectProps & React.RefAttributes<InputRef>
  > {
  Option: typeof SelectAnt.Option;
  Group: typeof SelectAnt.OptGroup;
}

const SelectGeneric: React.FC<SelectProps> = ({ ...props }) => {
  return <SelectAnt {...props} />;
};

const Select = SelectGeneric as CompoundedComponent;

Select.Option = SelectAnt.Option;

Select.Group = SelectAnt.OptGroup;

export default Select;
