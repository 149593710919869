import {
  useContext,
  createContext,
  useState,
  FC,
  PropsWithChildren,
  useRef,
  useCallback,
} from 'react';

interface MapsContextData {
  pin?: google.maps.LatLng;
  setPin(pin?: google.maps.LatLng): void;
  center?: google.maps.LatLngLiteral;
  setCenter(initialCenter: google.maps.LatLngLiteral): void;
  radius: number;
  setRadius(radius: number): void;
  placeSelected?: google.maps.places.PlaceResult;
  setPlaceSelected(place: google.maps.places.PlaceResult): void;
  getCurrentPosition(
    setCurrentPosition: (position: google.maps.LatLngLiteral) => void,
  ): void;
  getFirstStreetAddressByLocation(
    location: google.maps.LatLng,
  ): Promise<google.maps.GeocoderResult>;
}

const RADIUS = 0;

const MapsContext = createContext<MapsContextData>({} as MapsContextData);

const MapsProvider: FC<PropsWithChildren> = ({ children }) => {
  const ref = useRef(new google.maps.Geocoder());

  const [pin, setPin] = useState<google.maps.LatLng>();
  const [center, setCenter] = useState<google.maps.LatLngLiteral>();
  const [radius, setRadius] = useState<number>(RADIUS);
  const [placeSelected, setPlaceSelected] =
    useState<google.maps.places.PlaceResult>();

  const getCurrentPosition = useCallback(
    (setCurrentPosition: { (position: google.maps.LatLngLiteral): void }) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          ({ coords: { latitude, longitude } }: GeolocationPosition) => {
            setCurrentPosition({
              lat: latitude,
              lng: longitude,
            });
          },
        );
      }
      setCurrentPosition({
        lat: 0,
        lng: 0,
      });
    },
    [],
  );

  const getPlacesByLocation = useCallback(
    (location: google.maps.LatLng) => ref.current.geocode({ location }),
    [],
  );

  const getFirstStreetAddressByPlaces = useCallback(
    (places: google.maps.GeocoderResponse) => {
      return (
        places.results.find(place => place.types.includes('street_address')) ||
        places.results[0]
      );
    },
    [],
  );

  const getFirstStreetAddressByLocation = useCallback(
    (location: google.maps.LatLng) =>
      getPlacesByLocation(location).then(getFirstStreetAddressByPlaces),
    [getFirstStreetAddressByPlaces, getPlacesByLocation],
  );

  return (
    <MapsContext.Provider
      value={{
        pin,
        setPin,
        center,
        setCenter,
        radius,
        setRadius,
        placeSelected,
        setPlaceSelected,
        getCurrentPosition,
        getFirstStreetAddressByLocation,
      }}
    >
      {children}
    </MapsContext.Provider>
  );
};

function useMaps(): MapsContextData {
  const context = useContext(MapsContext);

  if (!context) {
    throw new Error('useMaps must be used within an MapsProvider');
  }

  return context;
}

export { MapsProvider, useMaps };
