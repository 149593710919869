import styled from 'styled-components';

import { Table } from 'antd';

export const Content03 = styled.div`
  width: 100%;
  display: inline-flex;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  align-items: center;
  padding: 1rem;
  background-color: #ffffff;
`;
export const TableGroup1 = styled(Table)`
  width: 100%;
`;

export const ColumnTableGroup1 = styled(Table.Column)`
  height: 10rem;
  color: #fff;
  line-height: 10rem;
  text-align: center;
  background: #364d79;
`;
