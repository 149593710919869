import styled from 'styled-components';
import { Drawer, Layout } from 'antd';

export const Wrapper = styled(Layout)`
  min-height: 100vh;
  height: 100%;
`;

export const ContentContainer = styled(Layout)``;

export const Sider = styled(ContentContainer.Sider)``;

export const Content = styled(Layout.Content)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.neutral.gray[2]};
`;

export const HeaderContainer = styled(Layout.Header)`
  padding: 0;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  z-index: 10;
`;

export const SiderMobile = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;
