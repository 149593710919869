import React, { useEffect, useState } from 'react';
// import { PlusOutlined, MoreOutlined, SyncOutlined } from '@ant-design/icons';

import {
  DatePicker,
  // Menu
} from 'antd';

import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useCustomTranslation } from '../../hooks/custom-translation';
import { H3 } from '../../styles/fonts';
// import DescriptiveAnalysisReport from '../../components/DescripriveAnalysisReport';
import WhatsappReport from '../../components/WhatsappReport';
import FacebookReport from '../../components/FacebookReport';
// import Button from '../../components/Button';
// import Dropdown from '../../components/Dropdown';

import {
  Reports,
  PageHeader,
  Body,
  TableImports,
  TabGroupPane,
  TabGroup,
  FilterSection,
  Select,
  // FAB,
  // AddContactButton,
  // ActionButton,
} from './styles';
import {
  useGetAllAccountsAndAdAccountsQuery,
  useGetAllFacebookInsightsQuery,
  useGetCampaignsByAccountIdQuery,
} from '../../services/facebookCampaign';
// import { useGetFacebookTokenQuery } from '../../services/facebookAuth';
import {
  useGetAllWhatsAppPhoneQuery,
  useGetAllWhatsAppPhoneReportsQuery,
} from '../../services/whatsAppPhone';
import { getFacebookToken } from '../../helpers/localStorage';

const ReportsPage = () => {
  const { tReports } = useCustomTranslation('reports');

  const [account, setAccount] = useState('');
  const [adAccount, setAdAccount] = useState('');
  const [campaign, setCampaign] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [waba, setWaba] = useState('');

  const { data: whatsAppNumbers } = useGetAllWhatsAppPhoneQuery();

  const token = getFacebookToken();

  const { data: campaigns } = useGetCampaignsByAccountIdQuery(
    {
      adAccount,
      accessToken: token as string,
    },
    {
      skip: !adAccount || !token,
    },
  );

  const { data: dataAccounts } = useGetAllAccountsAndAdAccountsQuery(
    token as string,
    {
      skip: !token,
    },
  );

  const { data: insightsWhatsApp } = useGetAllWhatsAppPhoneReportsQuery(waba);

  const { data: insights } = useGetAllFacebookInsightsQuery(
    {
      fields: [
        'impressions',
        'spend',
        'clicks',
        'cpp',
        'cpm',
        'cpc',
        'reach',
        'ctr',
        'actions',
      ],
      accessToken: token as string,
      time_range: `{ "since": '${startDate}', "until": '${endDate}' }`,
      timeIncrement: '1',
      adAccount: campaign || adAccount,
      summary: true,
    },
    {
      skip: !token,
    },
  );

  // const MenuDropdown = () => (
  //   <Menu
  //     items={[
  //       {
  //         key: '1',
  //         label: (
  //           <Button type="link" icon={<SyncOutlined />}>
  //             {tReports('update_analysis')}
  //           </Button>
  //         ),
  //       },
  //     ]}
  //   />
  // );

  const [activeTab, setActiveTab] = useState('1');

  const location = useLocation();

  const { RangePicker } = DatePicker;

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const handleDatePicker = (e: any) => {
    setStartDate(moment(e?.[0]).format('YYYY-MM-DD'));
    setEndDate(moment(e?.[1]).format('YYYY-MM-DD'));
  };

  useEffect(() => {
    if (location.state) {
      const { tab } = location.state as { tab: string };
      setActiveTab(tab);
    }
  }, [location?.state]);

  return (
    <Reports>
      <PageHeader>
        <H3 weight="medium">{tReports('reports')}</H3>
        {activeTab === '1' && (
          <FilterSection>
            <Select
              allowClear
              size="large"
              onChange={e => setAccount(e)}
              placeholder="Página"
            >
              {dataAccounts?.accounts?.map(option => (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
            <Select
              placeholder="Conta de Anúncio"
              allowClear
              size="large"
              onChange={e => setAdAccount(e)}
            >
              {dataAccounts?.adAccounts?.map(option => (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
            <Select
              placeholder="Todas"
              size="large"
              onChange={e => setCampaign(e)}
              allowClear
              onClear={() => setAdAccount(adAccount)}
            >
              {campaigns?.map((option: any) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
            <RangePicker
              format="DD/MM/YYYY"
              style={{ width: '40%' }}
              placeholder={[tReports('start_date'), tReports('end_date')]}
              onChange={e => handleDatePicker(e)}
            />
          </FilterSection>
        )}
        {activeTab === '2' && (
          <FilterSection>
            <Select
              allowClear
              size="large"
              onChange={e => setWaba(e)}
              placeholder="Número WhatsApp"
            >
              {whatsAppNumbers?.map(option => (
                <Select.Option key={option.id} value={option.id}>
                  {option.friendlyName}
                </Select.Option>
              ))}
            </Select>
            {/* <RangePicker
              format="DD/MM/YYYY"
              style={{ width: '40%' }}
              placeholder={[tReports('start_date'), tReports('end_date')]}
              onChange={e => handleDatePicker(e)}
            />
            <Select
              placeholder="Todas"
              size="large"
              onChange={e => setGranularity(e)}
              allowClear
              onClear={() => setAdAccount(adAccount)}
            >
              {granularityOptions?.map((option: any) => (
                <Select.Option key={option.key} value={option.key}>
                  {option.label}
                </Select.Option>
              ))}
            </Select> */}
          </FilterSection>
        )}
      </PageHeader>
      <Body>
        <TableImports>
          <TabGroup
            tabPosition="top"
            type="line"
            activeKey={activeTab}
            onChange={handleTabChange}
          >
            <TabGroupPane tab={tReports('facebook')} key="1">
              <FacebookReport
                insights={insights}
                hasDataOption={
                  !!(account && adAccount && campaign && startDate && endDate)
                }
              />
            </TabGroupPane>

            <TabGroupPane tab={tReports('whatsapp')} key="2">
              <WhatsappReport whatsappReportData={insightsWhatsApp} />
            </TabGroupPane>

            {/* <TabGroupPane tab={tReports('descriptive_analysis_base')} key="3">
              <DescriptiveAnalysisReport />
            </TabGroupPane> */}
          </TabGroup>
        </TableImports>
        {/* {activeTab === '3' && (
          <FAB>
            <AddContactButton
              type="primary"
              icon={<PlusOutlined />}
              size="large"
            >
              {tModalDescriptiveAnalysis('export_report')}
            </AddContactButton>
            <Dropdown overlay={<MenuDropdown />}>
              <ActionButton
                type="default"
                icon={<MoreOutlined />}
                size="large"
              />
            </Dropdown>
          </FAB>
        )} */}
      </Body>
    </Reports>
  );
};

export default ReportsPage;
