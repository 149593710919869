import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCustomTranslation } from '../../../hooks/custom-translation';

import * as CONSTANTS from '../../../navigation/constants';

import {
  Container,
  Body,
  Vector,
  Wrapper,
  TextTitle,
  TextSubTitle,
  ButtonFinishImport,
  Vector1,
  Vector2,
} from './styles';
import ProgressBar from '../../ProgressBar';
import { useGetAllFilesImportsQuery } from '../../../services/fileImporter';

interface ComponentsStatus {
  [key: number]: React.ComponentType<any>;
}

const FinishImport = ({ importerId }: any) => {
  const navigate = useNavigate();
  const { tImportContact } = useCustomTranslation('importContact');
  const [statusId, setStatusId] = useState(1);
  const handleFinishImport = () => {
    navigate(CONSTANTS.DATABASE_MANAGEMENT_PF);
  };

  const { data: importer } = useGetAllFilesImportsQuery(
    {
      top: 6,
      custom: `$expand=enrichmentRequests($expand=Status)&$filter=Id eq ${importerId}`,
    },
    {
      pollingInterval: 200,
      refetchOnFocus: false,
      skip: !importerId,
    },
  );

  // const statusId =
  //   importer?.data[0].enrichmentRequests?.[
  //     importer?.data[0].enrichmentRequests.length - 1
  //   ]?.status?.id || 1;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setStatusId(2);
    }, 10000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (
      importer?.data[0]?.enrichmentRequests &&
      importer.data[0].enrichmentRequests.length > 0 &&
      importer.data[0].enrichmentRequests[
        importer.data[0].enrichmentRequests.length - 1
      ]?.status?.id === 3
    ) {
      setStatusId(3);
    }
  }, [importer]);

  const componentsStatus: ComponentsStatus = {
    0: Vector,
    1: Vector,
    2: Vector1,
    3: Vector2,
  };
  const messageStatus = (status: any) => {
    switch (status) {
      case 0:
        return tImportContact('await_message_instruction');
      case 1:
      case 2:
        return tImportContact('await_message_instruction_segmentation');
      case 3:
        return tImportContact('finish_message_instruction_segmentation');
      default:
        return '';
    }
  };
  const titleStatus = (status: any) => {
    switch (status) {
      case 0:
      case 1:
        return tImportContact('base_contacts_processing');
      case 2:
        return tImportContact('segmentation_base_contacts');
      case 3:
        return tImportContact('finish_segmentation_base_contacts');
      default:
        return tImportContact('base_contacts_processing');
    }
  };

  // const { fileImportStatusId } = importer as FileImportProps;

  const VectorImage = componentsStatus[statusId] || Vector;
  return (
    <Container>
      <Body>
        <VectorImage />
        <Wrapper>
          <TextTitle>
            {titleStatus(
              importer?.data[0].enrichmentRequests?.[
                importer?.data[0].enrichmentRequests.length - 1
              ]?.status?.id,
            )}
          </TextTitle>
          <TextSubTitle>
            {messageStatus(
              importer?.data[0].enrichmentRequests?.[
                importer?.data[0].enrichmentRequests.length - 1
              ]?.status?.id,
            )}
          </TextSubTitle>
          <ButtonFinishImport
            type="primary"
            size="large"
            onClick={handleFinishImport}
          >
            {tImportContact('back_to_import_list')}
          </ButtonFinishImport>
          <ProgressBar fileImportStatusId={statusId} size="default" hasLabel />
        </Wrapper>
      </Body>
    </Container>
  );
};

export default FinishImport;
