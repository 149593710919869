import { Form, Select } from 'antd';
import styled from 'styled-components';

import pattern from '../../assets/pattern.svg';

import GenericCodeButton from '../Button';
import GenericInput from '../FormInput';
import GenericInputSwitch from '../InputSwitch';
import GenericSelect from '../Select';
import GenericText from '../Text';

export const ActionBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 0.5rem;
`;

export const ActiveSwitch = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;

export const Button = styled(GenericCodeButton)`
  height: 2.5rem;
`;

export const CloseButton = styled(GenericCodeButton)`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.12rem;
`;

export const Column = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Fields = styled.div`
  display: inline-flex;
  gap: 1.5rem;
`;

export const InputBasic = styled(GenericInput)`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;

export const InputLabelWrapper = styled.div`
  display: inline-flex;
  gap: 0.25rem;
  align-items: center;
  padding: 0.06rem 0rem 0.06rem 0rem;
`;

export const LayoutPage = styled.div`
  width: 35rem;
  min-height: 25.125rem;
  display: flex;
  box-shadow: 1.75rem 0.5rem rgba(0, 0, 0, 0.05), 1rem rgba(0, 0, 0, 0.08),
    0.38rem -0.25rem rgba(0, 0, 0, 0.12);
`;

export const PageHeader = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 1rem;
  align-items: center;
`;

export const PageHeaderTitle = styled(GenericText)`
  font-weight: 500;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.7);
  flex: 1;
`;

export const RightButtons = styled.div`
  display: inline-flex;
  gap: 0.5rem;
`;

export const SelectInput = styled(GenericSelect)``;

export const SelectInputOption = styled(Select.Option)``;

export const Sider1 = styled.div`
  width: 2.5rem;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  background-color: ${props => props.theme.colors.primary[3]};
  //background-image: url(${pattern});
`;

export const SwitchBasic = styled(GenericInputSwitch)`
  position: relative;
  width: 2.75rem;
`;

export const UserForm = styled(Form)`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;
