import React from 'react';

// import { Container } from './styles';

import { MaskedInput as MaskedInputAnt } from 'antd-mask-input';
import { MaskedInputProps } from 'antd-mask-input/build/main/lib/MaskedInput';

const MaskedInput = ({ ...props }: MaskedInputProps) => {
  return <MaskedInputAnt {...props} />;
};

export default MaskedInput;
