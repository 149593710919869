import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { PropsWithChildren, useState } from 'react';
import { useCustomTranslation } from '../../hooks/custom-translation';
import Modal from '../Modal';

import {
  WizardWelcome,
  LayoutPage,
  PageHeader,
  Title,
  CloseIconButton1,
  CloseIconButton,
  IconOutlinedClose,
  Content2,
  Checkbox1,
  ActionBar,
  CancelButton,
  DeleteButton,
} from './styles';

interface ModalDeleteProps extends PropsWithChildren {
  visible: boolean;
  onCancel: () => void;
  onOk?: () => void;
  title: string;
  deleteButton: string;
}
const ModalDelete = ({
  visible,
  onCancel,
  onOk,
  title,
  deleteButton,
  children,
}: ModalDeleteProps) => {
  const { tModals, tCommonWords } = useCustomTranslation(
    'modals',
    'commonWords',
  );

  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleCancel = () => {
    setIsChecked(false);
    onCancel();
  };

  const onChange = (e: CheckboxChangeEvent) => {
    setIsChecked(e.target.checked);
  };

  return (
    <Modal visible={visible} title={null} footer={null} closable={false}>
      <WizardWelcome>
        <LayoutPage>
          <PageHeader>
            <Title>{title}</Title>
            <CloseIconButton1>
              <CloseIconButton>
                <IconOutlinedClose onClick={handleCancel} />
              </CloseIconButton>
            </CloseIconButton1>
          </PageHeader>
          <Content2>
            {children}
            <Checkbox1 checked={isChecked} onChange={onChange}>
              {tModals('checkbox_delete')}
            </Checkbox1>
          </Content2>
          <ActionBar>
            <CancelButton type="default" size="large" onClick={handleCancel}>
              {tCommonWords('cancel')}
            </CancelButton>

            <DeleteButton
              danger
              type="primary"
              size="large"
              disabled={!isChecked}
              onClick={onOk}
            >
              {deleteButton}
            </DeleteButton>
          </ActionBar>
        </LayoutPage>
      </WizardWelcome>
    </Modal>
  );
};

export default ModalDelete;
