import { memo, useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useMaps } from '../../hooks/maps';

import Filters from './Filters';
import Map from './Map';
import SummaryResults from './SummaryResults';

import DescriptiveAnalysisModal from '../../components/DescriptiveAnalysisModal';
import Form from '../../components/Form';
import Loading from '../../components/Loading';
import ModalPersona from '../../components/ModalPersonas';
import getErrors from '../../helpers/errors';
import { ShowFormMessage } from '../../helpers/message';
import { createQueryById } from '../../helpers/odata';
import {
  IFilters,
  IPotentialAudienceResult,
  useGetAllPotentialAudienceFilteredQuery,
  usePostPotentialAudienceMutation,
} from '../../services/map/potentialAudience';
import { PotentialAudienceFilterGroupProps } from '../../services/map/potentialAudienceFilterGroup';
import { Personas } from '../../services/personas';
import { CustomDragLayer } from './Map/CustomDragLayer';
import ModalSavePotentialAudience from './ModalSavePotentialAudience';
import { Container, MapLoadingWrapper, MapWrapper } from './styles';
// eslint-disable-next-line import/extensions
// import { useTransaction } from '../../navigation/TransactionContext';

interface IPersonas {
  map_url: string;
  personas: Personas[];
}

interface IForm {
  name: string;
  address: string;
  radius: number;
  radiusType: 'm' | 'km';
  groups: PotentialAudienceFilterGroupProps[];
  fileImportId?: string;
}

const FilterMap = ({ hasSegmentatioAnalysisTab = false }: any) => {
  // const { dashboardData: files } = useTransaction();
  // const { data: files } = useGetDashboardDataQuery({});

  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (files && files.length) {
  //     console.log('files: ', files);
  //     const customerPF = files.find((file: any) => file.customerType === 'PF');
  //     const customerPJ = files.find((file: any) => file.customerType === 'PJ');

  //     const checkLeadsLimit = (customer: any) => {
  //       if (customer.leads > customer.maxQuantityLeads) {
  //         navigate(CONSTANTS.UPGRADE);
  //       }
  //     };

  //     if (customerPF) {
  //       checkLeadsLimit(customerPF);
  //     }

  //     if (customerPJ) {
  //       checkLeadsLimit(customerPJ);
  //     }
  //   }
  // }, [files, navigate]);

  const [posX, setPosX] = useState(0);
  const [posY, setPosY] = useState(60);
  const [potentialAudience, setPotentialAudience] =
    useState<IPotentialAudienceResult>();
  const [filters, setFilters] = useState<IFilters>();
  const [modalSaveIsOpen, setModalSaveIsOpen] = useState<boolean>(false);
  const [modalPersonaIsOpen, setModalPersonaIsOpen] = useState<boolean>(false);
  const [modalDescriptiveAnalysisIsOpen, setModalDescriptiveAnalysisIsOpen] =
    useState<boolean>(false);
  const [personas, setPersonas] = useState<IPersonas>();
  const [personaProfileNumber, setPersonaProfileNumber] = useState<number>();

  const { id } = useParams();
  const [form] = Form.useForm<IForm>();
  const {
    setRadius,
    setCenter,
    setPin,
    setPlaceSelected,
    getFirstStreetAddressByLocation,
  } = useMaps();

  const { data: filterPotentialAudience } =
    useGetAllPotentialAudienceFilteredQuery({
      top: 1,
      custom: `$expand=geoFilter,filterGroups($expand=filtersDefinition) &${createQueryById(
        id,
      )}`,
    });

  const [postPotentialAudience, configPostPotentialAudience] =
    usePostPotentialAudienceMutation();

  const { isLoading, isError, error } = configPostPotentialAudience;

  const handleSavePotentialAudience = async () => {
    setModalSaveIsOpen(true);
  };

  const handleCloseModalPersona = () => {
    setModalPersonaIsOpen(false);
  };

  const handleCloseModalDescriptiveAnalysis = () => {
    setModalDescriptiveAnalysisIsOpen(false);
  };

  const handleRadiusType = (r: string) => {
    if (r?.includes('km')) return 'km';
    return 'm';
  };

  const getPotentialAudience = useCallback(
    async (filterObj: IFilters) => {
      const preview = await postPotentialAudience(filterObj).unwrap();
      const location = {
        // lat: filterObj?.geoFilter?.latitude || -15.72,
        // lng: filterObj?.geoFilter?.longitude || -48.01,
        lat: filterObj?.geoFilter?.latitude,
        lng: filterObj?.geoFilter?.longitude,
      };

      const locationPin = filterObj?.geoFilter
        ? new google.maps.LatLng(location)
        : null;

      if (locationPin) {
        setCenter(location);
        setPin(locationPin);

        setPlaceSelected(await getFirstStreetAddressByLocation(locationPin));
      }

      const cordinates = filterObj?.geoFilter;
      const radius = Number(cordinates?.radiusSize?.match(/\d+/g));

      if (!cordinates) {
        setRadius(0);
      }

      if (cordinates?.radiusSize?.includes('km')) {
        setRadius(radius * 1000);
      } else {
        setRadius(radius);
      }

      setFilters(filterObj);
      setPotentialAudience(preview);
    },
    [
      getFirstStreetAddressByLocation,
      postPotentialAudience,
      setCenter,
      setPin,
      setPlaceSelected,
      setRadius,
    ],
  );

  const showMessagePostContact = useCallback(() => {
    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }

    const errorMessage = error ? getErrors(errors.errors) : '';

    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess: false,
        isError,
      },
      config: {
        successMessage: 'Público potencial adicionado com sucesso.',
        errorMessage: errorMessage
          ? `Desculpe, tente novamente mais tarde ou entre em contato com o suporte se o problema persistir`
          : 'Desculpe, tente novamente mais tarde ou entre em contato com o suporte se o problema persistir',
      },
    });
  }, [error, isError]);

  useEffect(showMessagePostContact, [showMessagePostContact]);

  useEffect(() => {
    if (filterPotentialAudience?.data[0]) {
      form.setFieldsValue({
        name: filterPotentialAudience?.data[0].name,
        address: filterPotentialAudience?.data[0].geoFilter?.address,
        radius:
          Number(
            filterPotentialAudience?.data[0].geoFilter?.radiusSize.match(
              /\d+/g,
            ),
          ) || 0,
        radiusType: handleRadiusType(
          filterPotentialAudience?.data[0].geoFilter?.radiusSize || 'km',
        ),
        groups: filterPotentialAudience?.data[0].filterGroups,
      });

      const filterObj = {
        name: filterPotentialAudience?.data[0].name,
        filterGroups: filterPotentialAudience?.data[0].filterGroups,
        geoFilter: filterPotentialAudience?.data[0].geoFilter,
        fileImportId: filterPotentialAudience?.data[0].fileImportId,
      };

      getPotentialAudience(filterObj);
    }
  }, [filterPotentialAudience, form, getPotentialAudience]);

  useEffect(() => {
    personas?.personas.map((p: Personas, index) =>
      p.name === potentialAudience?.segmentMostFound
        ? setPersonaProfileNumber(index)
        : null,
    );
  }, [personas, personaProfileNumber, potentialAudience]);

  return (
    <>
      <Container>
        <Filters
          setPotentialAudience={setPotentialAudience}
          setFilters={setFilters}
          postPotentialAudience={postPotentialAudience}
          isLoading={configPostPotentialAudience.isLoading}
          form={form}
          hasSegmentatioAnalysisTab={hasSegmentatioAnalysisTab}
          filterPotentialAudience={filterPotentialAudience}
        />
        <MapWrapper>
          {isLoading && (
            <MapLoadingWrapper>
              <Loading onFinish={() => null} looping />
            </MapLoadingWrapper>
          )}
          <Map
            setPosX={setPosX}
            setPosY={setPosY}
            data={potentialAudience && potentialAudience}
          />
          <CustomDragLayer
            potentialAudience={potentialAudience}
            personas={personas}
            setPersonas={setPersonas}
            setModalPersonaIsOpen={setModalPersonaIsOpen}
            setModalDescriptiveAnalysis={setModalDescriptiveAnalysisIsOpen}
          />
        </MapWrapper>
        {!isLoading && potentialAudience && (
          <SummaryResults
            posY={posY}
            posX={posX}
            closed={false}
            data={potentialAudience}
            personas={personas}
            setPersonas={setPersonas}
            setModalPersonaIsOpen={setModalPersonaIsOpen}
            handleSavePotentialAudience={handleSavePotentialAudience}
            // disableButton={false}
          />
        )}
        {/* <SaveButton
          type="primary"
          htmlType="submit"
          icon={<SaveOutlined />}
          onClick={handleSavePotentialAudience}
          disabled={!potentialAudience}
        >
          Salvar público potencial
        </SaveButton> */}
      </Container>
      <ModalSavePotentialAudience
        setVisible={setModalSaveIsOpen}
        visible={modalSaveIsOpen}
        filters={filters}
      />

      {personas && personaProfileNumber && (
        <ModalPersona
          visible={modalPersonaIsOpen}
          onCancel={handleCloseModalPersona}
          onOk={handleCloseModalPersona}
          personaProfile={personaProfileNumber}
          personaData={personas}
        />
      )}

      <DescriptiveAnalysisModal
        visible={modalDescriptiveAnalysisIsOpen}
        onCancel={handleCloseModalDescriptiveAnalysis}
        fileId=""
        fileTypeDataSource=""
      />
    </>
  );
};

export default memo(FilterMap);
