import React, { useCallback, useEffect, useState } from 'react';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';

import { ContactManagement, PageHeader, Body, FAB } from './styles';

import { useCustomTranslation } from '../../hooks/custom-translation';
import { H3 } from '../../styles/fonts';
import Contacts from '../../components/Contacts/Contact';
import ModalContact from '../../components/Contacts/Contact/ModalContact';
import Button from '../../components/Button';

const ContactManagementPage = () => {
  const { tContactManagement } = useCustomTranslation('contactManagement');
  const [isLayoutModalOpen, setIsLayoutModalOpen] = useState(false);
  const [refetch, setRefetch] = useState<boolean>(false);

  const handleShowLayoutModal = useCallback(() => {
    setIsLayoutModalOpen(true);
  }, []);

  const handleCloseLayoutModal = useCallback(() => {
    setIsLayoutModalOpen(false);
  }, []);

  const handleAddContact = useCallback(() => {}, []);

  const handleRefetch = () => {
    setRefetch(true);
  };

  useEffect(() => {
    if (refetch) {
      setRefetch(false);
    }
  }, [refetch]);

  return (
    <ContactManagement>
      <PageHeader>
        <H3 weight="medium">{tContactManagement('contact_management')}</H3>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            type="default"
            icon={<SyncOutlined />}
            size="large"
            onClick={handleRefetch}
            loading={refetch}
          >
            {tContactManagement('refresh')}
          </Button>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            onClick={() => handleShowLayoutModal()}
          >
            {tContactManagement('add_contact')}
          </Button>
          {/* <ActionButton type="default" icon={<MoreOutlined />} size="large" /> */}
        </div>
      </PageHeader>
      <Body>
        <Contacts refetch={refetch} />
      </Body>

      <FAB />

      <ModalContact
        // contact={contact}
        visible={isLayoutModalOpen}
        onCancel={handleCloseLayoutModal}
        onOk={handleAddContact}
      />
    </ContactManagement>
  );
};

export default ContactManagementPage;
