import React, { useState } from 'react';
// import { VerticalAlignBottomOutlined } from '@ant-design/icons';
import { useCustomTranslation } from '../../../hooks/custom-translation';
import { usePrompt } from '../../../hooks/usePrompt';
import { FileImportProps } from '../../../services/fileImporter';
import DragAndDropFile from './DragAndDropFile';
import {
  Container,
  DownloadExempleText,
  // CheckboxOption,
  // ButtonWrapper,
  // ButtonNextStep,
  DownloadLink,
  ButtonBetween,
  // DownloadTemplateButton,
} from './styles';

const UploadFile = ({ change, setImporterId, setCustomerId }: any) => {
  // const [hasChecked, sethasChecked] = useState(false);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [importData, setImportData] = useState<FileImportProps | undefined>();

  const { tImportRfv } = useCustomTranslation('importRfv');

  usePrompt(
    'Existe uma importação em andamento, caso voce navegue para outra pagina, voce perderá o processo. Deseja sair?',
    isLoadingFile && !importData,
  );

  return (
    <Container>
      <DragAndDropFile
        importData={importData}
        setImportData={setImportData}
        setImporterId={setImporterId}
        setIsLoadingFile={setIsLoadingFile}
        change={change}
        setCustomerId={setCustomerId}
      />
      <DownloadExempleText>
        {tImportRfv('download_template_1')}
        <DownloadLink
          download
          href="
          https://salesbox-portal-public-assets.s3.amazonaws.com/template-rfv.csv"
        >
          baixe aqui
        </DownloadLink>
        {tImportRfv('download_template_2')}
      </DownloadExempleText>
      {/* <CheckboxOption onChange={() => sethasChecked(!hasChecked)}>
        {tImportContact('certification_lgpd')}
      </CheckboxOption> */}
      <ButtonBetween>
        {/* <DownloadTemplateButton onClick={handleDownload}>
          <VerticalAlignBottomOutlined />
          <DownloadExempleText>Template análise RFV</DownloadExempleText>
        </DownloadTemplateButton> */}
      </ButtonBetween>
    </Container>
  );
};

export default UploadFile;
