import { error, success } from './message';

interface Props {
  status: {
    isError: boolean;
    isSuccess: boolean;
  };
  config: {
    errorMessage: string | any;
    successMessage: string;
  };
}

export const SuccessOrError = ({
  status: { isError, isSuccess },
  config: { errorMessage, successMessage },
}: Props): void => {
  if (isError) {
    error(errorMessage);
    return;
  }
  if (isSuccess) {
    success(successMessage);
  }
};
