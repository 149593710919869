import React, { useState, useEffect } from 'react';
import {
  Container,
  Body,
  Column01,
  Text1,
  Personas1,
  TextComponentsaiba1,
  List,
  Persona1,
  ConservadorButton,
  Column02,
  ImgMapPersonas,
} from './styles';
import ModalPersona from '../../components/ModalPersonas';
import { Personas } from '../../services/personas';
import { getPersonasData } from '../../services/personas/personas-service';

const PersonasPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [personaProfile, setPersonaProfile] = useState(0);
  const [screenData, setScreenData] = useState<{
    map_url: string;
    personas: Personas[];
  }>();

  useEffect(() => {
    getPersonasData().then(data => setScreenData(data));
  }, []);

  const handleShowModalPersona = (personaProfileNumber: number) => {
    setPersonaProfile(personaProfileNumber);
    setIsModalOpen(true);
  };

  const handleCloseModalPersona = () => {
    setIsModalOpen(false);
  };

  if (!screenData) return <div>Loading...</div>;

  return (
    <Container>
      <ModalPersona
        visible={isModalOpen}
        onCancel={handleCloseModalPersona}
        onOk={handleCloseModalPersona}
        personaProfile={personaProfile}
        personaData={screenData}
      />
      <Body>
        <Column01>
          <Text1>
            <Personas1>Personas</Personas1>
            <TextComponentsaiba1>
              Conheça cada Persona da Segmentação Brasil para criar campanhas
              customizadas.
            </TextComponentsaiba1>
          </Text1>
          <List>
            {screenData.personas.map((persona, index) => {
              return (
                <Persona1 key={persona.name}>
                  <ConservadorButton
                    type="link"
                    size="large"
                    onClick={() => handleShowModalPersona(index)}
                  >
                    {persona.name}
                  </ConservadorButton>
                </Persona1>
              );
            })}
          </List>
        </Column01>
        <Column02>
          <ImgMapPersonas>
            <img alt="Mapa Personas" src={screenData.map_url} width="100%" />
          </ImgMapPersonas>
        </Column02>
      </Body>
    </Container>
  );
};

export default PersonasPage;
