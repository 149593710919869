import styled, {
  css,
  CSSObject,
  FlattenSimpleInterpolation,
} from 'styled-components';

export const breakpoints = {
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
};

type Breakpoint = keyof typeof breakpoints;

interface ShowWhenProps {
  size: Breakpoint;
}

const mediaFn = (firstBreakpoint: string, lastBreakpoint?: string) =>
  `@media (${firstBreakpoint})${
    lastBreakpoint ? `and (${lastBreakpoint})` : ''
  }`;

const lessThanCSS = (breakpoint: Breakpoint) => {
  return `max-width: ${breakpoints[breakpoint]}`;
};

const greaterThanCSS = (breakpoint: Breakpoint) => {
  return `min-width: calc(${breakpoints[breakpoint]} + 1px)`;
};

const lessThan =
  (breakpoint: Breakpoint) =>
  (args: CSSObject | TemplateStringsArray): FlattenSimpleInterpolation =>
    css`
      ${mediaFn(lessThanCSS(breakpoint))} {
        ${css(args)}
      }
    `;

const greaterThan =
  (breakpoint: Breakpoint) =>
  (args: CSSObject | TemplateStringsArray): FlattenSimpleInterpolation =>
    css`
      ${mediaFn(greaterThanCSS(breakpoint))} {
        ${css(args)}
      }
    `;

const between =
  (firstBreakpoint: Breakpoint, lastBreakpoint: Breakpoint) =>
  (args: CSSObject | TemplateStringsArray): FlattenSimpleInterpolation =>
    css`
      ${mediaFn(lessThanCSS(firstBreakpoint), greaterThanCSS(lastBreakpoint))} {
        ${css(args)}
      }
    `;

export const media = {
  lessThan,
  greaterThan,
  between,
};

export const ShowWhenGreaterThan = styled.div<ShowWhenProps>`
  ${props => media.lessThan(props.size)`
    display: none;
  `}
`;

export const ShowWhenLessThan = styled.div<ShowWhenProps>`
  ${props => media.greaterThan(props.size)`
    display: none;
  `}
`;
