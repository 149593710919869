import { stringToNumber } from '../../../../helpers/numberFormatter';

import { useCustomTranslation } from '../../../../hooks/custom-translation';
import { Body, Footnote } from '../../../../styles/fonts';
import Collapse, { Panel } from '../../../Collapse';
import BarChart from '../../../Contacts/Contact/ContactProfile/BarChart';

import {
  CollapseItemContent,
  Content04,
  Data011,
  Label,
  Question1,
} from './styles';

interface ConsumptionTabProps {
  consumptionBehaviorQuestions:
    | { question: string; answer: string }[]
    | undefined;
}

const Consumption = ({ consumptionBehaviorQuestions }: ConsumptionTabProps) => {
  const { tModalPersona } = useCustomTranslation('modalPersona');

  const formatNumber = (num: string | undefined) => {
    if (num !== undefined) {
      return stringToNumber(num);
    }
    return 0;
  };

  return (
    <>
      <Content04>
        <Collapse>
          <Panel header={tModalPersona('consumer_behavior')} key="1">
            <CollapseItemContent>
              <Data011>
                {consumptionBehaviorQuestions?.map(question => {
                  return (
                    <Question1 key={question.question}>
                      <Body weight="medium">{question.question}</Body>
                      <Label>
                        <Footnote>{tModalPersona('0')}</Footnote>
                        <Footnote>{tModalPersona('10')}</Footnote>
                      </Label>
                      <BarChart
                        value={formatNumber(question.answer) * 10}
                        position="width"
                        backgroundBarColor="#E8ECFE"
                        backgroundBarBorder="#2966F5"
                        backgroundBarBorderLeft="hidden"
                        pointerBarColor="#2966F5"
                        pointerBarBorder="#2966F5"
                        pointerBarBorderRadius="0px 8px 8px 0px"
                      />
                    </Question1>
                  );
                })}
              </Data011>
            </CollapseItemContent>
          </Panel>
        </Collapse>
      </Content04>
    </>
  );
};

export default Consumption;
