import { memo } from 'react';
import { Collapse as CollapseAntd, CollapseProps } from 'antd';

import { Container } from './styles';

const Collapse: React.FC<CollapseProps> = ({ ...props }) => {
  return <Container {...props} ghost />;
};

export const { Panel } = CollapseAntd;

export default memo(Collapse);
