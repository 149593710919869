import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0.5rem;
  align-items: center;
  .ant-skeleton-content {
    display: flex;
    align-items: center;
    width: 100%;
  }
`;
