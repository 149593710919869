import { memo } from 'react';
import { Skeleton } from 'antd';

import { Container } from './styles';

const CheckboxList: React.FC = () => {
  return (
    <Container>
      {/* <FormCheckbox /> */}
      <Skeleton
        active
        paragraph={{
          rows: 0,
        }}
      />
    </Container>
  );
};

export default memo(CheckboxList);
