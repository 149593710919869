import { useEffect, useRef, EffectCallback } from 'react';

import { createCustomEqual, EqualityComparatorCreator } from 'fast-equals';

import { isLatLngLiteral } from '@googlemaps/typescript-guards';

const deepEqualCustom: EqualityComparatorCreator<undefined> =
  deepEqual => (a, b) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }

    // TODO extend to other types

    // use fast-equals for other objects
    return deepEqual(a, b);
  };

const deepEqualsForMaps = createCustomEqual(() => ({
  createIsNestedEqual: deepEqualCustom,
}));

function useDeepCompareMemoize(value: any) {
  const ref = useRef();

  if (!deepEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepCompareEffectForMaps(
  callback: EffectCallback,
  dependencies: any[],
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
