import React from 'react';

import { ColumnProps } from 'antd/lib/table';

import { Enrichment, TableGroup } from './styles';

interface ItemEnrichmentProps {
  key: string;
  infoName: string;
  infoValue: string;
  quantity: number;
  percentage: number;
}
export type EnrichmentProps = Array<ItemEnrichmentProps> | undefined;

const EnrichmentPage = ({ enrichment }: { enrichment: EnrichmentProps }) => {
  interface SortedEnrichmentProps {
    infoName: string;
    infoValue: string;
  }
  const sortedEnrichment = JSON.parse(JSON.stringify(enrichment))?.sort(
    (a: SortedEnrichmentProps, b: SortedEnrichmentProps) => {
      return (
        a.infoName.localeCompare(b.infoName) ||
        a.infoValue.localeCompare(b.infoValue)
      );
    },
  );

  const columnsTableGroup: ColumnProps<any>[] = [
    {
      title: 'Atributo',
      dataIndex: 'infoName',
      align: 'left',
      sorter: (a, b) => a.infoName.length - b.infoName.length,
    },
    {
      title: 'Sub atributo',
      dataIndex: 'infoValue',
      align: 'left',
      sorter: (a, b) => a.infoValue.length - b.infoValue.length,
    },
    {
      title: 'Percentual',
      dataIndex: 'percentage',
      align: 'left',
      sorter: (a, b) => a.percentage.length - b.percentage.length,
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantity',
      align: 'left',
      sorter: (a, b) => a.quantity.length - b.quantity.length,
    },
  ];

  return (
    <Enrichment>
      <TableGroup
        dataSource={sortedEnrichment}
        columns={columnsTableGroup}
        pagination={{ pageSize: 5 }}
      />
    </Enrichment>
  );
};

export default EnrichmentPage;
