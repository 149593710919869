import React from 'react';

import { DownloadOutlined, CloseOutlined } from '@ant-design/icons';

import Sanitization, {
  QualitiesProps,
} from '../../../components/TabGroupEnrichmentDetails/Sanitization';
import Enrichment, {
  EnrichmentProps,
} from '../../../components/TabGroupEnrichmentDetails/Enrichment';
// import image from '../../../assets/pattern.svg'

import {
  Container,
  Sider1,
  Wrapper,
  PageHeader,
  Column01,
  ReportType1,
  FileName1,
  Column02,
  ButtonsExportReport,
  // ButtonsUpdateReport,
  ButtonsButton5,
  TabGroupEnrichmentDetailsPane,
  TabGroupEnrichmentDetails,
  PerformedAnalysis,
  FooterContainer,
  FooterSubContainer,
} from './styles';
import Modal from '../../../components/Modal';

interface EnrichmentDetailsPageProps {
  onOk: () => void;
  onCancel: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  onUpdate: () => void;
  customerFilename: string;
  qualities: QualitiesProps;
  enrichment: EnrichmentProps;
  visible: boolean;
}

const analysisDate = new Date();
const analysisDateString = `Análise realizada em ${analysisDate.toLocaleDateString()}, às ${analysisDate.toLocaleTimeString(
  [],
  { hour: '2-digit', minute: '2-digit' },
)}`;

const ModalEnrichmentDetailsPage = ({
  onOk,
  onCancel,
  // onUpdate,
  customerFilename,
  qualities,
  enrichment,
  visible,
}: EnrichmentDetailsPageProps) => {
  return (
    <Modal
      visible={visible}
      title={null}
      footer={null}
      closable={false}
      width="60rem"
    >
      <Container>
        <Sider1 />
        <Wrapper>
          <PageHeader>
            <Column01>
              <ReportType1>RELATÓRIO</ReportType1>
              <FileName1>{customerFilename}</FileName1>
            </Column01>
            <Column02>
              <ButtonsButton5
                type="text"
                onClick={onCancel}
                icon={<CloseOutlined />}
              />
            </Column02>
          </PageHeader>
          <TabGroupEnrichmentDetails
            tabPosition="left"
            type="line"
            size="large"
          >
            <TabGroupEnrichmentDetailsPane tab="HIGIENIZAÇÃO" key="0">
              <Sanitization qualities={qualities} />
            </TabGroupEnrichmentDetailsPane>
            <TabGroupEnrichmentDetailsPane tab="ENRIQUECIMENTO" key="1">
              <Enrichment enrichment={enrichment} />
            </TabGroupEnrichmentDetailsPane>
          </TabGroupEnrichmentDetails>
          <FooterContainer>
            <PerformedAnalysis>{analysisDateString}</PerformedAnalysis>
            <FooterSubContainer>
              {/* <ButtonsUpdateReport type="default" onClick={onUpdate}>
                Atualizar análise
              </ButtonsUpdateReport> */}
              <ButtonsExportReport
                type="primary"
                onClick={onOk}
                icon={<DownloadOutlined />}
              >
                Exportar relatório
              </ButtonsExportReport>
            </FooterSubContainer>
          </FooterContainer>
        </Wrapper>
      </Container>
    </Modal>
  );
};

export default ModalEnrichmentDetailsPage;
