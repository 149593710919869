import styled from 'styled-components';

import GenericCodeButton from '../../components/Button';
import GenericText from '../../components/Text';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1.5rem;
  background-color: #fbfbfb;
  position: relative;
`;
export const Body = styled.div`
  width: 100%;
  height: 35.5rem;
  display: flex;
  gap: 1.5rem;
  padding: 1.5rem 2.93rem;
  align-items: center;
`;
export const Column01 = styled.div`
  width: 21.88rem;
  height: 35.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const Text1 = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
`;
export const Personas1 = styled(GenericText)`
  font-weight: 500;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.7);
  flex: 1;
`;

export const TextComponentsaiba1 = styled(GenericText)`
  font-weight: 400;
  font-size: 0.88rem;
  color: rgba(0, 0, 0, 0.45);
  flex: 1;
`;

export const List = styled.div`
  width: 100%;
  height: 100%;
  color: #2966f5;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const Persona1 = styled.div`
  display: inline-flex;
  flex-direction: column;
`;
export const ConservadorButton = styled(GenericCodeButton)`
  width: 7.31rem;
  height: 1.5rem;
  border-radius: 0.12rem;

  font-weight: 500;
  text-align: left;
  padding-left: 0;
`;

export const Column02 = styled.div`
  height: 35.5rem;
  display: flex;
  flex-direction: column;
  border: 0.06rem solid #f1f1f1;
  box-sizing: border-box;
  gap: 1rem;
  border-radius: 0.12rem;
  padding: 1.5rem;
  background-color: #ffffff;
`;

export const Content1 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1rem;
`;

export const ImgMapPersonas = styled.div`
  max-width: 44.125rem;
  max-height: 35.5rem;
`;
