import { memo, PropsWithChildren } from 'react';

import { Form as FormAnt, FormProps } from 'antd';

const Form: React.FC<PropsWithChildren<FormProps>> = ({ ...props }) => {
  return <FormAnt {...props} />;
};

const { useForm, useWatch } = FormAnt;

export { useForm, useWatch };

export default memo(Form);
