/* eslint-disable consistent-return */
/* eslint-disable radix */
import { salesBoxApi } from '../../config/apis';
import { FacebookCampaignRoute } from '../../config/services-route';
import { URLs } from '../../helpers/urls';
import { TagTypes, WithPagination } from '../types';
import { providesList } from '../../helpers/provides';
import {
  FacebookAdAccountAndAdAccountProps,
  FacebookCampaignProps,
  FacebookGetCampaignsByAdAccountProps,
  FacebookGetCreativePreview,
  FacebookGetInsights,
  FacebookGetInstagramAccountsProps,
  FacebookGetPageTokenProps,
  FacebookInterestProps,
  FacebookInterestsGetProps,
  FacebookLocationsProps,
  FacebookPostCreative,
  FacebookResponsePostCreative,
  ImpressionsProps,
  WhatsAppGetInsights,
} from './facebookCampaign';
import { facebookBaseUrl } from '../../config/services-route/base';
import { builderQueryOdata, BuilderQueryProps } from '../../helpers/odata';

const token = localStorage?.getItem('facebook');

const facebookCampaignApi = salesBoxApi.injectEndpoints({
  endpoints: builder => ({
    getAllFacebookCampaign: builder.query<FacebookCampaignProps[], void>({
      query: () => ({
        url: FacebookCampaignRoute.facebookCampaign,
      }),
      providesTags: response =>
        providesList(response, TagTypes.FacebookCampaign),
    }),
    getAllFacebookCampaignFiltered: builder.query<
      WithPagination<FacebookCampaignProps[]>,
      BuilderQueryProps<Partial<FacebookCampaignProps>>
    >({
      query: props => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            customerId: authData.customer,
          },
          url: URLs.combineOdataUrl(
            FacebookCampaignRoute.getFacebookODATA,
            builderQueryOdata<Partial<FacebookCampaignProps>>(props),
          ),
        };
      },
      providesTags: response =>
        providesList(response?.data, TagTypes.FacebookCampaign),
      transformResponse: (
        response: any,
      ): WithPagination<FacebookCampaignProps[]> => {
        return {
          data: response.value,
          meta: {
            size: response['@odata.count'],
          },
        };
      },
    }),
    getFacebookCampaignById: builder.query<FacebookCampaignProps, string>({
      query: id => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            customerId: authData.customer,
          },
          url: URLs.combine(FacebookCampaignRoute.facebookCampaign, `${id}`),
        };
      },
    }),
    postFacebookCampaign: builder.mutation<
      FacebookCampaignProps,
      Partial<FacebookCampaignProps>
    >({
      query: body => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            customerId: authData.customer,
          },
          url: FacebookCampaignRoute.facebookCampaign,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: TagTypes.FacebookCampaign, id: 'LIST' }],
    }),
    postFinishFacebookCampaign: builder.mutation<any, any>({
      query: ({ id, creativeId }) => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            customerId: authData.customer,
          },
          url: `${FacebookCampaignRoute.facebookCampaignDispatch}/${id}/finalizecreation`,
          method: 'POST',
          body: {
            creatives: [creativeId],
          },
        };
      },
      invalidatesTags: [{ type: TagTypes.FacebookCampaign, id: 'LIST' }],
    }),
    updateFacebookCampaign: builder.mutation<FacebookCampaignProps, any>({
      query: ({ id, ...body }) => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            customerId: authData.customer,
          },
          url: URLs.combine(FacebookCampaignRoute.facebookCampaign, `${id}`),
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [{ type: TagTypes.FacebookCampaign, id: 'LIST' }],
    }),
    deleteFacebookCampaign: builder.mutation<FacebookCampaignProps, string>({
      query: id => {
        return {
          url: URLs.combine(FacebookCampaignRoute.facebookCampaign, `${id}`),
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: TagTypes.FacebookCampaign, id: 'LIST' }],
    }),
    getAllAccountsAndAdAccounts: builder.query<
      FacebookAdAccountAndAdAccountProps,
      string
    >({
      query: (accessToken: any) => ({
        url: `${FacebookCampaignRoute.getFacebookAccount}&access_token=${accessToken}`,
      }),
      transformResponse: (
        response: any,
      ): FacebookAdAccountAndAdAccountProps => {
        return {
          accounts: response.accounts.data,
          adAccounts: response.adaccounts.data,
        };
      },
    }),
    getAllInterests: builder.query<
      FacebookInterestProps[],
      FacebookInterestsGetProps
    >({
      query: ({ adAccount, search }) => ({
        url: `${facebookBaseUrl}/${adAccount}/targetingsearch?q=${search}&access_token=${token}`,
      }),
      transformResponse: (response: any): any => {
        return response.data;
      },
    }),
    getAllLocations: builder.query<FacebookLocationsProps[], string>({
      query: search => ({
        url: `${facebookBaseUrl}/search?q=${search}&type=adgeolocation&country_code=BR&access_token=${token}`,
      }),
      transformResponse: (response: any): any => {
        return response.data;
      },
    }),
    postCreative: builder.mutation<
      FacebookResponsePostCreative,
      FacebookPostCreative
    >({
      query: ({
        URL,
        callToAction,
        message,
        adAccount,
        imageHashList,
        pageId,
        instagramAccountId,
      }) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const child_attachments = imageHashList.map(item => {
          return {
            link: URL,
            image_hash: item,
            // call_to_action: {
            //   type: 'NO_BUTTON',
            // },
            call_to_action: {
              type: callToAction,
              value: {
                link: URL,
              },
            },
          };
        });

        let linkData = {};

        if (imageHashList.length > 1) {
          linkData = {
            link: URL,
            message,
            // image_hash: imageHashList,
            // call_to_action: {
            //   type: callToAction,
            //   value: {
            //     link: URL,
            //   },
            // },
            child_attachments,
            multi_share_optimized: true,
            multi_share_end_card: true,
          };
        } else {
          linkData = {
            link: URL,
            message,
            image_hash: imageHashList[0],
            call_to_action: {
              type: callToAction,
              value: {
                link: URL,
              },
            },
          };
        }

        const foo = {
          degrees_of_freedom_type: 'USER_ENROLLED_NON_DCO',
          text_transformation_types: ['TEXT_LIQUIDITY'],
          creative_features_spec: {
            standard_enhancements: {
              enroll_status: 'OPT_OUT',
            },
            description_automation: {
              action_metadata: {
                type: 'DEFAULT',
              },
              enroll_status: 'OPT_IN',
            },
            inline_comment: {
              action_metadata: {
                type: 'DEFAULT',
              },
              enroll_status: 'OPT_IN',
            },
          },
        };

        const objectStorySpec = {
          page_id: pageId,
          instagram_actor_id: instagramAccountId,
          link_data: linkData,
        };

        const body = new FormData();
        body.append('object_story_spec', JSON.stringify(objectStorySpec));
        body.append('degrees_of_freedom_spec', JSON.stringify(foo));
        body.append('access_token', token as string);
        body.append('name', 'Criativo Sales Box');

        return {
          url: `${facebookBaseUrl}/${adAccount}/adcreatives`,
          method: 'POST',
          body,
        };
      },
    }),
    getCreativeFacebookPreview: builder.query<any, FacebookGetCreativePreview>({
      query: ({ creativeId, height }) => ({
        url: `${facebookBaseUrl}/${creativeId}/previews?ad_format=MOBILE_FEED_BASIC&width=320&height=${height}&access_token=${token}`,
      }),
    }),
    getCreativeFacebookPreviewById: builder.query<
      any,
      FacebookGetCreativePreview
    >({
      query: ({ creativeId }) => {
        return {
          url: `${facebookBaseUrl}/${creativeId}?fields=title,object_story_spec&access_token=${token}`,
        };
      },
    }),
    getCreativeInstagramPreview: builder.query<any, FacebookGetCreativePreview>(
      {
        query: ({ creativeId }) => ({
          url: `${facebookBaseUrl}/${creativeId}/previews?ad_format=INSTAGRAM_STANDARD&width=320&height=380&access_token=${token}`,
        }),
      },
    ),
    getAllInstagramAccounts: builder.query<
      any,
      FacebookGetInstagramAccountsProps
    >({
      query: ({ pageId, pageToken }) => ({
        url: `${facebookBaseUrl}/${pageId}/instagram_accounts?fields=username,profile_pic&access_token=${pageToken}`,
      }),
    }),

    uploadImage: builder.mutation<any, any>({
      query: ({ file, adAccount }) => {
        const formData = new FormData();
        formData.append('filename', file);
        formData.append('access_token', token as string);

        return {
          url: `${facebookBaseUrl}/${adAccount}/adimages`,
          method: 'POST',
          body: formData,
        };
      },
    }),
    getAllFacebookInsights: builder.query<any, Partial<FacebookGetInsights>>({
      query: ({
        fields,
        time_range,
        accessToken,
        timeIncrement,
        summary,
        adAccount,
      }) => {
        return {
          url: `${facebookBaseUrl}/${adAccount}/insights?fields=${fields}&time_range=${time_range}&time_increment=${timeIncrement}&default_summary=${summary}&access_token=${accessToken}`,
        };
      },
      transformResponse: (response: any): any => {
        if (response.data.length > 0) {
          const graph: ImpressionsProps[] = [];

          const first = response.data[0];
          const last = response.data.slice(-1)[0];

          const linkClick = response?.summary?.actions?.find(
            (a: any) => a.action_type === 'link_click',
          );

          const { summary } = response;

          const costByClick =
            parseInt(summary.spend) / parseInt(linkClick.value);

          response.data.forEach((element: any) => {
            graph.push({
              day: element?.date_start,
              value: parseInt(element?.impressions),
              type: 'Impressões',
            });
            graph.push({
              day: element?.date_start,
              value: parseInt(element?.clicks),
              type: 'Cliques',
            });
          });

          return {
            data: {
              ...response.data[0],
            },
            graph,
            impressionsPrefix:
              parseInt(last.impressions) > parseInt(first.impressions)
                ? 'up'
                : 'down',
            reachPrefix:
              parseInt(last.reach) > parseInt(first.reach) ? 'up' : 'down',
            clicksPrefix:
              parseInt(last.clicks) > parseInt(first.clicks) ? 'up' : 'down',
            ctrPrefix: parseInt(last.ctr) > parseInt(first.ctr) ? 'up' : 'down',
            spendPrefix:
              parseInt(last.spend) > parseInt(first.spend) ? 'up' : 'down',
            cpmPrefix: parseInt(last.cpm) > parseInt(first.cpm) ? 'up' : 'down',
            summary: {
              ...response.summary,
              costByClick: parseFloat(costByClick.toString()).toFixed(2),
              ctr: parseFloat(summary.ctr).toFixed(2),
              cpm: parseFloat(summary.cpm).toFixed(2),
              // post_reaction
            },
          };
        }
      },
    }),
    getAllWhatsAppInsights: builder.query<any, Partial<WhatsAppGetInsights>>({
      query: ({ granularity, accessToken, waba }) => {
        return {
          url: `${facebookBaseUrl}/${waba}?fields=conversation_analytics.start(1666107731).end(1674056531).granularity(${granularity}).dimensions(["conversation_type", "conversation_direction", "phone"])&access_token=${accessToken}`,
        };
      },
      transformResponse: (response: any): any => {
        console.log(response, 'responseWhatsapp');

        const responseData =
          response.conversation_analytics.data[0].data_points;

        const months = [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro',
        ];

        const data = responseData.map((item: any) => {
          return {
            x: months[new Date(item.start * 1000).getMonth()],
            value: item.conversation,
            type:
              item.conversation_direction === 'BUSINESS_INITIATED'
                ? 'Iniciado pela empresa'
                : 'Iniciado pelo cliente',
          };
        });

        return {
          data,
        };
      },
    }),
    getCampaignsByAccountId: builder.query<
      any,
      FacebookGetCampaignsByAdAccountProps
    >({
      query: ({ adAccount, accessToken }) => ({
        url: `${facebookBaseUrl}/${adAccount}/campaigns?fields=name&access_token=${accessToken}`,
      }),
      transformResponse: (response: any): any => {
        return response.data;
      },
    }),
    getFacebookPageToken: builder.query<any, FacebookGetPageTokenProps>({
      query: ({ pageId }) => ({
        url: `${facebookBaseUrl}/${pageId}/?fields=access_token&access_token=${token}`,
      }),
    }),
  }),
});
export const {
  useGetAllFacebookCampaignQuery,
  useGetFacebookCampaignByIdQuery,
  usePostFacebookCampaignMutation,
  useUpdateFacebookCampaignMutation,
  useDeleteFacebookCampaignMutation,
  useGetAllAccountsAndAdAccountsQuery,
  useGetAllInterestsQuery,
  useGetAllLocationsQuery,
  usePostCreativeMutation,
  useGetCreativeFacebookPreviewQuery,
  useUploadImageMutation,
  usePostFinishFacebookCampaignMutation,
  useGetAllFacebookCampaignFilteredQuery,
  useGetAllFacebookInsightsQuery,
  useGetCampaignsByAccountIdQuery,
  useGetCreativeInstagramPreviewQuery,
  useGetAllInstagramAccountsQuery,
  useGetFacebookPageTokenQuery,
  useGetCreativeFacebookPreviewByIdQuery,
  useGetAllWhatsAppInsightsQuery,
} = facebookCampaignApi;
