import React, { useState, useEffect, useCallback } from 'react';
import { DownloadOutlined, CloseOutlined } from '@ant-design/icons';

import { useCustomTranslation } from '../../hooks/custom-translation';
import { Body } from '../../styles/fonts';

import Modal from '../Modal';

import SocioDemographicTab from './TabGroup/SocioDemographicTab';
import IncomeBracketsTab from './TabGroup/IncomeBrackets';
// import PeopleTab from './TabGroup/PeopleTab';
import FinancialRiskTab from './TabGroup/FinancialRiskTab';
// import { Personas } from '../../services/personas';
// import { getPersonasData } from '../../services/personas/personas-service';

import {
  ModalImportDetails,
  Sider1,
  Wrapper,
  PageHeader,
  Column01,
  Column02,
  Body1,
  TabGroupPane,
  TabGroup,
  ActionBar,
  Buttons,
  Button,
  ExportFileButton,
  ModalTabTitle,
  // UpdateAnalysisButton,
} from './styles';
import PsychometricScoreTab from './TabGroup/PsychometricScore';
import { usePostFileExportMutation } from '../../services/fileExport/file-export-service';
import {
  useUpdateSegmentationAnalysisMutation,
  useGetAllDescriptiveAnalysisByOriginIdQuery,
} from '../../services/descriptiveAnalysisReport';
import { HelperDate } from '../../helpers/date';
import { ShowFormMessage } from '../../helpers/message';
import getErrors from '../../helpers/errors';

interface DescriptiveAnalysisModalProps {
  onCancel: () => void;
  visible: boolean;
  fileId?: string;
  fileTypeDataSource?: string;
}

const DescriptiveAnalysisModal = ({
  onCancel,
  visible,
  fileId,
  fileTypeDataSource,
}: DescriptiveAnalysisModalProps) => {
  const { tModalDescriptiveAnalysis } = useCustomTranslation(
    'modalDescriptiveAnalysis',
  );

  const [dataSourceId, setDataSourceId] = useState<string>();
  const [dataSource, setDataSource] = useState<string>();

  const { data: descriptiveAnalysisData } =
    useGetAllDescriptiveAnalysisByOriginIdQuery({
      origin: fileTypeDataSource,
      originId: fileId,
    });

  // const [personasProfileData, setPersonasProfileData] = useState<{
  //   personas: Personas[];
  // }>();

  // useEffect(() => {
  //   getPersonasData().then(profile => setPersonasProfileData(profile));
  // }, []);

  const handleCancel = () => {
    onCancel();
  };

  const [postFileExport, configPostFileExport] = usePostFileExportMutation();
  const [, configUpdateSegmentationAnalysis] =
    useUpdateSegmentationAnalysisMutation();

  const handleSubmit = async () => {
    await postFileExport({
      dataSourceId,
      dataSource,
      fileExportTypeId: 3,
    });
  };

  useEffect(() => {
    setDataSource(descriptiveAnalysisData?.dataSource);
    setDataSourceId(descriptiveAnalysisData?.dataSourceId);
  }, [descriptiveAnalysisData]);

  // const handleUpdateAnalysis = useCallback(() => {
  //   if (fileTypeDataSource && fileId) {
  //     updateSegmentationAnalysis({
  //       originId: fileId,
  //       origin: fileTypeDataSource,
  //     });
  //   }
  // }, [fileId, fileTypeDataSource, updateSegmentationAnalysis]);

  const showMessagePostFileExport = useCallback(() => {
    const { isSuccess, isError, error } = configPostFileExport;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }

    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Relatório exportado com sucesso.',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
    if (isSuccess) {
      onCancel();
    }
  }, [configPostFileExport, onCancel]);

  useEffect(showMessagePostFileExport, [showMessagePostFileExport]);

  const showMessageUpdateAnalysis = useCallback(() => {
    const { isSuccess, isError, error } = configUpdateSegmentationAnalysis;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }
    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Análise atualizada com sucesso.',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
    if (isSuccess) {
      onCancel();
    }
  }, [configUpdateSegmentationAnalysis, onCancel]);

  useEffect(showMessageUpdateAnalysis, [showMessageUpdateAnalysis]);

  return (
    <>
      <Modal
        visible={visible}
        title={null}
        footer={null}
        closable={false}
        width="57rem"
      >
        <ModalImportDetails>
          <Sider1 />
          {descriptiveAnalysisData ? (
            <Wrapper>
              <PageHeader>
                <Column01>
                  <ModalTabTitle weight="bold">
                    {tModalDescriptiveAnalysis('descriptive_analysis')}
                  </ModalTabTitle>
                  {/*  <H4 weight="medium">{data.file_name}</H4> */}
                </Column01>
                <Column02>
                  {/* <Button type="text" icon={<FullscreenOutlined />} /> */}

                  <Button
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={handleCancel}
                  />
                </Column02>
              </PageHeader>

              <Body1>
                <TabGroup tabPosition="left" type="line" size="small">
                  <TabGroupPane
                    tab={tModalDescriptiveAnalysis('socio-demographic')}
                    key="0"
                  >
                    <SocioDemographicTab
                      socioDemographic={
                        descriptiveAnalysisData.sociodemographic
                      }
                    />
                  </TabGroupPane>
                  <TabGroupPane
                    tab={tModalDescriptiveAnalysis('income_brackets')}
                    key="1"
                  >
                    <IncomeBracketsTab
                      income={descriptiveAnalysisData.income}
                    />
                  </TabGroupPane>
                  {/* <TabGroupPane
                    tab={tModalDescriptiveAnalysis('people')}
                    key="2"
                  >
                    <PeopleTab
                      personasData={descriptiveAnalysisData.personas}
                      personasProfileData={personasProfileData}
                    />
                  </TabGroupPane> */}
                  <TabGroupPane
                    tab={tModalDescriptiveAnalysis('financial_risk')}
                    key="3"
                  >
                    <FinancialRiskTab
                      financialRisks={descriptiveAnalysisData.financialRisks}
                    />
                  </TabGroupPane>
                  <TabGroupPane
                    tab={tModalDescriptiveAnalysis('psychometric_score')}
                    key="4"
                  >
                    <PsychometricScoreTab
                      psychometricScore={
                        descriptiveAnalysisData.psychometricScore
                      }
                    />
                  </TabGroupPane>
                </TabGroup>
              </Body1>
              <ActionBar>
                <Body weight="regular">
                  {tModalDescriptiveAnalysis('analysis_carried_out_on')}
                  {HelperDate.formateDate(
                    new Date(descriptiveAnalysisData.analysisDate),
                  )}
                </Body>
                <Buttons>
                  {/* {fileTypeDataSource && (
                    <UpdateAnalysisButton
                      onClick={handleUpdateAnalysis}
                      size="large"
                    >
                      {tModalDescriptiveAnalysis('update_analysis')}
                    </UpdateAnalysisButton>
                  )} */}

                  <ExportFileButton
                    type="primary"
                    icon={<DownloadOutlined />}
                    size="large"
                    onClick={handleSubmit}
                  >
                    {tModalDescriptiveAnalysis('export_report')}
                  </ExportFileButton>
                </Buttons>
              </ActionBar>
            </Wrapper>
          ) : null}
        </ModalImportDetails>
      </Modal>
    </>
  );
};

export default DescriptiveAnalysisModal;
