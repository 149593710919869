import { salesBoxApi } from '../../config/apis';
import { UsersRoute } from '../../config/services-route';
import { builderQueryOdata, BuilderQueryProps } from '../../helpers/odata';
import { providesList } from '../../helpers/provides';
import { URLs } from '../../helpers/urls';
import { TagTypes, WithPagination } from '../types';

import { ChangeUserProps, RoleProps, UserProps } from './users';

const usersApi = salesBoxApi.injectEndpoints({
  endpoints: builder => ({
    getAllRoles: builder.query<RoleProps[], void>({
      query: () => ({
        url: UsersRoute.getRoles,
      }),
    }),
    getAllUsers: builder.query<UserProps[], void>({
      query: () => ({
        url: UsersRoute.getAllUsers,
      }),
      providesTags: response => providesList(response, TagTypes.Users),
    }),
    getAllUsersFiltered: builder.query<
      WithPagination<UserProps[]>,
      BuilderQueryProps<Partial<UserProps>>
    >({
      query: props => {
        return {
          url: URLs.combineOdataUrl(
            UsersRoute.getQueryODATA,
            builderQueryOdata<Partial<UserProps>>(props),
          ),
        };
      },
      providesTags: response => providesList(response?.data, TagTypes.Users),
      transformResponse: (response: any): any => {
        return {
          data: response.value,
          meta: {
            size: response['@odata.count'],
          },
        };
      },
    }),
    postUsers: builder.mutation<UserProps, Omit<UserProps, 'id'>>({
      query: body => ({
        url: UsersRoute.postUsers,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: TagTypes.Users, id: 'LIST' }],
    }),
    editUsers: builder.mutation<UserProps, Partial<UserProps>>({
      query: ({ id, ...body }) => ({
        url: URLs.combine(UsersRoute.putUsers, `${id}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{ type: TagTypes.Users, id: 'LIST' }],
    }),
    deleteUser: builder.mutation<UserProps, string>({
      query: id => ({
        url: URLs.combine(UsersRoute.postUsers, `${id}`),
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: TagTypes.Users, id: 'LIST' }],
    }),
    postRecoveryPassword: builder.mutation<void, string>({
      query: email => ({
        url: UsersRoute.postNewPassword,
        method: 'POST',
        body: {
          email,
        },
      }),
    }),
    getUserById: builder.query<UserProps, string>({
      query: id => ({
        url: URLs.combine(UsersRoute.getUserById, `${id}`),
      }),
    }),
    updatePassword: builder.mutation<void, Partial<ChangeUserProps>>({
      query: ({ currentPassword, newPassword, id }) => ({
        url: `${UsersRoute.updatePassword}/${id}`,
        method: 'PATCH',
        body: {
          currentPassword,
          newPassword,
        },
      }),
    }),
    getUserRoleById: builder.query<string[], string>({
      query: id => ({
        url: URLs.combine(UsersRoute.getUserRoleById, `${id}`),
      }),
    }),
    getUserInformation: builder.query<any, void>({
      query: () => ({
        url: URLs.combine(UsersRoute.getUserInformation),
      }),
    }),
    postUserUncheckFirstAccess: builder.mutation<void, void>({
      query: () => ({
        url: UsersRoute.uncheckFirstAccess,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  usePostUsersMutation,
  useDeleteUserMutation,
  useGetUserRoleByIdQuery,
  useEditUsersMutation,
  useGetUserByIdQuery,
  useGetAllUsersFilteredQuery,
  usePostRecoveryPasswordMutation,
  useGetAllRolesQuery,
  useUpdatePasswordMutation,
  useGetUserInformationQuery,
  usePostUserUncheckFirstAccessMutation,
} = usersApi;
