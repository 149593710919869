import styled from 'styled-components';
import { Modal } from 'antd';

export const Container = styled(Modal)`
  .ant-btn span {
    font-size: 1rem;
    font-weight: 500;
  }

  .ant-modal-header {
    border: none;
    padding-bottom: 0;
  }

  .ant-modal-footer {
    padding: 0;

    border: none;
  }
  .ant-modal-body {
    padding: 0;
  }

  .anticon svg {
    color: rgba(0, 0, 0, 0.7);
  }
  padding: 0;
`;
