/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-syntax */
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCustomTranslation } from '../../../../hooks/custom-translation';
import {
  FileImportProps,
  useGetFileImporterByIdQuery,
  useImportFileMutation,
} from '../../../../services/fileImporter';
import message from '../../../Message';
import CardStatus from '../CardStatus';
import { UploadDragAndDrop } from './styles';

interface FileDataProps {
  setImportData: any;
  setImporterId: any;
  importData: FileImportProps | undefined;
  setIsLoadingFile: any;
}

const DragAndDropFile = ({
  importData,
  setImportData,
  setImporterId: setCurrentImporterId,
  setIsLoadingFile,
}: FileDataProps) => {
  const { id } = useParams();
  const [importerId, setImporterId] = useState<string>(id || '');
  const types = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
  ];
  const { tImportContact } = useCustomTranslation('importContact');
  const [importFile, { isLoading, isError }] = useImportFileMutation();
  const { data: importer } = useGetFileImporterByIdQuery(importerId || '', {
    pollingInterval: 2000,
    refetchOnFocus: false,
    skip: !importerId,
  });
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (importer) {
      setImportData(importer);
      if (importer?.fileImportStatusId === 2) {
        setCurrentImporterId(importer?.id);
        setImporterId('');
      }
    }
  }, [importer, setCurrentImporterId, setImportData]);

  const handleDropFile = useCallback(
    async (options: any) => {
      setIsLoadingFile(true);
      await importFile(options.file)
        .unwrap()
        .then(response => {
          setImporterId(response?.id);
          setImportData(response);
        })
        .catch(err => {
          setErrorMessage(err?.data?.errors[0]?.errorMessage);
          setIsLoadingFile(false);
        });
    },
    [importFile, setImportData, setIsLoadingFile],
  );

  useEffect(() => {
    if (importData?.fileImportStatusId === 2) {
      setIsLoadingFile(false);
    }
  }, [importData]);

  if (isError) {
    return (
      <CardStatus
        type="error"
        title={errorMessage}
        subtitle={tImportContact('select_other_file')}
      />
    );
  }

  if (importData?.fileImportStatusId === 1 || isLoading) {
    return (
      <CardStatus
        type="validating"
        title={tImportContact('wait_valition')}
        subtitle={tImportContact('dont_need_keep_page_open')}
      />
    );
  }

  return (
    <div className="salesbox-upload">
      <UploadDragAndDrop
        accept=".csv, .xlsx"
        showUploadList={false}
        customRequest={handleDropFile}
        beforeUpload={file => {
          if (!types.includes(file.type)) {
            message.error(
              `O arquivo ${file.name} é inválido, os formatos permitidos são: .csv ou .xlsx`,
            );
            return false;
          }
          return true;
        }}
        multiple
        onDrop={e => console.log(e)}
      >
        {!importData && (
          <CardStatus
            type="empty"
            title={tImportContact('drag_and_drop_file')}
            subtitle={tImportContact('search_local_file')}
          />
        )}
        {importData?.fileImportStatusId === 2 && (
          <CardStatus
            type="done"
            title={importData?.customerFileName as string}
            subtitle=""
          />
        )}
      </UploadDragAndDrop>
    </div>
  );
};

export default DragAndDropFile;
