import React from 'react';
import UpgradeIcon from '../../assets/UpgradeIcon.svg';

const Upgrade: React.FC = () => {
  return (
    <div
      style={{
        textAlign: 'center',
        padding: '20px',
        backgroundColor: '#f9f9f9',
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        maxWidth: '400px',
        margin: '20px auto',
      }}
    >
      <img
        src={UpgradeIcon}
        alt="Upgrade Icon"
        style={{ width: '20rem', height: '20rem' }}
      />
      <h2 style={{ fontSize: '2em', margin: '10px 0', color: '#333' }}>
        Limite de Uso Atingido – Atualização Necessária
      </h2>
      <p style={{ fontSize: '1.2em', color: '#666' }}>
        Você atingiu o limite da sua cota no plano atual. Para continuar
        utilizando a plataforma sem interrupções, faça o upgrade para um plano
        que atenda melhor às suas necessidades.
      </p>
    </div>
  );
};

export default Upgrade;
