import { Rule } from 'antd/lib/form';

import InternalForm, { useForm, useWatch } from './Form';
import FormItem from './Item';
import FormList, { FormListProps } from './List';

export * from './styles';

export type FormValidation<T> = { [key in keyof T]?: Rule[] };

export type { FormListProps };

type InternalFormType = typeof InternalForm;

interface FormInterface extends InternalFormType {
  useForm: typeof useForm;
  useWatch: typeof useWatch;
  Item: typeof FormItem;
  List: typeof FormList;
}

const Form = InternalForm as FormInterface;

Form.Item = FormItem;
Form.List = FormList;
Form.useForm = useForm;
Form.useWatch = useWatch;

export default Form;
