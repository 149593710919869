import React, { useCallback, useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { useNavigate } from 'react-router-dom';
import { useCustomTranslation } from '../../../hooks/custom-translation';
import { H3, H5, Body } from '../../../styles/fonts';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';

import {
  WizardWelcome,
  Sider1,
  Wrapper,
  PageHeader,
  TopBar,
  Fields,
  Row01,
  Row02,
  Row021,
  ActionBar,
  CheckboxGroup,
} from './styles';
import Form from '../../Form';
import FormCheckbox from '../../FormCheckbox';
import { usePostFileExportMutation } from '../../../services/fileExport';
import getErrors from '../../../helpers/errors';
import { ShowFormMessage } from '../../../helpers/message';
// eslint-disable-next-line import/extensions
// import { useTransaction } from '../../../navigation/TransactionContext';
import * as CONSTANTS from '../../../navigation/constants';
import { useGetDashboardDataQuery } from '../../../services/dashboard';

interface ModalExportFileProps {
  onOk: () => void;
  onCancel: () => void;
  visible: boolean;
  fileImportId: string;
  fileImportSource: string;
}

const ModalExportFile = ({
  onOk,
  onCancel,
  visible,
  fileImportId,
  fileImportSource,
}: ModalExportFileProps) => {
  const { tModalExportFile } = useCustomTranslation('modalExportFile');

  const dataTotal = [
    { value: 1, label: 'Nome completo', fieldCategory: 1, fieldKey: 'Name' },
    { value: 2, label: 'E-mail', fieldCategory: 1, fieldKey: 'Email' },
    {
      value: 3,
      label: 'Telefone',
      fieldCategory: 1,
      fieldKey: 'PhoneNumber',
    },
    { value: 4, label: 'CPF', fieldCategory: 1, fieldKey: 'Document' },
    { value: 5, label: 'Endereço', fieldCategory: 1, fieldKey: 'Address' },
    { value: 6, label: 'Bairro', fieldCategory: 1, fieldKey: 'District' },
    { value: 7, label: 'Cidade', fieldCategory: 1, fieldKey: 'City' },
    {
      value: 8,
      label: 'Estado',
      fieldCategory: 1,
      fieldKey: 'FederativeUnit',
    },
    { value: 9, label: 'CEP', fieldCategory: 1, fieldKey: 'PostalCode' },
    { value: 12, label: 'Nome', fieldCategory: 3, fieldKey: 'Enriched.Name' },
    {
      value: 13,
      label: 'CPF',
      fieldCategory: 3,
      fieldKey: 'Enriched.Document',
    },
    { value: 14, label: 'Rg', fieldCategory: 3, fieldKey: 'Enriched.Rg' },
    {
      value: 15,
      label: 'Data de nascimento',
      fieldCategory: 3,
      fieldKey: 'Enriched.BirthDate',
    },
    {
      value: 16,
      label: 'Logadouro - Tipo',
      fieldCategory: 3,
      fieldKey: 'Enriched.AddressType',
    },
    {
      value: 17,
      label: 'Logadouro - Título',
      fieldCategory: 3,
      fieldKey: 'Enriched.AddressTitle',
    },
    {
      value: 18,
      label: 'Logadouro - Nome',
      fieldCategory: 3,
      fieldKey: 'Enriched.Address',
    },
    {
      value: 19,
      label: 'Logadouro - Número',
      fieldCategory: 3,
      fieldKey: 'Enriched.AddressNumber',
    },
    {
      value: 20,
      label: 'Logadouro - Complemento',
      fieldCategory: 3,
      fieldKey: 'Enriched.AdditionalAddressDetail',
    },
    {
      value: 21,
      label: 'Bairro',
      fieldCategory: 3,
      fieldKey: 'Enriched.District',
    },
    {
      value: 22,
      label: 'Cidade',
      fieldCategory: 3,
      fieldKey: 'Enriched.City',
    },
    {
      value: 23,
      label: 'Estado',
      fieldCategory: 3,
      fieldKey: 'Enriched.FederativeUnit',
    },
    {
      value: 24,
      label: 'CEP',
      fieldCategory: 3,
      fieldKey: 'Enriched.PostalCode',
    },
    {
      value: 25,
      label: 'Telefone fixo (1)',
      fieldCategory: 3,
      fieldKey: 'Enriched.PhoneNumbers.0',
    },
    {
      value: 26,
      label: 'Telefone fixo (2)',
      fieldCategory: 3,
      fieldKey: 'Enriched.PhoneNumbers.1',
    },
    {
      value: 27,
      label: 'Telefone fixo (3)',
      fieldCategory: 3,
      fieldKey: 'Enriched.PhoneNumbers.2',
    },
    {
      value: 28,
      label: 'Telefone fixo (4)',
      fieldCategory: 3,
      fieldKey: 'Enriched.PhoneNumbers.3',
    },
    {
      value: 29,
      label: 'Telefone fixo (5)',
      fieldCategory: 3,
      fieldKey: 'Enriched.PhoneNumbers.4',
    },
    {
      value: 30,
      label: 'Telefone celular (1)',
      fieldCategory: 3,
      fieldKey: 'Enriched.CellPhones.0',
    },
    {
      value: 31,
      label: 'Telefone celular (2)',
      fieldCategory: 3,
      fieldKey: 'Enriched.CellPhones.1',
    },
    {
      value: 32,
      label: 'Telefone celular (3)',
      fieldCategory: 3,
      fieldKey: 'Enriched.CellPhones.2',
    },
    {
      value: 33,
      label: 'Telefone celular (4)',
      fieldCategory: 3,
      fieldKey: 'Enriched.CellPhones.3',
    },
    {
      value: 34,
      label: 'Telefone celular (5)',
      fieldCategory: 3,
      fieldKey: 'Enriched.CellPhones.4',
    },
    {
      value: 35,
      label: 'E-mail (1)',
      fieldCategory: 3,
      fieldKey: 'Enriched.Emails.0',
    },
    {
      value: 36,
      label: 'E-mail (2)',
      fieldCategory: 3,
      fieldKey: 'Enriched.Emails.1',
    },
    {
      value: 37,
      label: 'E-mail (3)',
      fieldCategory: 3,
      fieldKey: 'Enriched.Emails.2',
    },
    {
      value: 38,
      label: 'E-mail (4)',
      fieldCategory: 3,
      fieldKey: 'Enriched.Emails.3',
    },
    {
      value: 39,
      label: 'E-mail (5)',
      fieldCategory: 3,
      fieldKey: 'Enriched.Emails.4',
    },
    {
      value: 40,
      label: 'Sexo',
      fieldCategory: 3,
      fieldKey: 'Enriched.Gender',
    },
    {
      value: 41,
      label: 'Geolocalização (Latitude)',
      fieldCategory: 3,
      fieldKey: 'Enriched.Latitude',
    },
    {
      value: 42,
      label: 'Geolocalização (Longitude)',
      fieldCategory: 3,
      fieldKey: 'Enriched.Longitude',
    },
    {
      value: 43,
      label: 'Renda familiar presumida',
      fieldCategory: 3,
      fieldKey: 'Enriched.HouseholdIncome',
    },
    {
      value: 44,
      label: 'Estado civil',
      fieldCategory: 3,
      fieldKey: 'Enriched.MaritalStatus',
    },
    {
      value: 45,
      label: 'Status na Receita Federal',
      fieldCategory: 3,
      fieldKey: 'Enriched.FederalRevenueStatus',
    },
    {
      value: 46,
      label: 'Profissão',
      fieldCategory: 3,
      fieldKey: 'Enriched.Profession',
    },
    {
      value: 47,
      label: 'Escolaridade',
      fieldCategory: 3,
      fieldKey: 'Enriched.EducationLevel',
    },
    {
      value: 48,
      label: 'Bolsa Família?',
      fieldCategory: 3,
      fieldKey: 'Enriched.HasFamilyAllowance',
    },
    {
      value: 49,
      label: 'Auxílio Emergencial?',
      fieldCategory: 3,
      fieldKey: 'Enriched.HasEmergencyAid',
    },
    {
      value: 50,
      label: 'Aposentado?',
      fieldCategory: 3,
      fieldKey: 'Enriched.IsRetired',
    },
    {
      value: 51,
      label: 'Óbito?',
      fieldCategory: 3,
      fieldKey: 'Enriched.IsDeath',
    },
    {
      value: 52,
      label: 'PEP relacionado',
      fieldCategory: 3,
      fieldKey: 'Enriched.IsPep',
    },
    {
      value: 53,
      label: 'PEP',
      fieldCategory: 3,
      fieldKey: 'Enriched.RelatedPepCount',
    },
    {
      value: 54,
      label: 'Segmentação psicográfica',
      fieldCategory: 3,
      fieldKey: 'Enriched.PhysicalRetail',
    },
    {
      value: 55,
      label: 'Nome do segmento',
      fieldCategory: 3,
      fieldKey: 'Enriched.SegmentName',
    },
    {
      value: 56,
      label: 'Participação em Empresas',
      fieldCategory: 3,
      fieldKey: 'Enriched.HasCompanyParticipation',
    },
    {
      value: 57,
      label: 'Vantagem econômica',
      fieldCategory: 3,
      fieldKey: 'Enriched.EconomicAdvantage',
    },
    {
      value: 58,
      label: 'Brinde',
      fieldCategory: 3,
      fieldKey: 'Enriched.Gift',
    },
    {
      value: 59,
      label: 'Estabilidade',
      fieldCategory: 3,
      fieldKey: 'Enriched.Stability',
    },
    {
      value: 60,
      label: 'Instabilidade',
      fieldCategory: 3,
      fieldKey: 'Enriched.Instability',
    },
    {
      value: 61,
      label: 'Opção Pagamento',
      fieldCategory: 3,
      fieldKey: 'Enriched.PagOption',
    },
    {
      value: 62,
      label: 'Setor Premium',
      fieldCategory: 3,
      fieldKey: 'Enriched.Premium',
    },
    {
      value: 63,
      label: 'Premium Serviço',
      fieldCategory: 3,
      fieldKey: 'Enriched.PremiumService',
    },
    {
      value: 64,
      label: 'Score Financeiro',
      fieldCategory: 3,
      fieldKey: 'Enriched.FinancialScore',
    },
    {
      value: 65,
      label: 'Propensão ao neuroticismo',
      fieldCategory: 3,
      fieldKey: 'Enriched.ScoreNeur',
    },
    {
      value: 66,
      label: 'Propensão de abertura à experiência',
      fieldCategory: 3,
      fieldKey: 'Enriched.ScoreAber',
    },
    {
      value: 67,
      label: 'Propensão à extroversão',
      fieldCategory: 3,
      fieldKey: 'Enriched.ScoreExtr',
    },
    {
      value: 68,
      label: 'Propensão à conscienciosidade',
      fieldCategory: 3,
      fieldKey: 'Enriched.ScoreCons',
    },
    {
      value: 69,
      label: 'Propensão à agradabilidade',
      fieldCategory: 3,
      fieldKey: 'Enriched.ScoreSoci',
    },
    {
      value: 70,
      label: 'Confiança de marca',
      fieldCategory: 3,
      fieldKey: 'Enriched.BrandTrust',
    },
    {
      value: 71,
      label: 'Consumo potencial digital',
      fieldCategory: 3,
      fieldKey: 'Enriched.DigitalConsumptionPotential',
    },
    {
      value: 72,
      label: 'Conveniência',
      fieldCategory: 3,
      fieldKey: 'Enriched.Convenience',
    },
    {
      value: 73,
      label: 'Custo-benefício',
      fieldCategory: 3,
      fieldKey: 'Enriched.CostBenefit',
    },
    {
      value: 74,
      label: 'ESG (Ambiental, social e governamental)',
      fieldCategory: 3,
      fieldKey: 'Enriched.Esg',
    },
    {
      value: 75,
      label: 'Experiência de compra',
      fieldCategory: 3,
      fieldKey: 'Enriched.ShoppingExperience',
    },
    {
      value: 76,
      label: 'Facilidade de pagamento',
      fieldCategory: 3,
      fieldKey: 'Enriched.EaseOfPayment',
    },
    {
      value: 77,
      label: 'Influência de Consumo Offline',
      fieldCategory: 3,
      fieldKey: 'Enriched.InfluenceOfflineConsumption',
    },
    {
      value: 78,
      label: 'Influência de Consumo Online',
      fieldCategory: 3,
      fieldKey: 'Enriched.InfluenceOnlineConsumption',
    },
    {
      value: 79,
      label: 'Inovação',
      fieldCategory: 3,
      fieldKey: 'Enriched.Innovation',
    },
    {
      value: 80,
      label: 'Status social',
      fieldCategory: 3,
      fieldKey: 'Enriched.SocialStatus',
    },
    {
      value: 81,
      label: 'Valor sazonal',
      fieldCategory: 3,
      fieldKey: 'Enriched.SeasonalValue',
    },
    {
      value: 82,
      label: 'Varejo físico',
      fieldCategory: 3,
      fieldKey: 'Enriched.PhysicalRetail',
    },
    {
      value: 83,
      label: 'Risco financeiro',
      fieldCategory: 3,
      fieldKey: 'Enriched.PsychoFinancialRisk',
    },
    {
      value: 84,
      label: 'Faixa de renda presumida',
      fieldCategory: 3,
      fieldKey: 'Enriched.PresumedIndividualIncome',
    },
    {
      value: 85,
      label: 'Faixa de renda familiar',
      fieldCategory: 3,
      fieldKey: 'Enriched.HouseholdIncome',
    },
  ];

  const dataEntered = dataTotal.filter(data => data.fieldCategory === 1);
  const customFields = dataTotal.filter(data => data.fieldCategory === 2);
  const dataEnrichment = dataTotal.filter(data => data.fieldCategory === 3);

  const [form] = Form.useForm<any>();

  const [searchTerm, setSearchTerm] = useState('');

  const [itemsChecked, setItemsChecked] = useState<any[]>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [objMetadata, setObjMetadata] = useState({});

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setItemsChecked(
      e.target.checked ? dataTotal.map(value => value.value) : [],
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);

    if (e.target.checked) {
      const newObjMetaData = {};

      dataTotal.forEach(item => {
        Object.assign(newObjMetaData, {
          [`${item.fieldCategory === 3 ? 'E_' : ''}${item.label}`]:
            item.fieldKey,
        });
      });

      setObjMetadata(newObjMetaData);
    } else {
      setObjMetadata({});
    }
  };

  const searchResults = dataTotal.filter(data =>
    data.label.toLowerCase().includes(searchTerm),
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleChecked = (checkedValues: CheckboxValueType[]) => {
    setItemsChecked(
      checkedValues.map(
        value => dataTotal.find(data => data.value === value)?.value,
      ),
    );
    setIndeterminate(
      !!checkedValues.length && checkedValues.length < dataTotal.length,
    );

    checkedValues.map(value =>
      setObjMetadata(prevState => ({
        ...prevState,
        [`${
          dataTotal.find(item => item.value === value)?.fieldCategory === 3
            ? 'E_'
            : ''
        }${dataTotal.find(item => item.value === value)?.label}`]:
          dataTotal.find(item => item.value === value)?.fieldKey,
      })),
    );
  };

  const [postFileExport, configPostFileExport] = usePostFileExportMutation();
  const navigate = useNavigate();
  // const { dashboardData: files } = useTransaction();
  const { data: files } = useGetDashboardDataQuery({});

  const handleSubmit = async () => {
    if (files && files.length) {
      const customerPF = files.find((file: any) => file.customerType === 'PF');
      // const customerPJ = files.find((file: any) => file.customerType === 'PJ');

      const handleCustomerExport = async (customer: any) => {
        if (customer.leads > customer.maxQuantityLeads) {
          navigate(CONSTANTS.UPGRADE);
        }
        await postFileExport({
          dataSourceId: fileImportId,
          dataSource: fileImportSource,
          fileExportTypeId: 1,
          exportMetadata: objMetadata,
        });
      };

      if (customerPF) {
        await handleCustomerExport(customerPF);
      }

      // if (customerPJ) {
      //   await handleCustomerExport(customerPJ);
      // }
    }
  };

  const showMessagePostFileExport = useCallback(() => {
    const { isSuccess, isError, error } = configPostFileExport;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }

    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Seu arquivo foi gerado e está sendo processado.',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
    if (isSuccess) {
      form.resetFields();
      setItemsChecked([]);
      onCancel();
    }
  }, [configPostFileExport, form, onCancel]);

  const handleCancel = () => {
    onCancel();
    onOk();
    form.resetFields();
    setItemsChecked([]);
  };

  useEffect(showMessagePostFileExport, [showMessagePostFileExport]);

  return (
    <>
      <Modal
        visible={visible}
        title={null}
        footer={null}
        closable={false}
        width="41.25rem"
      >
        <WizardWelcome>
          <Sider1 />
          <Wrapper>
            <PageHeader>
              <H3 weight="medium">{tModalExportFile('export_enrichment')}</H3>
              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={handleCancel}
              />
            </PageHeader>
            <H5 weight="medium">
              {tModalExportFile('what_fields_do_you_want')}
            </H5>
            <TopBar>
              <Input
                size="large"
                placeholder={tModalExportFile('search_for_fields')}
                style={{ width: '100%' }}
                onChange={handleInputChange}
              />
              <FormCheckbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              >
                <Body weight="medium">{tModalExportFile('select_all')}</Body>
              </FormCheckbox>
            </TopBar>
            <Form form={form} onFinish={handleSubmit}>
              <CheckboxGroup onChange={handleChecked} value={itemsChecked}>
                {searchTerm !== '' && (
                  <Fields>
                    <Form.Item name="searchResults">
                      <Row021>
                        {searchResults.map((option: any) => (
                          <FormCheckbox key={option.value} value={option.value}>
                            {option.label}
                          </FormCheckbox>
                        ))}
                      </Row021>
                    </Form.Item>
                  </Fields>
                )}
                {searchTerm === '' && (
                  <Fields>
                    <H5 weight="medium">{tModalExportFile('data_entered')}</H5>

                    <Form.Item name="dataEntered">
                      <Row01>
                        {dataEntered.map((option: any) => (
                          <FormCheckbox
                            checked
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </FormCheckbox>
                        ))}
                      </Row01>
                    </Form.Item>

                    <H5 weight="medium">{tModalExportFile('custom_fields')}</H5>
                    <Form.Item name="customFields">
                      <Row02>
                        {customFields.map((option: any) => (
                          <FormCheckbox key={option.value} value={option.value}>
                            {option.label}
                          </FormCheckbox>
                        ))}
                      </Row02>
                    </Form.Item>

                    <H5 weight="medium">{tModalExportFile('enrichment')}</H5>
                    <Form.Item name="enrichment">
                      <Row021>
                        {dataEnrichment.map((option: any) => (
                          <FormCheckbox key={option.value} value={option.value}>
                            {option.label}
                          </FormCheckbox>
                        ))}
                      </Row021>
                    </Form.Item>
                  </Fields>
                )}
              </CheckboxGroup>
              <ActionBar>
                <Button type="default" size="large" onClick={handleCancel}>
                  {tModalExportFile('cancel')}
                </Button>
                <Button type="primary" size="large" htmlType="submit">
                  {tModalExportFile('export')}
                </Button>
              </ActionBar>
            </Form>
          </Wrapper>
        </WizardWelcome>
      </Modal>
    </>
  );
};

export default ModalExportFile;
