import { Typography } from 'antd';
import { Rectangle, ScatterChart } from 'recharts';
import styled from 'styled-components';
import AntenadoImg from '../../../assets/CoffeeBreak.svg';

export const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
`;
export const Graph = styled(ScatterChart)`
  width: 50rem;
  height: 50rem;
`;

export const CustomRectangle = styled(Rectangle)`
  fill: ${props => props.fill || 'pink'};
  stroke: ${props => props.stroke || 'blue'};
`;

export const GenericText = styled(Typography.Text)`
  font-size: 1rem;
  text-align: left;
  font-weight: 500;
  padding: 0.5rem 0px;
`;

export const GenericTextBold = styled(Typography.Text)`
  font-weight: 700;
`;

export const Column01 = styled.div`
  width: 78rem;
  height: auto;
  margin: 0 auto;
`;
export const Column02 = styled.div`
  max-width: 15rem;
  display: flex;
  flex-direction: column;
`;

export const CustomerImg = styled.img.attrs({
  src: AntenadoImg,
  height: '100%',
  width: 'auto',
})`
  max-height: 150px;
  position: relative;
  margin-top: 3rem;
`;

export const SpinContent = styled.div`
  display: flex;
  height: 70vh;
  align-items: center;
`;

export const CenterColumn = styled.div`
  display: flex;
  justify-content: center;
  grid-column: 2;
`;

export const FlexContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  column-gap: 16px;
  width: 100%;
  align-items: center;
    justify-content: center;
}
`;
