import styled from 'styled-components';

import BarChart01Image from '../../../assets/3669_124681.svg';
import BarChart011Image from '../../../assets/3669_124687.svg';
import BarChart012Image from '../../../assets/3669_124693.svg';
import BarChart013Image from '../../../assets/3669_124699.svg';
import BarChart014Image from '../../../assets/3669_124705.svg';
import Row02Image from '../../../assets/3643_123816.svg';
import { Body } from '../../../styles/fonts';

export const Content01 = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
`;
export const Column012 = styled.div`
  width: 100%;
`;
export const Row01 = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 1rem;
  padding: 1.5rem;
  background-color: #ffffff;
`;
export const Wrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  gap: 1rem;
`;
export const Ellipse2 = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 2.5rem;
  background-color: #e8ecfe;
`;
export const Content1 = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const Column022 = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Data01 = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const Label01 = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`;
export const BarChart01 = styled.img.attrs({
  src: BarChart01Image,
  heigth: 'auto',
  width: '100%',
})`
  max-width: 301.33px;
  position: relative;
`;

export const BarChart011 = styled.img.attrs({
  src: BarChart011Image,
  heigth: 'auto',
  width: '100%',
})`
  max-width: 301.33px;
  position: relative;
`;

export const BarChart012 = styled.img.attrs({
  src: BarChart012Image,
  heigth: 'auto',
  width: '100%',
})`
  max-width: 301.33px;
  position: relative;
`;

export const BarChart013 = styled.img.attrs({
  src: BarChart013Image,
  heigth: 'auto',
  width: '100%',
})`
  max-width: 301.33px;
  position: relative;
`;

export const BarChart014 = styled.img.attrs({
  src: BarChart014Image,
  heigth: 'auto',
  width: '100%',
})`
  max-width: 301.33px;
  position: relative;
`;

export const Column03 = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;
export const Row012 = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  padding: 1.5rem;
  background-color: #ffffff;
  align-items: start;
`;

export const Row02 = styled.img.attrs({
  src: Row02Image,
  heigth: 'auto',
  width: '100%',
})`
  max-width: 349.33px;
`;

export const Offer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;

  .tooltip {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: ${({ theme }) => theme.colors.primary[6]};
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;

    span[role='img'] {
      max-width: 1rem;
    }

    span {
      max-width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const OfferValue = styled.span`
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.character.secondary};
`;

export const LearnMoreButton = styled(Body)`
  cursor: pointer;
`;
