import { Icon } from './styles';

interface Props {
  isFiltered: boolean;
}

const FilterIcon: React.FC<Props> = props => {
  return <Icon {...props} />;
};

export default FilterIcon;
