import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomerChoice from '../../components/CustomerChoice';
import Loading from '../../components/Loading';
import { useAuth } from '../../hooks/auth';
import * as CONSTANTS from '../../navigation/constants';
import { Wrapper } from './styles';

const Landing = () => {
  const [finishLoading, setFinishLoading] = useState(false);
  const navigate = useNavigate();
  const { userInfo } = useAuth();

  const handleFinishLoading = () => {
    if (userInfo.roles[0] !== 'Revenda') {
      navigate(CONSTANTS.DASHBOARD);
    } else {
      setFinishLoading(true);
    }
  };

  return (
    <Wrapper>
      <Loading onFinish={handleFinishLoading} />
      {finishLoading && <CustomerChoice />}
    </Wrapper>
  );
};

export default Landing;
