import React, { useState } from 'react';
import PhoneChoice from '../../components/PhoneChoice';
import {
  useGetAllWhatsAppPhoneQuery,
  useGetChatTokenByPhoneIdQuery,
  WhatsAppPhoneProps,
} from '../../services/whatsAppPhone';

const Chat: React.FC = () => {
  const [selectedPhone, setSelectedPhone] = useState<WhatsAppPhoneProps>();

  const { data: phones } = useGetAllWhatsAppPhoneQuery();

  const { data: chat } = useGetChatTokenByPhoneIdQuery(
    selectedPhone?.id || '',
    {
      skip: !selectedPhone?.id,
    },
  );

  return (
    <>
      <PhoneChoice phoneNumbers={phones} selectPhone={setSelectedPhone} />
      {chat?.token && chat?.getChatUrl && (
        <iframe
          src={`https://main.dhh4rszxjxzl4.amplifyapp.com/salesbox/${
            chat.token
          }/${encodeURIComponent(`${chat.getChatUrl}`)}`}
          title="description"
          style={{ width: '100%', height: '100%', border: 'none' }}
        />
      )}
    </>
  );
};

export default Chat;
