import { ReactNode, useEffect, useState } from 'react';
import * as S from './styles';
import { H4 } from '../../../styles/fonts';

interface CreativeCardProps {
  id: number;
  title: string;
  description: string;
  icon: ReactNode;
  selected: number;
  disabled: boolean;
  onClick: () => void;
}

const CreativeCard = ({
  id,
  title,
  description,
  icon,
  selected,
  disabled,
  onClick,
}: CreativeCardProps) => {
  const [selectedCard, setSelectedCard] = useState<boolean>(false);

  useEffect(() => {
    if (selected === id) {
      setSelectedCard(true);
    } else {
      setSelectedCard(false);
    }
  }, [id, selected]);

  return (
    <S.CreativeCard
      onClick={onClick}
      selected={selectedCard}
      disabled={disabled}
    >
      <S.Wrapper>
        <S.IconWrapper>{icon}</S.IconWrapper>
        <S.TextWrapper>
          <H4 weight="medium">{title}</H4>
          <p>{description}</p>
        </S.TextWrapper>
      </S.Wrapper>
    </S.CreativeCard>
  );
};

export default CreativeCard;
