import styled from 'styled-components';

interface IProps {
  posX: number;
  posY: number;
}

const DivDefault = styled.div`
  padding: 1rem;
`;

export const Container = styled.div<IProps>`
  position: absolute;
  /* top: ${({ posY }) => `${posY}px`};
  left: ${({ posX }) => `${posX}px`}; */
  right: ${({ posX }) => (posX === 0 ? '24px' : 'initial')};
  translate: ${({ posX, posY }) => `${posX}px ${posY}px`};

  background-color: ${props => props.theme.colors.neutral.gray[1]};

  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  width: 20rem;
  cursor: move;
`;

export const Header = styled(DivDefault)`
  color: ${props => props.theme.colors.character.primary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.gray[5]};
`;

export const Content = styled(DivDefault)`
  height: 15.5rem;
  padding: 0;
  position: relative;
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & + & {
    margin-top: 1rem;
  }
`;
