import { CloseOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd';
import React from 'react';
import Button from '../../../components/Button';
import Input from '../../../components/FormInput/Input';
import { useCustomTranslation } from '../../../hooks/custom-translation';
import { Body, H4 } from '../../../styles/fonts';
import {
  FormItem,
  ModalContainer,
  ModalContent,
  ModalPatternBar,
  ModalSave,
  SaveForm,
} from './styles';

interface ModalSaveSegmentationProps {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance;
  handleSubmit: (data: FormInstance) => Promise<void>;
}

const ModalSaveSegmentation = ({
  name,
  setName,
  visible,
  setVisible,
  form,
  handleSubmit,
}: ModalSaveSegmentationProps) => {
  const { tContactSegmentsEdit } = useCustomTranslation('contactSegmentsEdit');

  return (
    <ModalSave
      visible={visible}
      title={null}
      footer={null}
      closable={false}
      width="35rem"
    >
      <ModalContainer>
        <ModalPatternBar />
        <ModalContent>
          <SaveForm
            form={form}
            layout="vertical"
            onFinish={data => handleSubmit(data)}
            initialValues={{ name }}
          >
            <header>
              <H4>{tContactSegmentsEdit('save_segmentation')}</H4>
              <CloseOutlined onClick={() => setVisible(!visible)} />
            </header>
            <main>
              <FormItem
                label={<Body>{tContactSegmentsEdit('name')}</Body>}
                name="name"
              >
                <Input
                  size="large"
                  style={{ width: '100%' }}
                  onChange={e => setName(e.target.value)}
                />
              </FormItem>
            </main>
            <footer>
              <Button onClick={() => setVisible(!visible)}>
                {tContactSegmentsEdit('cancel')}
              </Button>
              <Button type="primary" htmlType="submit">
                {tContactSegmentsEdit('save_segmentation')}
              </Button>
            </footer>
          </SaveForm>
        </ModalContent>
      </ModalContainer>
    </ModalSave>
  );
};

export default ModalSaveSegmentation;
