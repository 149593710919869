/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-syntax */
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Papa from 'papaparse';
import { useCustomTranslation } from '../../../../hooks/custom-translation';
import {
  FileImportProps,
  useLazyGetPresignedUrlFileQuery,
  usePostFileS3Mutation,
  useUpdateFileToS3Mutation,
} from '../../../../services/rfvFileImporter';
import message from '../../../../components/Message';
import CardStatus from '../CardStatus';
import { UploadDragAndDrop } from './styles';
import { Feedback } from '../../../../helpers/message';

interface FileDataProps {
  setImportData: any;
  setImporterId: any;
  setCustomerId: any;
  importData: FileImportProps | undefined;
  setIsLoadingFile: any;
  change: any;
}

interface CSVData {
  'Order ID': string;
  'Order Date': string;
  'Customer ID': string;
  'Product ID': string;
  Sales: number;
  Quantity: number;
  Discount: number;
  Profit: number;
  'Shipping Cost': number;
}

const DragAndDropFile = ({
  importData,
  setImportData,
  setImporterId,
  setCustomerId,
  setIsLoadingFile,
  change,
}: FileDataProps) => {
  const { id } = useParams();
  const types = ['text/csv'];

  const { tImportRfv } = useCustomTranslation('importRfv');
  // const [importFile, { isLoading, isError }] = useImportFileMutation();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [getPresignURL] = useLazyGetPresignedUrlFileQuery();
  const [updateFile] = useUpdateFileToS3Mutation();
  const [postFile, { isLoading, isError }] = usePostFileS3Mutation();
  // const { refetch } = useGetUserUrlLogoQuery();
  const [uuid, setUuid] = useState<string>(() => uuidv4().toString());

  const renameFile = (filename: any) => {
    return filename.replace('.csv', `-${uuid}.csv`);
  };

  const handleGetPresignURL = useCallback(
    async (file: File) => {
      console.log('uuid: ', uuid);
      const url = await getPresignURL({
        contentType: file.type,
        fileName: renameFile(file.name),
      });

      return url.data.url;
    },
    [getPresignURL],
  );

  const handleDropFile = useCallback(
    async (options: any) => {
      setIsLoadingFile(true);

      const validateCSV = (file: File): Promise<boolean> => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = function (event) {
            const csvData = event?.target?.result;

            if (typeof csvData === 'string') {
              Papa.parse<CSVData>(csvData, {
                header: true,
                complete(results: Papa.ParseResult<CSVData>) {
                  const columns = results.meta.fields;
                  const requiredColumns: (keyof CSVData)[] = [
                    'Order ID',
                    'Order Date',
                    'Customer ID',
                    'Product ID',
                    'Sales',
                    'Quantity',
                    'Discount',
                    'Profit',
                    'Shipping Cost',
                  ];

                  const hasAllColumns = requiredColumns.every(
                    column =>
                      columns !== undefined &&
                      columns.includes(column as string),
                  );
                  if (hasAllColumns) {
                    resolve(true);
                  } else {
                    resolve(false);
                  }
                },
                error(error: any) {
                  reject(error);
                },
              });
            } else {
              reject(new Error('Falha ao ler o arquivo.'));
            }
          };

          reader.onerror = error => {
            reject(error);
          };

          reader.readAsText(file);
        });
      };

      const isValidCSV = await validateCSV(options.file);

      if (!isValidCSV) {
        setIsLoadingFile(false);
        Feedback.error(
          'O arquivo CSV deve conter as mesmas colunas do template.',
        );
        return;
      }

      const url = await handleGetPresignURL(options.file);

      if (url) {
        await updateFile({
          preSignedUrl: url,
          file: options.file,
        })
          .unwrap()
          .then((e: any) => {
            change(1);
            postFile({
              file: renameFile(options.file.name),
              // renameFile: options.file.name + uuid,
            })
              .unwrap()
              .then((res: any) => {
                setCustomerId(res.customerId);
                setImporterId(res.id);
                Feedback.success('Upload concluído com sucesso!');
                // change(2);
                // refetch();
              })
              .catch(() => {
                Feedback.error('Falha no upload do arquivo.');
              });
          })
          .catch(error => Feedback.error('Falha no upload do arquivo.'));
      }
    },
    [
      change,
      handleGetPresignURL,
      postFile,
      setCustomerId,
      setImporterId,
      setIsLoadingFile,
      updateFile,
    ],
  );

  useEffect(() => {
    if (importData?.fileImportStatusId === 2) {
      setIsLoadingFile(false);
    }
  }, [importData, setIsLoadingFile]);

  if (isError) {
    return (
      <CardStatus
        type="error"
        title={errorMessage}
        subtitle={tImportRfv('select_other_file')}
      />
    );
  }

  if (importData?.fileImportStatusId === 1 || isLoading) {
    return (
      <CardStatus
        type="validating"
        title={tImportRfv('wait_valition')}
        subtitle={tImportRfv('dont_need_keep_page_open')}
      />
    );
  }

  return (
    <div className="salesbox-upload">
      <UploadDragAndDrop
        accept=".csv, .xlsx"
        showUploadList={false}
        customRequest={handleDropFile}
        beforeUpload={file => {
          console.log('file: ', file);
          if (!types.includes(file.type)) {
            message.error(
              `O arquivo ${file.name} é inválido, somemte o formato .csv é permitido.`,
            );
            return false;
          }
          return true;
        }}
        multiple
        onDrop={e => console.log(e)}
      >
        {!importData && (
          <CardStatus
            type="empty"
            title={tImportRfv('drag_and_drop_file')}
            subtitle={tImportRfv('search_local_file')}
          />
        )}
        {importData?.fileImportStatusId === 2 && (
          <CardStatus
            type="done"
            title={importData?.customerFileName as string}
            subtitle=""
          />
        )}
      </UploadDragAndDrop>
    </div>
  );
};

export default DragAndDropFile;
