import {
  Children,
  cloneElement,
  isValidElement,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';

import Geohash from 'latlon-geohash';
import { useDeepCompareEffectForMaps } from '../../../helpers/maps/use-deep-compare';

import { MapContainer } from './styles';

interface CustoMapProps extends google.maps.MapOptions {
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  heatMapGeoHashes?: { geoHashs: [{ geoHash: string; length: number }] };
  children?: React.ReactNode;
}

const mapGradient = [
  'rgba(29,28,56,0)',
  'rgba(29,28,56,1)',
  'rgba(48,48,119,1)',
  'rgba(71,48,152,1)',
  'rgba(115,37,149,1)',
  'rgba(158,30,149,1)',
  'rgba(196,26,149,1)',
  'rgba(216,55,107,1)',
  'rgba(235,55,41,1)',
  'rgba(235,104,37,1)',
  'rgba(235,128,34,1)',
  'rgba(241,160,25,1)',
  'rgba(243,184,20,1)',
  'rgba(244,213,26,1)',
];

const CustomMap: React.FC<CustoMapProps> = ({
  onClick,
  onIdle,
  children,
  heatMapGeoHashes,
  ...options
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const [heatMap, setHeatMap] =
    useState<google.maps.visualization.HeatmapLayer>();
  const [heatMapPoints, setHeatMapPoints] = useState<any[]>([]);

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }

    setHeatMap(
      new window.google.maps.visualization.HeatmapLayer({
        data: heatMapPoints,
        map,
        gradient: mapGradient,
      }),
    );
  }, [ref, map, heatMapPoints]);

  useEffect(() => {
    map?.getStreetView().setVisible(false);
    if (heatMapGeoHashes?.geoHashs?.length) {
      const points: any[] = [];
      setHeatMapPoints([]);
      heatMapGeoHashes.geoHashs.map(
        (item: { geoHash: string; length: number }) => {
          const { lat, lon } = Geohash.decode(item.geoHash);
          points.push({
            location: new google.maps.LatLng(lat, lon),
            weight: item?.length,
          });
          heatMap?.setMap(null);
          return setHeatMapPoints(points);
        },
      );
    } else {
      heatMap?.setMap(null);
      setHeatMapPoints([]);
    }
  }, [heatMapGeoHashes, map]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
      heatMap?.setMap(map);
    }
  }, [map, options]);

  useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach(eventName =>
        google.maps.event.clearListeners(map, eventName),
      );

      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <MapContainer ref={ref} />
      {Children.toArray(children)
        .filter(isValidElement)
        .map(child => cloneElement(child, { map } as any))}
    </>
  );
};

export default memo(CustomMap);
