import GaugeChart from 'react-gauge-chart';
import { memo } from 'react';

interface IProps {
  value: number | undefined;
}

const ProfileGaugeChart = ({ value }: IProps) => {
  const val = value ? Number((value / 1000).toFixed(2)) : 0;
  return (
    <GaugeChart
      id="gauge-chart"
      nrOfLevels={4}
      percent={val}
      hideText
      colors={['#FF7A45', '#FADB14', '#A0D911', '#389E0D']}
    />
  );
};

export default memo(ProfileGaugeChart);
