import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { EndpointBuilder as EndpointBuilderBase } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  BaseQueryFn as BaseQueryFnBase,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';

export type BaseQuery = BaseQueryArg<any>;

export enum TagTypes {
  Contact = 'Contact',
  Resales = 'Resale',
  ServicePlans = 'ServicePlan',
  Customer = 'Customer',
  CustomField = 'CustomField',
  FileImport = 'FileImport',
  FileExport = 'FileExport',
  FieldType = 'FieldType',
  Users = 'User',
  Auth = 'Auth',
  FilterFieldCategory = 'FilterFieldCategory',
  Segmentation = 'Segmentation',
  PotentialAudience = 'PotentialAudience',
  FacebookCampaign = 'FacebookCampaign',
  FacebookGetAccount = 'FacebookAccounts',
  FacebookAuth = 'FacebookAuth',
  WhatsAppCampaign = 'WhatsAppCampaign',
  WhatsAppPhone = 'WhatsAppPhone',
  WhatsAppTemplate = 'WhatsAppTemplate',
  FacebookPost = 'FacebookPost',
  AdCreative = 'AdCretive',
  AdCreativeBrandVoice = 'AdCreativeBrandVoice',
  AdCreativeFunnelStage = 'AdCreativeFunnelStage',
  AdCreativeSegment = 'AdCreativeSegment',
  BarChart = 'BarChart',
  Personas = 'Personas',
  TopPersonas = 'TopPersonas',
  UploadLogo = 'UploadLogo',
  ImportRfv = 'ImportRfv',
  AudienceConnection = 'AudienceConnections',
  Dashboard = 'Dashboard',
}

type BaseQueryFn = BaseQueryFnBase<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  any,
  FetchBaseQueryMeta
>;

export type EndpointBuilder<
  R extends string | never,
  T extends string,
> = EndpointBuilderBase<BaseQueryFn, R, T>;

export type PutQuery<T, U> = {
  id: T;
  body: U;
};

export interface Pagination {
  size: number;
}

export interface WithPagination<T> {
  data: T;
  meta: Pagination;
}
