import styled from 'styled-components';

import { Table, Select } from 'antd';

import GenericSelect from '../../Select';
import GenericCodeButton from '../../Button';

export const Container = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;
export const CombineFieldsTable = styled(Table)`
  width: 100%;
`;

export const SelectColumnFile = styled(GenericSelect)`
  width: 100%;
`;

export const SelectColumnFileOptions = styled(Select.Option)``;

export const ActionBar = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
`;
export const ButtonBeginImport = styled(GenericCodeButton)`
  width: 10.44rem;
  height: 2.5rem;
  border-radius: 0.12rem;
`;

export const SelectDefaultFieldGroup = styled(Select.OptGroup)``;
export const SelectCustomFieldGroup = styled(Select.OptGroup)``;

export const AddCustomField = styled(GenericCodeButton)`
  width: 100%;
  color: ${props => props.theme.colors.primary[6]};
  margin-bottom: -4px;
`;
