import { Form, FormItemProps } from 'antd';
import { memo } from 'react';

import { Text } from './styles';

export const Item: React.FC<FormItemProps> = ({ label, ...props }) => {
  const formLabel = typeof label === 'string' ? <Text>{label}</Text> : label;
  return <Form.Item label={formLabel} {...props} />;
};

export default memo(Item);
