import React, { memo, PropsWithChildren } from 'react';

import { Dropdown } from 'antd';

import Actions, { Props as PropsActions, ItemAction } from './Actions';

type Props = PropsWithChildren & PropsActions;

const ActionsDropdown: React.FC<Props> = ({ children, ...props }) => {
  return (
    <Dropdown trigger={['click']} overlay={<Actions {...props} />}>
      {children}
    </Dropdown>
  );
};

export type { ItemAction };

export default memo(ActionsDropdown);
