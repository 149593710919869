import { FC, PropsWithChildren } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import Skeletons from '../../../components/Skeletons';

import { useAuth } from '../../../hooks/auth';

const AuthGuardRoute: FC<PropsWithChildren> = () => {
  const { authenticatedIsSuccess, authenticatedIsPending } = useAuth();

  if (authenticatedIsPending) {
    return <Skeletons.LandingPage />;
  }

  return authenticatedIsSuccess ? (
    <Outlet />
  ) : (
    <Navigate
      to={`unauthorized?path=${window.location.pathname.substring(1)}`}
    />
  );
};

export default AuthGuardRoute;
