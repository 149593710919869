import React, { memo, PropsWithChildren } from 'react';

import { Wrapper, Body } from './styles';

interface DropDownProps extends PropsWithChildren {
  overlay: JSX.Element;
}

const Dropdown = ({ children, overlay }: DropDownProps) => {
  return (
    <Wrapper overlay={overlay} trigger={['click']}>
      <Body>{children}</Body>
    </Wrapper>
  );
};

export default memo(Dropdown);
