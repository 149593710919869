import { memo } from 'react';

import { Input } from 'antd';
import { PasswordProps } from 'antd/lib/input';

const Password: React.FC<PasswordProps> = ({ ...props }) => {
  return <Input.Password {...props} />;
};

export default memo(Password);
