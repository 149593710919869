import styled from 'styled-components';

import Form from '../../../components/Form';
import FormInput from '../../../components/FormInput';

const DivDefault = styled.div`
  padding: 1rem;
`;

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;

  background-color: ${props => props.theme.colors.neutral.gray[1]};
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  z-index: 1;
  min-width: 25rem;
  max-width: 25rem;
`;

export const Header = styled(DivDefault)`
  display: flex;
  gap: 1rem;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.gray[5]};
`;

export const HeaderButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
`;

export const Content = styled(DivDefault)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
`;

export const Footer = styled(DivDefault)`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  justify-content: space-between;
`;

export const RadiusInput = styled.div``;

export const FooterWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  overflow: auto;
  max-height: 35rem;
`;

export const RadiusInputLabel = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  white-space: nowrap;
  width: 100%;
`;

export const RadiusContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  gap: 0.5rem;

  .ant-form-item-label {
    width: 50%;
  }
`;

export const Radius = styled(Form.Item)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row nowrap !important;
  gap: 0.5rem;
`;

export const Input = styled(FormInput)`
  width: 100%;
`;

export const RadiusIcon = styled.div`
  max-height: 2rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.gray[5]};

  img {
    width: 1rem;
    height: 1rem;
  }
`;

export const RadioButtonGroup = styled.div`
  width: 100%;
  display: inline-flex;
`;
