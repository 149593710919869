import { stringToNumber } from '../../../../helpers/numberFormatter';

import { useCustomTranslation } from '../../../../hooks/custom-translation';
import { Body, Footnote } from '../../../../styles/fonts';
import Collapse, { Panel } from '../../../Collapse';
import BarChart from '../../../Contacts/Contact/ContactProfile/BarChart';

import {
  CollapseItemContent,
  Content03,
  Data011,
  Label,
  Question1,
} from './styles';

interface LifestyleTabProps {
  food: { question: string; answer: string }[] | undefined;
  lifestyle: { question: string; answer: string }[] | undefined;
  healthHabits: { question: string; answer: string }[] | undefined;
  socialPoliticalOpinion: { question: string; answer: string }[] | undefined;
  socialMedia: { question: string; answer: string }[] | undefined;
  values: { question: string; answer: string }[] | undefined;
}

const Lifestyle = ({
  food,
  lifestyle,
  healthHabits,
  socialPoliticalOpinion,
  socialMedia,
  values,
}: LifestyleTabProps) => {
  const { tModalPersona } = useCustomTranslation('modalPersona');

  const formatNumber = (num: string | undefined) => {
    if (num !== undefined) {
      return stringToNumber(num);
    }
    return 0;
  };

  return (
    <>
      <Content03>
        <Collapse>
          <Panel header={tModalPersona('food')} key="1">
            <CollapseItemContent>
              <Data011>
                {food?.map(question => {
                  return (
                    <Question1 key={question.question}>
                      <Body weight="medium">{question.question}</Body>
                      <Label>
                        <Footnote>{tModalPersona('0')}</Footnote>
                        <Footnote>{tModalPersona('10')}</Footnote>
                      </Label>
                      <BarChart
                        value={formatNumber(question.answer) * 10}
                        position="width"
                        backgroundBarColor="#E8ECFE"
                        backgroundBarBorder="#2966F5"
                        backgroundBarBorderLeft="hidden"
                        pointerBarColor="#2966F5"
                        pointerBarBorder="#2966F5"
                        pointerBarBorderRadius="0px 8px 8px 0px"
                      />
                    </Question1>
                  );
                })}
              </Data011>
            </CollapseItemContent>
          </Panel>

          <Panel header={tModalPersona('lifestyle')} key="2">
            <CollapseItemContent>
              <Data011>
                {lifestyle?.map(question => {
                  return (
                    <Question1 key={question.question}>
                      <Body weight="medium">{question.question}</Body>
                      <Label>
                        <Footnote>{tModalPersona('0')}</Footnote>
                        <Footnote>{tModalPersona('10')}</Footnote>
                      </Label>
                      <BarChart
                        value={formatNumber(question.answer) * 10}
                        position="width"
                        backgroundBarColor="#E8ECFE"
                        backgroundBarBorder="#2966F5"
                        backgroundBarBorderLeft="hidden"
                        pointerBarColor="#2966F5"
                        pointerBarBorder="#2966F5"
                        pointerBarBorderRadius="0px 8px 8px 0px"
                      />
                    </Question1>
                  );
                })}
              </Data011>
            </CollapseItemContent>
          </Panel>
          <Panel header={tModalPersona('health_habits')} key="3">
            <CollapseItemContent>
              <Data011>
                {healthHabits?.map(question => {
                  return (
                    <Question1 key={question.question}>
                      <Body weight="medium">{question.question}</Body>
                      <Label>
                        <Footnote>{tModalPersona('0')}</Footnote>
                        <Footnote>{tModalPersona('10')}</Footnote>
                      </Label>
                      <BarChart
                        value={formatNumber(question.answer) * 10}
                        position="width"
                        backgroundBarColor="#E8ECFE"
                        backgroundBarBorder="#2966F5"
                        backgroundBarBorderLeft="hidden"
                        pointerBarColor="#2966F5"
                        pointerBarBorder="#2966F5"
                        pointerBarBorderRadius="0px 8px 8px 0px"
                      />
                    </Question1>
                  );
                })}
              </Data011>
            </CollapseItemContent>
          </Panel>
          <Panel header={tModalPersona('social_and_political_opinion')} key="4">
            <CollapseItemContent>
              <Data011>
                {socialPoliticalOpinion?.map(question => {
                  return (
                    <Question1 key={question.question}>
                      <Body weight="medium">{question.question}</Body>
                      <Label>
                        <Footnote>{tModalPersona('0')}</Footnote>
                        <Footnote>{tModalPersona('10')}</Footnote>
                      </Label>
                      <BarChart
                        value={formatNumber(question.answer) * 10}
                        position="width"
                        backgroundBarColor="#E8ECFE"
                        backgroundBarBorder="#2966F5"
                        backgroundBarBorderLeft="hidden"
                        pointerBarColor="#2966F5"
                        pointerBarBorder="#2966F5"
                        pointerBarBorderRadius="0px 8px 8px 0px"
                      />
                    </Question1>
                  );
                })}
              </Data011>
            </CollapseItemContent>
          </Panel>
          <Panel header={tModalPersona('social_networks')} key="5">
            <CollapseItemContent>
              <Data011>
                {socialMedia?.map(question => {
                  return (
                    <Question1 key={question.question}>
                      <Body weight="medium">{question.question}</Body>
                      <Label>
                        <Footnote>{tModalPersona('0')}</Footnote>
                        <Footnote>{tModalPersona('10')}</Footnote>
                      </Label>
                      <BarChart
                        value={formatNumber(question.answer) * 10}
                        position="width"
                        backgroundBarColor="#E8ECFE"
                        backgroundBarBorder="#2966F5"
                        backgroundBarBorderLeft="hidden"
                        pointerBarColor="#2966F5"
                        pointerBarBorder="#2966F5"
                        pointerBarBorderRadius="0px 8px 8px 0px"
                      />
                    </Question1>
                  );
                })}
              </Data011>
            </CollapseItemContent>
          </Panel>
          <Panel header={tModalPersona('values')} key="6">
            <CollapseItemContent>
              <Data011>
                {values?.map(question => {
                  return (
                    <Question1 key={question.question}>
                      <Body weight="medium">{question.question}</Body>
                      <Label>
                        <Footnote>{tModalPersona('0')}</Footnote>
                        <Footnote>{tModalPersona('10')}</Footnote>
                      </Label>
                      <BarChart
                        value={formatNumber(question.answer) * 10}
                        position="width"
                        backgroundBarColor="#E8ECFE"
                        backgroundBarBorder="#2966F5"
                        backgroundBarBorderLeft="hidden"
                        pointerBarColor="#2966F5"
                        pointerBarBorder="#2966F5"
                        pointerBarBorderRadius="0px 8px 8px 0px"
                      />
                    </Question1>
                  );
                })}
              </Data011>
            </CollapseItemContent>
          </Panel>
        </Collapse>
      </Content03>
    </>
  );
};

export default Lifestyle;
