import { memo } from 'react';

import logo from '../../assets/logospc.png';

interface LogoProps {
  type?: 'default';
}

const logoMap = {
  default: logo,
};

const Logo: React.FC<LogoProps> = ({ type = 'default' }) => (
  <img alt="Logo SalesBox" src={logoMap[type]} style={{ width: '80px' }} />
);

export default memo(Logo);
