import { useEffect, useState, memo, useMemo } from 'react';

import { theme } from '../../../styles/theme';

const circleOptionsDefault: google.maps.CircleOptions = {
  strokeColor: theme.colors.primary[4],
  strokeOpacity: 1,
  strokeWeight: 2,
  fillColor: theme.colors.primary[6],
  fillOpacity: 0.15,
  clickable: false,
};

const Circle: React.FC<google.maps.CircleOptions> = options => {
  const [circle, setCircle] = useState<google.maps.Circle>();

  const circleOptions = useMemo(
    () => ({
      ...circleOptionsDefault,
      ...options,
    }),
    [options],
  );

  useEffect(() => {
    if (!circle) {
      setCircle(new google.maps.Circle());
    }

    // remove marker from map on unmount
    return () => {
      if (circle) {
        circle.setMap(null);
      }
    };
  }, [circle]);

  useEffect(() => {
    if (circle) {
      circle.setOptions(circleOptions);
    }
  }, [circle, circleOptions]);

  return null;
};

export default memo(Circle);
