import styled from 'styled-components';

import { Button, Tabs } from 'antd';

import GenericText from '../../components/Text';
import GenericInputSearch from '../../components/InputSearch';
import GenericSelect from '../../components/Select';

import GenericCodeButton from '../../components/Button';

export const Reports = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1.5rem 2.93rem;
  background-color: #fbfbfb;
  position: relative;
  overflow-y: scroll;
`;
export const PageHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Body = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0rem 0rem 5.5rem 0rem;
`;
export const TopBar = styled.div`
  justify-content: space-between;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;
export const Fields = styled.div`
  display: inline-flex;
  gap: 0.5rem;
`;
export const InputSearch = styled(GenericInputSearch)`
  position: relative;
  width: 20rem;
`;

export const ButtonInputSearch = styled(Button)``;

export const Select = styled(GenericSelect)`
  width: 30%;
`;

export const Totals = styled(GenericText)`
  font-weight: 400;
  font-size: 1rem;
`;

export const TableImports = styled.div`
  display: inline-flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  background-color: #ffffff;
`;
export const TabGroup = styled(Tabs).attrs({
  tabBarStyle: {
    padding: '0rem 2rem 0rem 2rem',
  },
})`
  width: 100%;
`;

export const TabGroupPane = styled(Tabs.TabPane)``;

export const FAB = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  padding: 0rem 1.5rem 1.5rem 0rem;
  box-shadow: 1.75rem 0.5rem rgba(0, 0, 0, 0.05), 1rem rgba(0, 0, 0, 0.08),
    0.38rem -0.25rem rgba(0, 0, 0, 0.12);
  position: fixed;
  right: 0rem;
  bottom: 0rem;
`;
export const AddContactButton = styled(GenericCodeButton)`
  width: 11.62rem;
  height: 2.5rem;
  border-radius: 0.12rem;
`;

export const ActionButton = styled(GenericCodeButton)`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.12rem;
`;

export const Title = styled(GenericText)`
  font-size: 1.88rem;
  flex: 1;
  font-weight: 500;
  color: ${props => props.theme.colors.character.primary};
`;

export const FilterSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;
