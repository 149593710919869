import styled from 'styled-components';

export const FormWrapper = styled.div`
  width: 100%;

  > form {
    > div + div {
      margin-top: 1rem;
    }
  }
`;

export const FormActions = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
`;
