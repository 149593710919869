import { Required } from './required';
import { SameAs } from './same-as';
import { Email } from './email';
import { URL } from './url';
import { NotZero } from './not-zero';

export const Validators = {
  Required,
  SameAs,
  Email,
  URL,
  NotZero,
};
