import { ImportRfvRoute } from '../../config/services-route';
import { TagTypes } from '../types';
import { providesList } from '../../helpers/provides';

// import { useGetInfoQuery } from '../auth';
import { deepDiveApi } from '../../config/apis/deep-dive-query';

const importRfvApi = deepDiveApi.injectEndpoints({
  endpoints: builder => ({
    getImportRfvFile: builder.query<any, any>({
      query: _ => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: ImportRfvRoute.getImportRfv,
        };
      },
      providesTags: response =>
        providesList(response?.data, TagTypes.FileImport),
    }),
    getPresignedUrlRfv: builder.query<any, any>({
      query: ({ fileName }) => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: ImportRfvRoute.getPresignedUrlRfv,
          params: { fileName },
        };
      },
      providesTags: response =>
        providesList(response?.data, TagTypes.FileImport),
    }),
  }),
});

export const { useGetImportRfvFileQuery, useGetPresignedUrlRfvQuery } =
  importRfvApi;
