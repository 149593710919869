/* eslint-disable prettier/prettier */
import React, { memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ActionsDropdown, {
  ItemAction,
} from '../../../../components/ActionsDropdown';

import Button from '../../../../components/Button';
import { useCustomTranslation } from '../../../../hooks/custom-translation';
import * as CONSTANTS from '../../../../navigation/constants';
import { Body } from '../../../../styles/fonts';

import { Item, List, Wrapper } from './styles';

interface ItemsMenu {
  key: string;
  label?: string;
  onClick?: () => void;
  items?: ItemAction[];
}

const HeaderNav: React.FC = () => {
  const { tHeader } = useCustomTranslation('header');
  const navigate = useNavigate();

  const ITEMS_MENU: ItemsMenu[] = useMemo(
    () => [
      {
        key: 'start',
        label: tHeader('start'),
        onClick: () => navigate(CONSTANTS.ROOT),
      },
      {
        key: 'to_analyze',
        label: tHeader('to_analyze'),
        items: [
          {
            label: tHeader('database_management_pf'),
            click: CONSTANTS.DATABASE_MANAGEMENT_PF,
          },
          {
            label: tHeader('database_management_pj'),
            click: CONSTANTS.DATABASE_MANAGEMENT_PJ,
          },
        ],
      },
      {
        key: 'to_plan',
        label: tHeader('to_plan'),
        items: [
          {
            label: tHeader('contact_segmentation'),
            click: CONSTANTS.CONTACT_SEGMENTATION,
          },
          {
            label: tHeader('my_potential_audience'),
            click: CONSTANTS.CREATE_POTENTIAL_AUDIENCE,
          },
        ],
      },
      {
        key: 'trigger',
        label: tHeader('trigger'),
        items: [
          {
            label: tHeader('audience_connection'),
            click: CONSTANTS.CONNECTION_MANAGEMENT,
          },
        ],
      },
      // {
      //   key: 'to_accompany',
      //   label: tHeader('to_accompany'),
      //   items: [
      //     {
      //       label: tHeader('campaign_reports'),
      //       click: CONSTANTS.REPORTS,
      //     },
      //   ],
      // },
    ],
    [navigate, tHeader],
  );

  const generateItem = useCallback((item: typeof ITEMS_MENU[number]) => {
    const itemElement = (
      <Item>
        <Button type="text" onClick={item.onClick}>
          <Body weight="medium">{item.label}</Body>
        </Button>
      </Item>
    );
    return (
      <React.Fragment key={item.key}>
        {item.items ? (
          <ActionsDropdown items={item.items}>{itemElement}</ActionsDropdown>
        ) : (
          itemElement
        )}
      </React.Fragment>
    );
  }, []);

  return (
    <Wrapper>
      <List>{ITEMS_MENU.map(generateItem)}</List>
    </Wrapper>
  );
};

export default memo(HeaderNav);
