/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  ClusterOutlined,
  EnvironmentOutlined,
  ImportOutlined,
  SettingOutlined,
  UploadOutlined,
  LinkOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { MenuProps } from 'antd';

import { useCustomTranslation } from '../../../../hooks/custom-translation';
import { useGetInfoQuery } from '../../../../services/auth';
import { AsideContainer, MenuGroup, Title } from './styles';

const SideNav: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { tSideNav, tHome } = useCustomTranslation('sideNav', 'home');
  const { data: info } = useGetInfoQuery();

  const createMenuByRoles = useCallback(() => {
    const menuOptions: MenuProps['items'] = [];

    const defaultMenuOptions = [
      {
        key: 'contact-import-wizard-pf',
        label: tHome('update_and_enrich_pf'),
        icon: <UploadOutlined />,
      },
      {
        key: 'contact-import-wizard-pj',
        label: tHome('update_and_enrich_pj'),
        icon: <UploadOutlined />,
      },
      {
        key: 'contact-segmentation',
        label: tSideNav('contact_segmentation'),
        icon: <ClusterOutlined />,
      },
      {
        key: 'database-management-pf',
        label: tSideNav('imports_exports_pf'),
        icon: <ImportOutlined />,
      },
      {
        key: 'database-management-pj',
        label: tSideNav('imports_exports_pj'),
        icon: <ImportOutlined />,
      },
      {
        key: 'map',
        label: tSideNav('map'),
        icon: <EnvironmentOutlined />,
      },
      {
        key: 'system-settings',
        label: tSideNav('settings'),
        icon: <SettingOutlined />,
        disabled: (info?.roles[0] as string) !== 'Admin',
      },
      {
        key: 'connection_management',
        label: tSideNav('audience_connection'),
        icon: <LinkOutlined />,
      },
      {
        key: 'personas',
        label: tSideNav('personas'),
        icon: <UsergroupAddOutlined />,
      },
    ];

    defaultMenuOptions.forEach(options => {
      if ((info?.roles[0] as string) === 'Admin') {
        if (options.key === 'system-settings') {
          menuOptions.push(options);
        }
      }
      if (
        (info?.roles[0] as string) === 'Cliente' ||
        (info?.roles[0] as string) === 'Revenda'
      ) {
        if (options.key !== 'system-settings') {
          menuOptions.push(options);
        }
      }
    });

    return menuOptions;
  }, [info, tHome, tSideNav]);

  const ITEMS_MENU: MenuProps['items'] = useMemo(
    () => createMenuByRoles(),
    [createMenuByRoles],
  );

  const handleClickItemMenu = useCallback(
    ({ key }: { key: string }) => {
      navigate(key);
    },
    [navigate],
  );

  return (
    <>
      <AsideContainer>
        <Title>{tSideNav('title')}</Title>
        <MenuGroup
          mode="inline"
          items={ITEMS_MENU}
          onSelect={handleClickItemMenu}
          selectedKeys={[location.pathname]} // Define o item selecionado com base no pathname
        />
      </AsideContainer>
    </>
  );
};

export default memo(SideNav);
