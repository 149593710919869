/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable radix */
import React, { useCallback, useEffect, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import Modal from '../../../Modal';
import { useCustomTranslation } from '../../../../hooks/custom-translation';
import { H3, H5, Body } from '../../../../styles/fonts';
import Button from '../../../Button';
import Form from '../../../Form';
import Input from '../../../Input';

import {
  Sider1,
  Wrapper,
  PageHeader,
  Fields,
  Row01,
  Column01,
  Column02,
  Wrapper1,
  Row02,
  ActionBar,
  LayoutModal,
} from './styles';
import {
  cellphoneMask,
  phoneMask as phoneMaskCustom,
  cpfMask,
  none,
} from '../../../../helpers/masks';
import MaskedInput from '../../../MaskedInput';
import {
  ContactProps,
  useGetContactByDocumentQuery,
  usePostContactMutation,
} from '../../../../services/contact';
import { ShowFormMessage } from '../../../../helpers/message';
import getErrors from '../../../../helpers/errors';
import { useAuth } from '../../../../hooks/auth';

interface LayoutModalProps {
  onOk: () => void;
  onCancel: () => void;
  visible: boolean;
  contact?: ContactProps;
}

const ModalContact = ({
  onOk,
  onCancel,
  visible,
  contact,
}: LayoutModalProps) => {
  const { tContactManagement } = useCustomTranslation('contactManagement');
  const { tModalContact } = useCustomTranslation('modalContact');

  const [documentNumber, setDocumentNumber] = useState<string>('');
  const [skipping, setSkipping] = useState(true);

  const [postContact, configPostContact] = usePostContactMutation();

  const [disableInputs, setDisableInputs] = useState<boolean>(true);

  const { data: contactByDocument, isSuccess } = useGetContactByDocumentQuery(
    {
      id: `/DefaultFields/${documentNumber}`,
    },
    { skip: skipping },
  );

  const [form] = Form.useForm<ContactProps>();

  const { userInfo } = useAuth();

  const phoneMask = React.useMemo(
    () => [
      {
        mask: cellphoneMask,
        lazy: false,
      },
      {
        mask: phoneMaskCustom,
        lazy: false,
      },
    ],
    [],
  );

  const docMask = React.useMemo(
    () => [
      {
        mask: cpfMask,
        lazy: true,
      },
      {
        mask: none,
        lazy: true,
      },
    ],
    [],
  );

  useEffect(() => {
    form.setFieldsValue({
      name: contactByDocument?.name,
      document: contactByDocument?.document,
      email: contactByDocument?.email,
      phoneNumber: contactByDocument?.phoneNumber,
      address: contactByDocument?.address,
      city: contactByDocument?.city,
      district: contactByDocument?.district,
      federativeUnit: contactByDocument?.federativeUnit,
      postalCode: contactByDocument?.postalCode,
      status: contactByDocument?.status,
      fileImportId: contactByDocument?.fileImportId,
      customerId: contactByDocument?.customerId,
      contactStatusId: contactByDocument?.contactStatusId,
    });
  }, [contactByDocument]);

  const handleCancel = () => {
    onCancel();
    onOk();
    form.resetFields();
    setDisableInputs(true);
  };

  const handleSubmit = async ({
    name,
    document,
    email,
    phoneNumber,
    address,
    city,
    district,
    federativeUnit,
    postalCode,
    status,
    fileImportId,
    createdUtc,
    modifiedUtc,
  }: ContactProps) => {
    await postContact({
      name,
      document,
      email,
      phoneNumber,
      address,
      city,
      district,
      federativeUnit,
      postalCode,
      status,
      fileImportId,
      customerId: userInfo.customer,
      createdUtc,
      modifiedUtc,
    }).unwrap();
  };

  /* const formValidation: FormValidation<any> = {
    email: [Validators.Required()],
  };
 */
  const handleChangeDocumentNumber = (e: any) => {
    const newValue = e.target.value.replace(/[- .]/g, '');
    setDocumentNumber(newValue);
    if (newValue.length === 11) {
      setSkipping(!skipping);
    }
  };

  const { isSuccess: postSuccess, isError, error } = configPostContact;

  const showMessagePostContact = useCallback(() => {
    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }
    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess: postSuccess,
        isError,
      },
      config: {
        successMessage: 'Contato adicionado com sucesso',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
    if (postSuccess) {
      form.resetFields();
      onCancel();
    }
  }, [configPostContact, onCancel]);

  useEffect(showMessagePostContact, [showMessagePostContact]);

  const testeCpf = (bla: any) => {
    const cpf = bla.replace(/\D/g, '');
    if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
    let result = true;
    [9, 10].forEach(j => {
      let soma = 0;
      let r;
      cpf
        .split(/(?=)/)
        .splice(0, j)
        .forEach((e: any, i: any) => {
          soma += parseInt(e) * (j + 2 - (i + 1));
        });
      r = soma % 11;
      r = r < 2 ? 0 : 11 - r;
      if (r != cpf.substring(j, j + 1)) result = false;
    });
    return result;
  };

  useEffect(() => {
    if (isSuccess) {
      setDisableInputs(false);
    }
    if (postSuccess) {
      setDisableInputs(true);
    }
  }, [isSuccess, postSuccess]);

  return (
    <Modal
      visible={visible}
      title={null}
      footer={null}
      closable={false}
      width="60rem"
    >
      <LayoutModal>
        <Sider1 />
        <Wrapper>
          <PageHeader>
            <H3 style={{ width: '100%' }} weight="medium">
              {tContactManagement('add_contact')}
            </H3>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={handleCancel}
            />
          </PageHeader>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={contact}
          >
            <Fields>
              <Row01>
                <Column01>
                  <H5 weight="medium">{tModalContact('general_data')}</H5>

                  <Form.Item
                    label={<Body>{tModalContact('cpf')}</Body>}
                    name="document"
                  >
                    <MaskedInput
                      size="large"
                      mask={docMask}
                      maskOptions={{
                        dispatch(appended, dynamicMasked) {
                          let isValidCPF = false;

                          if (dynamicMasked.unmaskedValue.length === 11) {
                            isValidCPF = testeCpf(dynamicMasked.value);
                            if (isValidCPF)
                              return dynamicMasked.compiledMasks[0];
                          }

                          return dynamicMasked.compiledMasks[1];
                        },
                      }}
                      onBlur={e => handleChangeDocumentNumber(e)}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<Body>{tContactManagement('e-mail')}</Body>}
                    name="email"
                  >
                    <Input
                      type="email"
                      size="large"
                      style={{ width: '100%' }}
                      disabled={disableInputs}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<Body>{tModalContact('full_name')}</Body>}
                    name="name"
                  >
                    <Input
                      size="large"
                      style={{ width: '100%' }}
                      disabled={disableInputs}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<Body>{tContactManagement('telephone')}</Body>}
                    name="phoneNumber"
                  >
                    <MaskedInput
                      size="large"
                      mask={phoneMask}
                      maskOptions={{
                        dispatch(appended, dynamicMasked) {
                          const isCellPhone =
                            dynamicMasked.unmaskedValue[2] === '9';
                          return dynamicMasked.compiledMasks[
                            isCellPhone ? 0 : 1
                          ];
                        },
                      }}
                      onChange={e =>
                        form.setFieldsValue({ phoneNumber: e.maskedValue })
                      }
                      disabled={disableInputs}
                    />
                  </Form.Item>
                </Column01>
                <Column02>
                  <H5 weight="medium">{tModalContact('address')}</H5>
                  <Form.Item
                    label={<Body>{tModalContact('address')}</Body>}
                    name="address"
                  >
                    <Input
                      size="large"
                      placeholder={tModalContact('av_da_saude,_123')}
                      style={{ width: '100%' }}
                      disabled={disableInputs}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<Body>{tModalContact('neighborhood')}</Body>}
                    name="district"
                  >
                    <Input
                      size="large"
                      style={{ width: '100%' }}
                      disabled={disableInputs}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<Body>{tModalContact('town')}</Body>}
                    name="city"
                  >
                    <Input
                      size="large"
                      style={{ width: '100%' }}
                      disabled={disableInputs}
                    />
                  </Form.Item>
                  <Wrapper1>
                    <Form.Item
                      label={<Body>{tModalContact('statehood')}</Body>}
                      name="federativeUnit"
                    >
                      <Input
                        size="large"
                        style={{ width: '100%' }}
                        disabled={disableInputs}
                      />
                    </Form.Item>
                    <Form.Item
                      label={<Body>{tModalContact('zip_code')}</Body>}
                      name="postalCode"
                    >
                      <MaskedInput
                        size="large"
                        placeholder={tModalContact('00000-000')}
                        mask="00000-000"
                        style={{ width: '100%' }}
                        disabled={disableInputs}
                      />
                    </Form.Item>
                  </Wrapper1>
                </Column02>
              </Row01>
              <Row02>
                {/*   <Wrapper2>
                  <Column021>
                    <Form.Item
                      label={
                        <Body>{tModalContact('are_you_a_customer?')}</Body>
                      }
                      name="eCliente"
                    >
                      <Input
                        size="large"
                        placeholder={tModalContact('av_da_saude,_123')}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Column021>
                </Wrapper2> */}
              </Row02>

              <ActionBar>
                <Button type="default" size="large" onClick={handleCancel}>
                  {tModalContact('cancel')}
                </Button>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={configPostContact.isLoading}
                  disabled={documentNumber.length < 11 && !isSuccess}
                >
                  {tModalContact('add')}
                </Button>
              </ActionBar>
            </Fields>
          </Form>
        </Wrapper>
      </LayoutModal>
    </Modal>
  );
};

export default ModalContact;
