import styled from 'styled-components';

import { Affix, Table, Tag, Menu, Dropdown } from 'antd';
import GenericButton from '../../Button';

export const Container = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
`;
export const TableGroup = styled(Table)`
  width: 100%;
`;

export const TagStatus = styled(Tag)``;

export const AddUserButton = styled(GenericButton)`
  width: 11.56rem;
  height: 2.5rem;
  border-radius: 0.12rem;
  align-self: flex-end;
`;

export const AddUserButtonSection = styled(Affix)`
  gap: 0.5rem;
  padding: 0rem 1.5rem 1.5rem 0rem;
  box-shadow: 1.75rem 0.5rem rgba(0, 0, 0, 0.05), 1rem rgba(0, 0, 0, 0.08),
    0.38rem -0.25rem rgba(0, 0, 0, 0.12);
  position: fixed;
  right: 0rem;
  bottom: 0rem;
`;

export const ActionsSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;

export const DescriptionTableSection = styled.div``;

export const DropdownUser = styled(Dropdown)``;

export const EditButton = styled(GenericButton)`
  width: 5.94rem;
  height: 2rem;
  font-weight: 500;
  border-radius: 0.12rem;
`;
export const MenuDropdown = styled(Menu)``;

export const MoreButton = styled(GenericButton)`
  width: 2rem;
  height: 2rem;
  border-radius: 0.12rem;
`;

export const ActionButton = styled(GenericButton)`
  width: 2rem;
  height: 2rem;
  border-radius: 0.12rem;
`;
