import React from 'react';

import CollapseItemGroup from '../../CollapseEnrichmentDetails/CollapseItemGroup';

import {
  Sanitization,
  CollapseEnrichmentDetails,
  CollapseEnrichmentDetailsPanel,
} from './styles';

interface ItemQualitiesProps {
  key: string;
  infoName: string;
  status: string;
  quantity: number;
  percentage: number;
}
export type QualitiesProps = Array<ItemQualitiesProps> | undefined;

const SanitizationPage = ({ qualities }: { qualities: QualitiesProps }) => {
  const infoNames = qualities?.map(item => item.infoName);
  const uniqueNames = infoNames
    ?.filter((item, index) => {
      return infoNames?.indexOf(item) === index;
    })
    .sort();
  const groupedQualities = qualities?.reduce(
    (groups: any, item: ItemQualitiesProps) => ({
      ...groups,
      [item.infoName]: [...(groups[item.infoName] || []), item],
    }),
    {},
  );

  return (
    <Sanitization>
      <CollapseEnrichmentDetails bordered ghost>
        {uniqueNames?.map((header: string) => (
          <CollapseEnrichmentDetailsPanel key={header} header={header}>
            <CollapseItemGroup items={groupedQualities[header]} />
          </CollapseEnrichmentDetailsPanel>
        ))}
      </CollapseEnrichmentDetails>
    </Sanitization>
  );
};
export default SanitizationPage;
