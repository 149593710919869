import styled from 'styled-components';

import { Table } from 'antd';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';

import GenericCodeButton from '../Button';

export const ActionButton = styled(GenericCodeButton)`
  width: 2rem;
  height: 2rem;
  border-radius: 0.12rem;
`;

export const Content02 = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
`;
export const TableGroup = styled(Table)`
  width: 100%;
`;

export const ColumnTableGroup = styled(Table.Column)`
  height: 10rem;
  color: #fff;
  line-height: 10rem;
  text-align: center;
  background: #364d79;
`;

export const Content2 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const TagStatus1 = styled.div`
  display: inline-flex;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.25rem;
  align-items: center;
  border-radius: 0.12rem;
  padding: 0.06rem 0.5rem 0.06rem 0.5rem;
  background-color: #fbfbfb;
`;
export const UnmapedComponenticonoutlinedexc = styled(
  ExclamationCircleOutlined,
)`
  color: #000000;
  font-size: 0.75rem;
`;

export const Content3 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const TagStatus2 = styled.div`
  display: inline-flex;
  border: 0.06rem solid #ffd692;
  box-sizing: border-box;
  gap: 0.25rem;
  align-items: center;
  border-radius: 0.12rem;
  padding: 0.06rem 0.5rem 0.06rem 0.5rem;
  background-color: #fff8e7;
`;
export const IconOutlinedSync = styled(SyncOutlined)`
  color: #fb8d17;
  font-size: 0.75rem;
`;

export const Content4 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const TagStatus3 = styled.div`
  display: inline-flex;
  border: 0.06rem solid #b8ec90;
  box-sizing: border-box;
  gap: 0.25rem;
  align-items: center;
  border-radius: 0.12rem;
  padding: 0.06rem 0.5rem 0.06rem 0.5rem;
  background-color: #f7ffee;
`;
export const IconOutlinedCheckCircle1 = styled(CheckCircleOutlined)`
  color: #53c51b;
  font-size: 0.75rem;
`;

export const Content5 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const TagStatus4 = styled.div`
  display: inline-flex;
  border: 0.06rem solid #b8ec90;
  box-sizing: border-box;
  gap: 0.25rem;
  align-items: center;
  border-radius: 0.12rem;
  padding: 0.06rem 0.5rem 0.06rem 0.5rem;
  background-color: #f7ffee;
`;
export const IconOutlinedCheckCircle2 = styled(CheckCircleOutlined)`
  color: #53c51b;
  font-size: 0.75rem;
`;

export const Content6 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
