import { FC, PropsWithChildren } from 'react';

import { Provider } from 'react-redux';
import { DefaultTheme, ThemeProvider } from 'styled-components';

import { store } from '../store';
import { theme } from '../styles/theme';

import { AntdProvider } from './antd';
import { AuthProvider } from './auth';

export const defaultTheme: DefaultTheme = {
  ...theme,
};

const AppProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={defaultTheme}>
        <AuthProvider>
          <AntdProvider>{children}</AntdProvider>
        </AuthProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default AppProvider;
