export const base = 'gateway/identity';
export const baseODATA = 'gateway/identityodata';

export const getQueryODATA = `${baseODATA}/appusersearchquery`;
export const getAllUsers = `${base}/appuser`;
export const getUserById = `${base}/appuser`;
export const getUserRoleById = `${base}/appuserroles`;
export const postUsers = `${base}/appuser`;
export const putUsers = `${base}/appuser`;
export const getRoles = `${base}/approle`;
export const postNewPassword = `${base}/userforgotpsw`;
export const updatePassword = `${base}/appuser/pwd`;
export const getUserInformation = `${base}/appuser/me`;
export const uncheckFirstAccess = `${base}/appuser/uncheckfirstaccess`;
