import { RfvImpoterRoute } from '../../config/services-route';
import { TagTypes } from '../types';
import { providesList } from '../../helpers/provides';

// import { useGetInfoQuery } from '../auth';
import { RfvProps } from './rfv-file-importer';
import { deepDiveApi } from '../../config/apis/deep-dive-query';

interface PresignedURLProps {
  fileName: string;
  contentType: string;
}

interface UpdateFileToS3Props {
  preSignedUrl: string;
  file: File;
}
const fileImporterApi = deepDiveApi.injectEndpoints({
  endpoints: builder => ({
    getPresignedUrlFile: builder.query<any, PresignedURLProps>({
      query: ({ fileName, contentType }) => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: `${RfvImpoterRoute.getPresignedUrl}?filename=${fileName}&contenttype=${contentType}`,
        };
      },
      providesTags: (response: any) => {
        return providesList(response?.data, TagTypes.UploadLogo);
      },
    }),
    updateFileToS3: builder.mutation<any, UpdateFileToS3Props>({
      query: file => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
            'Content-Type': file.file.type,
          },
          method: 'PUT',
          body: file?.file,
          url: file?.preSignedUrl,
        };
      },
      invalidatesTags: [{ type: TagTypes.ImportRfv, id: 'List' }],
    }),
    postFileS3: builder.mutation<RfvProps, any>({
      query: ({ file }) => {
        console.log('file: ', file);
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            fileName: file,
          }),
          url: `${RfvImpoterRoute.postLogo}`,
        };
      },
      invalidatesTags: [TagTypes.UploadLogo],
    }),
    getFileRfv: builder.query<any, any>({
      query: ({ importerId, customerId }) => {
        return {
          url: RfvImpoterRoute.getFileRfv,
          params: { importerId, customerId },
        };
      },
      providesTags: (response: any) => {
        return providesList(response.data, TagTypes.ImportRfv);
      },
    }),
  }),
});

export const {
  useGetPresignedUrlFileQuery,
  useLazyGetPresignedUrlFileQuery,
  useUpdateFileToS3Mutation,
  usePostFileS3Mutation,
  useGetFileRfvQuery,
} = fileImporterApi;
