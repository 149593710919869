import styled from 'styled-components';
import { Table, Tabs } from 'antd';
import Button from '../../components/Button';

export const PotentialAudienceContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1.5rem 2.93rem;
  background-color: #fbfbfb;
  position: relative;
  overflow-y: scroll;
`;

export const PageHeader = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;

export const Main = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0rem 0rem 5.5rem 0rem;
`;

export const Content = styled.div`
  width: 100%;
  padding: 1rem;
  display: inline-flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  background-color: #ffffff;
`;

export const PotentialAudienceTable = styled(Table)`
  width: 100%;
`;

export const CreatePotentialAudienceButton = styled(Button)``;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
`;

export const CreateCampaingButton = styled(Button)`
  padding: 0.3125rem 1rem;
`;

export const PlusButton = styled(Button)`
  width: 2rem;
  height: 2rem;
  border-radius: 0.12rem;
`;
export const TableImports = styled.div`
  display: inline-flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  background-color: #ffffff;
`;
export const TabGroup = styled(Tabs).attrs({
  tabBarStyle: {
    padding: '0rem 2rem 0rem 2rem',
  },
})`
  width: 100%;
`;

export const TabGroupPane = styled(Tabs.TabPane)``;
