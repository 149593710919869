import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import LandingPage from '../layouts/LandingPage';

import ContactImportWizard from '../pages/ContactImportWizard';

import RFVImportWizard from '../pages/RfvImportWizard';

import ContactManagementPage from '../pages/ContactManagement';
import AuthGuardRoute from './components/AuthGuardRoute';
import Suspense from './components/Suspense';

import * as CONSTANTS from './constants';
import ContactProfile from '../pages/ContactProfile';
import Home from '../pages/Home';
import Landing from '../pages/Landing';
import PersonasPage from '../pages/Personas';
import ContactSegmentsEditPage from '../pages/ContactSegmentsEdit';
import PotentialAudience from '../pages/PotentialAudience';
import FilterMap from '../pages/FilterMap';
import ReportsPage from '../pages/Reports';
import Chat from '../pages/Chat';
import { MapsProvider } from '../hooks/maps';
import Wrapper from '../components/GoogleMaps/Wrapper';
import DatabaseManagement from '../pages/DatabaseManagement';
import ContactSegmentations from '../pages/ContactSegmentations';
import CreativeManagementPage from '../pages/Creative/CreativeManagement';
import ChooseGenerator from '../pages/Creative/ChooseGenerator';
import CreativeTextPage from '../pages/Creative/CreativeTextPage';
import ImportRfv from '../pages/ImportRfv';
import Rfv from '../pages/ImportRfv/Rfv';
import Upgrade from '../pages/Upgrade';
import DatabaseManagementPj from '../pages/DatabaseManagementPj';
import ContactImportWizardPJ from '../pages/ContactImportWizardPJ';

// eslint-disable-next-line import/extensions
import { TransactionProvider } from './TransactionContext';

const Unauthorized = React.lazy(() => import('../pages/Unauthorized'));

const Dev = React.lazy(() => import('../pages/Dev'));

const SystemSettings = React.lazy(() => import('../pages/SystemSettings'));

const FacebookMangementCampaign = React.lazy(
  () => import('../pages/CampaignManagement'),
);

const FacebookCampaignWizard = React.lazy(
  () => import('../pages/FacebookCampaignWizard'),
);

const WhatsappManagementCampaign = React.lazy(
  () => import('../pages/WhatsappCampaignManagement'),
);
const SocialMediaPostMangement = React.lazy(
  () => import('../pages/SocialMediaPostManagement'),
);

const SocialMediaPostWizard = React.lazy(
  () => import('../pages/SocialMediaPostWizard'),
);

const WhatsappCampaignWizard = React.lazy(
  () => import('../pages/WhatsappCampaignWizard'),
);

const Integrations = React.lazy(() => import('../pages/Integrations'));

const SegmentationAnalysis = React.lazy(
  () => import('../pages/SegmentationAnalysis'),
);

const PublicManagement = React.lazy(() => import('../pages/PublicManagement'));
const ConnectionManagement = React.lazy(
  () => import('../pages/ConnectionManagement'),
);

export const RouterConfig = () => (
  <BrowserRouter>
    <TransactionProvider>
      <Routes>
        {/* Privates Route */}
        <Route path={CONSTANTS.ROOT} element={<AuthGuardRoute />}>
          <Route index element={<Landing />} />

          {/* LandingPage */}
          <Route element={<LandingPage />}>
            <Route
              path={CONSTANTS.DASHBOARD}
              element={
                <Suspense>
                  <Home />
                </Suspense>
              }
            />

            <Route
              path={CONSTANTS.SYSTEM_SETTINGS}
              element={
                <Suspense>
                  <SystemSettings />
                </Suspense>
              }
            />
            <Route path={CONSTANTS.CONTACT_IMPORT_WIZARD_PF}>
              <Route
                index
                element={
                  <Suspense>
                    <ContactImportWizard />
                  </Suspense>
                }
              />
              <Route
                path=":id"
                element={
                  <Suspense>
                    <ContactImportWizard />
                  </Suspense>
                }
              />
            </Route>
            <Route path={CONSTANTS.CONTACT_IMPORT_WIZARD_PJ}>
              <Route
                index
                element={
                  <Suspense>
                    <ContactImportWizardPJ />
                  </Suspense>
                }
              />
              <Route
                path=":id"
                element={
                  <Suspense>
                    <ContactImportWizardPJ />
                  </Suspense>
                }
              />
            </Route>
            <Route path={CONSTANTS.RFV_IMPORT_WIZARD}>
              <Route
                index
                element={
                  <Suspense>
                    <RFVImportWizard />
                  </Suspense>
                }
              />
              <Route
                path=":id"
                element={
                  <Suspense>
                    <RFVImportWizard />
                  </Suspense>
                }
              />
            </Route>
            <Route path={CONSTANTS.CHAT} element={<Chat />} />
            <Route path={CONSTANTS.CONTACT_PROFILE}>
              <Route
                index
                element={
                  <Suspense>
                    <ContactProfile />
                  </Suspense>
                }
              />
              <Route
                path=":id"
                element={
                  <Suspense>
                    <ContactProfile />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path={CONSTANTS.CONTACT_MANAGENENT}
              element={
                <Suspense>
                  <ContactManagementPage />
                </Suspense>
              }
            />
            <Route
              path={CONSTANTS.FACEBOOK_MANAGEMENT_CAMPAIGN}
              element={
                <Suspense>
                  <FacebookMangementCampaign />
                </Suspense>
              }
            />
            <Route path={CONSTANTS.CREATIVE_MANAGEMENT}>
              <Route
                index
                element={
                  <Suspense>
                    <CreativeManagementPage />
                  </Suspense>
                }
              />

              <Route
                path=":id"
                element={
                  <Suspense>
                    <CreativeTextPage />
                  </Suspense>
                }
              />
            </Route>

            <Route
              path={CONSTANTS.CHOOSE_GENERATOR}
              element={
                <Suspense>
                  <ChooseGenerator />
                </Suspense>
              }
            />

            <Route
              path={CONSTANTS.NEW_CREATIVE_TEXT}
              element={
                <Suspense>
                  <CreativeTextPage />
                </Suspense>
              }
            />

            <Route
              path={CONSTANTS.SOCIAL_MEDIA_POST_MANAGEMENT}
              element={
                <Suspense>
                  <SocialMediaPostMangement />
                </Suspense>
              }
            />
            <Route path={CONSTANTS.SOCIAL_MEDIA_POST_WIZARD}>
              <Route
                index
                element={
                  <Suspense>
                    <SocialMediaPostWizard />
                  </Suspense>
                }
              />
              <Route
                path=":id"
                element={
                  <Suspense>
                    <SocialMediaPostWizard />
                  </Suspense>
                }
              />
            </Route>

            <Route
              path={CONSTANTS.WHATSAPP_MANAGEMENT_CAMPAIGN}
              element={
                <Suspense>
                  <WhatsappManagementCampaign />
                </Suspense>
              }
            />
            <Route path={CONSTANTS.WHATSAPP_CAMPAIGN_WIZARD}>
              <Route
                index
                element={
                  <Suspense>
                    <WhatsappCampaignWizard />
                  </Suspense>
                }
              />
              <Route
                path=":id"
                element={
                  <Suspense>
                    <WhatsappCampaignWizard />
                  </Suspense>
                }
              />
            </Route>

            <Route
              path={CONSTANTS.INTEGRATIONS}
              element={
                <Suspense>
                  <Integrations />
                </Suspense>
              }
            />
            <Route path={CONSTANTS.NEW_CAMPAIGN_SOCIAL_MIDIA}>
              <Route
                index
                element={
                  <Suspense>
                    <FacebookCampaignWizard />
                  </Suspense>
                }
              />
              <Route
                path=":id"
                element={
                  <Suspense>
                    <FacebookCampaignWizard />
                  </Suspense>
                }
              />
            </Route>
            <Route path={CONSTANTS.EDIT_SEGMENT}>
              <Route index element={<ContactSegmentsEditPage />} />
              <Route path=":id" element={<ContactSegmentsEditPage />} />
            </Route>
            <Route
              path={CONSTANTS.SYSTEM_SETTINGS}
              element={
                <Suspense>
                  <SystemSettings />
                </Suspense>
              }
            />

            <Route
              path={CONSTANTS.SYSTEM_SETTINGS}
              element={<SystemSettings />}
            />
            <Route path={CONSTANTS.PERSONAS} element={<PersonasPage />} />
            <Route path={CONSTANTS.REPORTS} element={<ReportsPage />} />

            <Route
              path={CONSTANTS.MAP}
              element={
                <Suspense>
                  <PotentialAudience />
                </Suspense>
              }
            />

            <Route
              path={CONSTANTS.CREATE_POTENTIAL_AUDIENCE}
              element={
                <Suspense>
                  <Wrapper>
                    <MapsProvider>
                      <FilterMap />
                    </MapsProvider>
                  </Wrapper>
                </Suspense>
              }
            />

            <Route path={CONSTANTS.EDIT_POTENTIAL_AUDIENCE}>
              <Route
                index
                element={
                  <Suspense>
                    <Wrapper>
                      <MapsProvider>
                        <FilterMap />
                      </MapsProvider>
                    </Wrapper>
                  </Suspense>
                }
              />
              <Route
                path=":id"
                element={
                  <Suspense>
                    <Wrapper>
                      <MapsProvider>
                        <FilterMap />
                      </MapsProvider>
                    </Wrapper>
                  </Suspense>
                }
              />
            </Route>

            <Route
              path={CONSTANTS.DATABASE_MANAGEMENT_PF}
              element={
                <Suspense>
                  <DatabaseManagement />
                </Suspense>
              }
            />
            <Route
              path={CONSTANTS.DATABASE_MANAGEMENT_PJ}
              element={
                <Suspense>
                  <DatabaseManagementPj />
                </Suspense>
              }
            />
            <Route
              path={CONSTANTS.IMPORT_RFV}
              element={
                <Suspense>
                  <ImportRfv />
                </Suspense>
              }
            />
            <Route path={CONSTANTS.RFV}>
              <Route
                index
                element={
                  <Suspense>
                    <Rfv />
                  </Suspense>
                }
              />
              <Route
                path=":id"
                element={
                  <Suspense>
                    <Rfv />
                  </Suspense>
                }
              />
            </Route>
            <Route path={CONSTANTS.SEGMENTATION_ANALYSIS}>
              <Route
                index
                element={
                  <Suspense>
                    <SegmentationAnalysis />
                  </Suspense>
                }
              />
              <Route
                path=":id"
                element={
                  <Suspense>
                    <SegmentationAnalysis />
                  </Suspense>
                }
              />
            </Route>

            <Route
              path={CONSTANTS.PUBLIC_MANAGEMENT}
              element={
                <Suspense>
                  <PublicManagement />
                </Suspense>
              }
            />

            <Route
              path={CONSTANTS.CONNECTION_MANAGEMENT}
              element={
                <Suspense>
                  <ConnectionManagement />
                </Suspense>
              }
            />

            <Route
              path={CONSTANTS.CONTACT_SEGMENTATION}
              element={
                <Suspense>
                  <ContactSegmentations />
                </Suspense>
              }
            />
            <Route
              path={CONSTANTS.UPGRADE}
              element={
                <Suspense>
                  <Upgrade />
                </Suspense>
              }
            />

            <Route path="*" element={<h1>Aguardando desenvolvimento...</h1>} />
          </Route>
          {/* End LandingPage */}
        </Route>
        {/* End Privates Route */}

        {/* Public Routes */}
        <Route path={CONSTANTS.UNAUTHORIZED} element={<Unauthorized />} />
        {/* <Route path={CONSTANTS.MAP} element={<Map />} /> */}

        {process.env.NODE_ENV !== 'production' && (
          <Route path="portal/dev" element={<Dev />} />
        )}
        {/* End Public Routes */}
      </Routes>
    </TransactionProvider>
  </BrowserRouter>
);
