export const neutral = {
  gray: {
    1: '#fff',
    2: '#fafafa',
    3: '#f5f5f5',
    4: '#f0f0f0',
    5: '#d9d9d9',
    6: '#bfbfbf',
    7: '#8c8c8c',
    8: '#595959',
    9: '#434343',
    10: '#262626',
  },
};

export const daybreak = {
  blue: {
    1: '#fff3f0',
    2: '#ffd6cc',
    3: '#ffb3a3',
    4: '#ff8c7a',
    5: '#fa6150',
    6: '#ee3425',
    7: '#c71c16',
    8: '#a10a0a',
    9: '#7a0105',
    10: '#540006',
  },
};

export const dustRed = {
  1: '#fff1f0',
  3: '#ffa39e',
  4: '#ff7875',
  5: '#ff4d4f',
  6: '#f5222d',
};

export const volcano = {
  1: '#fff2e8',
  3: '#ffbb96',
  6: '#fa541c',
};

export const sunsetOrange = {
  1: '#fff7e6',
  3: '#ffd591',
  6: '#fa8c16',
};

export const calendulaGold = {
  1: '#fffbe6',
  3: '#ffe58f',
  5: '#ffc53d',
  6: '#faad14',
  7: '#d48806',
};

export const sunriseYellow = {
  1: '#feffe6',
  3: '#fffb8f',
  6: '#fadb14',
};

export const lime = {
  1: '#fcffe6',
  3: '#eaff8f',
  6: '#a0d911',
};

export const polarGreen = {
  1: '#f6ffed',
  3: '#b7eb8f',
  5: '#73d13d',
  6: '#52c41a',
  7: '#389e0d',
};

export const cyan = {
  1: '#e6fffb',
  3: '#87e8de',
  6: '#13c2c2',
};

export const geekBlue = {
  1: '#f0f5ff',
  3: '#adc6ff',
  6: '#2f54eb',
};

// export const goldenPurple = {
//   1: '#2966F5',
//   3: '#2966F5',
//   6: '#2966F5',
//   9: '#22075e',
// };
export const goldenPurple = {
  1: '#E8ECFE',
  2: '#90CAF9',
  3: '#5789F7',
  4: '#2196F3',
  5: '#1E88E5',
  6: '#2966F5',
  7: '#003B6F',
  8: '#001F3F',
  9: '#2966F5',
};

export const magenta = {
  1: '#fff0f6',
  3: '#ffadd2',
  6: '#eb2f96',
};

export const extra = {
  background: {
    1: '#f0f2f5',
    2: '#001529',
    3: '#000c17',
  },
  not_mapped: {
    text: {
      1: '#000',
    },
    background: {
      1: '#efefef',
      2: '#131212',
    },
    title: {
      1: '#5a5a5a',
      2: '#2c292b',
    },
    icon: {
      1: '#2b282a',
    },
  },
};

export const character = {
  primaryInverse: '#fff',
  primary: '#000000B2',
  secondary: '#00000073',
  disabled: '#00000040',
};

export const primary = goldenPurple;
