import { Select } from 'antd';
import styled from 'styled-components';

import GenericButton from '../../components/Button';
import { Body } from '../../styles/fonts';

export const Body1 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Body2 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ButtonAddFilter = styled(GenericButton)`
  text-align: left;
  color: ${props => props.theme.colors.primary[1]};
`;

export const ButtonAddFilterText = styled(Body).attrs({ weight: 'medium' })``;

export const ContactSegmentsEdit = styled.div`
  width: 100%;
  height: 44rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  background-color: #fbfbfb;
  position: relative;
`;

export const ContactSegmentsWrapper = styled.div`
  width: 100%;
  display: inline-flex;
`;

export const Content1 = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 1rem;
  padding: 1rem;
  background-color: #ffffff;
`;

export const ContentHeader = styled.div`
  display: inline-flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
`;

export const FAB = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  padding: 0rem 1.5rem 1.5rem 0rem;
  box-shadow: 1.75rem 0.5rem rgba(0, 0, 0, 0.05), 1rem rgba(0, 0, 0, 0.08),
    0.38rem -0.25rem rgba(0, 0, 0, 0.12);
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

export const Fields = styled.div`
  display: inline-flex;
  gap: 1rem;
  align-items: center;
`;

export const Filter01 = styled.div`
  width: 100%;
  display: inline-flex;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 1rem;
  align-items: flex-start;
  border-radius: 0.12rem;
  padding: 1rem;
  background-color: #fbfbfb;
`;

export const Filter02 = styled.div`
  width: 100%;
  display: flex;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 1rem;
  align-items: flex-start;
  border-radius: 0.12rem;
  padding: 1rem;
  background-color: #fbfbfb;
`;

export const FilterArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const OrAnd01 = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 1rem;
  border-radius: 0.12rem;
  margin-bottom: 1rem;
`;

export const OrAnd01None = styled.div`
  display: none;
`;

export const PageHeader = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavigateBack = styled.div`
  display: inline-flex;
  gap: 1rem;
  align-items: center;
`;

export const SelectFieldType = styled(Select.OptGroup)``;

export const TopBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
`;

export const BetweenContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
