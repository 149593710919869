import styled from 'styled-components';
import Column from '../Bar/ColumnBar';

export const Container = styled.div`
  gap: 20px;
`;

export const StackedColumn = styled(Column).attrs({
  color: ['#adc6ff', '#ffd591'],
})``;

export const StackedColumnSection = styled.div`
  padding-top: 1.5rem;
`;

export const StatisticsRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
`;

export const StatisticCard = styled.div`
  width: 25%;
  padding: 2rem 1rem;
  border: 1px solid ${props => props.theme.colors.neutral.gray[5]};
`;
