/* eslint-disable import/no-cycle */
import { salesBoxApi } from '../../config/apis';
import { ServicePlanRoute } from '../../config/services-route';
import { builderQueryOdata, BuilderQueryProps } from '../../helpers/odata';
import { providesList } from '../../helpers/provides';
import { URLs } from '../../helpers/urls';
import { TagTypes, WithPagination } from '../types';

import { ServicePlanProps } from './service-plan';

const servicePlanApi = salesBoxApi.injectEndpoints({
  endpoints: builder => ({
    getAllServicePlan: builder.query<ServicePlanProps[], void>({
      query: () => ({
        url: ServicePlanRoute.getAllServicePlan,
      }),
      providesTags: response => providesList(response, TagTypes.ServicePlans),
    }),

    getAllServicePlanFiltered: builder.query<
      WithPagination<ServicePlanProps[]>,
      BuilderQueryProps<Partial<ServicePlanProps>>
    >({
      query: props => {
        return {
          url: URLs.combineOdataUrl(
            ServicePlanRoute.getQueryODATA,
            builderQueryOdata<Partial<ServicePlanProps>>(props),
          ),
        };
      },
      providesTags: response =>
        providesList(response?.data, TagTypes.ServicePlans),
      transformResponse: (response: any): any => {
        return {
          data: response.value,
          meta: {
            size: response['@odata.count'],
          },
        };
      },
    }),
    postServicePlan: builder.mutation<
      ServicePlanProps,
      Partial<ServicePlanProps>
    >({
      query: body => ({
        url: ServicePlanRoute.postServicePlan,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: TagTypes.ServicePlans, id: 'LIST' }],
    }),
    updateServicePlan: builder.mutation<any, Partial<ServicePlanProps>>({
      query: ({ id, ...patch }) => {
        return {
          url: URLs.combine(ServicePlanRoute.postServicePlan, `${id}`),
          method: 'PUT',
          body: patch,
        };
      },
      invalidatesTags: [{ type: TagTypes.ServicePlans, id: 'LIST' }],
    }),
    getServicePlanById: builder.query<ServicePlanProps, string>({
      query: id => ({
        url: URLs.combine(ServicePlanRoute.getServicePlanById, `${id}`),
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetAllServicePlanQuery,
  usePostServicePlanMutation,
  useGetServicePlanByIdQuery,
  useGetAllServicePlanFilteredQuery,
  useUpdateServicePlanMutation,
} = servicePlanApi;
