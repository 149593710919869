import styled from 'styled-components';

interface BackgroundBarProps {
  backgroundBarBorder?: string;
  backgroundBarColor?: string;
  backgroundBarBorderLeft?: string;
}

interface PointerBarProps {
  pointerBarBorder?: string;
  pointerBarColor?: string;
  pointerBarBorderRadius?: string;
}

export const BackgroundBar = styled.div<BackgroundBarProps>`
  width: 100%;
  height: 16px;
  position: relative;
  border: 1px solid
    ${({ backgroundBarBorder, theme }) =>
      backgroundBarBorder || theme.colors.primary[3]};
  background-color: ${({ backgroundBarColor, theme }) =>
    backgroundBarColor || theme.colors.primary[1]};
  border-left-style: ${({ backgroundBarBorderLeft }) =>
    backgroundBarBorderLeft || ''};

  &::before {
    content: '';
    display: block;
    margin: 0;
    width: 1px;
    height: 9px;
    position: absolute;
    bottom: -1px;
    left: calc(50% - 1px);
    background-color: ${({ theme }) => theme.colors.primary[3]};
  }
`;

export const PointerBar = styled.div<PointerBarProps>`
  height: 16px;
  width: 24px;
  border: 1px solid ${({ pointerBarBorder }) => pointerBarBorder || '#9254de'};
  background-color: ${({ pointerBarColor }) => pointerBarColor || '#2966F5'};
  position: absolute;
  top: -1px;
  border-radius: ${({ pointerBarBorderRadius }) =>
    pointerBarBorderRadius || '8px'};
`;
