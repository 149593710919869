import Content from '../Content';
import Header from '../Header';

import { Container } from './styles';

const LandingPage: React.FC = () => {
  return (
    <Container>
      <Header />
      <Content />
    </Container>
  );
};

export default LandingPage;
