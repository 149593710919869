import styled from 'styled-components';

export interface CreativeCardProps {
  selected?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export const CreativeCard = styled.button<CreativeCardProps>`
  width: 23rem;
  height: 7.5rem;
  display: inline-flex;
  box-sizing: border-box;
  gap: 1rem;
  border: ${props =>
    props.selected
      ? `0.06rem solid ${props.theme.colors.primary[6]}`
      : '0.06rem solid #dadada'};
  border-radius: 0.12rem;
  padding: 1.5rem;
  background-color: #ffffff;
  transition: 0.3s;
  cursor: pointer;
  align-items: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  box-shadow: ${props =>
    props.selected
      ? '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)'
      : ''};

  &:hover {
    box-shadow: ${props =>
      props.disabled
        ? 'none'
        : '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)'};

    border-color: ${props =>
      props.disabled ? '' : props.theme.colors.primary[6]};

    div > .anticon-font-size {
      color: ${props => (props.disabled ? '' : '#9254de')};
    }

    div > .anticon-picture {
      color: ${props => (props.disabled ? '' : '#9254de')};
    }
  }

  div > .anticon-font-size {
    color: ${props => (props.selected ? '#9254de' : '')};
  }
  div > .anticon-picture {
    color: ${props => (props.selected ? '#9254de' : '')};
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 1rem;
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  min-width: 2rem;
  aspect-ratio: 1/1;

  svg {
    font-size: 2rem;
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;

  p {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.character.secondary};
    margin: 0;
  }
`;
