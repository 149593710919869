import React from 'react';

import { ColumnProps } from 'antd/lib/table';

import { CollapseItemContent, TableGroup } from './styles';

export interface DetailsProps {
  key: string;
  infoName: string;
  status: string;
  quantity: number;
  percentage: number;
}

const CollapseItemContentPage = ({ items }: { items: DetailsProps[] }) => {
  const dataTableGroup = items.map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ infoName, ...item }: { infoName: string }) => {
      return { ...item };
    },
  );

  const columnsTableGroup: ColumnProps<any>[] = [
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'left',
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: 'Percentual',
      dataIndex: 'percentage',
      align: 'left',
      sorter: (a, b) => a.percentage.length - b.percentage.length,
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantity',
      align: 'left',
      sorter: (a, b) => a.quantity.length - b.quantity.length,
    },
  ];

  return (
    <CollapseItemContent>
      <TableGroup
        dataSource={dataTableGroup}
        columns={columnsTableGroup}
        pagination={false}
      />
    </CollapseItemContent>
  );
};

export default CollapseItemContentPage;
