import React from 'react';
import { H3, Body, H5 } from '../../../../styles/fonts';
import { LineBar } from '../../../Bar/LineBar/styles';
import { Data } from './styles';
import { ColumnBarTab } from '../FinancialRiskTab/styles';
import {
  Content01,
  Data011,
  Data021,
  Row01,
  Row02,
} from '../SocioDemographicTab/styles';

interface DistributionData {
  key: string;
  documentCount: number;
  percent: number;
}

interface IncomeBracketsProps {
  income: {
    ufDistribution: DistributionData[];
    cityDistribution: DistributionData[];
    percentualEnriquecimento: number;
    situacaoAtiva: number;
    situacaoBaixada: number;
    registers: number;
  };
}

const DemographicPartnerTab = ({ income }: IncomeBracketsProps) => {
  const renderNoDataMessage = () => <Body weight="regular">Sem registros</Body>;

  const CityDistribution = () => {
    const data = income.cityDistribution;
    if (data.length === 0) return renderNoDataMessage();

    const config = {
      data: data.map(item => ({
        description: item.key,
        percentage: item.percent,
      })),
      xField: 'percentage',
      yField: 'description',
      barWidthRatio: 0.3,
      height: 350,
      width: 255,
      animation: false,
      meta: {
        description: {
          alias: 'Cidade',
        },
        percentage: {
          alias: 'Porcentagem (%)',
        },
      },
    };
    return <LineBar {...config} />;
  };

  const UfDistribution = () => {
    const data = income.ufDistribution;
    if (data.length === 0) return renderNoDataMessage();

    const config = {
      data: data.map(item => ({
        description: item.key,
        percentage: item.percent,
      })),
      xField: 'description',
      yField: 'percentage',
      barWidthRatio: 0.5,
      height: 350,
      width: 255,
      animation: false,
      xAxis: {
        label: {
          autoHide: false,
          autoRotate: false,
        },
      },
      meta: {
        description: {
          alias: 'UF',
        },
        percentage: {
          alias: 'Porcentagem (%)',
        },
      },
    };
    return <ColumnBarTab {...config} />;
  };

  return (
    <Content01>
      {/* Estatísticas no topo */}
      <Row01>
        <Data>
          <H3>{income.registers.toLocaleString('pt-BR')}</H3>
          <Body weight="regular">Registros</Body>
        </Data>
        <Data>
          <H3 weight="medium">
            {income.percentualEnriquecimento.toLocaleString('pt-BR')}%
          </H3>
          <Body>Quantidade Enriquecido</Body>
        </Data>
        <Data>
          <H3 weight="medium">
            {income.situacaoAtiva.toLocaleString('pt-BR')}%
          </H3>
          <Body>Situação Cadastral Ativa</Body>
        </Data>
        <Data>
          <H3 weight="medium">
            {income.situacaoBaixada.toLocaleString('pt-BR')}%
          </H3>
          <Body>Situação Cadastral Baixada</Body>
        </Data>
      </Row01>
      {/* Gráficos lado a lado */}
      <Row02>
        <Data011>
          <H5 weight="medium">Estado</H5>
          <UfDistribution />
        </Data011>
        <Data021>
          <H5 weight="medium">Cidade</H5>
          <CityDistribution />
        </Data021>
      </Row02>
    </Content01>
  );
};

export default DemographicPartnerTab;
