import styled from 'styled-components';

export const ClienteChoice = styled.div`
  width: 60rem;
  height: 37.5rem;
  display: flex;
  background-color: #ffffff;
`;
export const Wrapper = styled.div`
  width: 60rem;
  height: 37.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem 1.5rem 0rem 1.5rem;
`;
export const PageHeader = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 1rem;
  align-items: center;
`;
export const TopBar = styled.div`
  justify-content: space-between;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;
export const Content1 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
`;
export const Column01 = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0rem 0rem 1.5rem 0rem;
`;
export const ButtonsButton2 = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0.12rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
`;
export const Content2 = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
export const ButtonsButton3 = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0.12rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
`;
export const Content3 = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
export const ButtonsButton4 = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0.12rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
`;
export const Content4 = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
export const ButtonsButton5 = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0.12rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
`;
export const Content5 = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
export const ButtonsButton6 = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0.12rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
`;
export const Content6 = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
export const ButtonsButton7 = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0.12rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
`;
export const Content7 = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
export const ButtonsButton8 = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0.12rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
`;
export const Content8 = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
export const ButtonsButton9 = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0.12rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
`;
export const Content9 = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
export const ButtonsButton10 = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0.12rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
`;
export const Content10 = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
export const Column02 = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0rem 0rem 1.5rem 0rem;
`;
export const ButtonsButton11 = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0.12rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
`;
export const Content11 = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
export const ButtonsButton12 = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0.12rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
`;
export const Content12 = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
export const ButtonsButton13 = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0.12rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
`;
export const Content13 = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
export const ButtonsButton14 = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0.12rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
`;
export const Content14 = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
export const ButtonsButton15 = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0.12rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
`;
export const Content15 = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
export const ButtonsButton16 = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0.12rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
`;
export const Content16 = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
export const ButtonsButton17 = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0.12rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
`;
export const Content17 = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
export const ButtonsButton18 = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0.12rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
`;
export const Content18 = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
export const ButtonsButton19 = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0.12rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
`;
export const Content19 = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
export const ButtonsButton20 = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0.12rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
`;
export const Content20 = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;
