import React, { useCallback, useEffect, useState } from 'react';

import {
  UserOutlined,
  MoreOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { PaginationProps, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useCustomTranslation } from '../../../hooks/custom-translation';
import { Body } from '../../../styles/fonts';

import * as CONSTANTS from '../../../navigation/constants';

import {
  Content01,
  Content2,
  IconOutlinedWhatsApp,
  Content3,
  TableGroup,
  MenuDropdown,
  ActionButton,
} from './styles';
import {
  ContactProps,
  useDeleteContactMutation,
  useGetAllContactsFilteredQuery,
} from '../../../services/contact';
import ModalDelete from '../../ModalDelete';
import Dropdown from '../../Dropdown';
import Button from '../../Button';
import { createQueryByRole } from '../../../helpers/odata';
import { useAuth } from '../../../hooks/auth';
import FormInput from '../../FormInput';
import getErrors from '../../../helpers/errors';
import { ShowFormMessage } from '../../../helpers/message';
import FilterIcon from '../../FilterIcon';

export interface MenuDropDownProps {
  id: string;
}

interface IContactProps {
  refetch: boolean;
}

const Contacts = ({ refetch }: IContactProps) => {
  const { tContactManagement } = useCustomTranslation(
    'contactManagement',
    'systemSettings',
  );

  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const { userInfo } = useAuth();
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = useState('');

  const [contactId, setContactId] = useState('');
  const [fetchInterval, setFetchInterval] = useState(0);

  const [deleteContact, configDeleteContact] = useDeleteContactMutation();

  const [rowsPerPage, setRowsPerPage] = useState<number>(
    window.innerHeight < 900 ? 7 : 9,
  );

  const { data: contacts, refetch: contactsRefetch } =
    useGetAllContactsFilteredQuery(
      {
        top: rowsPerPage,
        skip: page,
        orderby: { createdUtc: 'desc' },
        expand: ['enriched', 'status'],
        custom: `$filter=contains(tolower(enriched/name), '${search}') ${
          createQueryByRole('customerId', 'customer', userInfo, '', false)
            ? 'and'
            : ''
        } ${createQueryByRole('customerId', 'customer', userInfo, '', false)}`,
      },
      {
        pollingInterval: fetchInterval,
      },
    );

  const handleShowModalDelete = useCallback((id: string) => {
    setContactId(id);
    setIsModalDeleteOpen(true);
  }, []);

  const handleCloseModalDelete = useCallback(() => {
    setIsModalDeleteOpen(false);
  }, []);

  const tagColor = ['default', 'warning', 'success'];

  const tagIcon = [
    <ExclamationCircleOutlined />,
    <SyncOutlined spin />,
    <CheckCircleOutlined />,
  ];

  const navigate = useNavigate();

  const handleShowProfile = (id: string) => {
    navigate(`${CONSTANTS.CONTACT_PROFILE}/${id}`);
  };

  const handleInputSearch = (e: any) => {
    setSearch(e.target.value.toLowerCase());
  };

  const handleChangeRowsPerPage: PaginationProps['onShowSizeChange'] = (
    current,
    pageSize,
  ) => {
    setRowsPerPage(pageSize);
  };

  const MenuDropDown = ({ id }: MenuDropDownProps) => {
    return (
      <MenuDropdown
        onClick={() => handleShowModalDelete(id)}
        items={[
          {
            label: tContactManagement('delete_contact'),
            key: '1',
            icon: <DeleteOutlined />,
            danger: true,
          },
        ]}
      />
    );
  };

  const columnsTableGroup: ColumnProps<any>[] = [
    {
      title: tContactManagement('name'),
      dataIndex: 'name',
      align: 'left',
      width: '15.31rem',
      sorter: (a, b) => a?.enriched?.name?.localeCompare(b?.enriched?.name),
      render: (text: string, record: ContactProps) => {
        return (
          <>
            {record?.enriched?.name ? (
              <Content2>
                <Body weight="regular">{record?.enriched?.name}</Body>
              </Content2>
            ) : (
              <Body weight="regular">-</Body>
            )}
          </>
        );
      },
      filterDropdown: () => (
        <FormInput placeholder="Nome" onChange={handleInputSearch} />
      ),
      filterIcon: () => <FilterIcon isFiltered={!!search} />,
    },
    {
      title: tContactManagement('e-mail'),
      dataIndex: 'email',
      align: 'left',
      width: '14.20rem',

      sorter: (a, b) => a?.email.localeCompare(b?.email),
      render: (text: string) => {
        return (
          <>
            {text ? (
              <Content2>
                <Body weight="regular">{text}</Body>
              </Content2>
            ) : (
              <Body weight="regular">-</Body>
            )}
          </>
        );
      },
    },
    {
      title: tContactManagement('telephone'),
      dataIndex: 'phoneNumber',
      align: 'left',
      width: '13.48rem',
      sorter: (a, b) =>
        a?.enriched?.cellPhones[0]?.localeCompare(b?.enriched?.cellPhones[0]),
      render: (text: string, record: ContactProps) => {
        return (
          <>
            {record?.enriched?.cellPhones?.length > 0 ? (
              <Content2>
                <Body weight="regular">{record?.enriched?.cellPhones[0]}</Body>
                <IconOutlinedWhatsApp />
              </Content2>
            ) : (
              <Body weight="regular">-</Body>
            )}
          </>
        );
      },
    },
    {
      title: tContactManagement('status'),
      dataIndex: 'status',
      width: '10.94rem',
      align: 'left',
      render: (text, record: ContactProps) => (
        <Tag
          color={tagColor[record.contactStatusId - 1]}
          icon={tagIcon[record.contactStatusId - 1]}
        >
          {record.status.friendlyName}
        </Tag>
      ),
      sorter: (a, b) => a?.status?.length - b?.status?.length,
    },
    {
      title: tContactManagement('actions'),
      dataIndex: 'acoes',
      align: 'center',
      width: '11.56rem',
      render: (_, record) => (
        <Content3>
          {record.status.friendlyName === 'Enriquecido' && (
            <Button
              type="default"
              icon={<UserOutlined />}
              style={{ fontWeight: 500 }}
              onClick={() => handleShowProfile(record.id)}
            >
              {tContactManagement('details')}
            </Button>
          )}
          <Dropdown overlay={<MenuDropDown id={record.id} />}>
            <ActionButton type="default" icon={<MoreOutlined />} />
          </Dropdown>
        </Content3>
      ),
    },
  ];

  const handleDeleteContact = async () => {
    await deleteContact(contactId).unwrap();
  };

  const showMessageDeleteContact = useCallback(() => {
    const { isSuccess, isError, error } = configDeleteContact;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }
    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Contato excluído com sucesso',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
    if (isSuccess) {
      setIsModalDeleteOpen(false);
    }
  }, [configDeleteContact]);

  useEffect(showMessageDeleteContact, [showMessageDeleteContact]);

  useEffect(() => {
    const contactsProccessing = contacts?.data.filter(
      contact => contact.contactStatusId === 2,
    );

    if (contactsProccessing?.length) {
      return setFetchInterval(120000);
    }

    return setFetchInterval(0);
  }, [contacts?.data]);

  useEffect(() => {
    if (refetch) {
      contactsRefetch();
    }
  }, [refetch]);

  console.log(window.innerHeight);

  return (
    <Content01>
      <TableGroup
        dataSource={contacts?.data}
        columns={columnsTableGroup}
        rowKey="id"
        pagination={{
          defaultPageSize: rowsPerPage,
          onShowSizeChange: handleChangeRowsPerPage,
          total: contacts?.meta?.size,
          onChange: currentPage => setPage(currentPage),
        }}
      />
      <ModalDelete
        visible={isModalDeleteOpen}
        onCancel={handleCloseModalDelete}
        title={`${tContactManagement('delete_contact')}`}
        deleteButton={`${tContactManagement('delete_contact')}`}
        onOk={handleDeleteContact}
      >
        Ao executar a exclusão, todos os registros deste contato serão removidos
        do sistema.
      </ModalDelete>
    </Content01>
  );
};

export default Contacts;
