import CheckboxList from './CheckboxList';
import Content from './Content';
import Header from './Header';
import LandingPage from './LandingPage';

const Skeleton = {
  CheckboxList,
  Content,
  Header,
  LandingPage,
};

export default Skeleton;
