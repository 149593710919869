import { InputProps, InputRef } from 'antd';

import InternalInput from './Input';
import Password from './Password';
import Search from './Search';
import Number from './Number';

interface CompoundedComponent
  extends React.ForwardRefExoticComponent<
    InputProps & React.RefAttributes<InputRef>
  > {
  Password: typeof Password;
  Search: typeof Search;
  Number: typeof Number;
}

const FormInput = InternalInput as CompoundedComponent;

FormInput.Password = Password;
FormInput.Search = Search;
FormInput.Number = Number;

export default FormInput;
