import styled from 'styled-components';

import HasNoInsightsImage from '../../assets/7524_143681.svg';
import HasNoDataImage from '../../assets/7524_144265.svg';

export const Column01 = styled.div`
  display: inline-flex;
  gap: 2rem;
  align-items: center;
  max-width: 554px;
  padding: 2rem 0rem 2rem 0rem;
`;

export const HasNoData = styled.img.attrs({
  src: HasNoDataImage,
  heigth: 'auto',
  width: '100%',
})`
  max-width: 206.52px;
  position: relative;
`;

export const HasNoInsights = styled.img.attrs({
  src: HasNoInsightsImage,
  heigth: 'auto',
  width: '100%',
})`
  max-width: 206.52px;
  position: relative;
`;

export const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
`;

export const Content01 = styled.div`
  width: 100%;
  height: 19.38rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;
