import { IconPersona } from './styles';

import IconAntenado from '../../../../assets/PersonaIcons/Antenado.svg';
import IconCompassivel from '../../../../assets/PersonaIcons/Compassível.svg';
import IconConservador from '../../../../assets/PersonaIcons/Conservador.svg';
import IconImediatista from '../../../../assets/PersonaIcons/Imediatista.svg';
import IconInovadores from '../../../../assets/PersonaIcons/Inovadores.svg';
import IconQuestionador from '../../../../assets/PersonaIcons/Questionador.svg';
import IconRacional from '../../../../assets/PersonaIcons/Racional.svg';
import IconSofisticado from '../../../../assets/PersonaIcons/Sofisticado.svg';
import IconTradicional from '../../../../assets/PersonaIcons/Tradicional.svg';

interface PersonaIconProps {
  persona: string;
}

const personaIcon = (persona: string) => {
  switch (persona) {
    case 'Antenado':
      return IconAntenado;
    case 'Compassível':
      return IconCompassivel;
    case 'Conservador':
      return IconConservador;
    case 'Imediatista':
      return IconImediatista;
    case 'Inovadores':
      return IconInovadores;
    case 'Questianador':
      return IconQuestionador;
    case 'Racional':
      return IconRacional;
    case 'Sofisticado':
      return IconSofisticado;
    default:
      return IconTradicional;
  }
};

const PersonaIcon = ({ persona }: PersonaIconProps) => {
  return <IconPersona src={personaIcon(persona)} alt={persona} />;
};

export default PersonaIcon;
