import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TagTypes } from '../../services/types';

import { AuthenticationSettingEnvs, SystemEnvs } from '../envs';

const getCookieValueByName = (cookieName: string): string => {
  console.log('[XrsfInterceptor] Try catch error 00', cookieName);
  console.log(
    '[XrsfInterceptor] Try catch error 01',
    JSON.stringify(document.cookie),
  );
  try {
    const cookie = document.cookie
      .split(';')
      .find((content: string) =>
        RegExp(`^\\s?${cookieName}=.*$`).test(content),
      );

    return cookie ? cookie.split('=')[1] : '';
  } catch (error) {
    console.log('[XrsfInterceptor] Try catch error 02', error);
    console.log('[XrsfInterceptor] Try catch error 03', cookieName);
  }

  return '';
};

export const salesBoxApi = createApi({
  reducerPath: 'salesBoxApi',
  tagTypes: Object.values(TagTypes),
  baseQuery: fetchBaseQuery({
    baseUrl: SystemEnvs.API_BASE_URL,
    prepareHeaders: (headers: Headers) => {
      headers.set('X-Requested-With', 'XmlHttpRequest');
      headers.set(
        AuthenticationSettingEnvs.HEADER_NAME,
        `${getCookieValueByName(AuthenticationSettingEnvs.COOKIE_NAME)}`,
      );

      return headers;
    },
  }),
  endpoints: () => ({}),
});
