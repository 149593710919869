import { salesBoxApi } from '../../config/apis';
import { FileImporterRoute } from '../../config/services-route';
import { TagTypes, WithPagination } from '../types';
import { providesList } from '../../helpers/provides';

// import { useGetInfoQuery } from '../auth';
import {
  CombineFieldProps,
  FileProps,
  FileImportProps,
  PostCombineProps,
  ColumnProps,
} from './file-importer';
import { builderQueryOdata, BuilderQueryProps } from '../../helpers/odata';
import { URLs } from '../../helpers/urls';

const fileImporterApi = salesBoxApi.injectEndpoints({
  endpoints: builder => ({
    importFile: builder.mutation<FileImportProps, FileProps>({
      query: file => {
        const formData = new FormData();
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        formData.append('File', file);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: FileImporterRoute.uploadFile,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: [{ type: TagTypes.FileImport, id: 'LIST' }],
    }),
    getAllImportsFile: builder.query<FileImportProps[], void>({
      query: () => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: FileImporterRoute.uploadFile,
        };
      },
    }),
    getAllFilesImports: builder.query<
      WithPagination<FileImportProps[]>,
      BuilderQueryProps<Partial<FileImportProps>>
    >({
      query: props => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: URLs.combineOdataUrl(
            FileImporterRoute.getQueryODATA,
            builderQueryOdata<Partial<FileImportProps>>(props),
          ),
        };
      },
      providesTags: response =>
        providesList(response?.data, TagTypes.FileImport),
      transformResponse: (response: any): any => {
        return {
          data: response.value,
          meta: {
            size: response['@odata.count'],
          },
        };
      },
    }),
    getDefaultColumns: builder.query<ColumnProps, void>({
      query: () => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: FileImporterRoute.getDefaultColumns,
        };
      },
      transformResponse: (response: any) => {
        const customFields = response.filter(
          (a: any) => a.groupType === 'custom',
        );
        const defaultFields = response.filter(
          (a: any) => a.groupType === 'default',
        );
        return { customFields, defaultFields };
      },
      providesTags: response =>
        providesList(response?.customFields, TagTypes.FileImport),
    }),
    postCombineFields: builder.mutation<CombineFieldProps[], PostCombineProps>({
      query: body => ({
        url: FileImporterRoute.postCombineFields,
        method: 'POST',
        body,
        keepalive: true,
      }),
      invalidatesTags: [{ type: TagTypes.FileImport, id: 'LIST' }],
    }),
    getFileImporterById: builder.query<FileImportProps, string>({
      query: uid => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: `${FileImporterRoute.getImportById}/${uid}`,
        };
      },
    }),
    deleteFileImport: builder.mutation<FileImportProps, string>({
      query: id => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: URLs.combine(FileImporterRoute.deleteImport, `${id}`),
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: TagTypes.FileImport, id: 'LIST' }],
    }),
  }),
});

export const {
  useImportFileMutation,
  useGetDefaultColumnsQuery,
  useGetFileImporterByIdQuery,
  usePostCombineFieldsMutation,
  useLazyGetFileImporterByIdQuery,
  useGetAllFilesImportsQuery,
  useGetAllImportsFileQuery,
  useDeleteFileImportMutation,
} = fileImporterApi;
