import React, { createContext, useState, useContext } from 'react';
import { useGetDashboardDataQuery } from '../services/dashboard';

const TransactionContext = createContext();

export function useTransaction() {
  return useContext(TransactionContext);
}

export function TransactionProvider({ children }) {
  const { data: dashboardData } = useGetDashboardDataQuery({});

  return (
    <TransactionContext.Provider value={{ dashboardData }}>
      {children}
    </TransactionContext.Provider>
  );
}
