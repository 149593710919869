/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { salesBoxApi } from '../../config/apis';
import { CustomerRoute } from '../../config/services-route';
import { builderQueryOdata, BuilderQueryProps } from '../../helpers/odata';
import { providesList } from '../../helpers/provides';
import { URLs } from '../../helpers/urls';
import { TagTypes, WithPagination } from '../types';

import { CustomerProps } from './customer';

const servicePlanApi = salesBoxApi.injectEndpoints({
  endpoints: builder => ({
    getAllCustomer: builder.query<CustomerProps[], void>({
      query: () => ({
        url: CustomerRoute.getAllCustomer,
      }),
      providesTags: response => providesList(response, TagTypes.Customer),
    }),

    getAllCustomerFiltered: builder.query<
      WithPagination<CustomerProps[]>,
      BuilderQueryProps<Partial<CustomerProps>>
    >({
      query: props => {
        return {
          url: URLs.combineOdataUrl(
            CustomerRoute.getQueryODATA,
            builderQueryOdata<Partial<CustomerProps>>(props),
          ),
        };
      },
      providesTags: response => providesList(response?.data, TagTypes.Customer),
      transformResponse: (response: any): any => {
        return {
          data: response.value,
          meta: {
            size: response['@odata.count'],
          },
        };
      },
    }),
    postCustomer: builder.mutation<CustomerProps, Partial<CustomerProps>>({
      query: body => ({
        url: CustomerRoute.postCustomer,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: TagTypes.Customer, id: 'LIST' }],
    }),
    updateCustomer: builder.mutation<any, Partial<CustomerProps>>({
      query: ({ id, ...patch }) => {
        return {
          url: URLs.combine(CustomerRoute.postCustomer, `${id}`),
          method: 'PUT',
          body: patch,
        };
      },
      invalidatesTags: [{ type: TagTypes.Customer, id: 'LIST' }],
    }),
    getCustomerById: builder.query<CustomerProps, string>({
      query: id => ({
        url: URLs.combine(CustomerRoute.getCustomerById, `${id}`),
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetAllCustomerQuery,
  usePostCustomerMutation,
  useLazyGetCustomerByIdQuery,
  useGetAllCustomerFilteredQuery,
  useGetCustomerByIdQuery,
  useUpdateCustomerMutation,
} = servicePlanApi;
