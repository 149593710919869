type Error = {
  errorMessage: string;
} & { [key: string]: Array<string> };

interface ErrorMessage {
  data: {
    errors: Error | Array<Error>;
  };
  status: number;
}

export function getErrorMessage(
  error: any,
  defaultMessage = 'Falha ao carregar a página. Por favor tente novamente.',
) {
  if (typeof error === 'string') {
    return error;
  }

  if (Array.isArray(error.data?.errors)) {
    return ((error as ErrorMessage).data.errors as Error[])
      .map(erro => erro.errorMessage)
      .join('\n');
  }

  if (error.data?.errors) {
    return Object.entries((error as ErrorMessage).data.errors as Error)
      .map(([_, list]) => (list as string[]).map(value => value))
      .flat()
      .join('\n');
  }

  return defaultMessage;
}
