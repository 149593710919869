import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Spin, Upload as UploadAnt } from 'antd';
import styled from 'styled-components';
import IconImage from '../../../../assets/empty-icon.svg';
import GenericText from '../../../Text';

const { Dragger } = UploadAnt;

export const Upload1 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 0.12rem solid #2966f5;
  box-sizing: border-box;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.12rem;
  padding: 1rem;
  background-color: #e7fffc;
`;

export const IconOutlinedCheckCircle = styled(CheckCircleOutlined)`
  color: #14c3c3;
  font-size: 3rem;
`;

export const IconOutlinedCloseCircle = styled(CloseCircleOutlined)`
  color: #f5222d;
  font-size: 3rem;
`;

export const TextWrapper1 = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
`;
export const ClientesBrasilxlsx = styled(GenericText)`
  font-size: 1rem;
`;

export const Selecionaroutroarquivo = styled(GenericText)`
  font-weight: 400;
  font-size: 0.88rem;
  color: #09989d;
`;

interface UploadProps {
  type: 'empty' | 'validating' | 'done' | 'error';
}

export const Upload = styled.div<UploadProps>`
  width: 100%;
  height: 10rem;
  display: flex;
  flex-direction: column;

  border: ${props => {
    switch (props.type) {
      case 'done':
        return '0.12rem solid #88e9df';
      case 'error':
        return '0.12rem solid #f5222d';
      default:
        return '0.12rem solid #2966f5';
    }
  }};
  box-sizing: border-box;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.12rem;
  padding: 1rem;
  background-color: ${props => {
    switch (props.type) {
      case 'done':
        return '#e7fffc';
      case 'error':
        return '#FFF1F0';
      default:
        return '#E8ECFE';
    }
  }};
`;

export const Icon = styled.img.attrs({
  src: IconImage,
  heigth: 'auto',
  width: '100%',
})`
  max-width: 48px;
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
`;
export const TextComponentarraste = styled(GenericText)`
  font-size: 1rem;
  color: ${props => props.theme.colors.character.primary};
`;

export const TextComponentcaso = styled(GenericText)<UploadProps>`
  font-weight: 400;
  font-size: 0.88rem;
  color: ${props => {
    switch (props.type) {
      case 'done':
        return '#08979C';
      case 'error':
        return '#f5222d';
      default:
        return '#2966F5';
    }
  }};
  flex: 1;
`;

export const UploadDragAndDrop = styled(Dragger)`
  width: 100% !important;
`;

export const SpinIcon = styled(Spin).attrs({
  size: 'large',
})``;
