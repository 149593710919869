import React, { useCallback, useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { useNavigate } from 'react-router-dom';
import { useCustomTranslation } from '../../../hooks/custom-translation';
import { H3, H5, Body } from '../../../styles/fonts';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import * as CONSTANTS from '../../../navigation/constants';
import {
  WizardWelcome,
  Sider1,
  Wrapper,
  PageHeader,
  TopBar,
  Fields,
  Row01,
  Row021,
  ActionBar,
  CheckboxGroup,
} from './styles';
import Form from '../../Form';
import FormCheckbox from '../../FormCheckbox';
import getErrors from '../../../helpers/errors';
import { ShowFormMessage } from '../../../helpers/message';
import { usePostFileExportPjMutation } from '../../../services/fileExportPj';
import { useGetDashboardDataQuery } from '../../../services/dashboard';

interface ModalExportFileProps {
  onOk: () => void;
  onCancel: () => void;
  visible: boolean;
  fileImportId: string;
  fileImportSource: string;
}

const ModalExportFilePj = ({
  onOk,
  onCancel,
  visible,
  fileImportId,
  fileImportSource,
}: ModalExportFileProps) => {
  const { tModalExportFile } = useCustomTranslation('modalExportFile');

  const dataTotal = [
    { value: 4, label: 'CNPJ', fieldCategory: 1, fieldKey: 'cnpj' },
    {
      value: 52,
      label: 'Razão Social',
      fieldCategory: 1,
      fieldKey: 'razao_social',
    },
    {
      value: 42,
      label: 'Nome Fantasia PJ',
      fieldCategory: 1,
      fieldKey: 'nome_fantasia_pj',
    },
    {
      value: 41,
      label: 'Natureza Jurídica',
      fieldCategory: 1,
      fieldKey: 'natureza_juridica',
    },
    { value: 39, label: 'Matriz', fieldCategory: 1, fieldKey: 'matriz' },
    {
      value: 25,
      label: 'Data de Fundação',
      fieldCategory: 1,
      fieldKey: 'data_fundacao',
    },
    {
      value: 2,
      label: 'Capital Social',
      fieldCategory: 1,
      fieldKey: 'capital_social',
    },
    { value: 51, label: 'Porte', fieldCategory: 1, fieldKey: 'porte' },
    { value: 49, label: 'Opção MEI', fieldCategory: 1, fieldKey: 'opcao_mei' },
    {
      value: 50,
      label: 'Opção Simples',
      fieldCategory: 1,
      fieldKey: 'opcao_simples',
    },
    {
      value: 35,
      label: 'Faturamento Presumido Anual',
      fieldCategory: 1,
      fieldKey: 'faturamento_presumido_anual',
    },
    {
      value: 37,
      label: 'Logradouro',
      fieldCategory: 1,
      fieldKey: 'logradouro',
    },
    {
      value: 54,
      label: 'Tipo de Logradouro',
      fieldCategory: 1,
      fieldKey: 'tp_logr',
    },
    { value: 48, label: 'Número', fieldCategory: 1, fieldKey: 'num' },
    { value: 18, label: 'Complemento', fieldCategory: 1, fieldKey: 'compl' },
    { value: 1, label: 'Bairro', fieldCategory: 1, fieldKey: 'bairro' },
    { value: 3, label: 'CEP', fieldCategory: 1, fieldKey: 'cep' },
    { value: 10, label: 'Cidade', fieldCategory: 1, fieldKey: 'cidade' },
    { value: 55, label: 'UF', fieldCategory: 1, fieldKey: 'uf' },
    { value: 36, label: 'Latitude', fieldCategory: 1, fieldKey: 'latitude' },
    { value: 38, label: 'Longitude', fieldCategory: 1, fieldKey: 'longitude' },
    { value: 34, label: 'E-mail PJ', fieldCategory: 1, fieldKey: 'email_pj' },
    { value: 26, label: 'DDD 1', fieldCategory: 1, fieldKey: 'ddd_1' },
    {
      value: 43,
      label: 'Nome Sócio 1',
      fieldCategory: 1,
      fieldKey: 'nome_socio1',
    },
    {
      value: 19,
      label: 'CPF Sócio 1',
      fieldCategory: 1,
      fieldKey: 'cpf_socio1',
    },
    {
      value: 5,
      label: 'Celular Sócio 1',
      fieldCategory: 1,
      fieldKey: 'celular_socio1',
    },
    {
      value: 44,
      label: 'Nome Sócio 2',
      fieldCategory: 1,
      fieldKey: 'nome_socio2',
    },
    {
      value: 20,
      label: 'CPF Sócio 2',
      fieldCategory: 1,
      fieldKey: 'cpf_socio2',
    },
    {
      value: 6,
      label: 'Celular Sócio 2',
      fieldCategory: 1,
      fieldKey: 'celular_socio2',
    },
    {
      value: 45,
      label: 'Nome Sócio 3',
      fieldCategory: 1,
      fieldKey: 'nome_socio3',
    },
    {
      value: 21,
      label: 'CPF Sócio 3',
      fieldCategory: 1,
      fieldKey: 'cpf_socio3',
    },
    {
      value: 7,
      label: 'Celular Sócio 3',
      fieldCategory: 1,
      fieldKey: 'celular_socio3',
    },
    {
      value: 46,
      label: 'Nome Sócio 4',
      fieldCategory: 1,
      fieldKey: 'nome_socio4',
    },
    {
      value: 22,
      label: 'CPF Sócio 4',
      fieldCategory: 1,
      fieldKey: 'cpf_socio4',
    },
    {
      value: 8,
      label: 'Celular Sócio 4',
      fieldCategory: 1,
      fieldKey: 'celular_socio4',
    },
    {
      value: 47,
      label: 'Nome Sócio 5',
      fieldCategory: 1,
      fieldKey: 'nome_socio5',
    },
    {
      value: 23,
      label: 'CPF Sócio 5',
      fieldCategory: 1,
      fieldKey: 'cpf_socio5',
    },
    {
      value: 9,
      label: 'Celular Sócio 5',
      fieldCategory: 1,
      fieldKey: 'celular_socio5',
    },
    {
      value: 11,
      label: 'CNAE Principal',
      fieldCategory: 1,
      fieldKey: 'cnae_principal',
    },
    {
      value: 27,
      label: 'Descrição CNAE Principal',
      fieldCategory: 1,
      fieldKey: 'descricao_cnae_principal',
    },
    {
      value: 12,
      label: 'CNAE Secundário 1',
      fieldCategory: 1,
      fieldKey: 'cnae_secundario1',
    },
    {
      value: 28,
      label: 'Descrição CNAE Secundário 1',
      fieldCategory: 1,
      fieldKey: 'descricao_cnae_secundario1',
    },
    {
      value: 13,
      label: 'CNAE Secundário 2',
      fieldCategory: 1,
      fieldKey: 'cnae_secundario2',
    },
    {
      value: 29,
      label: 'Descrição CNAE Secundário 2',
      fieldCategory: 1,
      fieldKey: 'descricao_cnae_secundario2',
    },
    {
      value: 14,
      label: 'CNAE Secundário 3',
      fieldCategory: 1,
      fieldKey: 'cnae_secundario3',
    },
    {
      value: 30,
      label: 'Descrição CNAE Secundário 3',
      fieldCategory: 1,
      fieldKey: 'descricao_cnae_secundario3',
    },
    {
      value: 15,
      label: 'CNAE Secundário 4',
      fieldCategory: 1,
      fieldKey: 'cnae_secundario4',
    },
    {
      value: 31,
      label: 'Descrição CNAE Secundário 4',
      fieldCategory: 1,
      fieldKey: 'descricao_cnae_secundario4',
    },
    {
      value: 16,
      label: 'CNAE Secundário 5',
      fieldCategory: 1,
      fieldKey: 'cnae_secundario5',
    },
    {
      value: 32,
      label: 'Descrição CNAE Secundário 5',
      fieldCategory: 1,
      fieldKey: 'descricao_cnae_secundario5',
    },
    {
      value: 17,
      label: 'CNAE Secundário 6',
      fieldCategory: 1,
      fieldKey: 'cnae_secundario6',
    },
    {
      value: 33,
      label: 'Descrição CNAE Secundário 6',
      fieldCategory: 1,
      fieldKey: 'descricao_cnae_secundario6',
    },
    {
      value: 53,
      label: 'Situação Cadastral',
      fieldCategory: 1,
      fieldKey: 'situacao_cadastral',
    },
    {
      value: 24,
      label: 'Data da Situação Cadastral',
      fieldCategory: 1,
      fieldKey: 'data_situacao_cadastral',
    },
    {
      value: 40,
      label: 'Motivo da Situação Cadastral',
      fieldCategory: 1,
      fieldKey: 'motivo_situacao_cadastral',
    },
  ];

  const dataEntered = dataTotal.filter(data => data.fieldCategory === 1);

  const [form] = Form.useForm<any>();

  const [searchTerm, setSearchTerm] = useState('');

  const [itemsChecked, setItemsChecked] = useState<any[]>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [objMetadata, setObjMetadata] = useState({});

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setItemsChecked(
      e.target.checked ? dataTotal.map(value => value.value) : [],
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);

    if (e.target.checked) {
      const newObjMetaData = {};

      dataTotal.forEach(item => {
        Object.assign(newObjMetaData, {
          [`${item.fieldCategory === 3 ? 'E_' : ''}${item.label}`]:
            item.fieldKey,
        });
      });

      setObjMetadata(newObjMetaData);
    } else {
      setObjMetadata({});
    }
  };

  const searchResults = dataTotal.filter(data =>
    data.label.toLowerCase().includes(searchTerm),
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleChecked = (checkedValues: CheckboxValueType[]) => {
    setItemsChecked(
      checkedValues.map(
        value => dataTotal.find(data => data.value === value)?.value,
      ),
    );
    setIndeterminate(
      !!checkedValues.length && checkedValues.length < dataTotal.length,
    );

    checkedValues.map(value =>
      setObjMetadata(prevState => ({
        ...prevState,
        [`${
          dataTotal.find(item => item.value === value)?.fieldCategory === 3
            ? 'E_'
            : ''
        }${dataTotal.find(item => item.value === value)?.label}`]:
          dataTotal.find(item => item.value === value)?.fieldKey,
      })),
    );
  };

  const [postFileExportPj, configPostFileExport] =
    usePostFileExportPjMutation();
  const navigate = useNavigate();

  const { data: files } = useGetDashboardDataQuery({});

  const handleSubmit = async () => {
    if (files && files.length) {
      // const customerPF = files.find((file: any) => file.customerType === 'PF');
      const customerPJ = files.find((file: any) => file.customerType === 'PJ');

      const handleCustomerExport = async (customer: any) => {
        if (customer.leads > customer.maxQuantityLeads) {
          navigate(CONSTANTS.UPGRADE);
        }
        await postFileExportPj({
          dataSourceId: fileImportId,
          dataSource: fileImportSource,
          fileExportTypeId: 1,
          exportMetadata: objMetadata,
        });
      };

      // if (customerPF) {
      //   await handleCustomerExport(customerPF);
      // }

      if (customerPJ) {
        await handleCustomerExport(customerPJ);
      }
    }
  };

  const showMessagePostFileExport = useCallback(() => {
    const { isSuccess, isError, error } = configPostFileExport;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }

    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Seu arquivo foi gerado e está sendo processado.',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
    if (isSuccess) {
      form.resetFields();
      setItemsChecked([]);
      onCancel();
    }
  }, [configPostFileExport, form, onCancel]);

  const handleCancel = () => {
    onCancel();
    onOk();
    form.resetFields();
    setItemsChecked([]);
  };

  useEffect(showMessagePostFileExport, [showMessagePostFileExport]);

  return (
    <>
      <Modal
        visible={visible}
        title={null}
        footer={null}
        closable={false}
        width="41.25rem"
      >
        <WizardWelcome>
          <Sider1 />
          <Wrapper>
            <PageHeader>
              <H3 weight="medium">{tModalExportFile('export_enrichment')}</H3>
              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={handleCancel}
              />
            </PageHeader>
            <H5 weight="medium">
              {tModalExportFile('what_fields_do_you_want')}
            </H5>
            <TopBar>
              <Input
                size="large"
                placeholder={tModalExportFile('search_for_fields')}
                style={{ width: '100%' }}
                onChange={handleInputChange}
              />
              <FormCheckbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              >
                <Body weight="medium">{tModalExportFile('select_all')}</Body>
              </FormCheckbox>
            </TopBar>
            <Form form={form} onFinish={handleSubmit}>
              <CheckboxGroup onChange={handleChecked} value={itemsChecked}>
                {searchTerm !== '' && (
                  <Fields>
                    <Form.Item name="searchResults">
                      <Row021>
                        {searchResults.map((option: any) => (
                          <FormCheckbox key={option.value} value={option.value}>
                            {option.label}
                          </FormCheckbox>
                        ))}
                      </Row021>
                    </Form.Item>
                  </Fields>
                )}
                {searchTerm === '' && (
                  <Fields>
                    <H5 weight="medium">Dados Enriquecidos</H5>
                    <Form.Item name="dataEntered">
                      <Row01>
                        {dataEntered.map((option: any) => (
                          <FormCheckbox
                            checked
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </FormCheckbox>
                        ))}
                      </Row01>
                    </Form.Item>
                  </Fields>
                )}
              </CheckboxGroup>
              <ActionBar>
                <Button type="default" size="large" onClick={handleCancel}>
                  {tModalExportFile('cancel')}
                </Button>
                <Button type="primary" size="large" htmlType="submit">
                  {tModalExportFile('export')}
                </Button>
              </ActionBar>
            </Form>
          </Wrapper>
        </WizardWelcome>
      </Modal>
    </>
  );
};

export default ModalExportFilePj;
