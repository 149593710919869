import { Tabs, Typography } from 'antd';
import styled from 'styled-components';
import VectorImage from '../../assets/finish-wizard-image.svg';
import SegmentationDimensionsImage from '../../assets/segmentation-dimensions.png';
import GenericCodeButton from '../Button';

const { Title } = Typography;

export const Body1 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
`;
export const TabGroup = styled(Tabs).attrs({
  tabBarStyle: {
    padding: '',
    borderRight: '1px solid #d9d9d9',
    height: '29rem',
    width: '9.625rem',
  },
})`
  .ant-tabs-tab-btn {
    white-space: normal;
    font-size: 14px;
    text-align: end;
    padding: 0;
  }
  .ant-tabs-tab {
    display: block;
    padding-left: 0 !important;
  }
  .ant-tabs-content {
    height: 100% !important;
  }
  width: 100%;
  height: 100%;
`;

export const TabGroupRow = styled(Tabs).attrs({})`
  width: 100%;
`;
export const TabGroupPaneRow = styled(Tabs.TabPane).attrs({
  tabBarExtraContent: {
    width: '100%',
  },
})`
  /* height: 70vh; */
`;

export const TabGroupPane = styled(Tabs.TabPane).attrs({})`
  display: flex;
  flex-direction: row;
`;
export const TabGroupPaneMap = styled.div`
  display: grid;
  text-align: center;
  gap: 1rem;
`;

export const FAB = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  padding: 0rem 1.5rem 1.5rem 0rem;
  box-shadow: 1.75rem 0.5rem rgba(0, 0, 0, 0.05), 1rem rgba(0, 0, 0, 0.08),
    0.38rem -0.25rem rgba(0, 0, 0, 0.12);
  position: fixed;
  right: 0rem;
  bottom: 0rem;
`;

export const PrevRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Row01 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  flex: 1;
`;

export const Row02 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 3rem 3rem;
  flex: 1;
`;

export const Row03 = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
`;

export const SegmentationCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  background-color: #ffffff;
  &:not(:last-child) {
    border-right: 0.06rem solid #dadada;
  }
`;

export const CardGroup = styled.div`
  display: inline-flex;
`;

export const Vector = styled.img.attrs({
  src: VectorImage,
  heigth: 'auto',
  width: '100%',
})`
  max-width: 159.78px;
  position: relative;
`;

export const COLUMN01 = styled.div`
  align-content: center;
  width: 100%;
`;

export const DimensionROW = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SegmentationTitle = styled(Title).attrs(({ editable }) => ({
  editable: editable || {
    maxLength: 70,
  },
  level: 4,
}))`
  font-family: inherit;
  font-weight: 700;
  font-size: 0.75rem;
  text-align: center;
  margin-top: 15px;
`;

export const GenericTitle = styled(Typography.Title)`
  font-family: inherit;
  font-weight: 700;
  font-size: 0.75rem;
  text-align: center;
`;

export const GenericText = styled(Typography.Text)`
  font-size: 1rem;
  text-align: center;
  font-weight: 500;
`;

export const PercentArea = styled.div`
  margin-top: 20px;
`;

export const SegmentationDimensions = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
`;

export const SegmentationImg = styled.img.attrs({
  src: SegmentationDimensionsImage,
  heigth: 'auto',
  width: '100%',
})`
  max-width: 600.78px;
  position: relative;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`;
export const ButtonShowTotalSegments = styled(GenericCodeButton)`
  width: 15rem;
  height: 2.5rem;
  border-radius: 0.12rem;
`;
export const Container = styled.div`
  width: 100%;
  height: auto;
  margin: 0 auto;
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  gap: 1rem;
`;
export const ContainerMap = styled.div`
  width: 100%;
  height: 70vh;
  margin: 0 auto;
`;

export const SpinContent = styled.div`
  display: flex;
  /* height: 70vh; */
  align-items: center;
`;
export const TabGroupOptions = styled(Tabs.TabPane)``;

// export const GenericText = styled(Typography.Text)`
//   font-size: 1rem;
//   text-align: left;
//   font-weight: 500;
//   padding: 0.5rem 0px;
// `;

export const GenericTextBold = styled(Typography.Text)`
  font-weight: 700;
`;

export const CenterColumn = styled.div`
  display: flex;
  justify-content: center;
  grid-column: 2;
`;

export const DivHeaderText = styled.div`
  display: flex;
  flex-direction: column;
`;
