import React from 'react';

import { ColumnProps } from 'antd/lib/table';
import { format } from 'date-fns';
import { useCustomTranslation } from '../../../hooks/custom-translation';

import { Content02, TableGroup } from './styles';
import { ContactProps } from '../../../services/contact';
import {
  currencyMask,
  maskCep,
  maskCpf,
  maskPhone,
} from '../../../helpers/masks/masksRegex';

interface IProps {
  contactInfo: ContactProps | undefined;
}

const Enrichment = ({ contactInfo }: IProps) => {
  const { tContactSegmentsEdit } = useCustomTranslation('contactSegmentsEdit');

  interface TableGroupProps {
    key: string;
    atributo: string;
    valor: any;
  }
  const dataTableGroup: TableGroupProps[] = [
    {
      key: '0',
      atributo: tContactSegmentsEdit('name'),
      valor: contactInfo?.name || '-',
    },
    {
      key: '1',
      atributo: tContactSegmentsEdit('cpf'),
      valor: maskCpf(contactInfo?.document as string),
    },
    {
      key: '2',
      atributo: 'RG',
      valor: contactInfo?.enriched.rg || '-',
    },
    {
      key: '3',
      atributo: tContactSegmentsEdit('birthdate'),
      valor: format(
        new Date(contactInfo?.enriched?.birthDate as any),
        'dd/MM/yyyy',
      ),
    },
    {
      key: '4',
      atributo: tContactSegmentsEdit('backyard_-_type'),
      valor: contactInfo?.enriched?.addressType || '-',
    },
    {
      key: '5',
      atributo: tContactSegmentsEdit('backyard_-_title'),
      valor: contactInfo?.enriched?.addressTitle || '-',
    },
    {
      key: '6',
      atributo: tContactSegmentsEdit('backyard_-_name'),
      valor: contactInfo?.enriched?.address || '-',
    },
    {
      key: '7',
      atributo: tContactSegmentsEdit('backyard_-_number'),
      valor: contactInfo?.enriched?.addressNumber || '-',
    },
    {
      key: '8',
      atributo: tContactSegmentsEdit('patio_-_complement'),
      valor: contactInfo?.enriched.additionalAddressDetail || '-',
    },
    {
      key: '9',
      atributo: tContactSegmentsEdit('neighborhood'),
      valor: contactInfo?.enriched?.district || '-',
    },
    {
      key: '10',
      atributo: tContactSegmentsEdit('town'),
      valor: contactInfo?.enriched?.city || '-',
    },
    {
      key: '11',
      atributo: tContactSegmentsEdit('statehood'),
      valor: contactInfo?.enriched?.federativeUnit || '-',
    },
    {
      key: '12',
      atributo: tContactSegmentsEdit('zip_code'),
      valor: maskCep(contactInfo?.enriched?.postalCode as string),
    },
    {
      key: '13',
      atributo: tContactSegmentsEdit('landline'),
      valor: contactInfo?.enriched?.phoneNumbers.map(
        (phone, index) => `${index > 0 ? ',' : ''} ${maskPhone(phone)}`,
      ),
    },
    {
      key: '14',
      atributo: tContactSegmentsEdit('cell_phone'),
      valor: contactInfo?.enriched?.cellPhones.map(
        (cellphone, index) => `${index > 0 ? ',' : ''} ${maskPhone(cellphone)}`,
      ),
    },
    {
      key: '15',
      atributo: tContactSegmentsEdit('e-mail'),
      valor: contactInfo?.enriched?.emails.map(email => `${email} `),
    },
    {
      key: '16',
      atributo: tContactSegmentsEdit('sex'),
      valor: contactInfo?.enriched?.gender === 'M' ? 'Masculino' : 'Feminino',
    },
    {
      key: '17',
      atributo: tContactSegmentsEdit('geolocation_(latitudelongitude)'),
      valor:
        contactInfo?.enriched.latitude || contactInfo?.enriched.longitude
          ? `${contactInfo?.enriched.latitude}, ${contactInfo?.enriched.longitude}`
          : '-',
    },
    {
      key: '18',
      atributo: tContactSegmentsEdit('presumed_individual_income'),
      valor: currencyMask(
        contactInfo?.enriched?.presumedIndividualIncome as number,
      ),
    },
    {
      key: '19',
      atributo: tContactSegmentsEdit('family_income'),
      valor: currencyMask(contactInfo?.enriched?.householdIncome as number),
    },
    {
      key: '20',
      atributo: tContactSegmentsEdit('marital_status'),
      valor: contactInfo?.enriched?.maritalStatus || '-',
    },
    {
      key: '21',
      atributo: tContactSegmentsEdit('internal_revenue_service_status'),
      valor: contactInfo?.enriched?.federalRevenueStatus || '-',
    },
    {
      key: '22',
      atributo: tContactSegmentsEdit('occupation'),
      valor: contactInfo?.enriched?.profession || '-',
    },
    {
      key: '23',
      atributo: tContactSegmentsEdit('schooling'),
      valor: contactInfo?.enriched?.educationLevel || '-',
    },
    {
      key: '24',
      atributo: tContactSegmentsEdit('bolsa_familia?'),
      valor: tContactSegmentsEdit(
        contactInfo?.enriched?.hasFamilyAllowance ? 'yes' : 'nope',
      ),
    },
    {
      key: '25',
      atributo: 'Participação em Empresas',
      valor: contactInfo?.enriched.hasCompanyParticipation || '-',
    },
    {
      key: '26',
      atributo: tContactSegmentsEdit('emergency_assistance?'),
      valor: tContactSegmentsEdit(
        contactInfo?.enriched?.hasEmergencyAid ? 'yes' : 'nope',
      ),
    },
    {
      key: '27',
      atributo: tContactSegmentsEdit('retired?'),
      valor: tContactSegmentsEdit(
        contactInfo?.enriched?.isRetired ? 'yes' : 'nope',
      ),
    },
    {
      key: '28',
      atributo: tContactSegmentsEdit('death?'),
      valor: tContactSegmentsEdit(
        contactInfo?.enriched?.isDeath ? 'yes' : 'nope',
      ),
    },
    {
      key: '29',
      atributo: tContactSegmentsEdit('related_pep'),
      valor: contactInfo?.enriched.relatedPepCount || '-',
    },
    {
      key: '30',
      atributo: tContactSegmentsEdit('pep'),
      valor: tContactSegmentsEdit(contactInfo?.enriched.isPep ? 'yes' : 'nope'),
    },
    {
      key: '31',
      atributo: tContactSegmentsEdit('psychographic_credit_score'),
      valor: contactInfo?.enriched.financialScore || '-',
    },
    {
      key: '32',
      atributo: tContactSegmentsEdit('segment_name'),
      valor: contactInfo?.enriched.segmentName || '-',
    },
    {
      key: '33',
      atributo: tContactSegmentsEdit('propensity_for_neuroticism'),
      valor: `${contactInfo?.enriched.scoreNeur}%` || '-',
    },
    {
      key: '34',
      atributo: tContactSegmentsEdit('propensity_to_be_open_to'),
      valor: `${contactInfo?.enriched.scoreAber}%` || '-',
    },
    {
      key: '35',
      atributo: tContactSegmentsEdit('propensity_for_extroversion'),
      valor: `${contactInfo?.enriched.scoreExtr}%` || '-',
    },
    {
      key: '36',
      atributo: tContactSegmentsEdit('propensity_to_conscientiousness'),
      valor: `${contactInfo?.enriched.scoreCons}%` || '-',
    },
    {
      key: '37',
      atributo: tContactSegmentsEdit('brand_trust'),
      valor: `${contactInfo?.enriched.brandTrust}%` || '-',
    },
    {
      key: '38',
      atributo: tContactSegmentsEdit('potential_digital_consumption'),
      valor: `${contactInfo?.enriched.digitalConsumptionPotential}%` || '-',
    },
    {
      key: '39',
      atributo: tContactSegmentsEdit('expediency'),
      valor: `${contactInfo?.enriched.convenience}%` || '-',
    },
    {
      key: '40',
      atributo: tContactSegmentsEdit('cost-benefit_ratio'),
      valor: `${contactInfo?.enriched.costBenefit}%` || '-',
    },
    {
      key: '41',
      atributo: tContactSegmentsEdit(
        'esg_(environmental,_social_and_governmental)',
      ),
      valor: `${contactInfo?.enriched.esg}%` || '-',
    },
    {
      key: '42',
      atributo: tContactSegmentsEdit('shopping_experience'),
      valor: `${contactInfo?.enriched.shoppingExperience}%` || '-',
    },
    {
      key: '43',
      atributo: tContactSegmentsEdit('ease_of_payment'),
      valor: `${contactInfo?.enriched.easeOfPayment}%` || '-',
    },
    {
      key: '44',
      atributo: tContactSegmentsEdit('influence_offline'),
      valor: `${contactInfo?.enriched.influenceOfflineConsumption}%` || '-',
    },
    {
      key: '45',
      atributo: tContactSegmentsEdit('influence_online_consumption'),
      valor: `${contactInfo?.enriched.influenceOnlineConsumption}%` || '-',
    },
    {
      key: '46',
      atributo: tContactSegmentsEdit('innovation'),
      valor: `${contactInfo?.enriched.innovation}%` || '-',
    },
    {
      key: '47',
      atributo: tContactSegmentsEdit('social_status'),
      valor: `${contactInfo?.enriched.socialStatus}%` || '-',
    },
    {
      key: '48',
      atributo: tContactSegmentsEdit('seasonal_value'),
      valor: `${contactInfo?.enriched.seasonalValue}%` || '-',
    },
    {
      key: '49',
      atributo: tContactSegmentsEdit('economic_advantage'),
      valor: `${contactInfo?.enriched.economicAdvantage}%` || '-',
    },
    {
      key: '50',
      atributo: tContactSegmentsEdit('physical_retail'),
      valor: `${contactInfo?.enriched.physicalRetail}%` || '-',
    },
    {
      key: '51',
      atributo: tContactSegmentsEdit('financial_risk'),
      valor: contactInfo?.enriched.psychoFinancialRisk || '-',
    },
    {
      key: '52',
      atributo: 'Brinde',
      valor: `${contactInfo?.enriched.gift}%` || '-',
    },
    {
      key: '53',
      atributo: 'Estabilidade',
      valor: `${contactInfo?.enriched.stability}%` || '-',
    },
    {
      key: '54',
      atributo: 'Instabilidade',
      valor: `${contactInfo?.enriched.instability}%` || '-',
    },
    {
      key: '55',
      atributo: 'Opção de Pagamento',
      valor: `${contactInfo?.enriched.pagOption}%` || '-',
    },
    {
      key: '56',
      atributo: 'Premium',
      valor: `${contactInfo?.enriched.premium}%` || '-',
    },
    {
      key: '57',
      atributo: 'Premium Serviço',
      valor: `${contactInfo?.enriched.premiumService}%` || '-',
    },
    {
      key: '58',
      atributo: 'Nome do Arquivo',
      valor: contactInfo?.enriched.id || '-',
    },
  ];
  const columnsTableGroup: ColumnProps<any>[] = [
    {
      title: tContactSegmentsEdit('attribute'),
      dataIndex: 'atributo',
      width: '50%',
      align: 'left',
      sorter: (a, b) => a.atributo.length - b.atributo.length,
    },
    {
      title: tContactSegmentsEdit('valor'),
      dataIndex: 'valor',
      align: 'left',
      width: '50%',
      sorter: (a, b) => a.valor.length - b.valor.length,
    },
  ];

  return (
    <>
      <Content02>
        <TableGroup dataSource={dataTableGroup} columns={columnsTableGroup} />
      </Content02>
    </>
  );
};

export default Enrichment;
