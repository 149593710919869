/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form } from 'antd';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import * as CONSTANTS from '../../navigation/constants';
import { useCustomTranslation } from '../../hooks/custom-translation';
import Button from '../../components/Button';
import {
  useGetSegmentationByIdQuery,
  usePostSegmentationMutation,
  usePostSegmentationPreviewContactsMutation,
  useUpdateSegmentationMutation,
} from '../../services/segmentation';
import {
  Feedback,
  getErrorMessage,
  ShowFormMessage,
} from '../../helpers/message';
import getErrors from '../../helpers/errors';
import { Container } from '../../styles/contents';
import { H3 } from '../../styles/fonts';

import {
  Body1,
  ContactSegmentsEdit,
  ContactSegmentsWrapper,
  FAB,
  NavigateBack,
  PageHeader,
} from './styles';
import FormGroups from './FormGroups';
import SegmentationCount from './SegmentationCount';
import ModalSaveSegmentation from './ModalSaveSegmentation';

const ContactSegmentsEditPage = () => {
  const { id } = useParams();

  const { data: segmentation } = useGetSegmentationByIdQuery(id || '', {
    skip: !id,
  });

  const { tContactSegmentsEdit } = useCustomTranslation('contactSegmentsEdit');

  const [withCountContacts, setWithCountContacts] = useState<boolean>(false);

  const [segmentationCount, setSegmentationCount] = useState<number>(0);

  const [groups, setGroups] = useState<any>();

  const [modalSaveSegmentsOpen, setModalSaveSegmentsOpen] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [postSegmentation] = usePostSegmentationMutation();

  const [updateSegmentation, configUpdateSegmentation] =
    useUpdateSegmentationMutation();

  const [postSegmentationPreviewContacts] =
    usePostSegmentationPreviewContactsMutation();

  const [segmentationName, setSegmentationName] = useState<string>(
    tContactSegmentsEdit('segmentation_name'),
  );

  const handleFilterValue = (filterValue: any, filterValue2?: any) => {
    if (!Array.isArray(filterValue)) {
      if (filterValue2) {
        return [filterValue, filterValue2];
      }
      return [filterValue];
    }
    return filterValue;
  };

  const prepareObjPostSegmentation = (data: any) => {
    const newData: any = { filterGroups: [] };
    data.groups.forEach((gr: any, index: number) => {
      const filtersDefinition = gr.filtersDefinition.map(
        (fd: any, i: number) => {
          console.log(fd, 'filterValue2');
          return {
            order: i + 1,
            originFilterFieldId: fd.originFilterFieldId,
            assertFilterOperatorId: fd.assertFilterOperatorId,
            joinFilterOperatorId: fd?.joinFilterOperatorId || null,
            filterValue: handleFilterValue(fd.filterValue, fd?.filterValue2),
            origin: fd.origin,
          };
        },
      );
      newData.filterGroups.push({
        order: index + 1,
        filterOperatorId: gr.filterOperatorId,
        filtersDefinition,
      });
    });

    newData.name = segmentationName;
    return newData;
  };

  const handleBackNavigation = () => {
    navigate(CONSTANTS.CONTACT_SEGMENTATION);
  };

  const handleOpenSaveModal = () => {
    setModalSaveSegmentsOpen(true);
  };

  const handleSubmitForm = async (data: any) => {
    const parsedBody = prepareObjPostSegmentation(data);

    if (withCountContacts) {
      const result = await postSegmentationPreviewContacts(parsedBody).unwrap();
      setSegmentationCount(result.qtyFound);
      setWithCountContacts(false);
    } else if (id) {
      await updateSegmentation({ id, ...parsedBody }).unwrap();
    } else {
      try {
        await postSegmentation(parsedBody).unwrap();
        Feedback.success(tContactSegmentsEdit('segmentation_success'));
        form.resetFields();
        handleBackNavigation();
      } catch (error) {
        Feedback.error(getErrorMessage(error));
      }
    }
    setWithCountContacts(false);
  };

  const handleSegmentationCount = () => {
    setWithCountContacts(true);
    form.submit();
  };

  const showMessageUpdateSegmentation = useCallback(() => {
    const { isSuccess, isError, error } = configUpdateSegmentation;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }
    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: tContactSegmentsEdit('segmentation_success'),
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
    if (isSuccess) {
      form.resetFields();
      handleBackNavigation();
    }
  }, [configUpdateSegmentation, tContactSegmentsEdit]);

  useEffect(showMessageUpdateSegmentation, [showMessageUpdateSegmentation]);

  useEffect(() => {
    setGroups(segmentation?.filterGroups);
    setSegmentationName(segmentationName);
  }, [segmentation, segmentationName]);

  if (!groups && id) {
    return <></>;
  }
  return (
    <Container>
      <ContactSegmentsWrapper>
        <Form
          form={form}
          style={{ width: '100%' }}
          initialValues={{ groups }}
          onFinish={data => handleSubmitForm(data)}
        >
          <ContactSegmentsEdit>
            <PageHeader>
              <NavigateBack>
                <Button
                  type="text"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => handleBackNavigation()}
                />
                <H3 weight="medium">
                  {segmentation?.name ||
                    tContactSegmentsEdit('create_segmentation')}
                </H3>
              </NavigateBack>
              <SegmentationCount
                handleSegmentationCount={handleSegmentationCount}
                segmentationCount={segmentationCount}
              />
            </PageHeader>
            <Body1>
              <FormGroups groupOrder="row" form={form} origin="Segmentation" />
            </Body1>
          </ContactSegmentsEdit>
          <FAB style={{ zIndex: 1000000 }}>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              size="large"
              onClick={() => handleOpenSaveModal()}
            >
              {tContactSegmentsEdit('save_segmentation')}
            </Button>
          </FAB>
        </Form>
      </ContactSegmentsWrapper>

      <ModalSaveSegmentation
        name={segmentation?.name || segmentationName}
        setName={setSegmentationName}
        visible={modalSaveSegmentsOpen}
        setVisible={setModalSaveSegmentsOpen}
        form={form}
        handleSubmit={handleSubmitForm}
      />
    </Container>
  );
};

export default ContactSegmentsEditPage;
