import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${props =>
    props.theme.colors.extra.not_mapped.background[1]};

  .ant-skeleton-button {
    width: 32px;
    min-width: 32px;
  }
`;
