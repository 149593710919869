import { memo, useCallback, useState } from 'react';

import { Outlet } from 'react-router-dom';

import { Header, SideNav } from './components';

import { BREAKPOINT_SIDER, SIDE_NAV_WIDTH } from './constants';

import {
  Content,
  ContentContainer,
  HeaderContainer,
  Sider,
  SiderMobile,
  Wrapper,
} from './styles';

enum MenuType {
  MENU,
  DRAWER,
}

const LandingPage = () => {
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [menuType, setMenuType] = useState<MenuType>(MenuType.MENU);

  const toggleMenu = useCallback(() => {
    if (menuType === MenuType.MENU) {
      setSiderCollapsed(value => !value);
      return;
    }
    setDrawerVisible(value => !value);
  }, [menuType]);

  const handleBreakpointChange = useCallback((broken: boolean) => {
    setMenuType(broken ? MenuType.DRAWER : MenuType.MENU);
    setDrawerVisible(false);
    setSiderCollapsed(true);
  }, []);

  return (
    <Wrapper hasSider>
      <Sider
        width={menuType === MenuType.MENU ? SIDE_NAV_WIDTH : 0}
        trigger={null}
        collapsedWidth={0}
        collapsible
        collapsed={siderCollapsed}
        breakpoint={BREAKPOINT_SIDER}
        onBreakpoint={handleBreakpointChange}
      >
        <SideNav />
      </Sider>
      <SiderMobile
        placement="left"
        onClose={toggleMenu}
        visible={drawerVisible}
        width={SIDE_NAV_WIDTH}
        closable={false}
      >
        <SideNav />
      </SiderMobile>

      <ContentContainer>
        <HeaderContainer>
          <Header toggleMenu={toggleMenu} siderCollapsed={siderCollapsed} />
        </HeaderContainer>
        <Content>
          <Outlet />
        </Content>
      </ContentContainer>
    </Wrapper>
  );
};

export default memo(LandingPage);
