import { Skeleton } from 'antd';

import { Container } from './styles';

const Content: React.FC = () => {
  return (
    <Container>
      <Skeleton active />
    </Container>
  );
};

export default Content;
