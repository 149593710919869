import { salesBoxApi } from '../../config/apis';
import { CreativeGenerator } from '../../config/services-route';
import { BuilderQueryProps, builderQueryOdata } from '../../helpers/odata';
import { providesList } from '../../helpers/provides';
import { URLs } from '../../helpers/urls';
import { TagTypes, WithPagination } from '../types';
import { AdCreativeProps } from './creativeGenerator';

const creativeGenerator = salesBoxApi.injectEndpoints({
  endpoints: builder => ({
    getAllCreative: builder.query<any[], void>({
      query: () => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: CreativeGenerator.creative,
        };
      },
      providesTags: response => providesList(response, TagTypes.AdCreative),
    }),
    getAllCreativeFiltered: builder.query<
      WithPagination<AdCreativeProps[]>,
      BuilderQueryProps<Partial<AdCreativeProps>>
    >({
      query: props => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: URLs.combineOdataUrl(
            CreativeGenerator.getCreativeQueryOdata,
            builderQueryOdata(props),
          ),
        };
      },
      providesTags: response =>
        providesList(response?.data, TagTypes.AdCreative),
      transformResponse: (response: any): any => {
        return {
          data: response.value,
          meta: {
            size: response['@odata.count'],
          },
        };
      },
    }),
    getCreativeById: builder.query<AdCreativeProps, string>({
      query: id => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: URLs.combine(CreativeGenerator.creative, `${id}`),
        };
      },
    }),
    postAdCreative: builder.mutation<
      AdCreativeProps,
      Omit<AdCreativeProps, 'id'>
    >({
      query: body => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: CreativeGenerator.creative,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: TagTypes.AdCreative, id: 'LIST' }],
    }),
    updateAdCreative: builder.mutation({
      query: ({ id, ...body }) => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: URLs.combine(CreativeGenerator.creative, `${id}`),
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [{ type: TagTypes.AdCreative, id: 'LIST' }],
    }),
    deleteAdCreative: builder.mutation({
      query: id => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: URLs.combine(CreativeGenerator.creative, `${id}`),
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: TagTypes.AdCreative, id: 'LIST' }],
    }),
    getAllCreativeBrandVoice: builder.query<any[], void>({
      query: () => ({
        url: CreativeGenerator.creativeBrandVoice,
      }),
      providesTags: response =>
        providesList(response, TagTypes.AdCreativeBrandVoice),
    }),
    getAllCreativeFunnelStage: builder.query<any[], void>({
      query: () => ({
        url: CreativeGenerator.creativeFunnelStage,
      }),
      providesTags: response =>
        providesList(response, TagTypes.AdCreativeFunnelStage),
    }),
    getAllCreativeSegment: builder.query<any[], void>({
      query: () => ({
        url: CreativeGenerator.creativeSegment,
      }),
      providesTags: response =>
        providesList(response, TagTypes.AdCreativeSegment),
    }),
  }),
});

export const {
  useGetAllCreativeQuery,
  useGetAllCreativeFilteredQuery,
  useGetCreativeByIdQuery,
  usePostAdCreativeMutation,
  useUpdateAdCreativeMutation,
  useDeleteAdCreativeMutation,
  useGetAllCreativeBrandVoiceQuery,
  useGetAllCreativeFunnelStageQuery,
  useGetAllCreativeSegmentQuery,
} = creativeGenerator;
