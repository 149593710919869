import { salesBoxApi } from '../../config/apis';
import { SegmentationRoute } from '../../config/services-route';
import { URLs } from '../../helpers/urls';
import { TagTypes, WithPagination } from '../types';
import { providesList } from '../../helpers/provides';
import { SegmentationProps } from './segmentation';
import { builderQueryOdata, BuilderQueryProps } from '../../helpers/odata';

const segmentationApi = salesBoxApi.injectEndpoints({
  endpoints: builder => ({
    getAllSegmentation: builder.query<SegmentationProps[], void>({
      query: () => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: SegmentationRoute.segmentation,
        };
      },
      providesTags: response => providesList(response, TagTypes.Segmentation),
    }),
    getSegmentationById: builder.query<SegmentationProps, string>({
      query: id => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: URLs.combine(SegmentationRoute.segmentation, `${id}`),
        };
      },
    }),
    getAllSegmentationFiltered: builder.query<
      WithPagination<SegmentationProps[]>,
      BuilderQueryProps<Partial<SegmentationProps>>
    >({
      query: props => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: URLs.combineOdataUrl(
            SegmentationRoute.getQueryODATA,
            builderQueryOdata<Partial<SegmentationProps>>(props),
          ),
        };
      },
      providesTags: response =>
        providesList(response?.data, TagTypes.Segmentation),
      transformResponse: (
        response: any,
      ): WithPagination<SegmentationProps[]> => {
        return {
          data: response.value,
          meta: {
            size: response['@odata.count'],
          },
        };
      },
    }),
    postSegmentation: builder.mutation<
      SegmentationProps,
      Partial<SegmentationProps>
    >({
      query: body => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: SegmentationRoute.segmentation,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: TagTypes.Segmentation, id: 'LIST' }],
    }),
    postSegmentationPreviewContacts: builder.mutation<
      Record<string, number>,
      Partial<SegmentationProps>
    >({
      query: body => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: SegmentationRoute.previewContacts,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: TagTypes.Segmentation, id: 'LIST' }],
    }),
    updateSegmentation: builder.mutation<
      SegmentationProps,
      Partial<SegmentationProps>
    >({
      query: ({ id, ...body }) => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: URLs.combine(SegmentationRoute.segmentation, `${id}`),
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [{ type: TagTypes.Segmentation, id: 'LIST' }],
    }),
    deleteSegmentation: builder.mutation<SegmentationProps, string>({
      query: id => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: URLs.combine(SegmentationRoute.segmentation, `${id}`),
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: TagTypes.Segmentation, id: 'LIST' }],
    }),
  }),
});
export const {
  useGetAllSegmentationQuery,
  useGetSegmentationByIdQuery,
  usePostSegmentationMutation,
  useUpdateSegmentationMutation,
  useGetAllSegmentationFilteredQuery,
  usePostSegmentationPreviewContactsMutation,
  useDeleteSegmentationMutation,
} = segmentationApi;
