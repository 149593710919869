import {
  useEffect,
  useContext,
  createContext,
  useState,
  FC,
  PropsWithChildren,
  useMemo,
  useCallback,
} from 'react';

import { AuthInfo, useGetInfoQuery } from '../services/auth';

interface AuthContextData {
  userInfo: AuthInfo;
  updateUserInfoWithClienteId: (customerId: string) => void;
  authenticatedIsPending: boolean;
  authenticatedIsSuccess: boolean;
  authenticatedIsError: boolean;
}

enum AuthenticatedState {
  PENDING,
  SUCCESS,
  ERROR,
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<AuthenticatedState>(
    AuthenticatedState.PENDING,
  );
  const [userInfo, setUserInfo] = useState({} as AuthInfo);

  const { data, isError } = useGetInfoQuery();

  const authenticatedIsError = useMemo(
    () => isAuthenticated === AuthenticatedState.ERROR,
    [isAuthenticated],
  );

  const authenticatedIsPending = useMemo(
    () => isAuthenticated === AuthenticatedState.PENDING,
    [isAuthenticated],
  );

  const authenticatedIsSuccess = useMemo(
    () => isAuthenticated === AuthenticatedState.SUCCESS,
    [isAuthenticated],
  );

  useEffect(() => {
    if (data) {
      setIsAuthenticated(AuthenticatedState.SUCCESS);
      setUserInfo({
        ...data,
        customer:
          JSON.parse(localStorage?.getItem('auth') as string)?.customer == null
            ? data.customer
            : JSON.parse(localStorage?.getItem('auth') as string)?.customer,
      });

      localStorage.setItem(
        'auth',
        JSON.stringify({
          ...data,
          customer:
            JSON.parse(localStorage?.getItem('auth') as string)?.customer ==
            null
              ? data.customer
              : JSON.parse(localStorage?.getItem('auth') as string)?.customer,
        }),
      );
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      setIsAuthenticated(AuthenticatedState.ERROR);
    }
  }, [isError]);

  const updateUserInfoWithClienteId = useCallback((customerId: string) => {
    const currentUser = JSON.parse(localStorage.getItem('auth') as string);
    const newData = { ...currentUser, customer: customerId };
    localStorage.setItem('auth', JSON.stringify(newData));
  }, []);

  return (
    <AuthContext.Provider
      value={{
        userInfo,
        updateUserInfoWithClienteId,
        authenticatedIsPending,
        authenticatedIsSuccess,
        authenticatedIsError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
