import { Table } from 'antd';
import styled from 'styled-components';

import GenericCodeButton from '../Button';

export const TableContent = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
`;
export const TableGroup = styled(Table)`
  width: 100%;
`;

export const ActionTab = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;

export const DownloadButton = styled(GenericCodeButton)`
  width: 7.19rem;
  height: 2rem;
  border-radius: 0.12rem;
  font-weight: 500;
`;

export const ActionButton = styled(GenericCodeButton)`
  width: 2rem;
  height: 2rem;
  border-radius: 0.12rem;
`;
