import styled from 'styled-components';

import { Menu } from 'antd';
import { Body } from '../../../../styles/fonts';

export const AsideContainer = styled.nav`
  height: 100%;
  padding: 1.125rem;
  color: ${props => props.theme.colors.neutral.gray[1]};
  background-color: ${props => props.theme.colors.primary[9]};
`;

// export const Nav = styled.nav`
//   display: flex;
//   flex-direction: column;
//   gap: 4px;

//   height: 100%;
//   width: 100%;

//   box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.08);
// `;

export const Title = styled(Body)`
  display: block;
  text-transform: uppercase;
  margin: 0.5rem 0;
`;

export const MenuGroup = styled(Menu)`
  width: 100%;
  border-right: 0;

  .ant-menu-item,
  .ant-menu-submenu-title {
    width: 100%;
  }

  .ant-menu-title-content {
    text-overflow: unset;
  }

  .ant-menu-item {
    padding-left: 0 !important;
    --ant-primary-1: transparent;

    &:hover {
      color: ${props => props.theme.colors.primary[3]};
    }
  }
`;
