import styled from 'styled-components';

import { Menu, Table } from 'antd';
import {
  WhatsAppOutlined,
  CheckCircleOutlined,
  SyncOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import GenericButton from '../../Button';

export const Content01 = styled.div`
  width: 100%;
  display: inline-flex;
`;
export const TableGroup = styled(Table)`
  width: 100%;
`;

export const ColumnTableGroup = styled(Table.Column)`
  height: 10rem;
  color: #fff;
  line-height: 10rem;
  text-align: center;
  background: #364d79;
`;

export const Content2 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const IconOutlinedWhatsApp = styled(WhatsAppOutlined)`
  color: #53c51b;
  font-size: 1.25rem;
`;

export const TagStatus = styled.div`
  display: inline-flex;
  border: 0.06rem solid #b8ec90;
  box-sizing: border-box;
  gap: 0.25rem;
  align-items: center;
  border-radius: 0.12rem;
  padding: 0.06rem 0.5rem 0.06rem 0.5rem;
  background-color: #f7ffee;
`;
export const IconOutlinedCheckCircle = styled(CheckCircleOutlined)`
  color: #53c51b;
  font-size: 0.75rem;
`;

export const Content3 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
`;
export const Content4 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const IconOutlinedWhatsApp1 = styled(WhatsAppOutlined)`
  color: #53c51b;
  font-size: 1.25rem;
`;

export const TagStatus1 = styled.div`
  display: inline-flex;
  border: 0.06rem solid #ffd692;
  box-sizing: border-box;
  gap: 0.25rem;
  align-items: center;
  border-radius: 0.12rem;
  padding: 0.06rem 0.5rem 0.06rem 0.5rem;
  background-color: #fff8e7;
`;
export const IconOutlinedSync = styled(SyncOutlined)`
  color: #fb8d17;
  font-size: 0.75rem;
`;

export const Content5 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const Content6 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const IconOutlinedWhatsApp2 = styled(WhatsAppOutlined)`
  color: #53c51b;
  font-size: 1.25rem;
`;

export const TagStatus2 = styled.div`
  display: inline-flex;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.25rem;
  align-items: center;
  border-radius: 0.12rem;
  padding: 0.06rem 0.5rem 0.06rem 0.5rem;
  background-color: #fbfbfb;
`;
export const UnmapedComponenticonoutlinedexc = styled(
  ExclamationCircleOutlined,
)`
  color: #000000;
  font-size: 0.75rem;
`;

export const Content7 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const Content8 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const IconOutlinedWhatsApp3 = styled(WhatsAppOutlined)`
  color: #53c51b;
  font-size: 1.25rem;
`;

export const TagStatus3 = styled.div`
  display: inline-flex;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  gap: 0.25rem;
  align-items: center;
  border-radius: 0.12rem;
  padding: 0.06rem 0.5rem 0.06rem 0.5rem;
  background-color: #fbfbfb;
`;
export const UnmapedComponenticonoutlinedexc1 = styled(
  ExclamationCircleOutlined,
)`
  color: #000000;
  font-size: 0.75rem;
`;

export const Content9 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const Content10 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const IconOutlinedWhatsApp4 = styled(WhatsAppOutlined)`
  color: #53c51b;
  font-size: 1.25rem;
`;

export const TagStatus4 = styled.div`
  display: inline-flex;
  border: 0.06rem solid #b8ec90;
  box-sizing: border-box;
  gap: 0.25rem;
  align-items: center;
  border-radius: 0.12rem;
  padding: 0.06rem 0.5rem 0.06rem 0.5rem;
  background-color: #f7ffee;
`;
export const IconOutlinedCheckCircle1 = styled(CheckCircleOutlined)`
  color: #53c51b;
  font-size: 0.75rem;
`;

export const Content11 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const Content12 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const IconOutlinedWhatsApp5 = styled(WhatsAppOutlined)`
  color: #53c51b;
  font-size: 1.25rem;
`;

export const TagStatus5 = styled.div`
  display: inline-flex;
  border: 0.06rem solid #b8ec90;
  box-sizing: border-box;
  gap: 0.25rem;
  align-items: center;
  border-radius: 0.12rem;
  padding: 0.06rem 0.5rem 0.06rem 0.5rem;
  background-color: #f7ffee;
`;
export const IconOutlinedCheckCircle2 = styled(CheckCircleOutlined)`
  color: #53c51b;
  font-size: 0.75rem;
`;

export const Content13 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const Content14 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const IconOutlinedWhatsApp6 = styled(WhatsAppOutlined)`
  color: #53c51b;
  font-size: 1.25rem;
`;

export const TagStatus6 = styled.div`
  display: inline-flex;
  border: 0.06rem solid #b8ec90;
  box-sizing: border-box;
  gap: 0.25rem;
  align-items: center;
  border-radius: 0.12rem;
  padding: 0.06rem 0.5rem 0.06rem 0.5rem;
  background-color: #f7ffee;
`;
export const IconOutlinedCheckCircle3 = styled(CheckCircleOutlined)`
  color: #53c51b;
  font-size: 0.75rem;
`;

export const Content15 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const Content16 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const IconOutlinedWhatsApp7 = styled(WhatsAppOutlined)`
  color: #53c51b;
  font-size: 1.25rem;
`;

export const TagStatus7 = styled.div`
  display: inline-flex;
  border: 0.06rem solid #b8ec90;
  box-sizing: border-box;
  gap: 0.25rem;
  align-items: center;
  border-radius: 0.12rem;
  padding: 0.06rem 0.5rem 0.06rem 0.5rem;
  background-color: #f7ffee;
`;
export const IconOutlinedCheckCircle4 = styled(CheckCircleOutlined)`
  color: #53c51b;
  font-size: 0.75rem;
`;

export const Content17 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const Content18 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const IconOutlinedWhatsApp8 = styled(WhatsAppOutlined)`
  color: #53c51b;
  font-size: 1.25rem;
`;

export const TagStatus8 = styled.div`
  display: inline-flex;
  border: 0.06rem solid #b8ec90;
  box-sizing: border-box;
  gap: 0.25rem;
  align-items: center;
  border-radius: 0.12rem;
  padding: 0.06rem 0.5rem 0.06rem 0.5rem;
  background-color: #f7ffee;
`;
export const IconOutlinedCheckCircle5 = styled(CheckCircleOutlined)`
  color: #53c51b;
  font-size: 0.75rem;
`;

export const Content19 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const Content20 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const IconOutlinedWhatsApp9 = styled(WhatsAppOutlined)`
  color: #53c51b;
  font-size: 1.25rem;
`;

export const TagStatus9 = styled.div`
  display: inline-flex;
  border: 0.06rem solid #b8ec90;
  box-sizing: border-box;
  gap: 0.25rem;
  align-items: center;
  border-radius: 0.12rem;
  padding: 0.06rem 0.5rem 0.06rem 0.5rem;
  background-color: #f7ffee;
`;
export const IconOutlinedCheckCircle6 = styled(CheckCircleOutlined)`
  color: #53c51b;
  font-size: 0.75rem;
`;

export const Content21 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;

export const MenuDropdown = styled(Menu)``;

export const ActionButton = styled(GenericButton)`
  width: 2rem;
  height: 2rem;
  border-radius: 0.12rem;
`;
