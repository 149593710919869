/* eslint-disable import/no-cycle */
import { deepDiveApi } from '../../config/apis/deep-dive-query';
import { UplodLogoRoute } from '../../config/services-route';
import {
  getPresignedUrl,
  postLogo,
} from '../../config/services-route/uploadLogo';
// import { UploadLogoQueryParams } from '../../helpers/odata';
import { providesList } from '../../helpers/provides';
import { TagTypes } from '../types';

// import { BarChartProps } from './chart';

interface PresignedURLProps {
  fileName: string;
  contentType: string;
}

interface UpdateImageToS3Props {
  preSignedUrl: string;
  file: File;
}

const uploadLogoApi = deepDiveApi.injectEndpoints({
  endpoints: builder => ({
    getPresignedUrl: builder.query<any, PresignedURLProps>({
      query: ({ fileName, contentType }) => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: `${getPresignedUrl}?filename=${fileName}&contenttype=${contentType}`,
        };
      },
      providesTags: (response: any) => {
        return providesList(response?.data, TagTypes.UploadLogo);
      },
    }),
    updateImageToS3: builder.mutation<any, UpdateImageToS3Props>({
      query: file => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
            'Content-Type': file.file.type,
          },
          method: 'PUT',
          body: file?.file,
          url: file?.preSignedUrl,
        };
      },
      invalidatesTags: [{ type: TagTypes.UploadLogo, id: 'List' }],
    }),
    postImageS3: builder.mutation<any, any>({
      query: file => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            url: `${file.url + encodeURIComponent(file?.file?.name)} `,
            fileName: file?.file?.name,
          }),
          url: `${postLogo}`,
        };
      },
      invalidatesTags: [TagTypes.UploadLogo],
    }),
    getUserUrlLogo: builder.query<any, void>({
      query: () => ({
        url: UplodLogoRoute.getUserUrlLogo,
      }),
    }),
  }),
});

export const selectIsLoadingGlobal = (state: any) => {
  const queries = Object.values(state.deepDiveApi.queries);
  const mutations = Object.values(state.deepDiveApi.mutations);

  const isLoadingQueries = queries.some(
    (query: any) => query.status === 'pending',
  );
  const isLoadingMutations = mutations.some(
    (mutation: any) => mutation.status === 'pending',
  );

  return isLoadingQueries || isLoadingMutations;
};

export const {
  useGetPresignedUrlQuery,
  useLazyGetPresignedUrlQuery,
  useGetUserUrlLogoQuery,
  useUpdateImageToS3Mutation,
  usePostImageS3Mutation,
} = uploadLogoApi;
