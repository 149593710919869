import { deepDiveApi } from '../../config/apis/deep-dive-query';
import { PersonasRoute } from '../../config/services-route';
import { providesList } from '../../helpers/provides';
import { TagTypes } from '../types';
import { URLs } from '../../helpers/urls';
import { PersonaRename, SegmentationPersonaProps } from './personas';

export function getPersonasData() {
  return fetch(
    'https://salesbox-portal-public-assets.s3.amazonaws.com/personas/personas.json',
  )
    .then(response => response.json())

    .catch(error => {
      console.error(error);
    });
}

const personasApi = deepDiveApi.injectEndpoints({
  endpoints: builder => ({
    getAllPersonas: builder.query<SegmentationPersonaProps[], any>({
      query: ({ fileImportId }) => ({
        url: PersonasRoute.getAllPersonas,
        params: { fileImportId },
      }),
      providesTags: response => providesList(response, TagTypes.Personas),
    }),
    getTopPersonas: builder.query<SegmentationPersonaProps[], any>({
      query: ({ fileImportId }) => ({
        url: PersonasRoute.getTopPersonas,
        params: { fileImportId },
      }),
      providesTags: response => providesList(response, TagTypes.TopPersonas),
    }),
    getPersonaById: builder.query<SegmentationPersonaProps, any>({
      query: ({ personaId, fileImportId }) => ({
        url: URLs.combine(PersonasRoute.getPersonaById, `${personaId}`),
        params: { fileImportId },
      }),
    }),
    postRenamePersona: builder.mutation<PersonaRename, Partial<PersonaRename>>({
      query: body => ({
        url: PersonasRoute.postRenamePersona,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: TagTypes.TopPersonas, id: 'LIST' }],
    }),
  }),
});

export const {
  useGetAllPersonasQuery,
  useGetTopPersonasQuery,
  useGetPersonaByIdQuery,
  usePostRenamePersonaMutation,
} = personasApi;
