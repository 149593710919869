import styled from 'styled-components';

import { Tabs } from 'antd';
import GaugeImage from '../../assets/3726_124746.svg';

export const ContactSegmentsEdit = styled.div`
  width: 85.38rem;
  height: 65.88rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1.5rem;
  background-color: #fbfbfb;
`;
export const PageHeader = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 1rem;
  align-items: center;
`;
export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const ContactHeader = styled.div`
  justify-content: space-between;
  width: 100%;
  display: inline-flex;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  background-color: #ffffff;
`;
export const Column01 = styled.div`
  width: 52rem;
  display: inline-flex;
  gap: 2rem;
`;
export const ContactInfo = styled.div`
  display: inline-flex;
  gap: 1rem;
  align-items: center;
`;

export const Data = styled.div`
  display: inline-flex;
  flex-direction: column;
`;
export const Column02 = styled.div`
  width: 27.31rem;
  display: inline-flex;
  gap: 3rem;
  justify-content: flex-end;
  align-items: center;
`;
export const Column011 = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: -4rem;
  align-items: center;
`;
export const Gauge = styled.img.attrs({
  src: GaugeImage,
  heigth: 'auto',
  width: '100%',
})`
  max-width: 192px;
  position: relative;
`;

export const Text1 = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: -0.38rem;
  align-items: center;
`;
export const Column021 = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
`;
export const Buttons = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  justify-content: flex-end;
`;
export const TabGroup = styled(Tabs).attrs({
  tabBarStyle: {
    padding: '0rem 2rem 0rem 2rem',
    background: '#fff',
    border: '0.06rem solid #dadada',
    borderTop: '0px',
  },
})`
  width: 100%;
`;

export const GaugeContainer = styled.div`
  max-width: 200px;
`;

export const TabGroupPane = styled(Tabs.TabPane)`
  padding: 0 !important;
`;
