import React from 'react';
import { H5 } from '../../../../styles/fonts';
import { LineBar } from '../../../Bar/LineBar/styles';
import { DonutChart } from '../../../Charts/DonutChart/styles';

import { Content02, Row011, Data012, Data022 } from './styles';

interface DistributionData {
  key: string;
  documentCount: number;
  percent: number;
}

interface RegistrationStatusProps {
  registrationStatus: DistributionData[];
  meiOption: DistributionData[];
}

const RegistrationStatusTab = ({
  registrationStatus,
  meiOption,
}: RegistrationStatusProps) => {
  const renderNoDataMessage = () => <H5 weight="regular">Sem registros</H5>;

  const RegistrationStatusChart = () => {
    const data = registrationStatus;
    if (data.length === 0) return renderNoDataMessage();

    const config = {
      data: data.map(item => ({
        description: item.key,
        percentage: item.percent,
      })),
      xField: 'percentage',
      yField: 'description',
      barWidthRatio: 0.3,
      height: 300,
      width: 235,
      animation: false,
      meta: {
        description: { alias: 'Situação' },
        percentage: { alias: 'Porcentagem (%)' },
      },
    };
    return <LineBar {...config} />;
  };

  const MEIOptionChart = () => {
    const data = meiOption;
    if (data.length === 0) return renderNoDataMessage();

    const config = {
      data: data.map(item => ({
        type: item.key,
        value: item.percent,
      })),
      height: 300,
      width: 235,
      animation: false,
      meta: {
        type: { alias: 'Opção MEI' },
        value: { alias: 'Porcentagem (%)' },
      },
      label: {
        content: '{value}%',
      },
      statistic: {
        title: {
          offsetY: -4,
          style: { fontSize: '14px', color: '#4D4D4D' },
          formatter: () => (data.length === 0 ? 'Sem Registro' : 'Total'),
        },
      },
    };

    return <DonutChart {...config} />;
  };

  return (
    <Content02>
      <Row011>
        <Data022>
          <H5 weight="medium">Situação Cadastral</H5>
          <RegistrationStatusChart />
        </Data022>
        <Data012>
          <H5 weight="medium">Opção MEI</H5>
          <MEIOptionChart />
        </Data012>
      </Row011>
    </Content02>
  );
};

export default RegistrationStatusTab;
