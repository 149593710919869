import styled from 'styled-components';

import { Dropdown } from 'antd';

import { DownOutlined as Icon } from '@ant-design/icons';

export const Wrapper = styled(Dropdown)``;

export const Body = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  width: auto;
`;

export const DownOutlined = styled(Icon)`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  color: red;
`;
