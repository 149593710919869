import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
`;
export const LineChartSection = styled.div``;
export const PieChartSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 50%;
  padding-top: 2rem;
  align-self: center;
`;

export const PieChartRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StatisticsRow = styled.div`
  display: grid;

  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  width: 100%;
`;

export const StatisticColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StatisticCard = styled.div`
  width: 100%;
  padding: 1.75rem 1rem;
  border: 1px solid ${props => props.theme.colors.neutral.gray[5]};
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  float: left;
  width: 30%;
`;
export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
`;
