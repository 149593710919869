import React, { useEffect, useState } from 'react';

import { ColumnProps } from 'antd/lib/table';
import { useCustomTranslation } from '../../../hooks/custom-translation';

import { Content03, TableGroup1 } from './styles';
import { CustomFieldsProps } from '../../../services/contact';

interface TableGroup1Props {
  key: string;
  atributo: string;
  valor: string;
}

const CustomFields = ({ fields }: { fields?: CustomFieldsProps }) => {
  const { tContactSegmentsEdit } = useCustomTranslation('contactSegmentsEdit');
  const [customFieldKeys, setCustomFieldKeys] = useState<string[]>([]);
  const [customFieldValues, setCustomFieldValues] = useState<string[]>([]);

  const dataTableGroup1: TableGroup1Props[] = customFieldKeys.map(
    (fieldKey, index) => ({
      key: `${index}`,
      atributo: fieldKey,
      valor: customFieldValues[index] || '-',
    }),
  );

  const columnsTableGroup1: ColumnProps<any>[] = [
    {
      title: tContactSegmentsEdit('attribute'),
      dataIndex: 'atributo',
      align: 'left',
      sorter: (a, b) => a?.atributo?.length - b?.atributo?.length,
    },
    {
      title: tContactSegmentsEdit('valor'),
      dataIndex: 'valor',
      align: 'left',
      sorter: (a, b) => a?.valor?.length - b?.valor?.length,
    },
  ];

  useEffect(() => {
    if (fields?.keys && fields.values) {
      setCustomFieldKeys(fields.keys);
      setCustomFieldValues(fields.values);
    }
  }, [fields]);

  return (
    <Content03>
      <TableGroup1 dataSource={dataTableGroup1} columns={columnsTableGroup1} />
    </Content03>
  );
};

export default CustomFields;
