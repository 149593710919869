import styled from 'styled-components';

import { Tabs } from 'antd';
import pattern from '../../../assets/pattern.svg';
import GenericCodeButton from '../../../components/Button';
import GenericText from '../../../components/Text';

export const Container = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
`;
export const Sider1 = styled.div`
  width: 2.5rem;
  height: 100%;
  gap: 0.5rem;
  //background-image: url(${pattern});
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  background-color: ${props => props.theme.colors.primary[3]};
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;
export const PageHeader = styled.div`
  justify-content: space-between;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;
export const Column01 = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: -0.25rem;
`;
export const ReportType1 = styled(GenericText)`
  font-weight: 700;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.45);
`;

export const FileName1 = styled(GenericText)`
  font-weight: 500;
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.7);
`;

export const Column02 = styled.div`
  display: inline-flex;
  gap: 1rem;
  align-items: center;
`;

export const ButtonsUpdateReport = styled(GenericCodeButton)`
  align-self: end;
  height: 2.5rem;
  border-radius: 0.12rem;
`;
export const ButtonsExportReport = styled(GenericCodeButton)`
  align-self: end;
  height: 2.5rem;
  border-radius: 0.12rem;
  svg {
    color: #ffffff !important;
  }
`;

export const ButtonsButton4 = styled(GenericCodeButton)`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.12rem;
`;

export const ButtonsButton5 = styled(GenericCodeButton)`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.12rem;
`;

export const TabGroupEnrichmentDetails = styled(Tabs).attrs({
  tabBarStyle: {
    padding: '',
  },
})`
  height: 100%;
  border: 1px solid #d9d9d9;
  width: 100%;
  .ant-tabs-tab {
    margin: 16px !important;
    height: 22px;
    padding: 0 !important;
    font-weight: 400;
    font-size: 12px !important;
  }
  .ant-tabs-tab ~ .ant-tabs-tab {
    margin: 28px 16px !important;
  }
  .ant-tabs-tab-active {
    font-weight: 700;
  }
  .ant-tabs-ink-bar-animated {
    transform: translate(0, -16px);
    height: 54px !important;
  }
`;

export const TabGroupEnrichmentDetailsPane = styled(Tabs.TabPane)`
  max-height: 400px;
  overflow-y: auto;
  padding: 0 !important;
  ::-webkit-scrollbar {
    display: none !important;
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FooterSubContainer = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PerformedAnalysis = styled(GenericText)`
  font-weight: 400;
  font-size: 1rem;
  color: #00000073;
`;
