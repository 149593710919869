import styled from 'styled-components';
import pattern from '../../../../assets/pattern.svg';

export const LayoutModal = styled.div`
  width: 60rem;
  display: flex;
  background-color: #ffffff;
  box-shadow: 1.75rem 0.5rem rgba(0, 0, 0, 0.05), 1rem rgba(0, 0, 0, 0.08),
    0.38rem -0.25rem rgba(0, 0, 0, 0.12);
`;
export const Sider1 = styled.div`
  width: 2.5rem;
  min-height: 100%;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  background-color: ${props => props.theme.colors.primary[3]};
  //background-image: url(${pattern});
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;
export const PageHeader = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 1rem;
  align-items: center;
`;
export const Fields = styled.div`
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
`;
export const Row01 = styled.div`
  display: inline-flex;
  gap: 1.5rem;
`;
export const Column01 = styled.div`
  width: 26.5rem;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
`;
export const Column02 = styled.div`
  width: 26.5rem;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
`;
export const Wrapper1 = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 1rem;
`;
export const Row02 = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.5rem;
`;
export const Wrapper2 = styled.div`
  display: inline-flex;
  gap: 1.5rem;
`;
export const Column011 = styled.div`
  width: 26.5rem;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
`;
export const Column021 = styled.div`
  width: 26.5rem;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
`;
export const ActionBar = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
`;
