/* eslint-disable import/no-cycle */
import { deepDiveApi } from '../../config/apis/deep-dive-query';
import { ChartRoute } from '../../config/services-route';
import { providesList } from '../../helpers/provides';
import { TagTypes } from '../types';
import { MarketShareTopPersonasProps } from './chart';

// import { BarChartProps } from './chart';

const chartApi = deepDiveApi.injectEndpoints({
  endpoints: builder => ({
    getBarChart: builder.query<any, any>({
      query: ({ personaId, fileImportId }) => ({
        url: ChartRoute.getBarChart,
        params: { personaId, fileImportId },
      }),
      providesTags: (response: any) => {
        return providesList(response?.data, TagTypes.BarChart);
      },
    }),
    getStackedChart: builder.query<any, any>({
      query: ({ personaId, fileImportId }) => ({
        url: ChartRoute.getStackedChart,
        params: { personaId, fileImportId },
      }),
      providesTags: (response: any) => {
        return providesList(response?.data, TagTypes.BarChart);
      },
    }),
    getThreeDimChart: builder.query<any, any>({
      query: ({ fileImportId }) => ({
        url: ChartRoute.getThreeDimChart,
        params: { fileImportId },
      }),
      providesTags: (response: any) => {
        return providesList(response?.data, TagTypes.BarChart);
      },
    }),
    getMarketShareTopPersonas: builder.query<MarketShareTopPersonasProps, any>({
      query: ({ fileImportId }) => ({
        url: ChartRoute.getMarketShareTopPersonas,
        params: { fileImportId },
      }),
      providesTags: (response: any) => {
        return providesList(response?.data, TagTypes.BarChart);
      },
    }),
    getTinyChart: builder.query<any, void>({
      query: () => ({
        url: ChartRoute.getTinyChart,
      }),
      providesTags: (response: any) => {
        return providesList(response?.data, TagTypes.BarChart);
      },
    }),
    getRadarChart: builder.query<any, any>({
      query: ({ personaId, fileImportId }) => ({
        url: ChartRoute.getRadarChart,
        params: { personaId, fileImportId },
      }),
      providesTags: (response: any) => {
        return providesList(response?.data, TagTypes.BarChart);
      },
    }),
    getTreeMapChart: builder.query<any, void>({
      query: () => ({
        url: ChartRoute.getTreeMapChart,
      }),
      providesTags: (response: any) => {
        return providesList(response?.data, TagTypes.BarChart);
      },
    }),
    getHorizontalChart: builder.query<any, any>({
      query: ({ personaId, fileImportId }) => ({
        url: ChartRoute.getHorizontalChart,
        params: { personaId, fileImportId },
      }),
      providesTags: (response: any) => {
        return providesList(response?.data, TagTypes.BarChart);
      },
    }),
    getMultiAxisChart: builder.query<any, any>({
      query: ({ fileImportId }) => ({
        url: ChartRoute.getMultiAxisChart,
        params: { fileImportId },
      }),
      providesTags: (response: any) => {
        return providesList(response?.data, TagTypes.BarChart);
      },
    }),
    getGeoHashs: builder.query<any, any>({
      query: ({ fileImportId }) => ({
        url: ChartRoute.getGeoHashs,
        params: { fileImportId },
      }),
      providesTags: (response: any) => {
        return providesList(response?.data, TagTypes.BarChart);
      },
    }),
    getRfvChart: builder.query<any, any>({
      query: ({ fileId }) => ({
        url: ChartRoute.getRfvChart,
        params: { fileId },
      }),
      providesTags: (response: any) => {
        return providesList(response?.data, TagTypes.BarChart);
      },
    }),
  }),
});

export const {
  useGetBarChartQuery,
  useGetStackedChartQuery,
  useGetThreeDimChartQuery,
  useGetMarketShareTopPersonasQuery,
  useGetTinyChartQuery,
  useGetRadarChartQuery,
  useGetTreeMapChartQuery,
  useGetHorizontalChartQuery,
  useGetMultiAxisChartQuery,
  useGetGeoHashsQuery,
  useGetRfvChartQuery,
} = chartApi;
