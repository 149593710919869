import styled from 'styled-components';

import { Steps } from 'antd';
import GenericCodeButton from '../../components/Button';
import GenericText from '../../components/Text';

export const ContactImportWizard1 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1.5rem;
  background-color: #fbfbfb;
`;
export const PageHeader = styled.div`
  width: 67.5rem;
  display: inline-flex;
  gap: 1rem;
  align-items: center;
`;
export const ButtonsButton48 = styled(GenericCodeButton)`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.12rem;
`;

export const Title12 = styled(GenericText)`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: ${props => props.theme.colors.character.primary};
`;

export const Body = styled.div`
  width: 67.5rem;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
`;
export const Content1 = styled.div`
  width: 100%;
  display: inline-flex;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  padding: 1rem;
  background-color: #ffffff;
`;
export const StepsItemGroup = styled(Steps)`
  width: 100%;
  margin-bottom: 24px;
`;

export const StepsItemGroupStep = styled(Steps.Step)``;

export const StepsItemGroupStepsContent = styled.div`
  width: 100%;
`;

export const StepsItemGroupWraper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
