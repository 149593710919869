export const maskCpf = (cpf: string) => {
  if (cpf) {
    if (cpf || cpf.length === 11) {
      return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
  }

  return '-';
};

export const maskCep = (cep: string) => {
  if (cep) {
    if (cep.length === 8) {
      return cep.replace(/^(\d{5})(\d{3})/, '$1-$2');
    }
  }

  return '-';
};

export const maskPhone = (phone: string) => {
  if (phone) {
    if (phone.length >= 10 || phone.length <= 11) {
      return phone.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
  }

  return '-';
};

export const currencyMask = (value: number) => {
  if (value > 0) {
    const val = value.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });

    return val;
  }

  return '-';
};
