import styled from 'styled-components';

export const Content01 = styled.div`
  width: 100%;
  height: 22rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 2rem;
  padding-right: 1rem;
`;
export const Row01 = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
`;
export const Row02 = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
`;
export const Row03 = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 2.5rem;
  padding: 0rem 0rem 1rem 0rem;
`;
export const Data01 = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const Label = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
