import { SyncOutlined, UploadOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import { useCustomTranslation } from '../../hooks/custom-translation';
import { H3 } from '../../styles/fonts';
import { Container, PageHeader, TableImports, Wrapper } from './styles';
import { AddContactButton } from '../ContactManagement/styles';
import * as CONTANTS from '../../navigation/constants';
import ImportsTabRfv from '../../components/Imports/ImportRfv';

const ImportRfv = () => {
  const { tContactManagement } = useCustomTranslation('contactManagement');
  const [refetchImports, setRefetchImports] = useState<boolean>(false);
  const [refetchExports, setRefetchExports] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleRefetch = () => {
    setRefetchImports(true);
  };

  useEffect(() => {
    if (refetchImports) {
      setRefetchImports(false);
    }

    if (refetchExports) {
      setRefetchExports(false);
    }
  }, [refetchImports, refetchExports]);

  const handleNewImportFile = () => {
    navigate(CONTANTS.RFV_IMPORT_WIZARD);
  };

  return (
    <Container>
      <PageHeader>
        <H3 weight="medium">Análise RFV</H3>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            type="default"
            size="large"
            icon={<SyncOutlined />}
            onClick={handleRefetch}
            loading={loading}
          >
            {tContactManagement('refresh')}
          </Button>
          <AddContactButton
            type="primary"
            icon={<UploadOutlined />}
            size="large"
            onClick={() => handleNewImportFile()}
          >
            {tContactManagement('import_file')}
          </AddContactButton>
        </div>
      </PageHeader>

      <Wrapper>
        <TableImports>
          <ImportsTabRfv setLoader={setLoading} refetch />
        </TableImports>
      </Wrapper>
    </Container>
  );
};

export default ImportRfv;
