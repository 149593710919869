import styled from 'styled-components';

import { InfoCircleOutlined } from '@ant-design/icons';

export const NewCreativeText = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background: var(--neutral-gray-2, #fafafa);
`;
export const PageHeader = styled.div`
  display: flex;
  width: 1080px;
  align-items: center;
  gap: 16px;
`;
export const Body1 = styled.div`
  display: flex;
  width: 1080px;
  padding: 24px;
  align-items: flex-start;
  gap: 32px;
  border: 1px solid var(--neutral-gray-5, #d9d9d9);
  background: var(--neutral-gray-1, #fff);
`;
export const ActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
export const Result = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  background: var(--neutral-gray-2, #fafafa);
`;
export const DefaultFeedback = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;
export const IconOutlinedInfoCircle = styled(InfoCircleOutlined)`
  color: #000000;
  font-size: 1.5rem;
`;

export const GeneratingFeedback = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`;

export const GeneratedText = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: grid;
  grid-template-rows: 1.5rem 1fr 1.5rem 2.5rem;
  gap: 1rem;

  .ant-form-item-control-input,
  .ant-form-item-control-input-content {
    height: 100%;
  }

  textarea {
    width: 100%;
    height: 100%;
    padding: 8px 12px;
    border-radius: 0.125rem;
    border: 1px solid #d9d9d9;
    background: #fff;
  }
`;

export const GeneratedTextHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NavButtons = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;

  button {
    background: none;
    border: none;
    outline: none;
    font-size: 1rem;
  }
`;

export const ActionButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
`;
