import { salesBoxApi } from '../../config/apis';
import { WhatsAppPhoneRoute } from '../../config/services-route';
import { URLs } from '../../helpers/urls';
import { TagTypes } from '../types';
import { providesList } from '../../helpers/provides';
import { GetChatProps, WhatsAppPhoneProps } from './whatsAppPhone';

const whatsAppPhoneApi = salesBoxApi.injectEndpoints({
  endpoints: builder => ({
    getAllWhatsAppPhone: builder.query<WhatsAppPhoneProps[], void>({
      query: () => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            customerId: authData.customer,
          },
          url: WhatsAppPhoneRoute.whatsAppPhone,
        };
      },
      providesTags: response => providesList(response, TagTypes.WhatsAppPhone),
    }),
    getChatTokenByPhoneId: builder.query<GetChatProps, string>({
      query: id => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            customerId: authData.customer,
          },
          url: URLs.combine(WhatsAppPhoneRoute.getChatToken, `${id}`),
        };
      },
    }),
    getWhatsAppPhoneById: builder.query<WhatsAppPhoneProps, string>({
      query: id => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            customerId: authData.customer,
          },
          url: URLs.combine(WhatsAppPhoneRoute.whatsAppPhone, `${id}`),
        };
      },
    }),
    postWhatsAppPhone: builder.mutation<
      WhatsAppPhoneProps,
      Partial<WhatsAppPhoneProps>
    >({
      query: body => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            customerId: authData.customer,
          },
          url: WhatsAppPhoneRoute.whatsAppPhone,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: TagTypes.WhatsAppPhone, id: 'LIST' }],
    }),
    updateWhatsAppPhone: builder.mutation<
      WhatsAppPhoneProps,
      Partial<WhatsAppPhoneProps>
    >({
      query: ({ id, ...body }) => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            customerId: authData.customer,
          },
          url: URLs.combine(WhatsAppPhoneRoute.whatsAppPhone, `${id}`),
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [{ type: TagTypes.WhatsAppPhone, id: 'LIST' }],
    }),
    deleteWhatsAppPhone: builder.mutation<WhatsAppPhoneProps, string>({
      query: id => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            customerId: authData.customer,
          },
          url: URLs.combine(WhatsAppPhoneRoute.whatsAppPhone, `${id}`),
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: TagTypes.WhatsAppPhone, id: 'LIST' }],
    }),
    getAllWhatsAppPhoneReports: builder.query<any, any>({
      query: (whatsAppPhoneKey: any) => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            customerId: authData.customer,
          },
          url: `${WhatsAppPhoneRoute.whatsAppPhone}/report/${whatsAppPhoneKey}`,
        };
      },
      transformResponse: (response: any) => {
        const responseData = response.usage;

        console.log(responseData, 'responseWhatsapp');
        const months = [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro',
        ];

        const data: any = [];

        responseData.forEach((item: any) => {
          console.log('responseWhatsapp', {
            x: months[new Date(item.period_date.replace('Z', '')).getMonth()],
            value: item.business_initiated_quantity,
            type: 'Iniciado pela empresa',
          });
          data.push({
            x: months[new Date(item.period_date.replace('Z', '')).getMonth()],
            value: item.business_initiated_quantity,
            type: 'Iniciado pela empresa',
          });
          data.push({
            x: months[new Date(item.period_date.replace('Z', '')).getMonth()],
            value: item.user_initiated_quantity,
            type: 'Iniciado pelo cliente',
          });
        });

        const totalMessagesCount = responseData.reduce(
          (accumulator: any, currentValue: any) =>
            accumulator +
            currentValue.business_initiated_quantity +
            currentValue.user_initiated_quantity,
          0,
        );

        const totalMessagesBusinessInitiated = responseData.reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + currentValue.business_initiated_quantity,
          0,
        );

        const totalMessagesUserInitiated = responseData.reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + currentValue.user_initiated_quantity,
          0,
        );

        console.log(
          totalMessagesCount,
          totalMessagesUserInitiated,
          totalMessagesBusinessInitiated,
          'sumWithInitial',
        );

        return {
          totalMessagesCount,
          totalMessagesUserInitiated,
          totalMessagesBusinessInitiated,
          insights: data,
        };
      },
      // providesTags: response => providesList(response, TagTypes.WhatsAppPhone),
    }),
  }),
});
export const {
  useGetAllWhatsAppPhoneQuery,
  useGetChatTokenByPhoneIdQuery,
  useGetWhatsAppPhoneByIdQuery,
  usePostWhatsAppPhoneMutation,
  useUpdateWhatsAppPhoneMutation,
  useDeleteWhatsAppPhoneMutation,
  useGetAllWhatsAppPhoneReportsQuery,
} = whatsAppPhoneApi;
