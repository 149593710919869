import React from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useCustomTranslation } from '../../hooks/custom-translation';
import Button from '../../components/Button';
import { H3, H5, H1, Body as BodyText } from '../../styles/fonts';

import {
  Body,
  ContactHeader,
  Column01,
  ContactInfo,
  Data,
  Column02,
  Column011,
  Text1,
  TabGroupPane,
  TabGroup,
  GaugeContainer,
} from './styles';
import General from '../../components/ProfileTab/General';
import Enrichment from '../../components/ProfileTab/Enrichment';
import CustomFields from '../../components/ProfileTab/CustomFields';
import { Container, PageHeader } from '../../styles/contents';

import * as CONSTANTS from '../../navigation/constants';
import { useGetAllContactsFilteredQuery } from '../../services/contact';
import ProfileGaugeChart from '../../components/Contacts/Contact/ContactProfile/ProfileGaugeChart';
import { createQueryById } from '../../helpers/odata';
import ProfileAvatar from '../../components/Contacts/Contact/ContactProfile/ProfileAvatar';
import { maskPhone } from '../../helpers/masks/masksRegex';

const ContactProfile = () => {
  const { tContactSegmentsEdit } = useCustomTranslation('contactSegmentsEdit');

  const navigate = useNavigate();
  const { id } = useParams();

  const { data: enrichedContact } = useGetAllContactsFilteredQuery({
    top: 1,
    expand: ['enriched'],
    custom: createQueryById(id),
  });

  const handleContactList = () => {
    navigate(CONSTANTS.CONTACT_MANAGENENT);
  };

  return (
    <Container>
      <PageHeader>
        <Button
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={handleContactList}
        />
        <H3 weight="medium">{tContactSegmentsEdit('contact_details')}</H3>
      </PageHeader>
      <Body>
        <ContactHeader>
          <Column01>
            <ContactInfo>
              <ProfileAvatar
                gender={enrichedContact?.data[0].enriched.gender || 'I'}
              />
              <Data>
                <H3 weight="medium">
                  {enrichedContact?.data[0].enriched.name || '-'}
                </H3>
                <H5 weight="regular">
                  {enrichedContact?.data[0].enriched.emails[0] || '-'}
                </H5>
                <H5 weight="regular">
                  {maskPhone(
                    enrichedContact?.data[0].phoneNumber ||
                      (enrichedContact?.data[0].enriched
                        .phoneNumbers[0] as string),
                  ) || '-'}
                </H5>
              </Data>
            </ContactInfo>
          </Column01>
          <Column02>
            <Column011>
              <GaugeContainer>
                <ProfileGaugeChart
                  value={enrichedContact?.data[0].enriched.financialScore}
                />
              </GaugeContainer>
              <Text1>
                <H1 weight="medium">
                  {enrichedContact?.data[0].enriched.financialScore}
                </H1>
                <BodyText weight="medium">
                  {tContactSegmentsEdit('psychometric_score')}
                </BodyText>
              </Text1>
            </Column011>
          </Column02>
        </ContactHeader>
        <TabGroup tabPosition="top" type="line" size="middle">
          <TabGroupPane tab={tContactSegmentsEdit('overview')} key="0">
            <General contactInfo={enrichedContact?.data[0]} />
          </TabGroupPane>
          <TabGroupPane tab={tContactSegmentsEdit('enrichment')} key="1">
            <Enrichment contactInfo={enrichedContact?.data[0]} />
          </TabGroupPane>
          <TabGroupPane tab={tContactSegmentsEdit('custom_fields')} key="2">
            <CustomFields fields={enrichedContact?.data[0].customFields} />
          </TabGroupPane>
        </TabGroup>
      </Body>
    </Container>
  );
};

export default ContactProfile;
