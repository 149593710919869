import { useNavigate } from 'react-router-dom';
// import { useCustomTranslation } from '../../../hooks/custom-translation';
import { useEffect } from 'react';
import { useCustomTranslation } from '../../../../hooks/custom-translation';

// import * as CONSTANTS from '../../../navigation/constants';
import * as CONSTANTS from '../../../../navigation/constants';

import {
  Container,
  Body,
  Vector,
  Wrapper,
  TextTitle,
  TextSubTitle,
  ButtonFinishImport,
  Vector1,
  Vector2,
} from './styles';
import ProgressBarRfv from '../../../../components/ProgressBarRfv';
// import { useGetAllFilesImportsQuery } from '../../../../services/fileImporter';
import { useGetFileRfvQuery } from '../../../../services/rfvFileImporter';

interface ComponentsStatus {
  [key: number]: React.ComponentType<any>;
}

const FinishImport = ({ importerId, customerId }: any) => {
  const navigate = useNavigate();
  const { tImportRfv } = useCustomTranslation('importRfv');

  const handleFinishImport = () => {
    navigate(CONSTANTS.IMPORT_RFV);
  };

  const { data: file, refetch } = useGetFileRfvQuery(
    { importerId, customerId },
    {
      pollingInterval: 200,
      refetchOnFocus: false,
      skip: !importerId,
    },
  );

  const statusId = file?.importRfvStatusId;

  const conclusionPercentage = file?.conclusionPercentage;

  useEffect(() => {
    const interval = setInterval(() => {
      if (conclusionPercentage !== '100') {
        refetch();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [conclusionPercentage, refetch]);

  const componentsStatus: ComponentsStatus = {
    0: Vector,
    1: Vector1,
    2: Vector2,
    // 3: Vector2,
  };
  const messageStatus = (status: any) => {
    switch (status) {
      case 0:
      case 1:
        return tImportRfv('await_message_instruction');
      case 2:
      case 3:
        return tImportRfv('finish_message_instruction_segmentation');
      default:
        return '';
    }
  };
  const titleStatus = (status: any) => {
    switch (status) {
      case 0:
      case 1:
        return tImportRfv('base_contacts_processing');
      case 2:
      case 3:
        return tImportRfv('finish_segmentation_base_contacts');
      default:
        return tImportRfv('base_contacts_processing');
    }
  };

  // const { fileImportStatusId } = importer as FileImportProps;

  const VectorImage = componentsStatus[statusId] || Vector;
  // const VectorImage = Vector;
  return (
    <Container>
      <Body>
        <VectorImage />
        <Wrapper>
          <TextTitle>{titleStatus(statusId)}</TextTitle>
          <TextSubTitle>{messageStatus(statusId)}</TextSubTitle>
          <ButtonFinishImport
            type="primary"
            size="large"
            onClick={handleFinishImport}
          >
            {tImportRfv('back_to_import_list')}
          </ButtonFinishImport>
          <ProgressBarRfv
            fileImportStatusId={statusId}
            conclusionPercentage={conclusionPercentage}
            size="default"
            hasLabel
          />
        </Wrapper>
      </Body>
    </Container>
  );
};

export default FinishImport;
