// import { sha256 } from 'js-sha256';

export function saveFacebookToken(token: string) {
  //   const tokenEncrypted = sha256.hmac(
  //     process.env.REACT_APP_SECRET_FACEBOOK as string,
  //     token,
  //   );
  //     conms
  localStorage.setItem('facebook', token);
}

export function getFacebookToken() {
  //   const tokenEncrypted = sha256.hmac(
  //     process.env.REACT_APP_SECRET_FACEBOOK as string,
  //     token,
  //   );
  //     conms
  return localStorage.getItem('facebook');
}

export function saveRDStationToken(token: string) {
  localStorage.setItem('rd-sation', token);
}

export function getRDStationToken() {
  return localStorage.getItem('rd-sation');
}
