import styled from 'styled-components';

import { Pie } from '@ant-design/plots';

export const DonutChart = styled(Pie).attrs({
  angleField: 'value', // Campo usado para o ângulo do gráfico de pizza
  colorField: 'type', // Campo usado para definir as cores dos segmentos
  radius: 1, // Tamanho do gráfico
  innerRadius: 0.6, // Para transformar em gráfico de donuts
  interactions: [{ type: 'element-active' }], // Interação ao passar o mouse

  color: ['#1d93ff', '#122499'],

  // pieStyle: {
  //   lineWidth: 2, // Largura da borda
  //   stroke: '#9254de', // Cor da borda padrão
  // },
})``;
