import { Avatar, FemaleIcon, GenericIcon, MaleIcon } from './styles';

interface IProps {
  gender: string;
}

const ProfileAvatar = ({ gender }: IProps) => {
  if (gender === 'F') {
    return (
      <Avatar>
        <FemaleIcon />
      </Avatar>
    );
  }
  if (gender === 'M') {
    return (
      <Avatar>
        <MaleIcon />
      </Avatar>
    );
  }

  return (
    <Avatar>
      <GenericIcon />
    </Avatar>
  );
};

export default ProfileAvatar;
