/* eslint-disable max-classes-per-file */
import React from 'react';
import { useParams } from 'react-router-dom';
import { ResponsiveContainer, Treemap } from 'recharts';
import { Spin } from 'antd';
import { useGetRfvChartQuery } from '../../../services/chart';
import { CenterColumn, SpinContent } from '../ThreeDimScatter/styles';

interface CustomizedContentProps {
  // root: any;
  depth: number;
  x: number;
  y: number;
  width: number;
  height: number;
  index: number;
  colors: string[];
  name: string;
  percentage: string;
  // Outras props, se houver
}

const TreeMap = () => {
  const { id } = useParams();
  const { data: rfvData, isFetching } = useGetRfvChartQuery({
    fileId: `${id}`,
  });

  if (!isFetching && rfvData?.length) {
    // const COLORS = [
    //   '#B0F419', // CAMPEAO
    //   '#660000', // EM RISCO
    //   '#A0D42E', // CONSUMIDOR LEAL
    //   '#FFF800', // NOVOS CONSUMIDORES
    //   '#CC6868', // HIBERNANDO
    //   '#FFC000', // PRESTES A FUGIR
    //   '#881515', // ATENCAO
    //   '#D7D422', // PROMISSORES
    //   '#88EE19', // LEALDADE EM POTENCIAL
    //   '#FF9A00', // NAO PODEMOS PERDE-LOS
    // ];
    const CustomizedContent: React.FC<CustomizedContentProps> = ({
      // root,
      depth,
      x,
      y,
      width,
      height,
      index,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      colors,
      name,
      percentage,
    }) => {
      return (
        <g>
          <rect
            x={x}
            y={y}
            width={width}
            height={height}
            style={{
              fill: rfvData[index].color,
              stroke: '#fff',
              strokeWidth: 2 / (depth + 1e-10),
              strokeOpacity: 1 / (depth + 1e-10),
            }}
          />
          {depth === 1 && (
            <>
              <text
                x={x + width / 2}
                y={y + height / 2 + 7}
                textAnchor="middle"
                fill="#fff"
                fontSize={14}
              >
                {name}
              </text>
              <text
                x={x + 4}
                y={y + 18}
                fill="#fff"
                fontSize={16}
                fillOpacity={0.9}
              >
                {index + 1}
              </text>

              {/* <text
              x={x + 4}
              y={y + 18}
              fill="#fff"
              fontSize={16}
              fillOpacity={0.9}
            >
              {index + 1} - {percentage !== undefined && percentage}%
            </text> */}

              <text
                x={x + width / 2}
                y={y + height / 2 + 25} // Ajuste a posição vertical para a porcentagem
                textAnchor="middle"
                fill="#fff"
                fontSize={16}
                fillOpacity={0.9}
              >
                {percentage !== undefined && percentage}
              </text>
            </>
          )}
        </g>
      );
    };
    const totalSize = rfvData.reduce(
      (sum: any, item: any) => sum + item.size,
      0,
    );
    if (totalSize === 0) {
      return (
        <span>
          Os dados analisados não são suficientes para uma apresentação gráfica
          na análise RFV. Por favor, verifique se há mais informações relevantes
          disponíveis e ajuste o seu arquivo de importação.
        </span>
      );
    }

    return (
      <ResponsiveContainer width="100%" height={600}>
        <Treemap
          width={400}
          height={200}
          data={rfvData}
          dataKey="size"
          stroke="#fff"
          fill="#8884d8"
          content={
            <CustomizedContent
              colors={[]}
              // root={60}
              depth={0}
              x={0}
              y={0}
              width={0}
              height={0}
              index={0}
              name="aa"
              percentage=""
            />
          }
        />
      </ResponsiveContainer>
    );
  }
  return (
    <>
      <CenterColumn>
        <SpinContent>
          <Spin size="large" />
        </SpinContent>
      </CenterColumn>
    </>
  );
};
export default TreeMap;
