import styled from 'styled-components';
import GenericLineBar from '../../../Bar/LineBar';

export const Content01 = styled.div`
  // width: 27.125rem;
  height: 26.125rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem 0.5rem 1rem 0.5rem;
`;
export const Row01 = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const Data = styled.div`
  width: 8.12rem;
  display: flex;
  flex-direction: column;
`;
export const Row02 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2.5rem;
`;
export const Data011 = styled.div`
  width: 50%;
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const Label = styled.div`
  justify-content: space-between;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;

export const Data021 = styled.div`
  width: 50%;
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const LineBar = styled(GenericLineBar)``;
