import styled from 'styled-components';

interface InputContainerProps {
  status: 'error' | 'default';
}

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;

  min-height: 2rem;
  width: 100%;

  border: 1px solid
    ${props =>
      props.status === 'error'
        ? `var(--ant-error-color)`
        : props.theme.colors.neutral.gray[5]};
`;

export const Input = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-beetween;
  position: relative;

  input {
    flex: 1;
    width: 100%;
    border: 0;
    padding: 0.312rem 2rem 0.312rem 0.75rem;
  }

  span {
    position: absolute;
    right: 0.75rem;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.neutral.gray[7]};
  }
`;

export const IconContainer = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;

  border-left: 1px solid ${props => props.theme.colors.neutral.gray[5]};
`;
