/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable array-callback-return */
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { DatePicker, FormInstance } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { isArray } from 'lodash';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../components/Button';
import Form from '../../components/Form';
import FormInput from '../../components/FormInput';
import Select from '../../components/Select';
import {
  FilterFieldProps,
  useGetAllFilterCollectionsQuery,
  useGetAllFilterFieldCategoryQuery,
} from '../../services/filterFieldCategory';
import { Body } from '../../styles/fonts';
import { Body2, Filter02, IconWrapper, OrAnd01, OrAnd01None } from './styles';

interface Teste {
  group: any;
  formInstance: FormInstance<any>;
  groupOrder: 'row' | 'column';
  origin: string;
  hasSegmentatioAnalysisTab: boolean;
}

function orderListByKey(data: any, key: string, order?: string,) {
  const compareValues =
    (key: string, order = 'asc') =>
      (elemA: any, elemB: any) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!elemA.hasOwnProperty(key) || !elemB.hasOwnProperty(key)) return 0;
        const comparison = elemA[key].localeCompare(elemB[key]);
        return order === 'desc' ? comparison * -1 : comparison;
      };
  return data?.sort(compareValues(key, order));
}

const Query = ({ group, formInstance, groupOrder, origin, hasSegmentatioAnalysisTab }: Teste) => {
  const [filter, setFilter] = useState<any[]>([]);
  const [subFilter, setSubFilter] = useState<FilterFieldProps[]>([]);
  const allValues = Form.useWatch([], formInstance);

  const { id } = useParams();

  const [flagUpdate, setFlagUpdate] = useState<boolean>(false);

  const [currentGroupAndQuery, setCurrentGroupAndQuery] = useState<any>({});

  const [odataName, setOdataName] = useState<string>('');

  const [operatorId, setOperatorId] = useState<string>('');

  const [, forceUpdate] = useReducer(x => x + 1, 2);

  const { data: filterFields } = useGetAllFilterFieldCategoryQuery({ origin });

  const dynamicOptions = [
    {
      idCollection: '48c1bcad-3d2b-4cc1-a44d-dc422eb47af0',
      data: [
        { label: "Micro Empresa", value: "ME" },
        { label: "Pequena Empresa", value: "EPP" },
        { label: "Média/Grande Empresa", value: "DEMAIS" }
      ],
    },
    {
      idCollection: '12d2cbd0-52a0-44c6-993a-e150e7a60c04',
      data: [
        { label: "SC", value: "SC" },
        { label: "MT", value: "MT" },
        { label: "MS", value: "MS" },
        { label: "PI", value: "PI" },
        { label: "AM", value: "AM" },
        { label: "AC", value: "AC" },
        { label: "GO", value: "GO" },
        { label: "MG", value: "MG" },
        { label: "CE", value: "CE" },
        { label: "RO", value: "RO" },
        { label: "RS", value: "RS" },
        { label: "PB", value: "PB" },
        { label: "AL", value: "AL" },
        { label: "PR", value: "PR" },
        { label: "BA", value: "BA" },
        { label: "MA", value: "MA" },
        { label: "SE", value: "SE" },
        { label: "PA", value: "PA" },
        { label: "DF", value: "DF" },
        { label: "RJ", value: "RJ" },
        { label: "ES", value: "ES" },
        { label: "RN", value: "RN" },
        { label: "RR", value: "RR" },
        { label: "SP", value: "SP" },
        { label: "TO", value: "TO" },
        { label: "PE", value: "PE" },
        { label: "AP", value: "AP" }
      ]
    },
    {
      idCollection: 'af060ca7-2e88-4a39-a340-2364026cd9d8',
      data: [

        { label: "Não", value: "N" },
        { label: "Sim", value: "S" }

      ]
    },
    {
      idCollection: '85ab63fa-2709-47ec-8b14-485bb9c073ae',
      data: [

        { label: "Empresa Ativa", value: "ATIVA" },
        { label: "Empresa Baixada", value: "BAIXADA" },
        { label: "Empresa Suspensa", value: "SUSPENSA" },
        { label: "Empresa Nula", value: "NULA" },
        { label: "Empresa Inapta", value: "INAPTA" }

      ]
    },
    {
      idCollection: '1a0be264-3b3a-48d4-8299-55050d90aa03',
      data: [

        { label: "Não", value: "N" },
        { label: "Sim", value: "S" }

      ]
    },
    {
      idCollection: '9c23c735-2e0c-4e99-9b4f-0177184f5c48',
      data: [

        { label: "Filial", value: "0" },
        { label: "Matriz", value: "1" }

      ]
    },
    {
      idCollection: '5cb47d2a-2012-4aa1-93ad-170259f2dd0f',
      data: [

        { label: "LUCRO REAL", value: "LUCRO REAL" },
        { label: "LUCRO REAL/ARBITRADO", value: "LUCRO REAL/ARBITRADO" },
        { label: "LUCRO PRESUMIDO/REAL/ARBITRADO", value: "LUCRO PRESUMIDO/REAL/ARBITRADO" },
        { label: "LUCRO PRESUMIDO/ARBITRADO", value: "LUCRO PRESUMIDO/ARBITRADO" },
        { label: "LUCRO PRESUMIDO/REAL", value: "LUCRO PRESUMIDO/REAL" },
        { label: "ISENTA DO IRPJ", value: "ISENTA DO IRPJ" },
        { label: "LUCRO PRESUMIDO", value: "LUCRO PRESUMIDO" },
        { label: "LUCRO ARBITRADO", value: "LUCRO ARBITRADO" },
        { label: "IMUNE DO IRPJ", value: "IMUNE DO IRPJ" }

      ]
    },
  ];

  const {
    currentData: filterCollections,
    refetch,
    isSuccess,
  } = useGetAllFilterCollectionsQuery(
    {
      nameControllerOdata: odataName,
    },
    {
      skip: odataName === 'Boolean',
    },
  );

  const handleChangeOperator = (
    groupName: any,
    field: any,
    filterCollection: any,
  ) => {
    console.log('filterCollection: ', filterCollection);
    const t = filter;

    const atual = t.find(a => a.group === groupName);

    if (atual) {
      if (atual.filters[field]) {
        atual.filters[field].options = [...filterCollection?.data];
      }

      const prev2 = [...t];
      prev2[t.findIndex(a => a.group === groupName)] = atual;

      setFilter(prev2);
    }
  };

  const handleChangeOperatorInput = (e: string) => {
    forceUpdate();
    setOperatorId(e);
  };

  useEffect(() => {
    if (filterCollections && currentGroupAndQuery) {
      handleChangeOperator(
        currentGroupAndQuery?.group?.name,
        currentGroupAndQuery?.field?.name,
        filterCollections,
      );
    }
  }, [filterCollections, currentGroupAndQuery]);

  useEffect(() => {
    const subFilters: any = [];
    if (filterFields) {
      filterFields?.map(t => {
        t.filterFields.map((fil: any) => {
          return subFilters.push(fil);
        });
      });
      setSubFilter(subFilters);
    }
  }, [filterFields]);

  const handleChange = (groupName: string, e: any, index: any) => {
    const newFilter = subFilter.find(a => a.id === e);
    const currentGroup = filter.find(a => a.group === groupName);

    const filterKey = uuidv4();

    const fields = formInstance.getFieldsValue();
    const { groups } = fields;

    Object.assign(groups[group.name]?.filtersDefinition[index], {
      filterKey,
      origin:
        newFilter?.filterFieldCategoryId ===
          '733c01a1-983a-43eb-a214-fd7e0d44f14b'
          ? 'CustomField'
          : 'FilterField',
    });

    formInstance.setFieldsValue({ groups });

    const filterInUse = currentGroup?.filters[index];
    if (filterInUse) {
      const u = filter.map(fil => {
        const filCopy = Object.assign(fil);
        filCopy.filters = fil.filters.map((f: any, i: number) => {
          if (i === index) {
            return { ...newFilter, options: undefined, filterKey };
          }
          return f;
        });
        filCopy.group = groupName;
        return filCopy;
      });
      setFilter(u);
      return;
    }

    if (!currentGroup) {
      setFilter(prev => [
        ...prev,
        {
          group: groupName,
          filters: [{ ...newFilter, options: undefined, filterKey }],
        } as any,
      ]);
    } else {
      setFilter(prev => {
        const atual = prev.find(a => a.group === groupName);

        atual.filters = [
          ...prev.find(a => a.group === groupName).filters,
          { ...newFilter, options: undefined, filterKey },
        ];
        const prev2 = [...prev];
        prev2[prev.findIndex(a => a.group === groupName)] = atual;
        return prev2;
      });
    }
    setFlagUpdate(true);
  };



  const genericListSelectInputPotentialAudiency = [
    {
      key: 'e1391998-a637-41af-b2f9-f117dd4b00ca',
      name: 'E',
    },
  ];

  const genericListSelectInputAnalysisSegmentation = [
    {
      key: '07174c20-607e-46cd-b7ac-1903f3d378ed',
      name: 'E',
    },
  ];

  const genericListSelector = hasSegmentatioAnalysisTab
    ? genericListSelectInputAnalysisSegmentation
    : genericListSelectInputPotentialAudiency;

  const emptyOperators = [
    '3313266f-1888-4c5a-8dfc-2c2ac1479d12',
    '89a6ad41-683e-40a3-9480-dcbb9e61a516',
    '98e71be4-fb73-44e5-8f64-4d606e377858',
    'f641ae32-a99f-4844-86fd-b906d4a12b63',
  ];

  const getFilterValue = useCallback(
    (group: any, field: any, key: any, valuePos: any) => {
      const foo = formInstance.getFieldsValue(['groups'])?.groups[group?.name]
        ?.filtersDefinition[field.name]?.[key];

      const assertFilterOperatorId = formInstance.getFieldsValue(['groups'])
        ?.groups[group?.name]?.filtersDefinition[field.name]
        ?.assertFilterOperatorId;

      console.log(
        'isArray',
        assertFilterOperatorId,
        formInstance.getFieldsValue(['groups'])?.groups[group?.name]
          ?.filtersDefinition[field.name]?.[key],
        foo?.[0],
        foo?.[1],
        Array.isArray(foo),
        isArray(foo),
        foo,
        formInstance.getFieldsValue(['groups'])?.groups,
        formInstance.getFieldsValue(['groups'])?.groups[group?.name]
          ?.filtersDefinition[field.name]?.[key]?.[valuePos],
        formInstance.getFieldsValue(['groups'])?.groups[group?.name]
          ?.filtersDefinition[field.name]?.[key],
      );

      // trocar para o entre
      if (
        assertFilterOperatorId === 'd6697357-f63f-4e8a-a198-fccafbb2c52e' &&
        isArray(foo)
      ) {
        console.log('isArray');
        return formInstance.getFieldsValue(['groups'])?.groups[group?.name]
          ?.filtersDefinition[field.name]?.[key]?.[valuePos];
      }
      return formInstance.getFieldsValue(['groups'])?.groups[group?.name]
        ?.filtersDefinition[field.name]?.[key];
    },
    [formInstance],
  );

  const getValue = useCallback(
    (group: any, field: any, key: any) => {
      return formInstance.getFieldsValue(['groups'])?.groups[group?.name]
        ?.filtersDefinition[field.name]?.[key];
    },
    [formInstance],
  );

  console.log(formInstance.getFieldsValue(), 'formInstance.getFieldsValue');

  const handleSelectOperator = async (
    filterId: string,
    group: any,
    field: any,
  ) => {
    console.log('subFilter: ', subFilter);
    const filterSelect = subFilter.find(a => a.id === filterId);
    const fields = formInstance.getFieldsValue();
    const { groups } = fields;

    Object.assign(groups[group.name]?.filtersDefinition[field.name], {
      filterValue: id
        ? await getFilterValue(group, field, 'filterValue', 0)
        : undefined,
      filterValue2: id
        ? await getFilterValue(group, field, 'filterValue', 1)
        : undefined,
    });

    formInstance.setFieldsValue({ groups });

    const selectedOptions = dynamicOptions.find(option => {
      return option.idCollection === filterSelect?.id;
    })?.data;
    if (selectedOptions) {
      setFilter(prevFilters => {
        const groupData = prevFilters.find(f => f.group === group.name) || { group: group.name, filters: [] };

        const filterIndex = groupData.filters.findIndex((f: any) => f.filterKey === field.name && f.id === filterSelect?.id);

        if (filterIndex >= 0) {
          groupData.filters[filterIndex].options = selectedOptions;
        } else {
          groupData.filters.push({
            ...field,
            filterKey: field.name,
            id: filterSelect?.id,
            options: selectedOptions,
          });
        }

        return [...prevFilters.filter(f => f.group !== group.name), groupData];
      });

      setFlagUpdate(true);
    }
    else if (filterSelect?.collectionType) {
      setCurrentGroupAndQuery({ group, field });
      setOdataName(filterSelect?.collectionType?.name);
      refetch();
    }

    forceUpdate();
  };

  const handleRemoveQuery = (
    remove: any,
    fieldname: any,

    filterKey: any,
  ) => {
    const finalFilter = filter[0].filters.filter(
      (a: any) => a.filterKey !== filterKey,
    );

    const fields = formInstance.getFieldsValue();
    const { groups } = fields;

    if (groups[group.name]?.filtersDefinition.length > fieldname + 1) {
      Object.assign(groups[group.name]?.filtersDefinition[fieldname + 1], {
        joinFilterOperatorId: null,
      });
    }

    formInstance.setFieldsValue({ groups });

    const filterCopy = Object.assign(filter);
    filterCopy[0].filters = finalFilter;

    remove(fieldname);
    setFilter([...filterCopy]);
    forceUpdate();
  };

  const isBeetwenConnector = (group: any, field: any) => {
    const connector = filter?.find((a: any) => a.group === group.name)?.filters[
      field.name
    ]?.componentType;

    console.log('isBeetwenConnector', connector);

    return connector === 'Beetwen';
  };

  const validateFields = (
    _: RuleObject,
    values: { field1: any; field2: any },
  ) => {
    console.log(values, 'validateFields');
    const { field1, field2 } = values;
    if (field1 && field2 && field2 > field1) {
      return Promise.resolve();
    }

    return Promise.reject(
      new Error('A idade desse campo deve ser maior que o anterior'),
    );
  };

  const handleField = (groupName: any, fieldName: any) => {

    const filterItem = filter?.find((a: any) => a.group === groupName)?.filters[fieldName];
    console.log('filterItem: ', filterItem);
    const selectedOptions = dynamicOptions.find(
      option => option.idCollection === filterItem?.id,
    )?.data;
    console.log('selectedOptions: ', selectedOptions);
    console.log(
      filter?.find((a: any) => a.group === groupName)?.filters[fieldName]
        ?.componentType,
    );
    switch (
    filter?.find((a: any) => a.group === groupName)?.filters[fieldName]
      ?.componentType
    ) {
      case 'InputText':
        return (
          <FormInput
            disabled={emptyOperators.includes(
              formInstance.getFieldValue('groups')[groupName].filtersDefinition[
                fieldName
              ].assertFilterOperatorId,
            )}
          />
        );
      case 'DatePicker':
        return (
          <DatePicker
            style={{ width: '100%' }}
            format="DD-MM-YYYY"
            disabled={emptyOperators.includes(
              formInstance.getFieldValue('groups')[groupName].filtersDefinition[
                fieldName
              ].assertFilterOperatorId,
            )}
          />
        );
      case 'InputNumber':
        return (
          <FormInput.Number
            disabled={emptyOperators.includes(
              formInstance.getFieldValue('groups')[groupName].filtersDefinition[
                fieldName
              ].assertFilterOperatorId,
            )}
          />
        );

      case 'SelectMultiple':
        return (
          <Select
            mode="multiple"
            optionFilterProp="children"
            disabled={emptyOperators.includes(
              formInstance.getFieldValue('groups')[groupName].filtersDefinition[
                fieldName
              ].assertFilterOperatorId,
            )}
          >
            {
              selectedOptions
                ? selectedOptions.map((option: any) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                )) :
                odataName !== 'Boolean' &&
                orderListByKey(
                  filter?.find((a: any) => a.group === groupName).filters[
                    fieldName
                  ]?.options,
                  'name',
                )?.map((filterCol: any) => {
                  return (
                    <Select.Option
                      key={filterCol.id}
                      value={filterCol.rawName || filterCol.id}
                    >
                      {filterCol.name || filterCol.customerFileName}
                    </Select.Option>
                  );
                })}

            {odataName === 'Boolean' && (
              <>
                <Select.Option key="true" value="true">
                  Sim
                </Select.Option>
                <Select.Option key="false" value="false">
                  Não
                </Select.Option>
              </>
            )}
          </Select>
        );

      case 'Beetwen':
        return <FormInput.Number />;
      default:
        break;
    }
  };

  return (
    <Body2>
      <Form.List
        name={[group.name, 'filtersDefinition']}
        initialValue={[{ originFilterFieldId: undefined }]}
      >
        {(query, { add, remove }) => {
          return (
            <>
              {query.map((field: any) => {
                const fieldOperatorValue =
                  allValues?.groups[group.name]?.filtersDefinition[1]
                    ?.joinFilterOperatorId;
                if (subFilter.length > 0 && !flagUpdate) {
                  handleChange(
                    group.name,
                    getValue(group, field, 'originFilterFieldId'),
                    field.name,
                  );

                  handleSelectOperator(
                    getValue(group, field, 'originFilterFieldId'),
                    group,
                    field,
                  );
                }

                return (
                  <>
                    {query.length > 1 && field.name > 0 && (
                      <OrAnd01None>
                        <OrAnd01>
                          <Form.Item
                            name={[field.name, 'joinFilterOperatorId']}
                            fieldKey={[field.fieldKey, 'joinFilterOperatorId']}
                            rules={[{ required: true }]}
                            initialValue={genericListSelector[0].key}
                          >
                            <Select>
                              {genericListSelector.map(item => (
                                <Select.Option key={item.key} value={item.key}>
                                  {item.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </OrAnd01>
                      </OrAnd01None>
                    )}
                    <Filter02
                      style={{
                        flexDirection: groupOrder === 'row' ? 'row' : 'column',
                      }}
                    >
                      <Form.Item
                        name={[field.name, 'originFilterFieldId']}
                        fieldKey={[field.fieldKey, 'originFilterFieldId']}
                        style={{
                          width: groupOrder === 'row' ? '33.33%' : '100%',
                        }}
                        rules={[{ required: true }]}
                      >
                        <Select
                          onChange={e => {
                            handleChange(group.name, e, field.name);
                            handleSelectOperator(
                              getValue(group, field, 'originFilterFieldId'),
                              group,
                              field,
                            );
                          }}
                          showSearch
                          optionFilterProp="children"
                        >
                          {filterFields?.map(item => (
                            <Select.Group key={item.name} label={item.name}>
                              {item.filterFields.map((i: any) => {
                                return (
                                  <Select.Option key={i.id} value={i.id}>
                                    {i.friendlyFieldName}
                                  </Select.Option>
                                );
                              })}
                            </Select.Group>
                          ))}
                        </Select>
                      </Form.Item>
                      {getValue(group, field, 'originFilterFieldId') && (
                        <Form.Item
                          name={[field.name, 'assertFilterOperatorId']}
                          fieldKey={[field.fieldKey, 'assertFilterOperatorId']}
                          style={{
                            width: groupOrder === 'row' ? '33.33%' : '100%',
                          }}
                          rules={[{ required: true }]}
                        >
                          <Select onChange={handleChangeOperatorInput}>
                            {subFilter
                              ?.find(
                                a =>
                                  a.id ===
                                  getValue(group, field, 'originFilterFieldId'),
                              )
                              ?.operators?.map(operator => (
                                <Select.Option
                                  key={operator.filterOperator.id}
                                  value={operator.filterOperator.id}
                                >
                                  {operator.filterOperator.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      )}
                      {getValue(group, field, 'assertFilterOperatorId') && (
                        <Form.Item
                          name={[field.name, 'filterValue']}
                          fieldKey={[field.fieldKey, 'filterValue']}
                          style={{
                            width: groupOrder === 'row' ? '33.33%' : '100%',
                          }}
                          rules={[
                            {
                              required: !emptyOperators.includes(
                                formInstance.getFieldValue('groups')[group.name]
                                  .filtersDefinition[field.name]
                                  .assertFilterOperatorId,
                              ),
                            },
                          ]}
                        >
                          {handleField(group.name, field.name)}
                        </Form.Item>
                      )}
                      {isBeetwenConnector(group, field) &&
                        getValue(group, field, 'assertFilterOperatorId') && (
                          <>
                            <Body
                              style={{ marginLeft: '10px' }}
                              weight="medium"
                            >
                              e
                            </Body>
                            <Form.Item
                              name={[field.name, 'filterValue2']}
                              fieldKey={[field.fieldKey, 'filterValue2']}
                              style={{
                                width: groupOrder === 'row' ? '33.33%' : '100%',
                              }}
                              rules={[
                                {
                                  required: !emptyOperators.includes(
                                    formInstance.getFieldValue('groups')[
                                      group.name
                                    ].filtersDefinition[field.name]
                                      .assertFilterOperatorId,
                                  ),
                                },
                                {
                                  validator: (_, value) =>
                                    validateFields(_, {
                                      field1:
                                        formInstance.getFieldValue('groups')[
                                          group.name
                                        ].filtersDefinition[field.name]
                                          .filterValue,
                                      field2:
                                        formInstance.getFieldValue('groups')[
                                          group.name
                                        ].filtersDefinition[field.name]
                                          .filterValue2,
                                    }),
                                },
                              ]}
                            >
                              <FormInput.Number />
                            </Form.Item>
                          </>
                        )}
                      <>
                        {field?.name >= 0 && query.length > 1 && (
                          <IconWrapper>
                            <CloseOutlined
                              style={{ color: '#2966F5' }}
                              onClick={() =>
                                handleRemoveQuery(
                                  remove,
                                  field.name,
                                  getValue(group, field, 'filterKey'),
                                )
                              }
                            />
                          </IconWrapper>
                        )}
                      </>
                    </Filter02>
                  </>
                );
              })}
              <div style={{ width: '200' }}>
                <Button
                  type="link"
                  icon={<PlusOutlined style={{ color: '#2966F5' }} />}
                  size="large"
                  onClick={() => add()}
                >
                  <Body color="#2966F5" weight="medium">
                    Adicionar outro filtro
                  </Body>
                </Button>
              </div>
            </>
          );
        }}
      </Form.List>
    </Body2>
  );
};

export default Query;
