import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontSizeOutlined, PictureOutlined } from '@ant-design/icons';
import * as CONSTANTS from '../../../navigation/constants';
import * as S from './styles';
import { H3, H4 } from '../../../styles/fonts';
import CreativeCard from '../../../components/Creative/CreativeCard';
import Button from '../../../components/Button';
import { useCustomTranslation } from '../../../hooks/custom-translation';

interface CreativeOptions {
  id: number;
  title: string;
  description: string;
  icon: ReactNode;
  disabled: boolean;
  path: string;
}

const ChooseGenerator = () => {
  const { tCreativeGenerator } = useCustomTranslation('creativeGenerator');
  const [selectedCard, setSelectedCard] = useState<number>(0);
  const navigateTo = useNavigate();

  const options: CreativeOptions[] = [
    {
      id: 0,
      icon: <FontSizeOutlined />,
      title: 'Textos',
      description:
        'Crie textos com inteligência artificial, filtros e comunicação psicográfica',
      disabled: false,
      path: CONSTANTS.NEW_CREATIVE_TEXT,
    },
    {
      id: 1,
      icon: <PictureOutlined />,
      title: 'Imagens',
      description: 'Crie imagens com filtro e intenção',
      disabled: true,
      path: '',
    },
  ];

  return (
    <S.Container>
      <S.PageHeader>
        <H3 weight="medium">
          {tCreativeGenerator('pages.chooseGenerator.title')}
        </H3>
      </S.PageHeader>
      <S.Wrapper>
        <S.Content>
          <H4 weight="medium">
            {tCreativeGenerator('pages.chooseGenerator.subtitle')}
          </H4>
          <S.CardsContainer>
            {options.map((opt, i) => (
              <CreativeCard
                key={opt.id}
                id={opt.id}
                title={opt.title}
                description={opt.description}
                icon={opt.icon}
                selected={selectedCard}
                disabled={opt.disabled}
                onClick={() => setSelectedCard(i)}
              />
            ))}
          </S.CardsContainer>

          <Button
            type="primary"
            onClick={() => navigateTo(options[selectedCard].path)}
          >
            {tCreativeGenerator('buttons.continue')}
          </Button>
        </S.Content>
      </S.Wrapper>
    </S.Container>
  );
};

export default ChooseGenerator;
