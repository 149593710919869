import styled from 'styled-components';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import Button from '../../../../components/Button';
import { Body, Footnote } from '../../../../styles/fonts';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  background-color: ${props => props.theme.colors.neutral.gray[1]};
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 100%;
  width: 100%;
  min-height: 4rem;

  padding: 0 1.5rem;
`;

export const ContentNav = styled.div`
  display: flex;
  align-content: center;
  align-items: center;

  gap: 0.625rem;
`;

export const DropdownMenu = styled.div``;

export const ButtonAction = styled(Button)`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  padding: 0;
`;

export const MenuUnfold = styled(MenuUnfoldOutlined)`
  font-size: 1.25rem;
`;

export const MenuFold = styled(MenuFoldOutlined)`
  font-size: 1.25rem;
`;

export const ContentActionsUser = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 1rem;
`;

export const ContentUser = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  background: transparent;
  border: 0;
`;

export const ContentActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 2rem;
`;

export const InfoUser = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;

  text-align: left;
`;

export const Name = styled(Body).attrs({ weight: 'medium' })`
  font-size: 0.875rem;
`;

export const Organization = styled(Footnote)`
  color: ${props => props.theme.colors.neutral.gray[7]};
`;
