import { memo } from 'react';
import { ModalProps } from 'antd';

import { Container } from './styles';

const Modal: React.FC<ModalProps> = ({ ...props }) => {
  return <Container {...props} />;
};

export default memo(Modal);
