export type FileProps = Blob;

export interface DefaultColumnProps {
  id: number;
  name: string;
  groupType: string;
  fieldType: Record<number, string>;
}

export interface ColumnProps {
  customFields: DefaultColumnProps[];
  defaultFields: DefaultColumnProps[];
}

export interface FileImportProps {
  id: string;
  userId: string;
  customerFileName: string | undefined;
  fileFields: string[];
  internalFileName: string | null;
  path: string | null;
  fileImportStatusId: number;
  enrichmentRequests: any;
  customerId?: string;
  importedAt: Date;
  infoErrors: string[];
  isEnabled: boolean;
  createdUtc: Date;
  modifiedUtc: Date;
  status: string | null;
  customer: null;
  qualities: null;
  descriptiveAnalysis: null;
}

export interface FileImportPjProps {
  id: string;
  userId: string;
  customerFileName: string | undefined;
  fileFields: string[];
  internalFileName: string | null;
  path: string | null;
  fileImportStatusPjId: number;
  enrichmentRequests: any;
  customerId?: string;
  importedAt: Date;
  infoErrors: string[];
  isEnabled: boolean;
  createdUtc: Date;
  modifiedUtc: Date;
  status: string | null;
  customer: null;
  qualities: null;
  descriptiveAnalysis: null;
}

export const statusMap: Record<string, any> = {
  '0': 0,
  '30': 1,
  '50': 2,
  '70': 2,
  '100': 3,
};
export interface CombineFieldProps {
  fileField: string;
  equivalentField: string;
}

export interface PostCombineProps {
  fileImportId: string;
  columnsMatch: CombineFieldProps[];
}
