import React from 'react';

import { useCustomTranslation } from '../../../../hooks/custom-translation';

import { Content04, GaugeContainer, PsychometricScoreSection } from './styles';
import { Body, H1 } from '../../../../styles/fonts';
import ProfileGaugeChart from '../../../Contacts/Contact/ContactProfile/ProfileGaugeChart';

interface PsychometricScoreProps {
  psychometricScore: number;
}
const PsychometricScoreTab = ({
  psychometricScore,
}: PsychometricScoreProps) => {
  const { tModalDescriptiveAnalysis } = useCustomTranslation(
    'modalDescriptiveAnalysis',
  );

  return (
    <>
      <Content04>
        <PsychometricScoreSection>
          <GaugeContainer>
            <ProfileGaugeChart value={psychometricScore} />
          </GaugeContainer>
          <H1>{psychometricScore}</H1>
          <Body>{tModalDescriptiveAnalysis('psychometric_score_average')}</Body>
        </PsychometricScoreSection>
      </Content04>
    </>
  );
};

export default PsychometricScoreTab;
