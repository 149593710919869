import { salesBoxApi } from '../../config/apis';
import { FileExportRoute } from '../../config/services-route';
import { URLs } from '../../helpers/urls';
import { TagTypes, WithPagination } from '../types';
import { providesList } from '../../helpers/provides';
import { FileExportProps } from './fileExport';
import { builderQueryOdata, BuilderQueryProps } from '../../helpers/odata';

const fileExportApi = salesBoxApi.injectEndpoints({
  endpoints: builder => ({
    getAllFileExport: builder.query<FileExportProps[], void>({
      query: () => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          url: FileExportRoute.fileExport,
          headers: { CustomerId: authData.customer },
        };
      },
      providesTags: response => providesList(response, TagTypes.FileExport),
    }),
    getAllFileExportFiltered: builder.query<
      WithPagination<FileExportProps[]>,
      BuilderQueryProps<Partial<FileExportProps>>
    >({
      query: props => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: URLs.combineOdataUrl(
            FileExportRoute.getQueryODATA,
            builderQueryOdata<Partial<FileExportProps>>(props),
          ),
        };
      },
      providesTags: response =>
        providesList(response?.data, TagTypes.FileExport),
      transformResponse: (response: any): any => {
        return {
          data: response.value,
          meta: {
            size: response['@odata.count'],
          },
        };
      },
      // invalidatesTags: [{ type: TagTypes.FileExport, id: 'LIST' }],
    }),
    getDownloadFileExport: builder.query<
      FileExportProps,
      Pick<FileExportProps, 'id'>
    >({
      query: ({ id }) => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: URLs.combineOdataUrl(FileExportRoute.fileExport, id),
        };
      },
    }),
    getFileExportById: builder.query<FileExportProps, string>({
      query: id => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: URLs.combine(FileExportRoute.fileExport, `${id}`),
        };
      },
      providesTags: (response: any) =>
        providesList(response, TagTypes.FileExport),
    }),
    postFileExport: builder.mutation<FileExportProps, Partial<FileExportProps>>(
      {
        query: body => {
          const authData = JSON.parse(localStorage?.getItem('auth') as string);

          return {
            url: FileExportRoute.fileExport,
            method: 'POST',
            headers: { CustomerId: authData.customer },
            body,
          };
        },
        invalidatesTags: [{ type: TagTypes.FileExport, id: 'LIST' }],
      },
    ),
    updateFileExport: builder.mutation<
      FileExportProps,
      Partial<FileExportProps>
    >({
      query: ({ id, ...body }) => {
        return {
          url: URLs.combine(FileExportRoute.fileExport, `${id}`),
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: [{ type: TagTypes.FileExport, id: 'LIST' }],
    }),
    deleteFileExport: builder.mutation<FileExportProps, string>({
      query: id => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: URLs.combine(FileExportRoute.fileExport, `${id}`),
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: TagTypes.FileExport, id: 'LIST' }],
    }),
  }),
});
export const {
  useGetAllFileExportQuery,
  useGetFileExportByIdQuery,
  useGetDownloadFileExportQuery,
  useGetAllFileExportFilteredQuery,
  usePostFileExportMutation,
  useUpdateFileExportMutation,
  useDeleteFileExportMutation,
} = fileExportApi;
