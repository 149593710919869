import React, {
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useDrag } from 'react-dnd';

import { Collapse } from 'antd';
import { QuestionCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router-dom';
import { useCustomTranslation } from '../../../hooks/custom-translation';
import * as CONSTANTS from '../../../navigation/constants';
import { Body, H5 } from '../../../styles/fonts';

import { Container, Content, ContentRow } from './styles';
import Tooltip from '../../../components/Tooltip';
import { currencyMask } from '../../../helpers/masks/masksRegex';
import { getPersonasData } from '../../../services/personas/personas-service';
import { Personas } from '../../../services/personas/personas';
import { IPotentialAudienceResult } from '../../../services/map/potentialAudience';

import { SaveButton } from '../styles';
import { useGetDashboardDataQuery } from '../../../services/dashboard';

interface IPersonas {
  map_url: string;
  personas: Personas[];
}

interface IProps {
  posX: number | null;
  posY: number | null;
  closed: boolean;
  data: IPotentialAudienceResult;
  personas?: IPersonas;
  setPersonas: Dispatch<SetStateAction<IPersonas | undefined>>;
  setModalPersonaIsOpen: Dispatch<SetStateAction<boolean>>;
  handleSavePotentialAudience: any;
  // disableButton: any;
}

const SummaryResults = ({
  posX,
  posY,
  closed,
  data,
  personas,
  setPersonas,
  setModalPersonaIsOpen,
  handleSavePotentialAudience,
}: // disableButton,
IProps) => {
  const { tSummaryResults } = useCustomTranslation('summaryResults');
  const [tooltip, setTooltip] = useState<string>('');

  const [{ isDragging }, dragRef, preview] = useDrag(
    () => ({
      type: 'box',
      item: { posX, posY },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
        opacity: monitor.isDragging() ? 1 : 1,
      }),
    }),
    [posX, posY],
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  useEffect(() => {
    if (!personas) {
      getPersonasData().then((value: IPersonas) => setPersonas(value));
    }
  }, [setPersonas, personas]);

  const navigate = useNavigate();

  const { data: files } = useGetDashboardDataQuery({});

  const handleSubmit = async (dataFile: any) => {
    if (files && files.length) {
      const customerFile = files.find(
        (file: any) => file.customerType === dataFile.customerType,
      );

      const handleCustomerExport = async (customer: any) => {
        if (customer.leads > customer.maxQuantityLeads) {
          navigate(CONSTANTS.UPGRADE);
        }
        handleSavePotentialAudience();
        // await postFileExportPj({
        //   dataSourceId: fileImportId,
        //   dataSource: fileImportSource,
        //   fileExportTypeId: 1,
        //   exportMetadata: objMetadata,
        // });
      };

      if (customerFile) {
        await handleCustomerExport(customerFile);
      }
    }
  };

  useEffect(() => {
    personas?.personas.map((p: Personas) =>
      p.name === data?.segmentMostFound
        ? setTooltip(p.general.description)
        : null,
    );
  }, [data?.segmentMostFound, personas]);

  return (
    <Container
      ref={dragRef}
      posX={posX || 0}
      posY={posY || 0}
      style={{ opacity: isDragging ? 0 : 1 }}
    >
      <Collapse defaultActiveKey={closed ? '0' : '1'}>
        <Collapse.Panel
          header={<H5 weight="medium">{tSummaryResults('title')}</H5>}
          key="1"
        >
          <Content>
            <ContentRow>
              <Body>Total:</Body>
              <H5 weight="bold">
                {new Intl.NumberFormat('pt-BR').format(data?.contactsFound) ||
                  '-'}
              </H5>
            </ContentRow>
            {data?.customerType === 'PJ' ? (
              <>
                <ContentRow>
                  <Body>Porte mais encontrado:</Body>
                  <H5 weight="bold">{data.mostCommonCompanySize}</H5>
                </ContentRow>
                <ContentRow>
                  <Body>Status mais encontrado:</Body>
                  <H5 weight="bold">{data.mostCommonCompanyStatus}</H5>
                </ContentRow>
              </>
            ) : (
              <>
                <ContentRow>
                  <Body>Persona mais encontrada:</Body>
                  <H5
                    weight="bold"
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      gap: '0.5rem',
                      alignItems: 'center',
                    }}
                    onClick={() => setModalPersonaIsOpen(true)}
                  >
                    {data?.segmentMostFound || '-'}
                    {data?.segmentMostFound && (
                      <Tooltip title={tooltip}>
                        <QuestionCircleOutlined />
                      </Tooltip>
                    )}
                  </H5>
                </ContentRow>
                <ContentRow>
                  <Body>Média de idade:</Body>
                  <H5 weight="bold">{data?.averageAge || '-'}</H5>
                </ContentRow>
              </>
            )}
            {data?.customerType === 'PF' && (
              <ContentRow>
                <Body>Renda domiciliar média:</Body>
                <H5 weight="bold">
                  {data?.averageHouseholdIncome
                    ? `${currencyMask(data?.averageHouseholdIncome)}`
                    : '-'}
                </H5>
              </ContentRow>
            )}
          </Content>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <SaveButton
              type="primary"
              htmlType="submit"
              icon={<SaveOutlined />}
              onClick={() => handleSubmit(data)}
            >
              Salvar público potencial
            </SaveButton>
          </div>
        </Collapse.Panel>
      </Collapse>
    </Container>
  );
};

export default memo(SummaryResults);
