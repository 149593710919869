import { AuthServiceRoute } from '../../config/services-route';
import { URLs } from '../urls';

const redirectTo = (url: string): void => {
  window.location.href = url;
};

export const login = (pathToReturn: string): void => {
  const returnUrl = encodeURIComponent(
    URLs.combine(window.location.origin, pathToReturn),
  );
  redirectTo(`${AuthServiceRoute.login}?returnUrl=${returnUrl}`);
};

export const logout = (): void => {
  redirectTo(AuthServiceRoute.logout);
};
