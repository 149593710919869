import React from 'react';
import { each, groupBy } from '@antv/util';

import GenericStatistics from '../Statistic';
import { useCustomTranslation } from '../../hooks/custom-translation';

import {
  StatisticCard,
  StatisticsRow,
  Container,
  StackedColumn,
  StackedColumnSection,
} from './styles';
import { Content01, HasNoInsights, Wrapper } from '../OnboardReport/styles';
import { Body, H4 } from '../../styles/fonts';

const WhatsappReport = ({ whatsappReportData }: any) => {
  const { tReports } = useCustomTranslation('reports');

  const ContactsChart = () => {
    const data = whatsappReportData?.insights;

    const annotations: any[] = [];

    if (!data)
      return (
        <>
          <Body>Carregando</Body>
        </>
      );

    each(groupBy(data, 'month'), (values, k) => {
      const value = values.reduce((a: number, b: number) => a + b, 0);
      annotations.push({
        type: 'text',
        position: [k, value],
        content: `${value}`,
        style: {
          textAlign: 'center',
          fontSize: 14,
          fill: 'rgba(0,0,0,0.85)',
        },
        offsetY: -10,
      });
    });

    const config = {
      data,
      isStack: true,
      xField: 'x',
      yField: 'value',
      seriesField: 'type',
      animation: false,
      autoFit: true,
      label: {
        position: 'middle',
        layout: [
          { type: 'interval-adjust-position' },
          { type: 'interval-hide-overlap' },
          {
            type: 'adjust-color',
          },
        ],
      },
      annotations,
    };
    return <StackedColumn {...config} />;
  };

  if (!whatsappReportData) {
    return (
      <Content01>
        <HasNoInsights />
        <Wrapper>
          <H4 weight="medium">{tReports('what_do_you_want_to')}</H4>
          <Body weight="regular">
            Selecione o telefone para gerar o relatório
          </Body>
        </Wrapper>
      </Content01>
    );
  }

  return (
    <Container>
      <StatisticsRow>
        <StatisticCard>
          <GenericStatistics
            title={tReports('total_contacts')}
            value={whatsappReportData?.totalMessagesCount ?? 0}
            decimalSeparator=","
            groupSeparator="."
            valueStyle={{ color: 'rgba(0, 0, 0, 0.7)' }}
          />
        </StatisticCard>

        <StatisticCard>
          <GenericStatistics
            title={tReports('client_contacts')}
            value={whatsappReportData?.totalMessagesUserInitiated ?? 0}
            decimalSeparator=","
            groupSeparator="."
            valueStyle={{ color: 'rgba(0, 0, 0, 0.7)' }}
          />
        </StatisticCard>
        <StatisticCard>
          <GenericStatistics
            title={tReports('company_contacts')}
            value={whatsappReportData?.totalMessagesBusinessInitiated ?? 0}
            decimalSeparator=","
            groupSeparator="."
            valueStyle={{ color: 'rgba(0, 0, 0, 0.7)' }}
          />
        </StatisticCard>
      </StatisticsRow>
      <StackedColumnSection>
        <ContactsChart />
      </StackedColumnSection>
    </Container>
  );
};

export default WhatsappReport;
