import React from 'react';

import GenericStatistics from '../Statistic';
import { useCustomTranslation } from '../../hooks/custom-translation';
import {
  Container,
  LeftColumn,
  LineChartSection,
  RightColumn,
  StatisticCard,
  StatisticsRow,
} from './styles';
import Line from '../Charts/LineChart';
import { Footnote } from '../../styles/fonts';
import OnboardReport from '../OnboardReport';
import { currencyFormatter } from '../Admin/ServicePlan/ModalAddServicePlan';

const ClicksImpressionsChart = ({ data }: any) => {
  const config = {
    data,
    xField: 'day',
    yField: 'value',
    autoFit: true,
    height: 520,
    animation: false,
    seriesField: 'type',

    color: ['#ffa940', '#597ef7'],
  };
  return <Line {...config} />;
};

const FacebookReport = ({ insights, hasDataOption }: any) => {
  const { tReports } = useCustomTranslation('reports');

  return (
    <Container>
      {!insights && (
        <OnboardReport insights={!!insights} hasData={hasDataOption} />
      )}
      {insights && (
        <>
          <LeftColumn>
            <StatisticsRow>
              <StatisticCard>
                <GenericStatistics
                  title={tReports('impressions')}
                  value={insights?.summary?.impressions}
                  decimalSeparator=","
                  groupSeparator="."
                  valueStyle={{
                    color: 'rgba(0, 0, 0, 0.7)',
                    fontWeight: '500',
                  }}
                />
              </StatisticCard>
              <StatisticCard>
                <GenericStatistics
                  title={tReports('reach')}
                  value={insights?.summary?.reach}
                  decimalSeparator=","
                  groupSeparator="."
                  valueStyle={{
                    color: 'rgba(0, 0, 0, 0.7)',
                    fontWeight: '500',
                  }}
                />
              </StatisticCard>
              <StatisticCard>
                <GenericStatistics
                  title={tReports('clicks')}
                  value={insights?.summary?.clicks}
                  decimalSeparator=","
                  groupSeparator="."
                  valueStyle={{
                    color: 'rgba(0, 0, 0, 0.7)',
                    fontWeight: '500',
                  }}
                />
              </StatisticCard>
              <StatisticCard>
                <GenericStatistics
                  title={
                    <Footnote style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                      {tReports('cost_per_click')}
                    </Footnote>
                  }
                  value={currencyFormatter(insights?.summary?.costByClick)}
                  decimalSeparator=","
                  groupSeparator="."
                  valueStyle={{
                    color: 'rgba(0, 0, 0, 0.7)',
                    fontWeight: '500',
                  }}
                />
              </StatisticCard>

              <StatisticCard>
                <GenericStatistics
                  title={
                    <Footnote style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                      {tReports('ctr')}
                    </Footnote>
                  }
                  value={insights?.summary?.ctr}
                  decimalSeparator=","
                  groupSeparator="."
                  valueStyle={{
                    color: 'rgba(0, 0, 0, 0.7)',
                    fontWeight: '500',
                  }}
                />
              </StatisticCard>
              <StatisticCard>
                <GenericStatistics
                  title={tReports('total_budget')}
                  value={insights?.summary?.spend}
                  decimalSeparator=","
                  groupSeparator="."
                  valueStyle={{
                    color: 'rgba(0, 0, 0, 0.7)',
                    fontWeight: '500',
                  }}
                />
              </StatisticCard>
              <StatisticCard>
                <GenericStatistics
                  title={tReports('cpm')}
                  value={insights?.summary?.cpm}
                  decimalSeparator=","
                  groupSeparator="."
                  valueStyle={{
                    color: 'rgba(0, 0, 0, 0.7)',
                    fontWeight: '500',
                  }}
                />
              </StatisticCard>
            </StatisticsRow>
          </LeftColumn>
          <RightColumn>
            <LineChartSection>
              {insights?.graph && (
                <ClicksImpressionsChart data={insights?.graph} />
              )}
            </LineChartSection>
          </RightColumn>
        </>
      )}
    </Container>
  );
};

export default FacebookReport;
