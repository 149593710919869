import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';

import { memo } from 'react';
import Logo from '../../../../components/Logo';
import { useAuth } from '../../../../hooks/auth';

import Dropdown from '../Dropdown';
import HeaderNav from '../HeaderNav';
import UserMenu from '../UserMenu';

import {
  ShowWhenGreaterThan,
  ShowWhenLessThan,
} from '../../../../styles/breakpoints';

import { useGetCustomerByIdQuery } from '../../../../services/customer';
import {
  ButtonAction,
  Container,
  Content,
  ContentActions,
  ContentActionsUser,
  ContentNav,
  ContentUser,
  DropdownMenu,
  InfoUser,
  MenuFold,
  MenuUnfold,
  Name,
  Organization,
} from './styles';

interface HeaderProps {
  toggleMenu: () => void;
  siderCollapsed: boolean;
}

const Header: React.FC<HeaderProps> = ({ toggleMenu, siderCollapsed }) => {
  const { userInfo } = useAuth();

  const { data: customer } = useGetCustomerByIdQuery(userInfo.customer || '', {
    skip: !userInfo.customer,
  });

  return (
    <Container>
      <Content>
        <ContentNav>
          <DropdownMenu>
            <ButtonAction
              onClick={toggleMenu}
              type="text"
              icon={siderCollapsed ? <MenuUnfold /> : <MenuFold />}
            />
          </DropdownMenu>
          <Logo />
        </ContentNav>
        <ShowWhenGreaterThan size="md">
          <HeaderNav />
        </ShowWhenGreaterThan>
        <ContentActionsUser>
          <ContentActions />
          <ContentUser role="button">
            <Dropdown overlay={<UserMenu />}>
              <ShowWhenLessThan size="md">
                <Avatar icon={<UserOutlined />} />
              </ShowWhenLessThan>
              <ShowWhenGreaterThan size="md">
                <InfoUser>
                  <Name>{userInfo.name || '-'}</Name>
                  <Organization>{customer?.name || '-'}</Organization>
                </InfoUser>
              </ShowWhenGreaterThan>
            </Dropdown>
          </ContentUser>
        </ContentActionsUser>
      </Content>
    </Container>
  );
};

export default memo(Header);
