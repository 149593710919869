import {
  removePercentage,
  stringToNumber,
} from '../../../../helpers/numberFormatter';

import { useCustomTranslation } from '../../../../hooks/custom-translation';
import { Body, Footnote, H5 } from '../../../../styles/fonts';
import BarChart from '../../../Contacts/Contact/ContactProfile/BarChart';

import { Content01, Data01, Label, Row01, Row02, Row03 } from './styles';

interface GeneralTabProps {
  financialProfile: string | undefined;
  consumptionProfile: string | undefined;
  personality: {
    open_to_experiences: string | undefined;
    conscientiousness: string | undefined;
    extroverted: string | undefined;
    neuroticism: string | undefined;
    pleasantability: string | undefined;
  };
}

const General = ({
  financialProfile,
  consumptionProfile,
  personality,
}: GeneralTabProps) => {
  const { tModalPersona } = useCustomTranslation('modalPersona');

  const formatPercentage = (num: string | undefined) => {
    if (num !== undefined) {
      return stringToNumber(removePercentage(num));
    }
    return 0;
  };

  return (
    <>
      <Content01>
        <Row01>
          <H5 weight="medium">{tModalPersona('financial_profile')}</H5>
          <Body weight="regular">{financialProfile}</Body>
        </Row01>
        <Row02>
          <H5 weight="medium">{tModalPersona('consumer_profile')}</H5>
          <Body weight="regular">{consumptionProfile}</Body>
        </Row02>
        <Row03>
          <Data01>
            <H5 weight="medium">{tModalPersona('personality')}</H5>
            <Label>
              <Footnote>{tModalPersona('introvert')}</Footnote>
              <Footnote>{tModalPersona('extroverted')}</Footnote>
            </Label>
            <BarChart
              value={formatPercentage(personality?.extroverted)}
              position="left"
              backgroundBarColor="#E8ECFE"
              backgroundBarBorder="#2966F5"
              pointerBarColor="#2966F5"
              pointerBarBorder="#2966F5"
            />
            <Label>
              <Footnote>{tModalPersona('stable')}</Footnote>
              <Footnote>{tModalPersona('neuroticism')}</Footnote>
            </Label>
            <BarChart
              value={formatPercentage(personality?.neuroticism)}
              position="left"
              backgroundBarColor="#E8ECFE"
              backgroundBarBorder="#2966F5"
              pointerBarColor="#2966F5"
              pointerBarBorder="#2966F5"
            />
            <Label>
              <Footnote>{tModalPersona('skeptical')}</Footnote>
              <Footnote>{tModalPersona('pleasantability')}</Footnote>
            </Label>
            <BarChart
              value={formatPercentage(personality?.pleasantability)}
              position="left"
              backgroundBarColor="#E8ECFE"
              backgroundBarBorder="#2966F5"
              pointerBarColor="#2966F5"
              pointerBarBorder="#2966F5"
            />
            <Label>
              <Footnote>{tModalPersona('undisciplined')}</Footnote>
              <Footnote>{tModalPersona('conscientiousness')}</Footnote>
            </Label>
            <BarChart
              value={formatPercentage(personality?.conscientiousness)}
              position="left"
              backgroundBarColor="#E8ECFE"
              backgroundBarBorder="#2966F5"
              pointerBarColor="#2966F5"
              pointerBarBorder="#2966F5"
            />
            <Label>
              <Footnote>{tModalPersona('traditional')}</Footnote>
              <Footnote>{tModalPersona('open_to_experiences')}</Footnote>
            </Label>
            <BarChart
              value={formatPercentage(personality?.open_to_experiences)}
              position="left"
              backgroundBarColor="#E8ECFE"
              backgroundBarBorder="#2966F5"
              pointerBarColor="#2966F5"
              pointerBarBorder="#2966F5"
            />
          </Data01>
        </Row03>
      </Content01>
    </>
  );
};

export default General;
