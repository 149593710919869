import React, { useCallback, useEffect, useState } from 'react';
import {
  ArrowLeftOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Form } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
  NewCreativeText,
  PageHeader,
  Body1,
  ActionBar,
  Result,
  DefaultFeedback,
  IconOutlinedInfoCircle,
  GeneratingFeedback,
  GeneratedText,
  NavButtons,
  GeneratedTextHeader,
  ActionButtons,
} from './styles';
import Button from '../../../components/Button';
import { Body, H3, H5 } from '../../../styles/fonts';
import Input from '../../../components/FormInput/Input';
import { useCustomTranslation } from '../../../hooks/custom-translation';
import Select from '../../../components/Select';
import GenericInputTextArea from '../../../components/InputTextArea';
import {
  useGetAllCreativeBrandVoiceQuery,
  useGetAllCreativeFilteredQuery,
  useGetAllCreativeFunnelStageQuery,
  useGetAllCreativeSegmentQuery,
  usePostAdCreativeMutation,
  useUpdateAdCreativeMutation,
} from '../../../services/creativeGenerator';
import useOpenIa from '../../../hooks/useOpenAI';
import GeneratingIllustration from '../../../assets/writting-illustration.svg';
import { createQueryById } from '../../../helpers/odata';
import { ShowFormMessage } from '../../../helpers/message';
import getErrors from '../../../helpers/errors';
import * as CONSTANTS from '../../../navigation/constants';
import { AdCreativeProps } from '../../../services/creativeGenerator/creativeGenerator';
import { FormValidation } from '../../../components/Form';
import { Validators } from '../../../helpers/validators';

export const CreativeTextPage = () => {
  const { id } = useParams();
  const { tCreativeGenerator } = useCustomTranslation('creativeGenerator');

  const { data: adCreativeBrandVoice } = useGetAllCreativeBrandVoiceQuery();
  const { data: adCreativeFunnelStage } = useGetAllCreativeFunnelStageQuery();
  const { data: adCreativeSegment } = useGetAllCreativeSegmentQuery();

  const [generated, setGenerated] = useState<any[]>([]);
  const [generatedIndexSelected, setGeneratedIndexSelected] =
    useState<number>(0);

  const [form] = Form.useForm();

  const descText = Form.useWatch('creativeText', form);

  const navigate = useNavigate();
  const openAI = useOpenIa();

  const formValidation: FormValidation<any> = {
    creativeName: [Validators.Required()],
    creativeFunnelStage: [Validators.Required()],
    creativeBrandVoice: [Validators.Required()],
  };

  const { data: adCreative } = useGetAllCreativeFilteredQuery({
    top: 1,
    expand: ['byText'],
    custom: createQueryById(id),
  });

  const [postCreative, configPostCreative] = usePostAdCreativeMutation();
  const [updateCreative, configUpdateCreative] = useUpdateAdCreativeMutation();

  const showPostAdCreativeMessage = useCallback(() => {
    const { isSuccess, isError, error } = configPostCreative;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }
    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Criativo criado com sucesso',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });

    if (isSuccess) {
      form.resetFields();
      navigate(CONSTANTS.CREATIVE_MANAGEMENT);
    }
  }, [configPostCreative]);

  const showUpdateAdCreativeMessage = useCallback(() => {
    const { isSuccess, isError, error } = configUpdateCreative;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }
    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Criativo editado com sucesso',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });

    if (isSuccess) {
      form.resetFields();
      navigate(CONSTANTS.CREATIVE_MANAGEMENT);
    }
  }, [configUpdateCreative]);

  useEffect(showPostAdCreativeMessage, [showPostAdCreativeMessage]);

  useEffect(showUpdateAdCreativeMessage, [showUpdateAdCreativeMessage]);

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const submitPrompt = useCallback(
    async (prompt: string) => {
      await openAI.handleSubmitPrompt(prompt);
    },
    [openAI],
  );

  const handleGenerateText = useCallback(() => {
    form.validateFields().then(() => {
      if (generated.length < 10) {
        const commonInitialPhrase =
          'Considere um exemplo de anúncio ad de comunicação';

        const {
          creativeSegment,
          companyName,
          creativeBrandVoice,
          creativeFunnelStage,
          creativeText,
        } = form.getFieldsValue();

        const segment = adCreativeSegment?.find(s => s.id === creativeSegment);
        const brandVoice = adCreativeBrandVoice?.find(
          bv => bv.id === creativeBrandVoice,
        );
        const funnelStage = adCreativeFunnelStage?.find(
          fs => fs.id === creativeFunnelStage,
        );

        const prompt = `${commonInitialPhrase} ${
          segment?.genIACommandPrompt || ''
        } ${
          companyName ? `com o nome do Produto/Serviço ${companyName}` : ''
        } ${brandVoice?.genIACommandPrompt} ${
          funnelStage?.genIACommandPrompt
        } ${creativeText ? `com a descrição informada ${creativeText}` : ''}`;

        const removeEmptySpaces = prompt.replaceAll('  ', ' ');

        submitPrompt(removeEmptySpaces);
      }
    });
  }, [form, submitPrompt, generated.length]);

  const handleNavigateText = (type: 'next' | 'prev') => {
    switch (type) {
      case 'next':
        if (generatedIndexSelected === generated.length - 1) break;
        setGeneratedIndexSelected(prev => prev + 1);
        form.setFieldsValue({
          creativeName: generated[generatedIndexSelected + 1].creativeName,
          creativeSegment:
            generated[generatedIndexSelected + 1].creativeSegment,
          companyName: generated[generatedIndexSelected + 1].companyName,
          creativeBrandVoice:
            generated[generatedIndexSelected + 1].creativeBrandVoice,
          creativeFunnelStage:
            generated[generatedIndexSelected + 1].creativeFunnelStage,
          creativeText: generated[generatedIndexSelected + 1].creativeText,
          generatedText: generated[generatedIndexSelected + 1].generatedText,
        });
        break;

      case 'prev':
        if (generatedIndexSelected === 0) break;
        setGeneratedIndexSelected(prev => prev - 1);
        form.setFieldsValue({
          creativeName: generated[generatedIndexSelected - 1].creativeName,
          creativeSegment:
            generated[generatedIndexSelected - 1].creativeSegment,
          companyName: generated[generatedIndexSelected - 1].companyName,
          creativeBrandVoice:
            generated[generatedIndexSelected - 1].creativeBrandVoice,
          creativeFunnelStage:
            generated[generatedIndexSelected - 1].creativeFunnelStage,
          creativeText: generated[generatedIndexSelected - 1].creativeText,
          generatedText: generated[generatedIndexSelected - 1].generatedText,
        });
        break;

      default:
        break;
    }
  };

  const handleSaveCreative = useCallback(async () => {
    const {
      creativeName,
      creativeSegment,
      companyName,
      creativeBrandVoice,
      creativeFunnelStage,
      creativeText,
      generatedText,
    } = form.getFieldsValue();

    const creativeObj = {
      name: creativeName,
      adCreativeTypeId: 1,
      byText: {
        productName: companyName,
        adCreativeSegmentId: creativeSegment,
        adCreativeFunnelStageId: creativeFunnelStage,
        adCreativeBrandVoiceId: creativeBrandVoice,
        description: creativeText,
        generatedText:
          generatedText || generated[generatedIndexSelected].generatedText,
      },
    };

    if (adCreative?.data[0]) {
      const put = await updateCreative({ id, ...creativeObj });
      return put;
    }

    const post = await postCreative(creativeObj as AdCreativeProps);

    return post;
  }, [generated]);

  useEffect(() => {
    if (!openAI.isLoading && openAI.result.length > 0) {
      const {
        creativeName,
        creativeSegment,
        companyName,
        creativeBrandVoice,
        creativeFunnelStage,
        creativeText,
      } = form.getFieldsValue();

      form.setFieldsValue({
        generatedText: openAI.result,
      });

      setGenerated(prev => [
        ...prev,
        {
          creativeName,
          creativeSegment,
          companyName,
          creativeBrandVoice,
          creativeFunnelStage,
          creativeText,
          generatedText: openAI.result,
        },
      ]);
      setGeneratedIndexSelected(generated.length);
    }
  }, [openAI.isLoading, openAI.result]);

  useEffect(() => {
    if (adCreative && adCreative?.data.length > 0) {
      form.setFieldsValue({
        creativeName: adCreative?.data[0].name,
        creativeSegment: adCreative?.data[0].byText?.adCreativeSegmentId,
        companyName: adCreative?.data[0].byText?.productName,
        creativeBrandVoice: adCreative?.data[0].byText?.adCreativeBrandVoiceId,
        creativeFunnelStage:
          adCreative?.data[0].byText?.adCreativeFunnelStageId,
        creativeText: adCreative?.data[0].byText?.description,
        generatedText: adCreative?.data[0].byText?.generatedText,
      });
      setGenerated([
        {
          creativeName: adCreative?.data[0].name,
          creativeSegment: adCreative?.data[0].byText?.adCreativeSegmentId,
          companyName: adCreative?.data[0].byText?.productName,
          creativeBrandVoice:
            adCreative?.data[0].byText?.adCreativeBrandVoiceId,
          creativeFunnelStage:
            adCreative?.data[0].byText?.adCreativeFunnelStageId,
          creativeText: adCreative?.data[0].byText?.description,
          generatedText: adCreative?.data[0].byText?.generatedText,
        },
      ]);
    }
  }, [adCreative]);

  return (
    <>
      <NewCreativeText>
        <PageHeader>
          <Button
            type="text"
            icon={<ArrowLeftOutlined />}
            onClick={handleGoBack}
          />
          <H3 weight="medium" color="rgba(0, 0, 0, 0.7) ">
            {adCreative?.data[0]
              ? 'Editar criativo'
              : tCreativeGenerator(
                  'pages.textCreativeGenerator.new_creative_in_text',
                )}
          </H3>
        </PageHeader>
        <Body1>
          <Form
            style={{
              display: 'flex',
              width: '500px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '16px',
              flexShrink: '0',
            }}
            layout="vertical"
            form={form}
          >
            <Form.Item
              label={
                <Body>
                  {tCreativeGenerator(
                    'pages.textCreativeGenerator.what_is_the_name_of',
                  )}
                </Body>
              }
              name="creativeName"
              rules={formValidation.creativeName}
              style={{
                width: '100%',
              }}
            >
              <Input
                size="large"
                placeholder={tCreativeGenerator(
                  'pages.textCreativeGenerator.institutional_text',
                )}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              label={
                <Body>
                  {tCreativeGenerator(
                    'pages.textCreativeGenerator.product_name',
                  )}
                </Body>
              }
              name="companyName"
              style={{
                width: '100%',
              }}
            >
              <Input
                size="large"
                placeholder={tCreativeGenerator(
                  'pages.textCreativeGenerator.beatrix',
                )}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              label={
                <Body>
                  {tCreativeGenerator(
                    'pages.textCreativeGenerator.what_segment_do_you_want',
                  )}
                </Body>
              }
              name="creativeSegment"
              style={{
                width: '100%',
              }}
            >
              <Select
                allowClear
                style={{}}
                size="large"
                placeholder={tCreativeGenerator(
                  'pages.textCreativeGenerator.innovators',
                )}
              >
                {adCreativeSegment?.map(
                  option =>
                    !option.isDeleted && (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    ),
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <Body>
                  {tCreativeGenerator(
                    'pages.textCreativeGenerator.at_what_stage_of_the',
                  )}
                </Body>
              }
              name="creativeFunnelStage"
              rules={formValidation.creativeFunnelStage}
              style={{
                width: '100%',
              }}
            >
              <Select
                allowClear
                style={{}}
                size="large"
                placeholder={tCreativeGenerator(
                  'pages.textCreativeGenerator.knowledge',
                )}
              >
                {adCreativeFunnelStage?.map(
                  option =>
                    !option.isDeleted && (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    ),
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <Body>
                  {tCreativeGenerator(
                    'pages.textCreativeGenerator.what_is_the_brands_vox',
                  )}
                </Body>
              }
              name="creativeBrandVoice"
              rules={formValidation.creativeBrandVoice}
              style={{
                width: '100%',
              }}
            >
              <Select
                allowClear
                style={{}}
                size="large"
                placeholder={tCreativeGenerator(
                  'pages.textCreativeGenerator.informal',
                )}
              >
                {adCreativeBrandVoice?.map(
                  option =>
                    !option.isDeleted && (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    ),
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <Body>
                  {tCreativeGenerator(
                    'pages.textCreativeGenerator.describe_the_text_you_want',
                  )}
                </Body>
              }
              name="creativeText"
              rules={[
                {
                  min: descText?.length > 0 ? 10 : 0,
                  message: 'A descrição deve conter no mínimo 10 caracteres',
                },
              ]}
              style={{
                width: '100%',
              }}
            >
              <GenericInputTextArea
                size="large"
                placeholder={tCreativeGenerator(
                  'pages.textCreativeGenerator.make_an_announcement_presenting_the',
                )}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <ActionBar>
              <Button
                type={generated.length > 0 ? 'default' : 'primary'}
                size="large"
                onClick={() => handleGenerateText()}
                disabled={generated.length >= 10 || openAI.isLoading}
                loading={openAI.isLoading}
              >
                {generated.length > 0 ? 'Gerar novamente' : 'Gerar texto'}
              </Button>
            </ActionBar>
          </Form>
          <Result>
            {openAI.isLoading && (
              <GeneratingFeedback>
                <img src={GeneratingIllustration} alt="Generating text" />
                <Body
                  weight="regular"
                  color="rgba(0, 0, 0, 0.45) "
                  style={{ flexGrow: 1 }}
                >
                  {tCreativeGenerator('pages.textCreativeGenerator.generating')}
                </Body>
              </GeneratingFeedback>
            )}
            {!openAI.isLoading && generated.length === 0 && (
              <DefaultFeedback>
                <IconOutlinedInfoCircle />
                <Body
                  weight="regular"
                  color="rgba(0, 0, 0, 0.45) "
                  style={{ flexGrow: 1 }}
                >
                  {tCreativeGenerator(
                    'pages.textCreativeGenerator.to_have_your_text_created,',
                  )}
                </Body>
              </DefaultFeedback>
            )}
            {!openAI.isLoading && generated.length > 0 && (
              <GeneratedText>
                <GeneratedTextHeader>
                  <H5>Texto gerado:</H5>
                </GeneratedTextHeader>
                <Form form={form}>
                  <Form.Item
                    name="generatedText"
                    style={{ width: '100%', height: '100%' }}
                  >
                    <textarea />
                  </Form.Item>
                </Form>
                <NavButtons>
                  <button
                    type="button"
                    onClick={() => handleNavigateText('prev')}
                  >
                    <LeftOutlined />
                  </button>
                  <span>{`${generatedIndexSelected + 1} de ${
                    generated.length
                  }`}</span>
                  <button
                    type="button"
                    onClick={() => handleNavigateText('next')}
                  >
                    <RightOutlined />
                  </button>
                </NavButtons>
                <ActionButtons>
                  <Button
                    type="default"
                    size="large"
                    onClick={() => setGenerated([])}
                  >
                    Limpar
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => handleSaveCreative()}
                  >
                    Salvar
                  </Button>
                </ActionButtons>
              </GeneratedText>
            )}
          </Result>
        </Body1>
      </NewCreativeText>
    </>
  );
};

export default CreativeTextPage;
