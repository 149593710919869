import styled from 'styled-components';
import pattern from '../../../assets/pattern.svg';
import Form from '../../../components/Form';
import Modal from '../../../components/Modal';

export const ModalSave = styled(Modal)`
  top: 50%;
  translate: 0 -50%;
`;

export const ModalContainer = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
`;

export const ModalPatternBar = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: ${props => props.theme.colors.primary[3]};
  //background-image: url(${pattern});
`;

export const ModalContent = styled.div`
  width: 100%;
  padding: 1.5rem;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  main {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }

  footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;

    button {
      padding: 0.5rem 1rem;
      height: fit-content;
    }
  }
`;

export const SaveForm = styled(Form)``;

export const FormItem = styled(Form.Item)`
  display: block;
`;
