import { CloseOutlined } from '@ant-design/icons';
import { Dispatch, memo, SetStateAction, useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/Button';
import Form from '../../../components/Form';
import Input from '../../../components/FormInput/Input';
import getErrors from '../../../helpers/errors';
import { ShowFormMessage } from '../../../helpers/message';
import { MAP } from '../../../navigation/constants';
import {
  IFilters,
  PotentialAudienceProps,
  usePostSavePotentialAudienceMutation,
  useUpdatePotentialAudienceMutation,
} from '../../../services/map/potentialAudience';
import { Body, H4 } from '../../../styles/fonts';
import {
  FormItem,
  ModalContainer,
  ModalContent,
  ModalPatternBar,
  ModalSave,
  SaveForm,
} from './styles';

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  filters?: IFilters;
}

const ModalSavePotentialAudience = ({
  visible,
  setVisible,
  filters,
}: IProps) => {
  const [form] = Form.useForm();

  const { id } = useParams();
  const { pathname } = useLocation();

  const [postSavePotentialAudience, configSavePotentialAudience] =
    usePostSavePotentialAudienceMutation();

  const [updatePotentialAudience, configUpdatePotentialAudience] =
    useUpdatePotentialAudienceMutation();

  const navigate = useNavigate();

  const handleSubmit = async (data: PotentialAudienceProps) => {
    if (filters) {
      const newObj = filters;
      newObj.name = data.name;

      if (!id) {
        await postSavePotentialAudience(newObj);
      } else {
        if (pathname.includes('segmentation-analysis')) {
          await postSavePotentialAudience(newObj);
        }
        await updatePotentialAudience({ id, ...newObj });
      }
    }
  };

  const showMessagePostSavePotentialAudience = useCallback(() => {
    const { isSuccess, isError, error } = configSavePotentialAudience;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }
    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Público potencial adicionado com sucesso.',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
    if (isSuccess) {
      setVisible(false);
      form.resetFields();
    }
  }, [configSavePotentialAudience, form, setVisible]);

  useEffect(showMessagePostSavePotentialAudience, [
    showMessagePostSavePotentialAudience,
  ]);

  const showMessageUpdatePotentialAudience = useCallback(() => {
    const { isSuccess, isError, error } = configUpdatePotentialAudience;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }
    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Público potencial editado com sucesso.',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
    if (isSuccess) {
      setVisible(false);
      form.resetFields();
    }
  }, [configUpdatePotentialAudience, form, setVisible]);

  useEffect(showMessageUpdatePotentialAudience, [
    showMessageUpdatePotentialAudience,
  ]);

  useEffect(() => {
    const { isSuccess: saveSuccess } = configSavePotentialAudience;
    const { isSuccess: updateSuccess } = configUpdatePotentialAudience;

    if (saveSuccess || updateSuccess) {
      navigate(MAP);
    }
  }, [configSavePotentialAudience, configUpdatePotentialAudience]);

  return (
    <ModalSave
      visible={visible}
      title={null}
      footer={null}
      closable={false}
      width="35rem"
    >
      <ModalContainer>
        <ModalPatternBar />
        <ModalContent>
          <SaveForm
            form={form}
            layout="vertical"
            onFinish={data => handleSubmit(data)}
            initialValues={{ name: filters?.name }}
          >
            <header>
              <H4>Salvar público potencial</H4>
              <CloseOutlined onClick={() => setVisible(!visible)} />
            </header>
            <main>
              <FormItem label={<Body>Nome</Body>} name="name">
                <Input size="large" style={{ width: '100%' }} />
              </FormItem>
            </main>
            <footer>
              <Button onClick={() => setVisible(!visible)}>Cancelar</Button>
              <Button type="primary" htmlType="submit">
                Salvar e finalizar
              </Button>
            </footer>
          </SaveForm>
        </ModalContent>
      </ModalContainer>
    </ModalSave>
  );
};

export default memo(ModalSavePotentialAudience);
