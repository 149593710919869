import { salesBoxApi } from '../../config/apis';
import { FilterFieldCategoryRoute } from '../../config/services-route';
import { URLs } from '../../helpers/urls';
import { TagTypes, WithPagination } from '../types';
import { providesList } from '../../helpers/provides';
import { FilterFieldCategoryProps } from './filterFieldCategory';

const filterFieldCategoryApi = salesBoxApi.injectEndpoints({
  endpoints: builder => ({
    getAllFilterFieldCategory: builder.query<
      FilterFieldCategoryProps[],
      { origin: string }
    >({
      query: ({ origin }) => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: `${FilterFieldCategoryRoute.filterFieldCategory}?origin=${origin}`,
        };
      },
      providesTags: response =>
        providesList(response, TagTypes.FilterFieldCategory),
    }),
    getAllFilterCollections: builder.query<
      WithPagination<any>,
      { nameControllerOdata: string }
    >({
      query: props => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: URLs.combineOdataUrl(
            `${FilterFieldCategoryRoute.getWithODATA}${props.nameControllerOdata}`,
            `${
              props.nameControllerOdata === 'FileImportSearchQuery'
                ? `?&$count=true&$expand=enrichmentRequests&$filter=enrichmentRequests/any() and enrichmentRequests/all(er: er/contactEnrichmentRequestStatusId eq 3)`
                : `?&$count=true`
            }`,
          ),
        };
      },
      transformResponse: (response: any): WithPagination<any[]> => {
        return {
          data: response.value,
          meta: {
            size: response['@odata.count'],
          },
        };
      },
    }),
    getFilterFieldCategoryById: builder.query<FilterFieldCategoryProps, string>(
      {
        query: id => ({
          url: URLs.combine(
            FilterFieldCategoryRoute.filterFieldCategory,
            `${id}`,
          ),
        }),
      },
    ),
    postFilterFieldCategory: builder.mutation<
      FilterFieldCategoryProps,
      Omit<FilterFieldCategoryProps, 'id'>
    >({
      query: body => ({
        url: FilterFieldCategoryRoute.filterFieldCategory,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: TagTypes.FilterFieldCategory, id: 'LIST' }],
    }),
    updateFilterFieldCategory: builder.mutation<
      FilterFieldCategoryProps,
      Partial<FilterFieldCategoryProps>
    >({
      query: ({ id, ...body }) => {
        return {
          url: URLs.combine(
            FilterFieldCategoryRoute.filterFieldCategory,
            `${id}`,
          ),
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [{ type: TagTypes.FilterFieldCategory, id: 'LIST' }],
    }),
    deleteFilterFieldCategory: builder.mutation<
      FilterFieldCategoryProps,
      string
    >({
      query: id => {
        return {
          url: URLs.combine(
            FilterFieldCategoryRoute.filterFieldCategory,
            `${id}`,
          ),
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: TagTypes.FilterFieldCategory, id: 'LIST' }],
    }),
  }),
});
export const {
  useGetAllFilterFieldCategoryQuery,
  useGetFilterFieldCategoryByIdQuery,
  usePostFilterFieldCategoryMutation,
  useUpdateFilterFieldCategoryMutation,
  useDeleteFilterFieldCategoryMutation,
  useGetAllFilterCollectionsQuery,
} = filterFieldCategoryApi;
