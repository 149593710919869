import styled from 'styled-components';
import { Typography } from 'antd';

export const GenericTextStyle = styled(Typography.Text)`
  font-family: IBM Plex Sans;
  font-weight: 700;
  font-size: 0.75rem;
  color: ${props => props.theme.colors.character.primary};
  text-align: left;
`;
