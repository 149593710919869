import { salesBoxApi } from '../../config/apis';
import { AuthServiceRoute } from '../../config/services-route';
import { AuthInfo } from './auth';

const authApi = salesBoxApi.injectEndpoints({
  endpoints: builder => ({
    getInfo: builder.query<AuthInfo, void>({
      keepUnusedDataFor: 0,
      query: () => AuthServiceRoute.info,
    }),
  }),
  overrideExisting: true,
});

export const { useGetInfoQuery } = authApi;
