import styled from 'styled-components';

export const Container = styled.ul`
  position: relative;
  margin: 0;

  background-color: ${props => props.theme.colors.neutral.gray[1]};
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);

  padding: 0.25rem 0.75rem;
  text-align: left;
  border-radius: 2px;

  min-width: 5rem;
`;

export const Item = styled.li`
  display: flex;

  min-height: 2rem;
  max-height: 2rem;

  > a {
    width: 100%;
    height: 2rem;
    padding: 0.5rem 0;
    color: ${props => props.theme.colors.character.primary};
  }
  > button {
    width: 100%;
  }
`;
