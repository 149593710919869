/* eslint-disable react-hooks/exhaustive-deps */
import { Progress } from 'antd';
import { ProgressSize } from 'antd/lib/progress/progress';
import React, { useEffect, useState } from 'react';
import { H5 } from '../../styles/fonts';

// import { Container } from './styles';

interface ProgressBarProps {
  fileImportStatusId: number;
  conclusionPercentage: number;
  size: ProgressSize;
  hasLabel?: boolean;
}

const ProgressBarRfv = ({
  fileImportStatusId,
  conclusionPercentage,
  size,
  hasLabel,
}: ProgressBarProps) => {
  const [percent, setPercent] = useState(0);
  const [label, setLabel] = useState('');

  const handleProgress = () => {
    setPercent(conclusionPercentage);

    switch (fileImportStatusId) {
      case 0:
        // setPercent(25);
        setLabel('Iniciando');
        break;
      case 1:
        setLabel('Processando Dados de RFV');
        break;
      case 2:
        setLabel('Processamento concluído com sucesso!');
        break;
      case 3:
        setLabel('Ocorreu um erro inesperado, tente mais tarde.');
        break;

      default:
    }
  };

  useEffect(() => {
    handleProgress();
  }, [fileImportStatusId, handleProgress]);

  return (
    <>
      <Progress percent={percent} size={size} status="active" />
      {hasLabel && <H5>{label}</H5>}
    </>
  );
};

export default ProgressBarRfv;
