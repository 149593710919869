import styled from 'styled-components';

import VectorImage from '../../../../assets/finish-wizard-image.svg';
import VectorImage2 from '../../../../assets/middle-wizard-image.svg';
import VectorImage3 from '../../../../assets/finish-enrichment-data-image.svg';
import GenericText from '../../../../components/Text';
import GenericCodeButton from '../../../../components/Button';

export const Container = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 3.5rem;
  justify-content: center;
  align-items: center;
`;
export const Body = styled.div`
  height: 100%;
  max-width: 546px;
  display: inline-flex;
  gap: 2rem;
  align-items: center;
  padding: 2rem 0rem 2rem 0rem;
`;
export const Vector = styled.img.attrs({
  src: VectorImage,
  heigth: 'auto',
  width: '100%',
})`
  max-width: 197.78px;
  position: relative;
`;

export const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
`;
export const TextTitle = styled(GenericText)`
  font-size: 1.25rem;
  flex: 1;
  font-weight: 500;
  color: ${props => props.theme.colors.character.primary};
`;

export const TextSubTitle = styled(GenericText)`
  font-weight: 400;
  font-size: 0.88rem;
  font-size: 14px;
  color: ${props => props.theme.colors.character.primary};
`;

export const ButtonFinishImport = styled(GenericCodeButton)`
  width: 20rem;
  height: 2.5rem;
  border-radius: 0.12rem;
`;

export const Vector1 = styled.img.attrs({
  src: VectorImage2,
  heigth: 'auto',
  width: '100%',
})`
  max-width: 197.78px;
  position: relative;
`;

export const Vector2 = styled.img.attrs({
  src: VectorImage3,
  heigth: 'auto',
  width: '100%',
})`
  max-width: 197.78px;
  position: relative;
`;
