/* eslint-disable prettier/prettier */
import { TFunction, useTranslation } from 'react-i18next';

import * as ptBR from '../i18n/ptBR';

// eslint-disable-next-line @typescript-eslint/ban-types
type DataIds<Data> = Data extends object
  ? {
      [Key in keyof Data]: DotJoin<Key, DataIds<Data[Key]>>;
    }[keyof Data]
  : '';

type DotJoin<FirstKey, SecondKey> = FirstKey extends string
  ? SecondKey extends string
    ? `${FirstKey}${SecondKey extends '' ? '' : '.'}${SecondKey}`
    : never
  : never;

type ResourceNamespace = typeof ptBR;
export type ResourceNamespaceKeys = keyof ResourceNamespace;
type TranslateKey<Key extends ResourceNamespaceKeys> = `t${Capitalize<Key>}`;

type NSObject<T extends ResourceNamespaceKeys> = Record<
  TranslateKey<T>,
  (key: DataIds<typeof ptBR[T]>, config?: Record<string, unknown>) => string
>;

function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

function capitalize(value: string) {
  return value.split(' ').map(capitalizeFirstLetter);
}

function getTranslates<NS extends ResourceNamespaceKeys[]>(
  translate: TFunction,
  ...ns: NS
) {
  return ns.reduce(
    (object, nsKey) => ({
      ...object,
      [`t${capitalize(nsKey)}`]: (
        key: string,
        config: Record<string, unknown>,
      ) => translate(key, { ns: nsKey, ...config }),
    }),
    {},
  ) as NSObject<NS[number]>;
}

export function useCustomTranslation<
  T extends ResourceNamespaceKeys,
  NS extends ResourceNamespaceKeys[],
>(nsFirst: T, ...ns: NS) {
  const { t } = useTranslation(ns);

  return {
    ...getTranslates(t, nsFirst, ...ns),
  };
}
