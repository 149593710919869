/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { memo, useCallback, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  KeyOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';

import Button from '../../../../components/Button';

import { useCustomTranslation } from '../../../../hooks/custom-translation';

import { Container, Item } from './styles';

import { useAuth } from '../../../../hooks/auth';
import * as CONSTANTS from '../../../../navigation/constants';
// import { useLogoutQuery } from '../../../../services/auth';
import ImportLogoModal from '../../../../components/ImportLogo';
import PasswordChangeModal from '../../../../components/PasswordChangeModal';
import { Auth } from '../../../../helpers/auth';

const UserMenu: React.FC = () => {
  const { tCommonWords, tSideNav } = useCustomTranslation(
    'commonWords',
    'sideNav',
  );
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { userInfo } = useAuth();

  // const { data: logout } = useLogoutQuery(undefined, { skip: !logoutTrigger });

  const ITEM_ACTIONS = useMemo(() => {
    return [
      {
        Icon: SettingOutlined,
        label: tSideNav('settings'),
        key: 'settings',
        onClick: CONSTANTS.SYSTEM_SETTINGS,
        roles: ['Revenda', 'Admin'],
      },
      {
        Icon: UserSwitchOutlined,
        label: tSideNav('change_customer'),
        key: 'changeCustomer',
        onClick: CONSTANTS.ROOT,
        roles: ['Revenda'],
      },
      {
        Icon: UserSwitchOutlined,
        label: tSideNav('integrations'),
        key: 'integrations',
        onClick: CONSTANTS.INTEGRATIONS,
        roles: ['Cliente', 'Revenda'],
      },
      {
        Icon: KeyOutlined,
        label: tSideNav('change_password'),
        key: 'changePassword',
        onClick: () => setIsModalOpen(true),
        roles: ['Cliente', 'Revenda', 'Admin'],
      },
      {
        Icon: LogoutOutlined,
        label: tCommonWords('logout'),
        key: 'logout',
        color: 'danger',
        onClick: () => Auth.logout(),
        roles: ['Cliente', 'Admin', 'Revenda'],
      },
    ];
  }, [tCommonWords, tSideNav]);

  const handleClickItem = useCallback(
    (action: string | (() => void)) => {
      if (typeof action === 'string') {
        navigate(action);
        return;
      }
      action();
    },
    [navigate],
  );

  return (
    <Container>
      <ImportLogoModal />
      {ITEM_ACTIONS.map(({ Icon, ...item }) => {
        if (item.roles?.includes(userInfo.roles[0])) {
          return (
            <React.Fragment key={item.key}>
              {item.key === 'changeCustomer' && <Item type="divider" />}
              <Item color={item?.color as 'danger'}>
                <Button
                  type="text"
                  icon={<Icon />}
                  onClick={() => handleClickItem(item.onClick)}
                >
                  {item.label}
                </Button>
              </Item>
            </React.Fragment>
          );
        }
      })}
      <PasswordChangeModal
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => setIsModalOpen(false)}
      />
    </Container>
  );
};

export default memo(UserMenu);
