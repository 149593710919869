import { FilterFilled } from '@ant-design/icons';
import styled from 'styled-components';

interface Props {
  isFiltered: boolean;
}

export const Icon = styled(FilterFilled)<Props>`
  color: ${props =>
    props.isFiltered ? props.theme.colors.goldenPurple[6] : ''};
`;
