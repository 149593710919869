import { salesBoxApi } from '../../config/apis';
import { descriptiveAnalysis } from '../../config/services-route/descriptiveAnalysis';

import { DescriptiveAnalysisProps } from './descriptiveAnalysis';

const descriptiveAnalysisApi = salesBoxApi.injectEndpoints({
  endpoints: builder => ({
    getAllDescriptiveAnalysis: builder.query<DescriptiveAnalysisProps, any>({
      query: ({ origin }) => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: `${descriptiveAnalysis}?origin=${origin}`,
        };
      },
    }),

    getAllDescriptiveAnalysisByOriginId: builder.query<
      DescriptiveAnalysisProps,
      any
    >({
      query: ({ origin, originId }) => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: `${descriptiveAnalysis}?origin=${origin}&originId=${originId}`,
        };
      },
    }),

    updateSegmentationAnalysis: builder.mutation<
      Partial<DescriptiveAnalysisProps>,
      { originId: string; origin: string }
    >({
      query: body => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: descriptiveAnalysis,
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const {
  useGetAllDescriptiveAnalysisQuery,
  useGetAllDescriptiveAnalysisByOriginIdQuery,
  useUpdateSegmentationAnalysisMutation,
} = descriptiveAnalysisApi;
