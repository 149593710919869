import styled from 'styled-components';
import Column from '../../../Bar/ColumnBar';

/* import Frame22831Image from '../../../assets/2080_142614.svg' 
import Frame22821Image from '../../../assets/2080_142621.svg' 
import Bars4Image from '../../../assets/2080_142628.svg'  */

export const Content04 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem 2rem 1rem 2rem;
`;

export const ColumnBarTab = styled(Column).attrs({
  color: '#2966F5',
  columnStyle: {
    stroke: '#2966F5',
  },
  xAxis: {
    label: {
      autoHide: true,
      autoRotate: false,
      rotate: 6,
      offsetY: 5,
      style: {
        fill: '#000',
      },
    },
  },
  yAxis: {
    label: {
      style: {
        fill: '#000',
      },
    },
  },
})``;

export const Row019 = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 2.5rem;
`;
export const Data013 = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
`;
