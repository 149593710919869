import React, { useState, useEffect, useCallback } from 'react';
import { CloseOutlined } from '@ant-design/icons';

import { Spin } from 'antd';
import { useCustomTranslation } from '../../hooks/custom-translation';

import Modal from '../Modal';

// import IncomeBracketsTab, {
//   DemographicPartner,
// } from './TabGroup/IncomeBrackets';
import { Personas } from '../../services/personas';
import { getPersonasData } from '../../services/personas/personas-service';

import {
  ModalImportDetails,
  Sider1,
  Wrapper,
  PageHeader,
  Column01,
  Column02,
  Body1,
  TabGroupPane,
  TabGroup,
  ActionBar,
  Buttons,
  Button,
  ModalTabTitle,
  // UpdateAnalysisButton,
} from './styles';
import { usePostFileExportMutation } from '../../services/fileExport/file-export-service';
import { useUpdateSegmentationAnalysisMutation } from '../../services/descriptiveAnalysisReport';
// import { HelperDate } from '../../helpers/date';
import { ShowFormMessage } from '../../helpers/message';
import getErrors from '../../helpers/errors';
import { useGetAllDescriptiveAnalysisPjByOriginIdQuery } from '../../services/descriptiveAnalysisReportPj';
import DemographicPartnerTab from './TabGroup/DemographicPartner';
import CnaeDescriptionTab from './TabGroup/CnaeDescription';
import TaxRegimeTab from './TabGroup/TaxRegime';
import RegistrationStatusTab from './TabGroup/RegistrationStatus';
import EstimatedRevenueTab from './TabGroup/EstimatedRevenue';
import { CenterColumn, SpinContent } from '../SegmentationAnalysisTab/styles';

interface DescriptiveAnalysisModalProps {
  onCancel: () => void;
  visible: boolean;
  fileId?: string;
  fileTypeDataSource?: string;
}

const DescriptiveAnalysisModalPj = ({
  onCancel,
  visible,
  fileId,
  fileTypeDataSource,
}: DescriptiveAnalysisModalProps) => {
  const { tModalDescriptiveAnalysis } = useCustomTranslation(
    'modalDescriptiveAnalysis',
  );

  // const [dataSourceId] = useState<string>();
  const [, setDataSource] = useState<string>();

  const { data: descriptiveAnalysisData, isFetching } =
    useGetAllDescriptiveAnalysisPjByOriginIdQuery({
      origin: fileTypeDataSource,
      originId: fileId,
    });

  const [, setPersonasProfileData] = useState<{
    personas: Personas[];
  }>();

  useEffect(() => {
    getPersonasData().then(profile => setPersonasProfileData(profile));
  }, []);

  const handleCancel = () => {
    onCancel();
  };

  const [, configPostFileExport] = usePostFileExportMutation();
  const [, configUpdateSegmentationAnalysis] =
    useUpdateSegmentationAnalysisMutation();

  // const handleSubmit = async () => {
  //   await postFileExport({
  //     dataSourceId,
  //     dataSource,
  //     fileExportTypeId: 3,
  //   });
  // };

  useEffect(() => {
    setDataSource('FileImport');
    // setDataSourceId(descriptiveAnalysisData?.dataSourceId);
  }, [descriptiveAnalysisData]);

  // const handleUpdateAnalysis = useCallback(() => {
  //   if (fileTypeDataSource && fileId) {
  //     updateSegmentationAnalysis({
  //       originId: fileId,
  //       origin: fileTypeDataSource,
  //     });
  //   }
  // }, [fileId, fileTypeDataSource, updateSegmentationAnalysis]);

  const showMessagePostFileExport = useCallback(() => {
    const { isSuccess, isError, error } = configPostFileExport;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }

    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Relatório exportado com sucesso.',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
    if (isSuccess) {
      onCancel();
    }
  }, [configPostFileExport, onCancel]);

  useEffect(showMessagePostFileExport, [showMessagePostFileExport]);

  const showMessageUpdateAnalysis = useCallback(() => {
    const { isSuccess, isError, error } = configUpdateSegmentationAnalysis;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }
    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Análise atualizada com sucesso.',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
    if (isSuccess) {
      onCancel();
    }
  }, [configUpdateSegmentationAnalysis, onCancel]);

  useEffect(showMessageUpdateAnalysis, [showMessageUpdateAnalysis]);

  console.log('descriptiveAnalysisData: ', descriptiveAnalysisData);
  return (
    <>
      <Modal
        visible={visible}
        title={null}
        footer={null}
        closable={false}
        width="57rem"
      >
        <ModalImportDetails>
          {isFetching ? (
            <CenterColumn>
              <SpinContent>
                <Spin size="large" />
              </SpinContent>
            </CenterColumn>
          ) : (
            descriptiveAnalysisData && (
              <>
                <Sider1 />
                <Wrapper>
                  <PageHeader>
                    <Column01>
                      <ModalTabTitle weight="bold">
                        {tModalDescriptiveAnalysis('descriptive_analysis')}
                      </ModalTabTitle>
                      {/* <H4 weight="medium">{data.file_name}</H4> */}
                    </Column01>
                    <Column02>
                      {/* Botão de fechar */}
                      <Button
                        type="text"
                        icon={<CloseOutlined />}
                        onClick={handleCancel}
                      />
                    </Column02>
                  </PageHeader>

                  <Body1>
                    <TabGroup tabPosition="left" type="line" size="small">
                      <TabGroupPane
                        tab={tModalDescriptiveAnalysis('socio-demographic')}
                        key="0"
                      >
                        <DemographicPartnerTab
                          income={descriptiveAnalysisData?.demographicData}
                        />
                      </TabGroupPane>

                      <TabGroupPane
                        tab={tModalDescriptiveAnalysis('presumed_revenue')}
                        key="1"
                      >
                        <EstimatedRevenueTab
                          estimatedRevenue={
                            descriptiveAnalysisData?.estimatedRevenue
                          }
                          companySize={descriptiveAnalysisData?.companySize}
                        />
                      </TabGroupPane>

                      <TabGroupPane
                        tab={tModalDescriptiveAnalysis('cadastral_status')}
                        key="2"
                      >
                        <RegistrationStatusTab
                          registrationStatus={
                            descriptiveAnalysisData?.registrationStatus
                          }
                          meiOption={descriptiveAnalysisData?.meiOption}
                        />
                      </TabGroupPane>

                      <TabGroupPane
                        tab={tModalDescriptiveAnalysis('cnae_description')}
                        key="3"
                      >
                        <CnaeDescriptionTab
                          descricaoCnaeDistribution={
                            descriptiveAnalysisData?.cnaeDistributionData
                              ?.descricaoCnaeDistribution
                          }
                        />
                      </TabGroupPane>

                      <TabGroupPane
                        tab={tModalDescriptiveAnalysis('tax_regime')}
                        key="4"
                      >
                        <TaxRegimeTab
                          taxRegimeDistribution={
                            descriptiveAnalysisData?.taxRegimeData
                              ?.regimeTributarioDistribution
                          }
                        />
                      </TabGroupPane>
                    </TabGroup>
                  </Body1>

                  <ActionBar>
                    <Buttons>
                      {/* Comentários removidos para clareza */}
                    </Buttons>
                  </ActionBar>
                </Wrapper>
              </>
            )
          )}
        </ModalImportDetails>
      </Modal>
    </>
  );
};

export default DescriptiveAnalysisModalPj;
