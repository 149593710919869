import { Select } from 'antd';
import styled from 'styled-components';

import pattern from '../../../assets/pattern.svg';

export const WizardWelcome = styled.div`
  width: 32rem;
  height: 13rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Sider1 = styled.div`
  width: 2.5rem;
  height: 100%;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  background-color: ${props => props.theme.colors.primary[3]};
  //background-image: url(${pattern});
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  background-color: #ffffff;
  box-shadow: 1.75rem 0.5rem rgba(0, 0, 0, 0.05), 1rem rgba(0, 0, 0, 0.08),
    0.38rem -0.25rem rgba(0, 0, 0, 0.12);
`;
export const PageHeader = styled.div`
  justify-content: space-between;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
`;
export const PreviewForm = styled.div`
  width: 100%;
  height: 8.38rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
`;
export const PreviewFormItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;

  background-color: ${props => props.theme.colors.neutral.gray[2]};
`;
export const ActionBar = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
`;

export const SelectFieldType = styled(Select.OptGroup)``;
