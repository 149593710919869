import styled from 'styled-components';

import { Table } from 'antd';

import GenericCodeButton from '../Button';

export const Container = styled.div``;

export const TableContent = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
`;
export const TableGroup2 = styled(Table)`
  width: 100%;
`;

export const ActionTab = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
export const ReportButton = styled(GenericCodeButton)`
  width: fit-content;
  height: 2rem;
  border-radius: 0.12rem;
  font-weight: 500;
`;

export const ActionButton = styled(GenericCodeButton)`
  width: 2rem;
  height: 2rem;
  border-radius: 0.12rem;
`;

export const ContinueButton = styled(GenericCodeButton)`
  width: 5.94rem;
  height: 2rem;
  border-radius: 0.12rem;
  font-weight: 500;
`;
