import React, { memo, PropsWithChildren } from 'react';

import { ShowWhenGreaterThan } from '../../../../styles/breakpoints';

import { Container, Content, DownOutlined } from './styles';

interface DropDownProps extends PropsWithChildren {
  overlay: JSX.Element;
}

const Dropdown: React.FC<DropDownProps> = ({ children, overlay }) => {
  return (
    <Container overlay={overlay} trigger={['click']}>
      <Content>
        {children}
        <ShowWhenGreaterThan size="md">
          <DownOutlined />
        </ShowWhenGreaterThan>
      </Content>
    </Container>
  );
};

export default memo(Dropdown);
