import { memo } from 'react';
import { CheckboxProps } from 'antd';

import { Container } from './styles';

const FormCheckbox: React.FC<CheckboxProps> = ({ ...rest }) => (
  <Container {...rest} />
);

export default memo(FormCheckbox);
