import { Table as AntdTable } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1.5rem 2.93rem;
  background-color: #fbfbfb;
  position: relative;
  overflow-y: scroll;
`;

export const PageHeader = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0rem 0rem 5.5rem 0rem;
`;

export const Content = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  border: 0.06rem solid #dadada;
  box-sizing: border-box;
  align-items: center;
  background-color: #ffffff;
  padding: 16px;
`;

export const Table = styled(AntdTable)`
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  padding: 0rem 1.5rem 1.5rem 0rem;
  box-shadow: 1.75rem 0.5rem rgba(0, 0, 0, 0.03), 1rem rgba(0, 0, 0, 0.04),
    0.38rem -0.25rem rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0rem;
  bottom: 0rem;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
`;
