import { PotentialAudienceExportRoute } from '../../config/services-route';
import { TagTypes } from '../types';
import { deepDiveApi } from '../../config/apis/deep-dive-query';
import { providesList } from '../../helpers/provides';

const potentialAudienceExportApi = deepDiveApi.injectEndpoints({
  endpoints: builder => ({
    postPotentialExport: builder.mutation<any, any>({
      query: body => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          url: PotentialAudienceExportRoute.postPotentialExportFile,
          method: 'POST',
          headers: { CustomerId: authData.customer },
          body,
        };
      },
      invalidatesTags: [{ type: TagTypes.FileExport, id: 'LIST' }],
    }),
    getAllFilesExportsPotential: builder.query<any, any>({
      query: () => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: PotentialAudienceExportRoute.getAllDataFileExport,
        };
      },
      providesTags: response =>
        providesList(response?.data, TagTypes.FileImport),
      transformResponse: (response: any): any => {
        console.log('response: ', response);
        return {
          data: response,
          meta: {
            size: response['@odata.count'],
          },
        };
      },
    }),
    deleteFileExportPotential: builder.mutation<any, any>({
      query: id => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: `${PotentialAudienceExportRoute.deleteFileExportById}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: TagTypes.FileExport, id: 'LIST' }],
    }),
    getPresignedUrlFileExportPotential: builder.query<any, any>({
      query: ({ fileExportId }) => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: PotentialAudienceExportRoute.downloadExportFile,
          params: { fileExportId },
        };
      },
      providesTags: response =>
        providesList(response?.data, TagTypes.FileImport),
    }),
  }),
  // updateFileExport: builder.mutation<
  //   FileExportProps,
  //   Partial<FileExportProps>
  // >({
  //   query: ({ id, ...body }) => {
  //     return {
  //       url: URLs.combine(FileExportRoute.fileExport, `${id}`),
  //       method: 'PATCH',
  //       body,
  //     };
  //   },
  //   invalidatesTags: [{ type: TagTypes.FileExport, id: 'LIST' }],
  // }),
  // deleteFileExport: builder.mutation<FileExportProps, string>({
  //   query: id => {
  //     const authData = JSON.parse(localStorage?.getItem('auth') as string);

  //     return {
  //       headers: {
  //         CustomerId: authData.customer,
  //       },
  //       url: URLs.combine(FileExportRoute.fileExport, `${id}`),
  //       method: 'DELETE',
  //     };
  //   },
  //   invalidatesTags: [{ type: TagTypes.FileExport, id: 'LIST' }],
  // }),
});
export const {
  usePostPotentialExportMutation,
  useGetAllFilesExportsPotentialQuery,
  useDeleteFileExportPotentialMutation,
  useGetPresignedUrlFileExportPotentialQuery,
} = potentialAudienceExportApi;
